export default Object.freeze({
  PAGE_HEADER: 'Upload File',
  ADD_NEW_SOURCE: {
    BUTTON_TEXT: 'Add New Source',
  },
  SOURCE_TABLE: {
    DEFAULTS: {
      STARTING_PAGE: 1,
      PER_PAGE: 10,
      SORT_ORDER: 'asc',
      SORT_BY: 'name',
      PARAMS: {
        page: 1,
        per_page: 10,
        sort_order: 'asc',
        sort_by: 'name',
        type: 2,
      },
    },
    HEADERS: [
      { label: 'Source Identifier', db_key: 'name' },
      { label: 'License Issuer', db_key: 'license_issuer' },
      { label: 'License Type', db_key: 'license_type' },
      { label: 'State', db_key: 'state' },
      { label: 'Country Code', db_key: 'country' },
      { label: 'Source', db_key: 'source' },
      { label: 'Type', db_key: 'type' },
      { label: '' },
    ],
    NO_DATA_TEXT: 'No Records Found',
  },
  CREATE_SOURCE: {
    SOURCE_FORMAT_OPTIONS: [
      { value: 'PDF', label: 'PDF' },
      { value: 'CSV', label: 'CSV' },
      { value: 'XLS', label: 'XLS' },
      { value: 'WEB', label: 'WEB' },
    ],
    FORM_INIT: {
      licenseIssuer: '',
      licenseType: '',
      state: null,
      country: { label: 'US', value: { code: 'US', name: 'United States of America' } },
      sourceFormat: { value: 'PDF', label: 'PDF' },
      sourceModel: null,
      sourceType: 'link',
      sourceLink: '',
      uploadData: null,
      frequency: '',
    },
  },
  EDIT_SOURCE: {
    CHANGE_SOURCE_TYPE_ALERT: {
      TITLE: 'Are you sure you want to replace the existing source?',
      BODY: 'Only the source uploaded to the currently selected source type will be added, and the existing source will be replaced.',
      BUTTONS: {
        CONFIRM: 'Confirm',
        CANCEL: 'Cancel',
      },
    },
  },
  DELETE_SOURCE: {
    ALERT: {
      TITLE: 'Are you sure you want to delete this source?',
      BUTTONS: {
        CONFIRM: 'Confirm',
        CANCEL: 'Cancel',
      },
    },
  },
  FILE_UPLOADER_CONFIGS: {
    PDF: { 'application/pdf': [] },
    CSV: { 'text/csv': [] },
    XLS: {
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
    },
    WEB: { 'text/html': [] },
  },
});
