/* eslint-disable no-nested-ternary */

import React, { useContext } from 'react';
import moment from 'moment';
import { Container, Row, Col, Image, Form } from 'react-bootstrap';
import Select from 'react-select';
import isEmpty from 'lodash/isEmpty';
import uniqueId from 'lodash/uniqueId';

import DetailsListRow from './DetailsListRow';
import { StylePage } from '../../styles/Page.styled';
import StyleForm from '../../common/input/Form.styled';
import PageHeader from '../../common/pageHeader/PageHeader';
import Permissions from '../../auth/Permissions';
import Search from '../../common/search/Search';
import AppButton from '../../styles/ButtonLink.styled';
import PaginationDropdown from '../../common/input/PaginationDropdown';
import PaginationComponent from '../../common/pagination/Pagination';
import TableComponent from '../../common/table/Table';
import TableHeader from '../../common/table/TableHeader';
import SingleTableHeader from '../../common/table/SingleTableHeader';
import TableBody from '../../common/table/TableBody';
import WarningAlert from '../../common/alerts/WarningAlert';

import { ExecutionDetailsContext } from '../../../containers/dataValidation/executionDetails/ExecutionDetailsContext';
import DataValidationHelper from '../../../helpers/features/DataValidationHelper';
import StateHelper from '../../../helpers/stateHelper';

import FilterIcon from '../../../assets/images/filter.svg';
import LoadingIcon from '../../../assets/images/spinner.gif';

const DetailsListView = props => {
  const {
    isApproved,
    pageHeaderData,
    totalPages,
    totalRecords,
    recordsPerPage,
    currentPage,
    nextPage,
    searchParameter,
    setSearchParameter,
    selectedColumnNameFilter,
    handleColumnNameFilterChange,
    handleSearch,
    handleRecordsPerPage,
    handleTablePageNavigation,
    entryTableData,
    entryTableDataErrors,
    editedEntryTableData,
    isLoadingEntryTableData,
    handleNavigateBack,
    handleUpdate,
    handleApprove,
    handleEntryFieldUpdate,
    handleEntryTableFieldErrors,
    showEditedEntries,
    setShowEditedEntries,
    editedEntryData,
    updatingEntries,
    isApproving,
    showDiscardUnsavedChangesAlert,
    setShowDiscardUnsavedChangesAlert,
  } = useContext(ExecutionDetailsContext);

  const { BACK, UPDATE, APPROVE, REJECT, EDIT_TOGGLE_TEXT, DETAILS_TABLE, UNSAVED_CHANGES_ALERT } =
    DataValidationHelper.ENTRY_DETAILS_LIST;

  return (
    <Container>
      <StylePage>
        <Row>
          <Col md={4}>
            <PageHeader
              title={`${pageHeaderData.source_identifier} - ${moment(
                pageHeaderData.execution_date,
              ).format('Do MMMM YYYY')}`}
              subheader={`${totalRecords} Records`}
            />
          </Col>
          <Col md={8} className="d-flex justify-content-end">
            <Permissions permissionRole="ADMIN">
              <div style={{ marginRight: '8px' }}>
                <AppButton
                  className="popup cancel m-0"
                  onClick={() => {
                    if (isEmpty(editedEntryData)) handleNavigateBack();
                    else setShowDiscardUnsavedChangesAlert(true);
                  }}
                >
                  {BACK}
                </AppButton>
              </div>
              <div style={{ marginRight: '8px' }}>
                <AppButton
                  fill="true"
                  onClick={handleUpdate}
                  disabled={
                    isApproved ||
                    updatingEntries ||
                    isApproving ||
                    isLoadingEntryTableData ||
                    isEmpty(editedEntryData) ||
                    !isEmpty(entryTableDataErrors)
                  }
                >
                  {UPDATE}
                </AppButton>
              </div>
              <div style={{ marginRight: '8px' }}>
                <AppButton
                  fill="true"
                  onClick={() => handleApprove(true)}
                  disabled={
                    isApproved ||
                    updatingEntries ||
                    isApproving ||
                    isLoadingEntryTableData ||
                    !isEmpty(editedEntryData)
                  }
                >
                  {APPROVE}
                </AppButton>
              </div>
              <AppButton
                fill="true"
                onClick={() => handleApprove(false)}
                disabled={
                  isApproved ||
                  updatingEntries ||
                  isApproving ||
                  isLoadingEntryTableData ||
                  !isEmpty(editedEntryData)
                }
              >
                {REJECT}
              </AppButton>
            </Permissions>
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col md={3} className="mb-3">
            <StyleForm className="state-dropdown">
              <Image src={FilterIcon} />
              <Select
                className="single-select"
                options={DETAILS_TABLE.COLUMNS_FILTER_OPTIONS}
                onChange={value => {
                  handleColumnNameFilterChange(value);
                }}
                value={showEditedEntries ? null : selectedColumnNameFilter}
                placeholder="Column Name"
                isDisabled={showEditedEntries}
                isSearchable
                isClearable
                closeMenuOnSelect
                hideSelectedOptions
              />
            </StyleForm>
          </Col>
          <Col md={2} className="admin-sarch">
            <Permissions permissionRole="ADMIN">
              <Search
                customClass="admin-search"
                handleSearch={handleSearch}
                setSearchParameter={setSearchParameter}
                searchParameter={showEditedEntries ? '' : searchParameter}
                disabled={showEditedEntries || !selectedColumnNameFilter}
              />
            </Permissions>
          </Col>
          <Col md={3}>
            <StyleForm>
              <Form.Group className="custom-checkbox-green">
                <div className="d-flex justify-content-start">
                  <Form.Check
                    readOnly
                    type="checkbox"
                    id="disabledFieldsetCheck"
                    checked={showEditedEntries}
                    onChange={e => {
                      setShowEditedEntries(e.target.checked);
                    }}
                  />
                  <p style={{ marginLeft: '5px' }}>{EDIT_TOGGLE_TEXT}</p>
                </div>
              </Form.Group>
            </StyleForm>
          </Col>
          {!showEditedEntries && (
            <Col md={4} className="mb-3 d-flex justify-content-end">
              <PaginationDropdown
                selectedOption={recordsPerPage}
                handleRecordsPerPage={handleRecordsPerPage}
              />
              {totalPages > 1 && (
                <PaginationComponent
                  totalPages={totalPages}
                  currentPage={currentPage}
                  handleTablePageNavigation={handleTablePageNavigation}
                  nextPage={nextPage}
                />
              )}
            </Col>
          )}
        </Row>
        <TableComponent className="data-validation d-v-edit">
          <TableHeader>
            {DETAILS_TABLE.HEADERS.map(header => {
              return <SingleTableHeader title={header} key={uniqueId()} enableSorting={false} />;
            })}
          </TableHeader>
          <TableBody>
            {isLoadingEntryTableData ? (
              <tr className="loding-data">
                <td colSpan="24">
                  <Image src={LoadingIcon} sizes="50px" />
                </td>
              </tr>
            ) : (
              <TableBodyContents
                data={showEditedEntries ? editedEntryTableData : entryTableData}
                editedEntryFields={editedEntryData}
                entryFieldErrors={entryTableDataErrors}
                handleFieldUpdate={handleEntryFieldUpdate}
                handleFieldError={handleEntryTableFieldErrors}
                noDataText={DETAILS_TABLE.NO_DATA_TEXT}
              />
            )}
          </TableBody>
        </TableComponent>
        {showDiscardUnsavedChangesAlert && (
          <WarningAlert
            show={showDiscardUnsavedChangesAlert}
            title={UNSAVED_CHANGES_ALERT.TITLE}
            body={UNSAVED_CHANGES_ALERT.BODY}
            cancelButtonText={UNSAVED_CHANGES_ALERT.BUTTONS.CANCEL}
            confirmButtonText={UNSAVED_CHANGES_ALERT.BUTTONS.CONFIRM}
            onCancel={() => setShowDiscardUnsavedChangesAlert(false)}
            onConfirm={() => {
              setShowDiscardUnsavedChangesAlert(false);
              handleNavigateBack();
            }}
          />
        )}
      </StylePage>
    </Container>
  );
};

const TableBodyContents = ({
  data,
  editedEntryFields,
  entryFieldErrors,
  handleFieldUpdate,
  handleFieldError,
  noDataText,
}) => {
  return data?.length > 0 ? (
    data.map(item => (
      <DetailsListRow
        data={item}
        fieldErrors={entryFieldErrors[item.id]}
        handleFieldUpdate={handleFieldUpdate}
        handleFieldError={handleFieldError}
        editedEntryFields={editedEntryFields}
      />
    ))
  ) : (
    <tr className="without-data">
      <td colSpan="24">
        <p>{noDataText}</p>
      </td>
    </tr>
  );
};

export default DetailsListView;
