import { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { createNewLicense } from '../../apis/searchLicensesApi';
import store from '../../store/store';
import * as commonActions from '../../store/actions/common';

const LicensesContext = createContext();

function LicensesContextProvider({ children }) {
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [message, setMessage] = useState({
    type: null,
    message: null,
  });
  const [isAllMandotoryFeilds, setIsAllMandotoryFeilds] = useState(false);
  const [isFormCancel, setisFormCancel] = useState(false);

  const navigate = useNavigate();

  const navigateSuccessAdd = () => {
    navigate('/search-licenses');
  };

  /**
   * add new licenses form submit
   */
  const handleAddNewLicense = async body => {
    try {
      setIsFormSubmit(true);
      const response = await createNewLicense(body);
      if (response.success) {
        navigateSuccessAdd();
        store.dispatch(
          commonActions.showSuccessAlert({
            type: 'success',
            delay: 3000,
            message: response.message,
            body: 'License record added successfully.',
          }),
        );
      } else {
        setIsFormSubmit(false);
        setMessage({
          type: 'error',
          message: response.message,
        });
        setTimeout(() => {
          setMessage({
            type: null,
            message: null,
          });
        }, 3000);
      }
    } catch (e) {
      console.log(e);
      setMessage({
        type: 'error',
        message: e,
      });
      setTimeout(() => {
        setMessage({
          type: null,
          message: null,
        });
      }, 3000);
      console.error('Unable to authorize user from backend', e);
    }
  };

  const handleSubmitBtn = () => {
    setIsFormSubmit(false);
  };

  const openErrorAlert = () => {
    setIsAllMandotoryFeilds(true);
  };

  const closeErrorAlert = () => {
    setIsAllMandotoryFeilds(false);
  };

  const openCancelAlert = () => {
    setisFormCancel(true);
  };

  const closeCancelAlert = () => {
    setisFormCancel(false);
  };

  /* -------------------------------------------------------------------------- */
  /*                                Return values                               */
  /* -------------------------------------------------------------------------- */
  const value = useMemo(
    () => ({
      handleAddNewLicense,
      message,
      handleSubmitBtn,
    }),
    [handleAddNewLicense, message, handleSubmitBtn],
  );
  value.openErrorAlert = openErrorAlert;
  value.closeErrorAlert = closeErrorAlert;
  value.openCancelAlert = openCancelAlert;
  value.closeCancelAlert = closeCancelAlert;
  value.isFormSubmit = isFormSubmit;
  value.isAllMandotoryFeilds = isAllMandotoryFeilds;
  value.isFormCancel = isFormCancel;
  return <LicensesContext.Provider value={value}>{children}</LicensesContext.Provider>;
}

function useLicenses() {
  return useContext(LicensesContext);
}

LicensesContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { LicensesContextProvider, useLicenses };
