/* eslint-disable operator-linebreak */
/* eslint-disable no-confusing-arrow */
import React from 'react';
import { NavLink, matchRoutes, useLocation } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import isEmpty from 'lodash';

import Permissions from '../../auth/Permissions';
import routes from '../../../helpers/routes';
import CommonHelper from '../../../helpers/common';

function HeaderNavigation() {
  const activeStyles = { borderBottom: '2px solid', fontWeight: 700 };
  /*  const routeList = [{ path: `/${routes.AUTHENTICATED.ADMIN_ADD_NEW_LICENSES.PATH}` }];

  const { pathname } = useLocation(); */

  return (
    <>
      <Permissions permissionRole="ADMIN">
        <NavLink
          to="/users"
          style={({ isActive }) => (isActive ? { ...activeStyles, pointerEvents: 'none' } : {})}
        >
          {CommonHelper.NAVIGATIN_HEADER_TEXT.USERS}
        </NavLink>
        <NavLink
          to="/search-licenses"
          style={({ isActive }) =>
            isActive /* || isEmpty(matchRoutes(routeList, pathname))  */ ? activeStyles : {}
          }
        >
          {CommonHelper.NAVIGATIN_HEADER_TEXT.LICENSES}
        </NavLink>
        <NavDropdown
          title={CommonHelper.NAVIGATIN_HEADER_TEXT.REPORTS}
          id="basic-nav-dropdown"
          className="p-0  sub-menu"
        >
          <NavDropdown.Item href={`/${routes.AUTHENTICATED.USER_LOGINS_REPORT.PATH}`}>
            {CommonHelper.REPORT_MENU_TEXT.USERS}
          </NavDropdown.Item>
          <NavDropdown.Item href={`/${routes.AUTHENTICATED.LICENSES_UPDATE_LOGS.PATH}`}>
            {CommonHelper.REPORT_MENU_TEXT.LICENSES}
          </NavDropdown.Item>
          <NavDropdown.Item href={`/${routes.AUTHENTICATED.WEEKLY_LICENSE_REPORT.PATH}`}>
            {CommonHelper.REPORT_MENU_TEXT.WEEKLY_LICENSE}
          </NavDropdown.Item>
        </NavDropdown>
        <NavLink
          to={routes.AUTHENTICATED.SOURCES.PATH}
          style={({ isActive }) => (isActive ? { ...activeStyles, pointerEvents: 'none' } : {})}
        >
          {CommonHelper.NAVIGATIN_HEADER_TEXT.SOURCES}
        </NavLink>
        <NavLink
          to={routes.AUTHENTICATED.DATA_VALIDATION.PATH}
          style={({ isActive }) => (isActive ? { ...activeStyles, pointerEvents: 'none' } : {})}
        >
          {CommonHelper.NAVIGATIN_HEADER_TEXT.DATA_VALIDATION}
        </NavLink>
        <NavLink
          to="/settings"
          style={({ isActive }) => (isActive ? { ...activeStyles, pointerEvents: 'none' } : {})}
        >
          {CommonHelper.NAVIGATIN_HEADER_TEXT.SETTINGS}
        </NavLink>
      </Permissions>
      <Permissions permissionRole="STANDARD">
        <NavLink to="/search-licenses" style={({ isActive }) => (isActive ? activeStyles : {})}>
          {CommonHelper.NAVIGATIN_HEADER_TEXT.SEARCH_LICENSES}
        </NavLink>
        <NavLink to="/data-dictionary" style={({ isActive }) => (isActive ? activeStyles : {})}>
          {CommonHelper.NAVIGATIN_HEADER_TEXT.DATA_DICTONARY}
        </NavLink>
        <NavLink to="/faq" style={({ isActive }) => (isActive ? activeStyles : {})}>
          {CommonHelper.NAVIGATIN_HEADER_TEXT.FAQ}
        </NavLink>
      </Permissions>
    </>
  );
}
export default HeaderNavigation;
