import React, { useContext } from 'react';
import { Button, Image } from 'react-bootstrap';
import size from 'lodash/size';

import SearchLicensesHelper from '../../../helpers/features/SearchLicensesHelper';
import { SearchLicencesContext } from '../../../containers/searchLicenses/SearchLicensesContext';
import BulDelete from '../../../assets/images/bulk-delete-icon.svg';
import BulkEdit from '../../../assets/images/bulk-edit.svg';

function BulkRecordTableHeader() {
  const {
    setIsDeleteWarningPopUpOpen,
    setIsBulkDelete,
    setIsBulkEditModalOpen,
    bulkIdList,
    isSelectAll,
    reordsPerPage,
  } = useContext(SearchLicencesContext);
  return (
    <>
      {`${size(bulkIdList)} Selected`}
      <Button className="link-btn" onClick={() => setIsBulkEditModalOpen(true)}>
        <Image src={BulkEdit} />
        {SearchLicensesHelper.BULK_OPERATIONS_HEADER_TEXT_EDIT}
      </Button>
      <Button
        className="link-btn"
        onClick={() => {
          setIsDeleteWarningPopUpOpen(true);
          setIsBulkDelete(true);
        }}
      >
        <Image src={BulDelete} />
        {SearchLicensesHelper.BULK_OPERATIONS_HEADER_TEXT_DELETE}
      </Button>
    </>
  );
}

export default BulkRecordTableHeader;
