/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */

import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

import TableRow from '../../common/table/TableRow';

import { ExecutionHistoryContext } from '../../../containers/dataValidation/executionHistory/ExecutionHistoryContext';
import DataValidationHelper from '../../../helpers/features/DataValidationHelper';

import Download from '../../../assets/images/download-icon.svg';
import View from '../../../assets/images/view.svg';

const HistoryListRow = ({ data }) => {
  const { id, upload_id, source_name, execution_date, state, status, approved_date, approved_by } =
    data;

  const { handleDownloadExecution } = useContext(ExecutionHistoryContext);

  const navigate = useNavigate();

  return (
    <TableRow>
      <td>
        <span>{source_name || '-'}</span>
      </td>
      <td>
        <span>{execution_date || '-'}</span>
      </td>
      <td>
        <span>{state || '-'}</span>
      </td>
      <td>
        <span>{status || '-'}</span>
      </td>
      <td>
        <span>{approved_date || '-'}</span>
      </td>
      <td>
        <span>{approved_by || '-'}</span>
      </td>
      <td>
        <div className="d-flex">
          <button
            type="button"
            className="table-action-btn outline-btn"
            onClick={() => {
              navigate(`/data-validation/${upload_id}/${id}`);
            }}
          >
            <Image src={View} />
          </button>
          <button
            type="button"
            className="table-action-btn outline-btn"
            onClick={() => {
              handleDownloadExecution(source_name, id);
            }}
          >
            <Image src={Download} />
          </button>
        </div>
      </td>
    </TableRow>
  );
};

HistoryListRow.propTypes = {
  data: PropTypes.object.isRequired,
};

export default HistoryListRow;
