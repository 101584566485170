import React from 'react';

import ReportTable from '../../../components/reports/ReportsTableBody';
import ReportProvider from '../ReportsContext';

function LicenseUpdateLogs(props) {
  return (
    <ReportProvider componentProps={props}>
      <ReportTable reportType={1} />
    </ReportProvider>
  );
}

export default LicenseUpdateLogs;
