/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';
import { Image } from 'react-bootstrap';
import StyleEntryCard from './EntryCard.styled';

const EntryCountCard = props => {
  const { title, count, icon, handleClick, isActive } = props;
  return (
    <StyleEntryCard>
      <div
        className={`entry-count-card gap-3 d-flex align-items-center justify-content-center ${
          isActive ? 'active' : ''
        }`}
        onClick={e => {
          e.preventDefault();
          if (!isActive) handleClick();
        }}
      >
        <Image src={icon} alt="Card Icon" />
        <div>
          <p className="name m-0">{title}</p>
          <h1 className="count m-0">{count}</h1>
        </div>
      </div>
    </StyleEntryCard>
  );
};

export default EntryCountCard;
