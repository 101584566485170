import React, { useContext } from 'react';

import CreateSourceForm from './CreateSourceForm';
import CommonModal from '../../common/modal/CommonModal';

import { SourcesContext } from '../../../containers/sources/SourcesContext';
import SourcesHelper from '../../../helpers/features/SourcesHelper';

const CreateSourceModal = () => {
  const { showCreateSourceModal, setShowCreateSourceModal } = useContext(SourcesContext);

  if (!showCreateSourceModal.show) return null;

  return (
    <CommonModal
      className=""
      size="lg"
      title={showCreateSourceModal.isCreate ? 'Add New Source' : 'Edit Source'}
      handleClose={() => {
        setShowCreateSourceModal({ data: null, isCreate: true, show: false });
      }}
    >
      <CreateSourceForm />
    </CommonModal>
  );
};

export default CreateSourceModal;
