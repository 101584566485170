/* eslint-disable arrow-body-style */
/* eslint-disable operator-linebreak */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form, Image, Button } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';

import InlineEditModal from './InlineEditModal';
import TableRow from '../../common/table/TableRow';
import SearchLicenseHelper from '../../../helpers/features/SearchLicensesHelper';
import StateHelper from '../../../helpers/stateHelper';
import { trimString } from '../../../helpers/commonHelper';
import { SearchLicencesContext } from '../../../containers/searchLicenses/SearchLicensesContext';
// import Save from '../../../assets/images/done.svg';
import Close from '../../../assets/images/btn-close.svg';
import InputEdit from '../../../assets/images/input-edit.svg';
import 'react-datepicker/dist/react-datepicker.css';

function SearchLicenseRow({ data }) {
  const { setIsInlineEditing, setEditedValues, setEditingRowIndex } =
    useContext(SearchLicencesContext);

  // eslint-disable-next-line no-unused-vars
  const [modalParameters, setModalParameters] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openEditModal = (
    title,
    charLimit,
    onChangeInput,
    type,
    rowdata,
    feild,
    inputType,
    minDate,
    maxDate,
    options,
    placeholder,
  ) => {
    setModalParameters({
      title,
      charLimit,
      onChangeInput,
      type,
      rowdata,
      feild,
      inputType,
      minDate,
      maxDate,
      options,
      placeholder,
    });
    setIsModalOpen(true);
  };

  return (
    <>
      {isModalOpen && (
        <InlineEditModal
          title={modalParameters.title}
          label={modalParameters.label}
          charLimit={modalParameters.charLimit}
          onChangeInput={modalParameters.onChangeInput}
          type={modalParameters.type}
          closeModal={() => {
            setIsModalOpen(false);
            setModalParameters({});
          }}
          data={modalParameters.rowdata}
          feild={modalParameters.feild}
          minimumDate={modalParameters.minDate}
          maximumDate={modalParameters.maxDate}
          inputType={modalParameters.inputType}
          options={modalParameters.options}
          placeholder={modalParameters.placeholder}
        />
      )}
      <TableRow>
        <td>
          <Form.Group>
            <Form.Check type="checkbox" id="disabledFieldsetCheck" disabled />
          </Form.Group>
        </td>
        <td>
          <Form.Group className="note-td">
            <Button
              type="button"
              className="link-btn"
              onClick={() =>
                openEditModal(
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[0].label,
                  100,
                  setEditedValues,
                  2,
                  data,
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[0].db_key,
                )
              }
            >
              <Image src={InputEdit} />
            </Button>
            <Form.Control
              disabled
              className={data?.meta?.license_number || data?.meta?.temp_id ? 'isPermanent' : ''}
              name="license_number"
              type="text"
              value={trimString(
                25,
                data.license_number || data?.temp_id || SearchLicenseHelper.NO_VALUES_EDIT,
              )}
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group className="note-td">
            <Button
              type="button"
              className="link-btn"
              onClick={() =>
                openEditModal(
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[1].label,
                  100,
                  setEditedValues,
                  2,
                  data,
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[1].db_key,
                )
              }
            >
              <Image src={InputEdit} />
            </Button>
            <Form.Control
              disabled
              className={data?.meta?.legal_name ? 'isPermanent' : ''}
              type="text"
              value={trimString(5, data.legal_name) || SearchLicenseHelper.NO_VALUES_EDIT}
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group className="note-td">
            <Button
              type="button"
              className="link-btn"
              onClick={() =>
                openEditModal(
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[2].label,
                  100,
                  setEditedValues,
                  2,
                  data,
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[2].db_key,
                )
              }
            >
              <Image src={InputEdit} />
            </Button>
            <Form.Control
              disabled
              className={data?.meta?.dba_name ? 'isPermanent' : ''}
              type="text"
              value={trimString(20, data.dba_name) || SearchLicenseHelper.NO_VALUES_EDIT}
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group className="note-td">
            <Button
              type="button"
              className="link-btn"
              onClick={() =>
                openEditModal(
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[3].label,
                  100,
                  setEditedValues,
                  2,
                  data,
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[3].db_key,
                )
              }
            >
              <Image src={InputEdit} />
            </Button>
            <Form.Control
              disabled
              className={data?.meta?.street ? 'isPermanent' : ''}
              type="text"
              value={trimString(10, data.street) || SearchLicenseHelper.NO_VALUES_EDIT}
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group className="note-td">
            <Button
              type="button"
              className="link-btn"
              onClick={() =>
                openEditModal(
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[4].label,
                  100,
                  setEditedValues,
                  2,
                  data,
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[4].db_key,
                )
              }
            >
              <Image src={InputEdit} />
            </Button>
            <Form.Control
              disabled
              className={data?.meta?.city ? 'isPermanent' : ''}
              type="text"
              value={trimString(15, data.city) || SearchLicenseHelper.NO_VALUES_EDIT}
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group className="note-td">
            <Button
              type="button"
              className="link-btn"
              onClick={() =>
                openEditModal(
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[5].label,
                  2,
                  setEditedValues,
                  2,
                  data,
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[5].db_key,
                )
              }
            >
              <Image src={InputEdit} />
            </Button>
            <Form.Control
              disabled
              className={data?.meta?.state ? 'isPermanent' : ''}
              type="text"
              value={data.state || SearchLicenseHelper.NO_VALUES_EDIT}
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group className="note-td">
            <Button
              type="button"
              className="link-btn"
              onClick={() =>
                openEditModal(
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[6].label,
                  100,
                  setEditedValues,
                  5,
                  data,
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[6].db_key,
                  '',
                  false,
                  false,
                  StateHelper.STATE_AS_LABEL,
                )
              }
            >
              <Image src={InputEdit} />
            </Button>
            <Form.Control
              disabled
              className={data?.meta?.state_name ? 'isPermanent' : ''}
              type="text"
              value={data.state_name || SearchLicenseHelper.NO_VALUES_EDIT}
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group className="note-td">
            <Button
              type="button"
              className="link-btn"
              onClick={() =>
                openEditModal(
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[7].label,
                  10,
                  setEditedValues,
                  2,
                  data,
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[7].db_key,
                )
              }
            >
              <Image src={InputEdit} />
            </Button>
            <Form.Control
              disabled
              className={data?.meta?.zip ? 'isPermanent' : ''}
              type="number"
              value={data.zip || SearchLicenseHelper.NO_VALUES_EDIT}
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group className="note-td">
            <Button
              type="button"
              className="link-btn"
              onClick={() =>
                openEditModal(
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[8].label,
                  100,
                  setEditedValues,
                  5,
                  data,
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[8].db_key,
                  '',
                  false,
                  false,
                  SearchLicenseHelper.AVAILABLE_COUNTRIES,
                )
              }
            >
              <Image src={InputEdit} />
            </Button>
            <Form.Control
              disabled
              className={data?.meta?.country_name ? 'isPermanent' : ''}
              type="text"
              value={trimString(10, data.country_name) || SearchLicenseHelper.NO_VALUES_EDIT}
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group className="note-td">
            <Button
              type="button"
              className="link-btn"
              onClick={() =>
                openEditModal(
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[9].label,
                  100,
                  setEditedValues,
                  5,
                  data,
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[9].db_key,
                  '',
                  false,
                  false,
                  SearchLicenseHelper.COUNTRY_CODES,
                )
              }
            >
              <Image src={InputEdit} />
            </Button>
            <Form.Control
              disabled
              className={data?.meta?.country_code ? 'isPermanent' : ''}
              type="text"
              value={trimString(10, data.country_code) || SearchLicenseHelper.NO_VALUES_EDIT}
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group className="note-td">
            <Button
              type="button"
              className="link-btn"
              onClick={() =>
                openEditModal(
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[10].label,
                  100,
                  setEditedValues,
                  2,
                  data,
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[10].db_key,
                )
              }
            >
              <Image src={InputEdit} />
            </Button>
            <Form.Control
              disabled
              className={data?.meta?.license_issuer ? 'isPermanent' : ''}
              type="text"
              value={trimString(10, data.license_issuer) || SearchLicenseHelper.NO_VALUES_EDIT}
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group className="note-td">
            <Button
              type="button"
              className="link-btn"
              onClick={() =>
                openEditModal(
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[11].label,
                  100,
                  setEditedValues,
                  2,
                  data,
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[11].db_key,
                )
              }
            >
              <Image src={InputEdit} />
            </Button>
            <Form.Control
              disabled
              className={data?.meta?.license_type ? 'isPermanent' : ''}
              type="text"
              value={trimString(10, data.license_type) || SearchLicenseHelper.NO_VALUES_EDIT}
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group className="note-td" controlId="">
            <Button
              type="button"
              className="link-btn"
              onClick={() =>
                openEditModal(
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[12].label,
                  500,
                  setEditedValues,
                  1,
                  data,
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[12].db_key,
                )
              }
            >
              <Image src={InputEdit} />
            </Button>
            <Form.Control
              type="text"
              className={data?.meta?.license_details ? 'isPermanent' : ''}
              value={trimString(10, data.license_details) || SearchLicenseHelper.NO_VALUES_EDIT}
              disabled
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group className="note-td" controlId="">
            <Button
              type="button"
              className="link-btn"
              onClick={() =>
                openEditModal(
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[13].label,
                  100,
                  setEditedValues,
                  2,
                  data,
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[13].db_key,
                )
              }
            >
              <Image src={InputEdit} />
            </Button>
            <Form.Control
              type="text"
              className={data?.meta?.license_designation ? 'isPermanent' : ''}
              value={trimString(10, data.license_designation) || SearchLicenseHelper.NO_VALUES_EDIT}
              disabled
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group className="note-td">
            <Button
              type="button"
              className="link-btn"
              onClick={() =>
                openEditModal(
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[14].label,
                  100,
                  setEditedValues,
                  4,
                  data,
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[14].db_key,
                  '',
                  false,
                  true,
                )
              }
            >
              <Image src={InputEdit} />
            </Button>
            <Form.Control
              disabled
              type="text"
              value={
                data.license_issue_date
                  ? moment(data.license_issue_date).format('MM/DD/YYYY')
                  : SearchLicenseHelper.NO_VALUES_EDIT
              }
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group className="note-td">
            <Button
              type="button"
              className="link-btn"
              onClick={() =>
                openEditModal(
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[15].label,
                  100,
                  setEditedValues,
                  3,
                  data,
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[15].db_key,
                  '',
                  true,
                  false,
                )
              }
            >
              <Image src={InputEdit} />
            </Button>
            <Form.Control
              disabled
              type="text"
              className={data?.meta?.license_expiration_date ? 'isPermanent' : ''}
              value={
                data.license_expiration_date
                  ? moment(data.license_expiration_date).format('MM/DD/YYYY')
                  : SearchLicenseHelper.NO_VALUES_EDIT
              }
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group className="note-td">
            <Button
              type="button"
              className="link-btn"
              onClick={() =>
                openEditModal(
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[16].label,
                  100,
                  setEditedValues,
                  2,
                  data,
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[16].db_key,
                )
              }
            >
              <Image src={InputEdit} />
            </Button>
            <Form.Control
              disabled
              className={data?.meta?.county_name ? 'isPermanent' : ''}
              type="text"
              value={trimString(10, data.county_name) || SearchLicenseHelper.NO_VALUES_EDIT}
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group className="note-td">
            <Button
              type="button"
              className="link-btn"
              onClick={() =>
                openEditModal(
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[17].label,
                  100,
                  setEditedValues,
                  2,
                  data,
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[17].db_key,
                )
              }
            >
              <Image src={InputEdit} />
            </Button>
            <Form.Control
              disabled
              className={data?.meta?.parent_name ? 'isPermanent' : ''}
              type="text"
              value={trimString(10, data.parent_name) || SearchLicenseHelper.NO_VALUES_EDIT}
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group className="note-td">
            <Button
              type="button"
              className="link-btn"
              onClick={() =>
                openEditModal(
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[18].label,
                  100,
                  setEditedValues,
                  2,
                  data,
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[18].db_key,
                )
              }
            >
              <Image src={InputEdit} />
            </Button>
            <Form.Control
              disabled
              className={data?.meta?.company_website ? 'isPermanent' : ''}
              type="text"
              value={trimString(15, data.company_website) || SearchLicenseHelper.NO_VALUES_EDIT}
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group className="note-td">
            <Button
              type="button"
              className="link-btn"
              onClick={() =>
                openEditModal(
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[19].label,
                  100,
                  setEditedValues,
                  2,
                  data,
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[19].db_key,
                )
              }
            >
              <Image src={InputEdit} />
            </Button>
            <Form.Control
              disabled
              className={data?.meta?.company_contact_name ? 'isPermanent' : ''}
              type="text"
              value={
                trimString(15, data.company_contact_name) || SearchLicenseHelper.NO_VALUES_EDIT
              }
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group className="note-td">
            <Button
              type="button"
              className="link-btn"
              onClick={() =>
                openEditModal(
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[20].label,
                  100,
                  setEditedValues,
                  2,
                  data,
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[20].db_key,
                )
              }
            >
              <Image src={InputEdit} />
            </Button>
            <Form.Control
              disabled
              className={data?.meta?.company_email ? 'isPermanent' : ''}
              type="email"
              value={trimString(12, data.company_email) || SearchLicenseHelper.NO_VALUES_EDIT}
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group className="note-td">
            <Button
              type="button"
              className="link-btn"
              onClick={() =>
                openEditModal(
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[21].label,
                  10,
                  setEditedValues,
                  2,
                  data,
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[21].db_key,
                  'number',
                  false,
                  false,
                )
              }
            >
              <Image src={InputEdit} />
            </Button>
            <Form.Control
              disabled
              className={data?.meta?.company_phone ? 'isPermanent' : ''}
              type="number"
              value={data.company_phone || SearchLicenseHelper.NO_VALUES_EDIT}
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group className="note-td" controlId="">
            <Button
              type="button"
              className="link-btn"
              onClick={() =>
                openEditModal(
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[22].label,
                  2000,
                  setEditedValues,
                  1,
                  data,
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[22].db_key,
                )
              }
            >
              <Image src={InputEdit} />
            </Button>
            <Form.Control
              type="text"
              className={data?.meta?.note ? 'isPermanent' : ''}
              disabled
              value={trimString(12, data.note) || SearchLicenseHelper.NO_VALUES_EDIT}
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group>
            <Form.Control
              type="text"
              className={
                data?.meta?.last_update || data?.meta?.created_at || data?.meta?.updated_at
                  ? 'isPermanent'
                  : ''
              }
              value={
                data.last_update || data.created_at || data.updated_at
                  ? moment(data.last_update || data.created_at || data.updated_at).format(
                      'MM/DD/YYYY',
                    )
                  : SearchLicenseHelper.NO_VALUES_EDIT
              }
              disabled
            />
          </Form.Group>
        </td>
        <td>
          <Form.Group className="note-td" controlId="">
            <Button
              type="button"
              className="link-btn"
              onClick={() =>
                openEditModal(
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[23].label,
                  100,
                  setEditedValues,
                  2,
                  data,
                  SearchLicenseHelper.HEMP_LICENSES_ADMIN_EDITABLE_FEILDS[23].db_key,
                )
              }
            >
              {isEmpty(data.license_expiration_date) && <Image src={InputEdit} />}
            </Button>
            <Form.Control
              style={
                !isEmpty(data.license_expiration_date)
                  ? { background: '#e9ecef' }
                  : { background: '#fff' }
              }
              type="text"
              className={data?.meta?.status ? 'isPermanent' : ''}
              disabled
              value={trimString(15, data.status) || SearchLicenseHelper.NO_VALUES_EDIT}
            />
          </Form.Group>
        </td>
        <td>
          <div className="d-flex">
            <button
              type="button"
              className="table-action-btn outline-btn"
              onClick={() => {
                setIsInlineEditing(false);
                setEditingRowIndex(-1);
              }}
            >
              <Image src={Close} />
            </button>
          </div>
        </td>
      </TableRow>
    </>
  );
}

SearchLicenseRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    license_number: PropTypes.string,
    legal_name: PropTypes.string,
    dba_name: PropTypes.string,
    street: PropTypes.string,
    country_name: PropTypes.string,
    license_issuer: PropTypes.string,
    license_details: PropTypes.string,
    license_type: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    status: PropTypes.string,
    title8: PropTypes.string,
    license_issue_date: PropTypes.string,
    license_expiration_date: PropTypes.string,
    parent_name: PropTypes.string,
    company_contact_name: PropTypes.string,
    company_email: PropTypes.string,
    company_website: PropTypes.string,
    company_phone: PropTypes.string,
    county_name: PropTypes.string,
    last_update: PropTypes.string,
    user_updated_at: PropTypes.string,
    note: PropTypes.string,
    state_name: PropTypes.string,
    license_designation: PropTypes.string,
    country_code: PropTypes.string,
    temp_id: PropTypes.string,
    updated_at: PropTypes.string,
    created_at: PropTypes.string,
  }).isRequired,
};

export default SearchLicenseRow;
