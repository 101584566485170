/* eslint-disable max-len */
const DictonaryHelper = Object.freeze({
  TERMS_LIST: [
    {
      term: 'License Number',
      definition:
        'The License Number is the unique identifier for a particular license. Typically license numbers are assigned by the licensing agency. In some instances, the agency does not provide a license number. In those cases, we assign a temporary license number in the structure of “TMP-12345678”. The temporary license number will be replaced when a permanent license number is obtained. Occasionally, the licensing authority will assign the same license number for multiple license types under the same business. When this happens, we add “-01”, “-02” at the end of the license number to maintain database integrity.',
      id: 1,
    },
    {
      term: 'License Issuer',
      definition:
        'The License Issuer is the government agency responsible for overseeing a given jurisdiction’s hemp program and issuing hemp licenses.',
      id: 2,
    },
    {
      term: 'License Type',
      definition: 'The License Type is the specific activity for which the licensee is authorized.',
      id: 3,
    },
    {
      term: 'License Details',
      definition:
        'The License Details section includes any additional information about the license that is provided by the agency.',
      id: 4,
    },
    {
      term: 'License Status',
      definition: 'The License Status denotes the current validity of the license.',
      id: 5,
    },
    {
      term: 'Location Address',
      definition:
        'The Location Address is the address of the licensed premises. When available, we include street, city, state, zip code, county, and country.',
      id: 6,
    },
    {
      term: 'Legal Name',
      definition:
        'The Legal Name is the name of the legal entity, usually the incorporated business that obtained the license.',
      id: 7,
    },
    {
      term: 'DBA Name',
      definition:
        'The DBA (“doing business as”) name is the public-facing name that the licensee operates under. The DBA name is often, but not always, the same as the legal name.',
      id: 8,
    },
    {
      term: 'Company Details',
      definition:
        'When available, we provide information on the licensed company such as point of contact, phone number, email address, and website.',
      id: 9,
    },
    {
      term: 'Last Verified',
      definition:
        'The Verified date is the date that Simplifya most recently verified the license and its details.',
      id: 10,
    },
    {
      term: 'Notes',
      definition:
        'The Notes section includes any additional information about the license that Simplifya wants to share with you!',
      id: 11,
    },
  ],
});

export default DictonaryHelper;
