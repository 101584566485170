import React, { useState } from 'react';
import { Form, Button, Image } from 'react-bootstrap';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import { SingleUserFieldEditModal } from './SingleUserFieldEditModal';
import TableRow from '../common/table/TableRow';
import { UsersHelper } from '../../helpers/usersHelper';
import { trimString, toTitleCase } from '../../helpers/commonHelper';
import InputEdit from '../../assets/images/input-edit.svg';
import Close from '../../assets/images/btn-close.svg';

const RenderUneditableColumns = ({ column, row, setSelectedRowForEdit }) => {
  if (column.name === 'lastLogin') {
    return (
      <td>
        {!isEmpty(row.last_login)
          ? moment(new Date(row.last_login).toLocaleDateString('en-US')).format('MM/DD/YYYY')
          : '-'}
      </td>
    );
  }
  if (column.name === 'resetPassword') {
    return (
      <td>
        <strong className="app-link-green">Reset Password</strong>
      </td>
    );
  }
  if (column.name === 'actions') {
    return (
      <td>
        <div className="d-flex">
          <button
            type="button"
            className="table-action-btn outline-btn"
            onClick={() => {
              setSelectedRowForEdit(null);
            }}
          >
            <Image src={Close} />
          </button>
        </div>
      </td>
    );
  }
  return null;
};

export const InlineRowEditor = ({
  rowData,
  setSelectedRowForEdit,
  handleInlineEdit,
  isInlineEditModalOpen,
  setInlineEditModalOpen,
}) => {
  const [editFieldData, setEditFieldData] = useState(null);

  const formatColumnData = (dataName, row) => {
    const stringData = ['email', 'company'];
    if (dataName === 'first_name') {
      return trimString(8, `${row.first_name} ${row.last_name}`);
    }
    if (stringData.includes(dataName)) {
      return trimString(11, rowData[dataName]);
    }
    if (dataName === 'status') {
      return rowData[dataName] ? 'Active' : 'Inactive';
    }
    if (dataName === 'role') {
      return toTitleCase(rowData[dataName]);
    }
    return rowData[dataName];
  };
  return (
    <>
      <TableRow>
        {UsersHelper.INLINE_EDITOR_COLUMNS.map(column => {
          if (!column.isEditable) {
            return (
              <RenderUneditableColumns
                column={column}
                row={rowData}
                setSelectedRowForEdit={setSelectedRowForEdit}
              />
            );
          }
          return (
            <td>
              <Form.Group className="note-td">
                <Button
                  type="button"
                  className="link-btn"
                  onClick={() => {
                    setInlineEditModalOpen(true);
                    setEditFieldData({
                      title: column.title,
                      fieldData:
                        column.name === 'name'
                          ? { first_name: rowData.first_name, last_name: rowData.last_name }
                          : rowData[column.dataName],
                      fieldType: column.type,
                      dataName: column.dataName,
                      maxLength: column.maxlength,
                    });
                  }}
                >
                  <Image src={InputEdit} />
                </Button>
                <Form.Control
                  disabled
                  name="name"
                  type="text"
                  value={formatColumnData(column.dataName, rowData)}
                />
              </Form.Group>
            </td>
          );
        })}
      </TableRow>
      {isInlineEditModalOpen && (
        <SingleUserFieldEditModal
          setInlineEditModalOpen={setInlineEditModalOpen}
          editFieldData={editFieldData}
          setEditFieldData={setEditFieldData}
          handleInlineEdit={handleInlineEdit}
        />
      )}
    </>
  );
};
