import { createSlice } from '@reduxjs/toolkit';

import DataValidationHelper from '../../helpers/features/DataValidationHelper';
import reducerTypes from '../reducerTypes';

const initialState = {
  dataValidationTableFilters: DataValidationHelper.HISTORY_LIST.HISTORY_TABLE.DEFAULTS.PARAMS,
  entryDetailsTableFilters: DataValidationHelper.ENTRY_DETAILS_LIST.DETAILS_TABLE.DEFAULTS.PARAMS,
};

export const dataValidationSlice = createSlice({
  name: reducerTypes.dataValidation,
  initialState,
  reducers: {
    SET_DATA_VALIDATION_TABLE_FILTERS: (state, data) => {
      state.dataValidationTableFilters = data.payload;
    },
    SET_ENTRY_DETAILS_TABLE_FILTERS: (state, data) => {
      state.entryDetailsTableFilters = data.payload;
    },
  },
});

export const { SET_DATA_VALIDATION_TABLE_FILTERS, SET_ENTRY_DETAILS_TABLE_FILTERS } =
  dataValidationSlice.actions;

export default dataValidationSlice.reducer;
