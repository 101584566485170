/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import uniqueId from 'lodash/uniqueId';
import isNull from 'lodash/isNull';

import { TextInput } from './listInputs/TextInput';
import DateInput from './listInputs/DateInput';
// import SelectInput from './listInputs/SelectInput';
import TableRow from '../../common/table/TableRow';

import { ExecutionDetailsContext } from '../../../containers/dataValidation/executionDetails/ExecutionDetailsContext';
import StyleForm from '../../common/input/Form.styled';
// import StateHelper from '../../../helpers/stateHelper';

import CalendarIcon from '../../../assets/images/calender-icon.svg';

const DetailsListRow = ({
  data,
  fieldErrors,
  handleFieldUpdate,
  handleFieldError,
  editedEntryFields,
}) => {
  const {
    id,
    license_number,
    license_type,
    license_issuer,
    license_issue_date,
    license_expiration_date,
    status,
    legal_name,
    dba_name,
    company_contact_name,
    company_email,
    company_phone,
    street,
    city,
    county_name,
    state_name,
    state,
    country_name,
    country_code,
    zip,
    company_website,
    note,
    license_details,
  } = data;

  const { isApproved, initialEntryTableData, editedEntriesInitData } =
    useContext(ExecutionDetailsContext);

  const handleFieldValueChange = updatedValue => {
    const entryData = initialEntryTableData.filter(entry => entry.id == id)[0];

    handleFieldUpdate(id, entryData, updatedValue);
  };

  const getInitialDateValue = key => {
    const entryData = editedEntriesInitData.filter(entry => entry.id == id)[0];

    if (entryData?.[key]) return entryData?.[key];
    return null;
  };

  const validateField = fieldData => {
    switch (fieldData.key) {
      case 'license_details':
        if (fieldData?.value?.length > 500) {
          handleFieldError(id, {
            key: 'license_details',
            value: 'You have exceeded the character limit',
          });
        } else {
          handleFieldError(id, { key: 'license_details', value: '' });
        }
        break;
      default:
        break;
    }
  };

  return (
    <TableRow key={uniqueId()}>
      <td>
        <TextInput
          id={`${id}-license-number`}
          name="license_number"
          value={license_number}
          placeholder={license_number ? '' : '-'}
          handleUpdate={value => handleFieldValueChange({ key: 'license_number', value })}
          edited={
            editedEntryFields?.[id]?.values &&
            Object.keys(editedEntryFields[id].values).includes('license_number')
          }
          disabled={isApproved}
        />
      </td>
      <td>
        <TextInput
          id={`${id}-legal-name`}
          name="legal_name"
          value={legal_name}
          placeholder={legal_name ? '' : '-'}
          handleUpdate={value => handleFieldValueChange({ key: 'legal_name', value })}
          edited={
            editedEntryFields?.[id]?.values &&
            Object.keys(editedEntryFields[id].values).includes('legal_name')
          }
          disabled={isApproved}
        />
      </td>
      <td>
        <TextInput
          id={`${id}-dba-name`}
          name="dba_name"
          value={dba_name}
          placeholder={dba_name ? '' : '-'}
          handleUpdate={value => handleFieldValueChange({ key: 'dba_name', value })}
          edited={
            editedEntryFields?.[id]?.values &&
            Object.keys(editedEntryFields[id].values).includes('dba_name')
          }
          disabled={isApproved}
        />
      </td>
      <td>
        <TextInput
          id={`${id}-street`}
          name="street"
          value={street}
          placeholder={street ? '' : '-'}
          handleUpdate={value => handleFieldValueChange({ key: 'street', value })}
          edited={
            editedEntryFields?.[id]?.values &&
            Object.keys(editedEntryFields[id].values).includes('street')
          }
          disabled={isApproved}
        />
      </td>
      <td>
        <TextInput
          id={`${id}-city`}
          name="city"
          value={city}
          placeholder={city ? '' : '-'}
          handleUpdate={value => handleFieldValueChange({ key: 'city', value })}
          edited={
            editedEntryFields?.[id]?.values &&
            Object.keys(editedEntryFields[id].values).includes('city')
          }
          disabled={isApproved}
        />
      </td>
      <td>
        <TextInput
          id={`${id}-state`}
          name="state"
          value={state}
          placeholder={state ? '' : '-'}
          handleUpdate={value => handleFieldValueChange({ key: 'state', value })}
          edited={
            editedEntryFields?.[id]?.values &&
            Object.keys(editedEntryFields[id].values).includes('state')
          }
          disabled={isApproved}
        />
      </td>
      <td>
        <TextInput
          id={`${id}-zip`}
          name="zip"
          value={zip}
          placeholder={zip ? '' : '-'}
          handleUpdate={value => handleFieldValueChange({ key: 'zip', value })}
          edited={
            editedEntryFields?.[id]?.values &&
            Object.keys(editedEntryFields[id].values).includes('zip')
          }
          disabled={isApproved}
        />
      </td>
      <td>
        <TextInput
          id={`${id}-country-code`}
          name="country_code"
          value={country_code}
          placeholder={country_code ? '' : '-'}
          handleUpdate={value => handleFieldValueChange({ key: 'country_code', value })}
          edited={
            editedEntryFields?.[id]?.values &&
            Object.keys(editedEntryFields[id].values).includes('country_code')
          }
          disabled={isApproved}
        />
      </td>
      <td>
        <TextInput
          id={`${id}-license-issuer`}
          name="license_issuer"
          value={license_issuer}
          placeholder={license_issuer ? '' : '-'}
          handleUpdate={value => handleFieldValueChange({ key: 'license_issuer', value })}
          edited={
            editedEntryFields?.[id]?.values &&
            Object.keys(editedEntryFields[id].values).includes('license_issuer')
          }
          disabled={isApproved}
        />
      </td>
      <td>
        <TextInput
          id={`${id}-license-type`}
          name="license_type"
          value={license_type}
          placeholder={license_type ? '' : '-'}
          handleUpdate={value => handleFieldValueChange({ key: 'license_type', value })}
          edited={
            editedEntryFields?.[id]?.values &&
            Object.keys(editedEntryFields[id].values).includes('license_type')
          }
          disabled={isApproved}
        />
      </td>
      <td>
        <StyleForm>
          <div className="admin-filter-datepicke">
            <DateInput
              id={`${id}-license_issue_date`}
              name="license_issue_date"
              value={license_issue_date}
              handleUpdate={value => handleFieldValueChange({ key: 'license_issue_date', value })}
              getInitialValue={getInitialDateValue}
              edited={
                editedEntryFields?.[id]?.values &&
                Object.keys(editedEntryFields[id].values).includes('license_issue_date')
              }
              disabled={isApproved}
            />
            {isNull(license_issue_date) && <Image src={CalendarIcon} />}
          </div>
        </StyleForm>
      </td>
      <td>
        <StyleForm>
          <div className="admin-filter-datepicke">
            <DateInput
              id={`${id}-license_expiration_date`}
              name="license_expiration_date"
              value={license_expiration_date}
              handleUpdate={value =>
                handleFieldValueChange({ key: 'license_expiration_date', value })
              }
              getInitialValue={getInitialDateValue}
              edited={
                editedEntryFields?.[id]?.values &&
                Object.keys(editedEntryFields[id].values).includes('license_expiration_date')
              }
              disabled={isApproved}
            />
            {isNull(license_expiration_date) && <Image src={CalendarIcon} />}
          </div>
        </StyleForm>
      </td>
      <td>
        <TextInput
          id={`${id}-county-name`}
          name="county_name"
          value={county_name}
          placeholder={county_name ? '' : '-'}
          handleUpdate={value => handleFieldValueChange({ key: 'county_name', value })}
          edited={
            editedEntryFields?.[id]?.values &&
            Object.keys(editedEntryFields[id].values).includes('county_name')
          }
          disabled={isApproved}
        />
      </td>
      <td>
        <TextInput
          id={`${id}-company-website`}
          name="company_website"
          value={company_website}
          placeholder={company_website ? '' : '-'}
          handleUpdate={value => handleFieldValueChange({ key: 'company_website', value })}
          edited={
            editedEntryFields?.[id]?.values &&
            Object.keys(editedEntryFields[id].values).includes('company_website')
          }
          disabled={isApproved}
        />
      </td>
      <td>
        <TextInput
          id={`${id}-company-contact-name`}
          name="company_contact_name"
          value={company_contact_name}
          placeholder={company_contact_name ? '' : '-'}
          handleUpdate={value => handleFieldValueChange({ key: 'company_contact_name', value })}
          edited={
            editedEntryFields?.[id]?.values &&
            Object.keys(editedEntryFields[id].values).includes('company_contact_name')
          }
          disabled={isApproved}
        />
      </td>
      <td>
        <TextInput
          id={`${id}-company-email`}
          name="company_email"
          value={company_email}
          placeholder={company_email ? '' : '-'}
          handleUpdate={value => handleFieldValueChange({ key: 'company_email', value })}
          edited={
            editedEntryFields?.[id]?.values &&
            Object.keys(editedEntryFields[id].values).includes('company_email')
          }
          disabled={isApproved}
        />
      </td>
      <td>
        <TextInput
          id={`${id}-company-phone`}
          name="company_phone"
          value={company_phone}
          placeholder={company_phone ? '' : '-'}
          handleUpdate={value => handleFieldValueChange({ key: 'company_phone', value })}
          edited={
            editedEntryFields?.[id]?.values &&
            Object.keys(editedEntryFields[id].values).includes('company_phone')
          }
          disabled={isApproved}
        />
      </td>
      <td>
        <TextInput
          id={`${id}-note`}
          name="note"
          value={note}
          placeholder={note ? '' : '-'}
          handleUpdate={value => handleFieldValueChange({ key: 'note', value })}
          edited={
            editedEntryFields?.[id]?.values &&
            Object.keys(editedEntryFields[id].values).includes('note')
          }
          disabled={isApproved}
        />
      </td>
      <td>
        <TextInput
          id={`${id}-status`}
          name="status"
          value={status}
          placeholder={status ? '' : '-'}
          handleUpdate={value => handleFieldValueChange({ key: 'status', value })}
          edited={
            editedEntryFields?.[id]?.values &&
            Object.keys(editedEntryFields[id].values).includes('status')
          }
          disabled={isApproved}
        />
      </td>
      <td>
        <TextInput
          id={`${id}-license-details`}
          name="license_details"
          value={license_details}
          placeholder={license_details ? '' : '-'}
          handleUpdate={value => {
            validateField({ key: 'license_details', value });
            handleFieldValueChange({ key: 'license_details', value });
          }}
          edited={
            editedEntryFields?.[id]?.values &&
            Object.keys(editedEntryFields[id].values).includes('license_details')
          }
          disabled={isApproved}
          showCounter
          maxLength={500}
          error={fieldErrors?.license_details}
        />
      </td>
      <td>
        <TextInput
          id={`${id}-country-name`}
          name="country_name"
          value={country_name}
          placeholder={country_name ? '' : '-'}
          handleUpdate={value => handleFieldValueChange({ key: 'country_name', value })}
          edited={
            editedEntryFields?.[id]?.values &&
            Object.keys(editedEntryFields[id].values).includes('country_name')
          }
          disabled={isApproved}
        />
      </td>
      <td>
        <TextInput
          id={`${id}-state-name`}
          name="state_name"
          value={state_name}
          placeholder={state_name ? '' : '-'}
          handleUpdate={value => handleFieldValueChange({ key: 'state_name', value })}
          edited={
            editedEntryFields?.[id]?.values &&
            Object.keys(editedEntryFields[id].values).includes('state_name')
          }
          disabled={isApproved}
        />
      </td>
    </TableRow>
  );
};

DetailsListRow.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DetailsListRow;
