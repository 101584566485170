import React from 'react';
import PropTypes from 'prop-types';

function TableHeader({ children }) {
  return (
    <thead>
      <tr>{children}</tr>
    </thead>
  );
}

TableHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TableHeader;
