import styled from 'styled-components';

export const StylePage = styled.div`
  padding: 30px 0;
`;
export const StylePageHeader = styled.div`
  padding-bottom: 30px;
  h2 {
    font-size: ${props => props.theme?.header?.headerFontSize};
    font-weight: ${props => props.theme?.header?.headerFontWeight};
    color: ${props => props.theme?.header?.headerFontColor};
  }
`;
export const PageWrapper = styled.div`
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 74px;
  b {
    font-size: 14px;
    font-weight: 900;
  }
  p {
    font-size: 16px;
    font-weight: 400;
  }
`;

export const StyleSteps = styled.div`
  .sv-steps {
    position: relative;
    padding-bottom: 50px;
  }
  .step-count {
    float: left;
    background: #5dadae;
    outline: 10px solid white;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    border: aliceblue;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    transition: all 0.1s linear 0s;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -5px 15px 0 0;
    padding: 14px;
    line-height: 8px;
  }
  .v-line {
    position: absolute;
    top: 0;
    left: 16px;
    width: 1px;
    height: 100%;
    padding: 38px 12px 6px;
    &:after {
      width: 1px;
      height: 100%;
      display: inline-block;
      background: rgba(0, 0, 0, 0.06);
      border-radius: 1px;
      transition: background 0.3s;
      content: '';
    }
    &.second {
      &:after {
        background: none;
      }
    }
  }
`;

export const MinContentWrap = styled.div`
  padding: 40px 0;
  text-align: center;
  min-height: calc(100vh - 370px);
  .table-action-btn {
    background: ${props => props.theme?.general?.secondaryColor};
    color: white;
    border: 1px solid ${props => props.theme?.general?.secondaryColor};
    padding: 13px 25px;
    font-size: 15px;
    text-decoration: none;
    font-weight: 900;
    border-radius: 8px;
    margin: 0 0 25px 0;
    transition: 0.2s cubic-bezier(0.39, 0.58, 0.57, 1);
    display: inline-block;
    width: 200px;
    height: auto;
  }
  .disabled {
    pointer-events: none;
    opacity: 0.6;
  }
  .not-allowed-cursor {
    cursor: not-allowed;
  }
`;
