/* eslint-disable no-nested-ternary */
/* eslint-disable import/newline-after-import */
import React, { useContext } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { StyleTable } from '../../styles/Table.styled';
function TableComponet({ children, className, isSelectAll, isInlineEditing }) {
  // const { isSelectAll, isInlineEditing } = useContext(SearchLicencesContext);

  return (
    <StyleTable>
      <div className={isSelectAll ? 'selectallclass' : isInlineEditing ? 'editOnlyClass' : ''}>
        <Table responsive className={className}>
          {children}
        </Table>
      </div>
    </StyleTable>
  );
}

TableComponet.propTypes = {
  className: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default TableComponet;
