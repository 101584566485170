import React from 'react';

import ReportTable from '../../../components/reports/ReportsTableBody';
import ReportProvider from '../ReportsContext';

function UserLoginsReport(props) {
  return (
    <ReportProvider componentProps={props}>
      <ReportTable reportType={2} />
    </ReportProvider>
  );
}

export default UserLoginsReport;
