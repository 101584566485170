/* eslint-disable max-len */
const FAQHelper = Object.freeze({
  FAQ_LIST: [
    {
      question: "What does it mean when I can't find a record for a license?",
      answer:
        'Often, the business has applied for a license but not yet been approved (or authorized, certified, permitted, etc.). In some cases, the agency has approved the license but not yet updated its records. Another common reason is that you are searching for one business name while the license is held in the name of another. This happens when businesses merge or are acquired. Also, keep in mind that Simplifya Verified tracks hemp licenses (not cannabis) issued at the state level (not city).',
      id: 1,
    },
    {
      question:
        "I've tried all of your suggestions, but still can't find a license. What do I do next?",
      answer:
        "Tell us! Send an email to support@simplifya.com with whatever information you have about the license in question and we'll do a deep-dive for you.",
      id: 2,
    },
    {
      question: 'Why are some fields blank?',
      answer:
        "We obtain most of our data through open records requests and what is shared on an agency's website. Ultimately, we are limited by what the agency is willing and able to share.",
      id: 3,
    },
    {
      question: 'What does it mean when a state defers to the USDA?',
      answer:
        'The USDA has established a framework for regulating hemp production in the US. Certain states have not established their own hemp program, and instead opt for hemp businesses within their state to apply for licensing through the USDA program.',
      id: 4,
    },
  ],
});

export default FAQHelper;
