/* eslint-disable radix */
/* eslint-disable eqeqeq */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-use-before-define */

import React, { createContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';

import {
  downloadExecution,
  getAllHistoryEntries,
  getEntryCounts,
} from '../../../apis/dataValidationApi';
import { setDataValidationTableFilters } from '../../../store/actions/dataValidation';
import { showErrorAlert } from '../../../store/actions/common';

import DataValidationHelper from '../../../helpers/features/DataValidationHelper';

const ExecutionHistoryContext = createContext();

const ExecutionHistoryProvider = ({ children, componentProps }) => {
  /* CONSTANTS */
  const { HISTORY_TABLE } = DataValidationHelper.HISTORY_LIST;
  const dispatch = useDispatch();
  const dataValidationTableFilters = useSelector(
    state => state.DATA_VALIDATION.dataValidationTableFilters,
  );

  /* STATES */
  // History Table Functional States
  const [currentPage, setCurrentPage] = useState(
    dataValidationTableFilters.page || HISTORY_TABLE.DEFAULTS.STARTING_PAGE,
  );
  const [nextPage, setNextPage] = useState(
    dataValidationTableFilters.page
      ? parseInt(dataValidationTableFilters.page) + 1
      : HISTORY_TABLE.DEFAULTS.STARTING_PAGE,
  );
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentSortedTitle, setCurrentSortedTitle] = useState(
    dataValidationTableFilters.sort_by || '',
  );
  const [sortingOrder, setSortingOrder] = useState(dataValidationTableFilters.sort_order || '');
  const [recordsPerPage, setRecordsPerPage] = useState(
    dataValidationTableFilters.per_page
      ? parseInt(dataValidationTableFilters.per_page)
      : HISTORY_TABLE.DEFAULTS.PER_PAGE,
  );
  const [isLoadingHistoryTableData, setIsLoadingHistoryTableData] = useState(false);
  const [isDownloadingRecord, setIsDownloadingRecord] = useState(false);

  // History Table Contents
  const [historyTableData, setHistoryTableData] = useState([]);
  const [entryCounts, setEntryCounts] = useState({ pending: 0, approved: 0, rejected: 0 });

  // History Table Search & Filter
  const [searchParameter, setSearchParameter] = useState(dataValidationTableFilters.source || '');
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(
    !isNil(dataValidationTableFilters.status)
      ? HISTORY_TABLE.STATUS_FILTER_OPTIONS.filter(
          option => option.value == dataValidationTableFilters.status,
        )[0]
      : null,
  );
  const [selectedFromDateFilter, setSelectedFromDateFilter] = useState(
    dataValidationTableFilters.from_date ? new Date(dataValidationTableFilters.from_date) : null,
  );
  const [selectedToDateFilter, setSelectedToDateFilter] = useState(
    dataValidationTableFilters.to_date ? new Date(dataValidationTableFilters.to_date) : null,
  );

  /* USE-EFFECTS */
  useEffect(() => {
    handleFetchEntryCounts();
  }, []);

  useEffect(() => {
    handleFetchHistoryEntries(dataValidationTableFilters);
  }, [dataValidationTableFilters]);

  /* FUNCTIONS */
  const handleFetchHistoryEntries = async parameters => {
    setIsLoadingHistoryTableData(true);

    const response = await getAllHistoryEntries(parameters);
    if (response.success && response.data) {
      setHistoryTableData(response.data);
      if (response.meta) {
        setTotalPages(Math.ceil(response.meta.total / response.meta.per_page));
        setTotalRecords(response.meta.total);
      }
    }

    setIsLoadingHistoryTableData(false);
  };

  const handleFetchEntryCounts = async () => {
    const response = await getEntryCounts();

    if (response?.success && response?.data) {
      setEntryCounts(response.data);
    }
  };

  const handleStatusFilterChange = selectedStatus => {
    setSelectedStatusFilter(selectedStatus);
    const parameters = {
      ...dataValidationTableFilters,
      page: 1,
      status: selectedStatus ? selectedStatus.value : undefined,
    };

    setNextPage(0);
    setCurrentPage(HISTORY_TABLE.DEFAULTS.STARTING_PAGE);
    dispatch(setDataValidationTableFilters(parameters));
  };

  const handleSelectedFromDateFilter = value => {
    setSelectedFromDateFilter(value);

    if (value > selectedToDateFilter) setSelectedToDateFilter(null);

    const parameters = {
      ...dataValidationTableFilters,
      page: 1,
      from_date: value ? moment(value).format('YYYY-MM-DD') : undefined,
      to_date:
        selectedToDateFilter && !value > selectedToDateFilter
          ? moment(selectedToDateFilter).format('YYYY-MM-DD')
          : undefined,
    };

    setCurrentPage(1);
    setNextPage(0);
    dispatch(setDataValidationTableFilters(parameters));
  };

  const handleSelectedToDateFilter = value => {
    setSelectedToDateFilter(value);

    const parameters = {
      ...dataValidationTableFilters,
      page: 1,
      from_date: selectedFromDateFilter
        ? moment(selectedFromDateFilter).format('YYYY-MM-DD')
        : undefined,
      to_date: value ? moment(value).format('YYYY-MM-DD') : undefined,
    };

    setCurrentPage(1);
    setNextPage(0);
    dispatch(setDataValidationTableFilters(parameters));
  };

  const handleRecordsPerPage = records => {
    const parameters = {
      ...dataValidationTableFilters,
      page: 1,
      per_page: records,
    };

    setRecordsPerPage(records);
    setCurrentPage(1);
    setNextPage(0);
    dispatch(setDataValidationTableFilters(parameters));
  };

  const handleTablePageNavigation = nPage => {
    const parameters = {
      ...dataValidationTableFilters,
    };

    if (!isEqual(nPage, currentPage) && nPage > currentPage) {
      setCurrentPage(nPage);
      setNextPage(nPage + 1);
      dispatch(
        setDataValidationTableFilters({
          ...parameters,
          page: nPage,
        }),
      );
    } else if (!isEqual(nPage, currentPage) && nPage < currentPage && currentPage > 0) {
      setCurrentPage(nPage);
      setNextPage(nPage + 1);
      dispatch(
        setDataValidationTableFilters({
          ...parameters,
          page: nPage,
        }),
      );
    }
  };

  const handleFetchSortedHistoryEntries = async title => {
    let parameters = {};

    if (isEqual(title.db_key, currentSortedTitle) && isEqual(sortingOrder, 'asc')) {
      setSortingOrder('desc');
      parameters = { sort_order: 'desc', sort_by: title.db_key };
    } else if (isEqual(sortingOrder, 'desc')) {
      setSortingOrder('asc');
      parameters = { sort_order: 'asc', sort_by: title.db_key };
    } else {
      setSortingOrder('asc');
      parameters = { sort_order: 'asc', sort_by: title.db_key };
    }

    if (selectedStatusFilter) {
      parameters = { ...parameters, state: selectedStatusFilter.value };
    }

    dispatch(
      setDataValidationTableFilters({
        ...dataValidationTableFilters,
        ...parameters,
      }),
    );
  };

  const handleSearch = searchParam => {
    const trimmedSearchParam = searchParam.trim();
    const parameters = {
      ...dataValidationTableFilters,
      page: 1,
      source: undefined,
    };

    if (searchParam) {
      dispatch(
        setDataValidationTableFilters({
          ...parameters,
          source: trimmedSearchParam,
        }),
      );
      setSearchParameter(searchParam);
    } else {
      setNextPage(0);
      setSearchParameter('');
      dispatch(setDataValidationTableFilters(parameters));
    }
    setCurrentPage(HISTORY_TABLE.DEFAULTS.STARTING_PAGE);
  };

  const handleDownloadExecution = async (sourceId, badgeId) => {
    const name = `${sourceId}-${badgeId}.csv`;
    const payload = {
      files: [{ name }],
      source_type: 2,
    };

    setIsDownloadingRecord(true);
    const response = await downloadExecution(payload);
    if (response?.success && response?.signUrl?.[name]) {
      window.open(response.signUrl[name], '_blank');
    } else {
      showErrorAlert({
        type: 'error',
        delay: 4000,
        body: '',
        message: 'Something went wrong!',
      });
    }
    setIsDownloadingRecord(false);
  };

  const handleEntryCardClick = status => {
    switch (status) {
      case 'pending':
        setSelectedStatusFilter(HISTORY_TABLE.STATUS_FILTER_OPTIONS[0]);
        dispatch(
          setDataValidationTableFilters({ ...dataValidationTableFilters, page: 1, status: 0 }),
        );
        break;
      case 'approved':
        setSelectedStatusFilter(HISTORY_TABLE.STATUS_FILTER_OPTIONS[1]);
        dispatch(
          setDataValidationTableFilters({ ...dataValidationTableFilters, page: 1, status: 1 }),
        );
        break;
      case 'rejected':
        setSelectedStatusFilter(HISTORY_TABLE.STATUS_FILTER_OPTIONS[2]);
        dispatch(
          setDataValidationTableFilters({ ...dataValidationTableFilters, page: 1, status: 2 }),
        );
        break;
      default:
        break;
    }
  };

  return (
    <ExecutionHistoryContext.Provider
      value={{
        totalPages,
        totalRecords,
        currentPage,
        nextPage,
        recordsPerPage,
        currentSortedTitle,
        setCurrentSortedTitle,
        selectedStatusFilter,
        handleStatusFilterChange,
        selectedFromDateFilter,
        handleSelectedFromDateFilter,
        selectedToDateFilter,
        handleSelectedToDateFilter,
        handleRecordsPerPage,
        handleTablePageNavigation,
        handleFetchSortedHistoryEntries,
        handleSearch,
        searchParameter,
        setSearchParameter,
        historyTableData,
        entryCounts,
        isLoadingHistoryTableData,
        handleDownloadExecution,
        handleEntryCardClick,
      }}
    >
      {children}
    </ExecutionHistoryContext.Provider>
  );
};

ExecutionHistoryProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

export { ExecutionHistoryContext };
export default ExecutionHistoryProvider;
