/* eslint-disable no-use-before-define */

import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';

import { getLicenseRecords } from '../../../apis/searchLicensesApi';

import ReportsHelper from '../../../helpers/features/ReportsHelper';

const WeeklyLicenseReportContext = createContext();

const WeeklyLicenseReportProvider = ({ children }) => {
  const currentUserPermissions = useSelector(state => state.AUTH.rolePermissions);

  // Licenses
  const [licenseData, setLicenseData] = useState([]);
  const [isLoadingLicenseData, setIsLoadingLicenseData] = useState(false);
  const [totalLicenseRecords, setTotalLicenseRecords] = useState(0);

  // Pagination
  const [currentPage, setCurrentPage] = useState(ReportsHelper.WEEKLY_LICENSES_TABLE.STARTING_PAGE);
  const [nextPage, setNextPage] = useState(ReportsHelper.WEEKLY_LICENSES_TABLE.STARTING_PAGE);
  const [totalPages, setTotalPages] = useState(0);

  // Sorting
  const [currentSortedTitle, setCurrentSortedTitle] = useState(
    ReportsHelper.WEEKLY_LICENSES_TABLE.DEFAULT_SORTING_PARAMETER,
  );
  const [sortingOrder, setSortingOrder] = useState('asc');

  // Filters
  const [recordsPerPage, setRecordsPerPage] = useState(
    ReportsHelper.WEEKLY_LICENSES_TABLE.LICENSE_RECORDS_DEFAULT_LIMIT,
  );
  const [stateList, setStateList] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);

  // Search
  const [searchParameter, setSearchParameter] = useState('');

  // Admin Identifier
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    handleFetchLicenseData(ReportsHelper.WEEKLY_LICENSES_TABLE.DEFAULT_PARAMETERS);
    setSearchParameter('');
    setSelectedOption([]);
    setCurrentPage(ReportsHelper.WEEKLY_LICENSES_TABLE.STARTING_PAGE);
    setNextPage(0);
  }, []);

  // To check admin permissions
  useEffect(() => {
    if (!isEmpty(currentUserPermissions)) {
      checkIsAdmin();
    }
  }, [currentUserPermissions]);

  // Check user is admin
  const checkIsAdmin = () => {
    if (
      currentUserPermissions.includes(
        ReportsHelper.WEEKLY_LICENSES_TABLE.USER_PERMISSION_ROLE_ADMIN,
      )
    ) {
      setIsAdmin(true);
    }
  };

  // Fetch total count and license data
  const handleFetchLicenseData = async parameters => {
    setIsLoadingLicenseData(true);
    const response = await getLicenseRecords({ ...parameters, get_not_updated_records: true });
    if (response.success && response.data) {
      setIsLoadingLicenseData(false);
      setLicenseData(response.data);
      // setLicenseData([]);
      if (response.data.meta) {
        setTotalLicenseRecords(response.data.meta.totalRecords);
        setTotalPages(response.data.meta.totalPages);
      }
    }
  };

  // Handle State Filter
  const handleStateFilter = selectedState => {
    const selectedStateValues = [];

    let params = {
      page: ReportsHelper.WEEKLY_LICENSES_TABLE.STARTING_PAGE,
      limit: recordsPerPage,
    };

    // eslint-disable-next-line array-callback-return
    selectedState.map(state => {
      selectedStateValues.push({
        code: state.label,
        name: state.value,
      });
    });

    if (!isEmpty(searchParameter)) {
      params = { ...params, key: searchParameter.trim() };
    }

    if (!isEmpty(sortingOrder)) {
      if (isArray(currentSortedTitle)) {
        params[sortingOrder] = currentSortedTitle;
      } else {
        params[sortingOrder] = [currentSortedTitle];
      }
    }

    if (isEmpty(selectedState)) {
      handleFetchLicenseData(params);
    } else {
      params = { ...params, state: selectedStateValues };
      setCurrentPage(ReportsHelper.WEEKLY_LICENSES_TABLE.STARTING_PAGE);
      setNextPage(0);
      handleFetchLicenseData(params);
    }
  };

  // Handle search records
  const handleSearch = searchParam => {
    let parameters = {
      page: ReportsHelper.WEEKLY_LICENSES_TABLE.STARTING_PAGE,
      limit: recordsPerPage,
    };

    const trimmedSearchParam = searchParam.trim();

    if (!isEmpty(selectedOption)) {
      const selectedStateValues = [];
      // eslint-disable-next-line array-callback-return
      selectedOption.map(state => {
        selectedStateValues.push({
          code: state.label,
          name: state.value,
        });
      });
      parameters = { ...parameters, state: selectedStateValues };
    }
    if (!isEmpty(sortingOrder)) {
      if (isArray(currentSortedTitle)) {
        parameters[sortingOrder] = currentSortedTitle;
      } else {
        parameters[sortingOrder] = [currentSortedTitle];
      }
    }

    if (searchParam) {
      parameters = { ...parameters, key: trimmedSearchParam };
      handleFetchLicenseData(parameters);
      setSearchParameter(searchParam);
      setCurrentPage(1);
    } else {
      setCurrentPage(ReportsHelper.WEEKLY_LICENSES_TABLE.STARTING_PAGE);
      setNextPage(0);
      setSearchParameter('');
      setCurrentPage(1);
      handleFetchLicenseData(parameters);
    }
  };

  // Handle records per page filter
  const handleRecordsPerPage = records => {
    let params = {
      page: ReportsHelper.WEEKLY_LICENSES_TABLE.STARTING_PAGE,
      limit: records,
    };

    if (!isEmpty(selectedOption)) {
      const selectedStateValues = [];
      // eslint-disable-next-line array-callback-return
      selectedOption.map(state => {
        selectedStateValues.push({
          code: state.label,
          name: state.value,
        });
      });
      params = { ...params, state: selectedStateValues };
    }

    if (!isEmpty(searchParameter)) {
      params = { ...params, key: searchParameter.trim() };
    }

    if (!isEmpty(sortingOrder)) {
      if (isArray(currentSortedTitle)) {
        params[sortingOrder] = currentSortedTitle;
      } else {
        params[sortingOrder] = [currentSortedTitle];
      }
    }

    setRecordsPerPage(records);
    setCurrentPage(ReportsHelper.WEEKLY_LICENSES_TABLE.STARTING_PAGE);
    setNextPage(0);
    handleFetchLicenseData(params);
  };

  // Handle navigation between pages
  const handleLicenseTablePageNavigation = npage => {
    let parameters = {
      limit: recordsPerPage,
    };

    if (!isEmpty(selectedOption)) {
      const selectedStateValues = [];
      // eslint-disable-next-line array-callback-return
      selectedOption.map(state => {
        selectedStateValues.push({
          code: state.label,
          name: state.value,
        });
      });
      parameters = { ...parameters, state: selectedStateValues };
    }

    if (!isEmpty(searchParameter)) {
      parameters = { ...parameters, key: searchParameter.trim() };
    }

    if (!isEmpty(sortingOrder)) {
      if (isArray(currentSortedTitle)) {
        parameters[sortingOrder] = currentSortedTitle;
      } else {
        parameters[sortingOrder] = [currentSortedTitle];
      }
    }

    if (npage !== currentPage && npage > currentPage) {
      setCurrentPage(npage);
      setNextPage(npage + 1);
      handleFetchLicenseData({
        ...parameters,
        page: npage,
      });
    } else if (npage !== currentPage && npage < currentPage && currentPage > 0) {
      setCurrentPage(npage);
      setNextPage(npage + 1);
      handleFetchLicenseData({
        ...parameters,
        page: npage,
      });
    }
  };

  // Handle sorting of the table
  const handleFetchSortedLicenseData = async title => {
    let parameters = {};
    if (title.db_key === currentSortedTitle && sortingOrder === 'asc') {
      setSortingOrder('desc');
      parameters = isArray(title.db_key) ? { desc: title.db_key } : { desc: [title.db_key] };
    } else if (sortingOrder === 'desc') {
      setSortingOrder('asc');
      parameters = isArray(title.db_key) ? { asc: title.db_key } : { asc: [title.db_key] };
    } else {
      setSortingOrder('asc');
      parameters = isArray(title.db_key) ? { asc: title.db_key } : { asc: [title.db_key] };
    }

    if (!isEmpty(selectedOption)) {
      const selectedStateValues = [];
      // eslint-disable-next-line array-callback-return
      selectedOption.map(state => {
        selectedStateValues.push({
          code: state.label,
          name: state.value,
        });
      });
      parameters = { ...parameters, state: selectedStateValues };
    }

    if (!isEmpty(searchParameter)) {
      parameters = { ...parameters, key: searchParameter.trim() };
    }

    const queryParameters = {
      page: currentPage,
      limit: recordsPerPage,
      ...parameters,
    };

    handleFetchLicenseData(queryParameters);
  };

  const value = useMemo(
    () => ({
      isAdmin,
      licenseData,
      isLoadingLicenseData,
      totalLicenseRecords,
      selectedOption,
      setSelectedOption,
      handleStateFilter,
      searchParameter,
      setSearchParameter,
      handleSearch,
      handleRecordsPerPage,
      totalPages,
      currentPage,
      handleLicenseTablePageNavigation,
      nextPage,
      currentSortedTitle,
      setCurrentSortedTitle,
      handleFetchSortedLicenseData,
    }),
    [
      isAdmin,
      licenseData,
      isLoadingLicenseData,
      totalLicenseRecords,
      selectedOption,
      setSelectedOption,
      handleStateFilter,
      searchParameter,
      setSearchParameter,
      handleSearch,
      handleRecordsPerPage,
      totalPages,
      currentPage,
      handleLicenseTablePageNavigation,
      nextPage,
      currentSortedTitle,
      setCurrentSortedTitle,
      handleFetchSortedLicenseData,
    ],
  );

  return (
    <WeeklyLicenseReportContext.Provider value={value}>
      {children}
    </WeeklyLicenseReportContext.Provider>
  );
};

const useWeeklyLicenseReportContext = () => {
  return useContext(WeeklyLicenseReportContext);
};

WeeklyLicenseReportProvider.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

export default WeeklyLicenseReportProvider;
export { WeeklyLicenseReportContext, useWeeklyLicenseReportContext };
