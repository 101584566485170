import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import StyleModal from './Modal.styled';
// import AppButton from '../../styles/ButtonLink.styled';

function CommonModal(props) {
  const {
    title,
    handleClose,
    className,
    size,
    children,
    isAlertModal,
    // primaryAction,
    // secondaryAction,
  } = props;
  const [show] = useState(true);

  return (
    <Modal dialogClassName={className} size={size} show={show} onHide={handleClose}>
      <StyleModal>
        {!isAlertModal && (
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body className="pt-0">{children}</Modal.Body>
        {/* <Modal.Footer>
          <Button variant="" className="back-btn" onClick={handleClose}>
            {secondaryAction}
          </Button>
          <AppButton fill variant="pr" onClick={handleClose}>
            {primaryAction}
          </AppButton>
        </Modal.Footer> */}
      </StyleModal>
    </Modal>
  );
}

CommonModal.propTypes = {
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  // primaryAction: PropTypes.element.isRequired,
  // secondaryAction: PropTypes.element.isRequired,

  isAlertModal: PropTypes.bool,
};

CommonModal.defaultProps = {
  isAlertModal: false,
};

export default CommonModal;
