/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import * as yup from 'yup';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import size from 'lodash/size';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';

import { Formik } from 'formik';
import AppButton from '../styles/ButtonLink.styled';
import AppInput from '../common/input/AppInput';
import { StyleSteps } from '../styles/Page.styled';
import ErrorNotification from '../common/errorNotification/ErrorNotification';
import ThemeSelect from '../common/input/ThemeSelect';
import LicensesHelper from '../../helpers/features/LicensesHelper';
import { checkMandotoryFeilds, cleanValues, isValidState } from '../../helpers/commonHelper';
import StateHelper from '../../helpers/stateHelper';
import { useLicenses } from '../../containers/licenses/LicensesContext';
import 'react-datepicker/dist/react-datepicker.css';
import StyleForm from '../common/input/Form.styled';

function AddNewLicesensForm() {
  const [clickNext, setClickNext] = useState(false);
  const [startDate, setStartDate] = useState({
    license_issue_date: '',
    license_expiration_date: '',
  });
  const [isLStatusFieldDisabled, setLStatusFieldDisabled] = useState(false);

  const initailValues = {
    license_number: '',
    legal_name: '',
    dba_name: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    country_code: '',
    country_name: '',
    license_issuer: '',
    license_status: '',
    license_designation: '',
    license_type: '',
    license_issue_date: '',
    license_expiration_date: '',
    county_name: '',
    parent_name: '',
    company_website: '',
    company_contact_name: '',
    company_email: '',
    company_phone: '',
    note: '',
    status: '',
    license_details: '',
    state_name: '',
  };

  // const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  const licenseSchema = yup.object().shape({
    license_number: yup.string().max(100, 'You have reached the character limit').nullable(true),
    legal_name: yup.string().max(100, 'You have reached the character limit').nullable(true),
    dba_name: yup.string().max(100, 'You have reached the character limit').nullable(true),
    license_issuer: yup.string().max(100, 'You have reached the character limit').nullable(true),
    street: yup.string().max(100, 'You have reached the character limit').nullable(true),
    city: yup
      .string()
      .matches(/^\D+$/g, 'Numbers are not allowed')
      .max(100, 'You have reached the character limit')
      .nullable(true),
    state: yup
      .string()
      .matches(/^[A-Z]+$/g, 'Numbers, lowercase letters and special characters are not allowed')
      .test(
        'test-valid-state',
        'Invalid state name or value contains numbers, lowercase letters or special characters',
        value => isValidState(value),
      )
      .max(2, 'You have reached the character limit')
      .nullable(true),
    zip: yup
      .string()
      .matches(/^[0-9-]+$/i, 'Invalid character incuded')
      .max(10, 'You have reached the character limit')
      .nullable(true),
    country_code: yup
      .string()
      .matches(/^[A-Z]+$/g, 'Numbers, lowercase letters and special characters are not allowed')
      .max(2, 'You have reached the character limit')
      .nullable(true),
    country_name: yup
      .string()
      .matches(/^\D+$/g, 'Numbers are not allowed')
      .max(100, 'You have reached the character limit')
      .nullable(true),
    license_designation: yup
      .string()
      .max(100, 'You have reached the character limit')
      .nullable(true),
    license_status: yup.string().max(100, 'You have reached the character limit').nullable(true),
    license_type: yup.string().max(100, 'You have reached the character limit').nullable(true),
    license_issue_date: yup
      .string()
      .max(100, 'You have reached the character limit')
      .nullable(true),
    parent_name: yup.string().max(100, 'You have reached the character limit').nullable(true),
    company_contact_name: yup
      .string()
      .max(100, 'You have reached the character limit')
      .nullable(true),
    county_name: yup
      .string()
      .max(100, 'You have reached the character limit')
      .matches(/^\D+$/g, 'Numbers are not allowed')
      .nullable(true),
    company_website: yup.string().max(100, 'You have reached the character limit').nullable(true),
    company_email: yup
      .string()
      .email('Please check the format')
      .max(100, 'You have reached the character limit')
      .nullable(true),
    company_phone: yup.string().max(9, 'You have reached the character limit').nullable(true),
    note: yup.string().max(2000, 'You have reached the character limit').nullable(true),
    status: yup.string().max(100, 'You have reached the character limit').nullable(true),
    license_details: yup.string().max(500, 'You have reached the character limit').nullable(true),
    state_name: yup.string().max(100, 'You have reached the character limit').nullable(true),
  });

  const license = useLicenses();

  const handleOnChange = () => {};
  const handleNextClick = () => {
    setClickNext(true);
  };

  const handleBackClick = () => {
    setClickNext(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initailValues}
      onSubmit={async values => {
        if (!checkMandotoryFeilds(values) && !license.isFormCancel) {
          license.openErrorAlert();
        } else if (checkMandotoryFeilds(values) && !license.isFormCancel) {
          license.handleAddNewLicense(cleanValues(values, ''));
        }
      }}
      validationSchema={licenseSchema}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        isValid,
        setFieldValue,
        dirty,
      }) => (
        <Form onSubmit={handleSubmit} onChange={handleOnChange} noValidate>
          <StyleSteps>
            <div className="sv-steps">
              <div className="v-line" />
              <div className="step-count">1</div>
              <div className="d-grid">
                <h3 className="mb-4">License Details</h3>
                <Row style={{ display: clickNext ? 'none' : '' }}>
                  <Col md={3}>
                    <AppInput
                      label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_NUMBER}
                      type="text"
                      requied=""
                      placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_NUMBER_PLACEHOLDER}
                      helpText=""
                      helpTextLink=""
                      linkUrl=""
                      name="license_number"
                      onChangeInput={handleChange}
                      onBlurInput={handleBlur}
                      value={values.license_number}
                      errorText={errors.license_number ? errors.license_number : null}
                    />
                    <AppInput
                      label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_DETAIL}
                      type="text"
                      placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_DETAIL_PLACEHOLDER}
                      helpText=""
                      helpTextLink=""
                      linkUrl=""
                      name="license_details"
                      onChangeInput={handleChange}
                      onBlurInput={handleBlur}
                      value={values.license_details}
                      errorText={errors.license_details ? errors.license_details : null}
                    />
                    <p>
                      <small>{`(${size(values.license_details)}/500)`}</small>
                    </p>
                    <AppInput
                      label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_STREET}
                      type="text"
                      placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_STREET_PLACEHOLDER}
                      helpText=""
                      helpTextLink=""
                      linkUrl=""
                      name="street"
                      onChangeInput={handleChange}
                      onBlurInput={handleBlur}
                      value={values.street}
                      errorText={errors.street ? errors.street : null}
                    />
                    <AppInput
                      label={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_COUNTY}
                      type="text"
                      placeholder={
                        LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_COUNTY_NAME_PLACEHOLDER
                      }
                      helpText=""
                      helpTextLink=""
                      linkUrl=""
                      name="county_name"
                      onChangeInput={handleChange}
                      onBlurInput={handleBlur}
                      value={values.county_name}
                      errorText={errors.county_name ? errors.county_name : null}
                    />
                  </Col>
                  <Col md={3}>
                    <AppInput
                      label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_ISSUER}
                      type="text"
                      placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_ISSUER_PLACEHOLDER}
                      helpText=""
                      helpTextLink=""
                      linkUrl=""
                      name="license_issuer"
                      onChangeInput={handleChange}
                      onBlurInput={handleBlur}
                      value={values.license_issuer}
                      errorText={errors.license_issuer ? errors.license_issuer : null}
                    />
                    <AppInput
                      label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_STATUS}
                      type="text"
                      placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_STATUS_PLACEHOLDER}
                      helpText=""
                      helpTextLink=""
                      linkUrl=""
                      name="status"
                      onChangeInput={handleChange}
                      onBlurInput={handleBlur}
                      value={values.status}
                      errorText={errors.status ? errors.status : null}
                      disabled={isLStatusFieldDisabled}
                    />
                    <AppInput
                      label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_CITY}
                      type="text"
                      placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_CITY_PLACEHOLDER}
                      helpText=""
                      helpTextLink=""
                      linkUrl=""
                      name="city"
                      onChangeInput={handleChange}
                      onBlurInput={handleBlur}
                      value={values.city}
                      errorText={errors.city ? errors.city : null}
                    />
                    <ThemeSelect
                      label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_STATE_NAME}
                      placeholder={
                        // eslint-disable-next-line max-len
                        LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_STATE_PLACEHOLDER
                      }
                      options={StateHelper.STATE_AS_LABEL}
                      onChangeInput={val => {
                        setFieldValue('state_name', val.value);
                      }}
                      errorText={errors.state_name ? errors.state_name : null}
                      isSearchable
                    />
                  </Col>
                  <Col md={3}>
                    <AppInput
                      label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_DESIGNATION}
                      type="text"
                      placeholder={
                        LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_DESIGNATION_PLACEHOLDER
                      }
                      helpText=""
                      helpTextLink=""
                      linkUrl=""
                      name="license_designation"
                      onChangeInput={handleChange}
                      onBlurInput={handleBlur}
                      value={values.license_designation}
                      errorText={errors.license_designation ? errors.license_designation : null}
                    />
                    <StyleForm>
                      <Form.Group className="form-group">
                        <label className="form-label" htmlFor="">
                          {LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_ISSUE_DATE}
                        </label>
                        <DatePicker
                          className="form-control date-picker"
                          format="MM/DD/YYYY"
                          name="license_issue_date"
                          placeholderText={
                            LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_ISSUE_DATE_PLACEHOLDER
                          }
                          maxDate={new Date()}
                          value={startDate.license_issue_date}
                          selected={
                            startDate.license_issue_date
                              ? new Date(startDate.license_issue_date)
                              : new Date()
                          }
                          onChange={date => {
                            setStartDate({
                              license_expiration_date: '',
                              license_issue_date: moment(date).format('MM/DD/YYYY'),
                            });
                            setFieldValue('license_issue_date', moment(date).format('MM/DD/YYYY'));
                          }}
                          onKeyDown={e => {
                            e.preventDefault();
                          }}
                        />
                        <Form.Text className="error-text">
                          {errors.license_issue_date ? errors.license_issue_date : null}
                        </Form.Text>
                      </Form.Group>
                    </StyleForm>
                    <AppInput
                      label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_STATE}
                      type="text"
                      placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_STATE_PLACEHOLDER}
                      helpText=""
                      helpTextLink=""
                      linkUrl=""
                      name="state"
                      onChangeInput={handleChange}
                      onBlurInput={handleBlur}
                      value={values.state}
                      errorText={errors.state ? errors.state : null}
                    />
                    <ThemeSelect
                      label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_COUNTRY_CODE}
                      placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_COUNTRY_PLACEHOLDER}
                      options={LicensesHelper.COUNTRY_CODES}
                      onChangeInput={val => {
                        setFieldValue('country_code', val.value);
                      }}
                      errorText={errors.country_code ? errors.country_code : null}
                    />
                  </Col>
                  <Col md={3}>
                    <AppInput
                      label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_TYPE}
                      type="text"
                      placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_TYPE_PLACEHOLDER}
                      helpText=""
                      helpTextLink=""
                      linkUrl=""
                      name="license_type"
                      onChangeInput={handleChange}
                      onBlurInput={handleBlur}
                      value={values.license_type}
                      errorText={errors.license_type ? errors.license_type : null}
                    />
                    <StyleForm>
                      <Form.Group className="form-group" controlId="formBasicEmail">
                        <label className="form-label" htmlFor="formBasicEmail">
                          {LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_EXPIRATION_DATE}
                        </label>
                        <DatePicker
                          className="form-control date-picker"
                          format="MM/DD/YYYY"
                          name="license_expiration_date"
                          placeholderText={
                            // eslint-disable-next-line max-len
                            LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_EXPIRATION_DATE_PLACEHOLDER
                          }
                          value={startDate.license_expiration_date}
                          selected={
                            startDate.license_expiration_date
                              ? new Date(startDate.license_expiration_date)
                              : new Date()
                          }
                          minDate={
                            startDate.license_issue_date
                              ? new Date(startDate.license_issue_date)
                              : ''
                          }
                          onChange={date => {
                            setStartDate({
                              ...startDate,
                              license_expiration_date: isNull(date)
                                ? ''
                                : moment(date).format('MM/DD/YYYY'),
                            });
                            setFieldValue(
                              'license_expiration_date',
                              isNull(date) ? '' : moment(date).format('MM/DD/YYYY'),
                            );
                            if (isNull(date)) {
                              setFieldValue('status', '');
                              setLStatusFieldDisabled(false);
                              return;
                            }
                            if (
                              moment(date).isAfter() ||
                              moment(date).isSame(moment().toDate(), 'day')
                            ) {
                              setFieldValue('status', 'Active');
                            } else {
                              setFieldValue('status', 'Inactive');
                            }
                            setLStatusFieldDisabled(true);
                          }}
                          onKeyDown={e => {
                            e.preventDefault();
                          }}
                          isClearable={!isEmpty(values.license_expiration_date)}
                        />
                        <Form.Text className="error-text">
                          {errors.license_expiration_date ? errors.license_expiration_date : null}
                        </Form.Text>
                      </Form.Group>
                    </StyleForm>
                    <AppInput
                      label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_ZIP}
                      type="text"
                      placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_ZIP_PLACEHOLDER}
                      helpText=""
                      helpTextLink=""
                      linkUrl=""
                      name="zip"
                      onChangeInput={handleChange}
                      onBlurInput={handleBlur}
                      value={values.zip}
                      errorText={errors.zip ? errors.zip : null}
                    />
                    <ThemeSelect
                      label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_COUNTRY}
                      placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_COUNTRY_PLACEHOLDER}
                      options={LicensesHelper.AVAILABLE_COUNTRIES}
                      onChangeInput={val => {
                        setFieldValue('country_name', val.value, false);
                      }}
                      errorText={errors.country_name ? errors.country_name : null}
                    />
                  </Col>
                </Row>
                <div className="d-flex mt-2 gap-3 steps-buttons">
                  {!clickNext && (
                    <AppButton className="popup cancel" onClick={() => license.openCancelAlert()}>
                      {LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_CANCEL}
                    </AppButton>
                  )}
                  {!clickNext && (
                    <AppButton fill className="d-block" onClick={() => handleNextClick()}>
                      {LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_BUTTON_TEXT}
                    </AppButton>
                  )}
                </div>
              </div>
            </div>

            <div className="sv-steps">
              <div className="v-line second" />
              <div className="step-count">2</div>
              <div className="d-grid">
                <h3 className="mb-4">Company Details</h3>
                <div style={{ display: !clickNext ? 'none' : '' }}>
                  <Row>
                    <Col md={3}>
                      <AppInput
                        label={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_PARENT_NAME}
                        type="text"
                        placeholder={
                          LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_PARENT_NAME_PLACEHOLDER
                        }
                        helpText=""
                        helpTextLink=""
                        linkUrl=""
                        name="parent_name"
                        onChangeInput={handleChange}
                        onBlurInput={handleBlur}
                        value={values.parent_name}
                        errorText={errors.parent_name ? errors.parent_name : null}
                      />
                    </Col>
                    <Col md={3}>
                      <AppInput
                        label={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_LEGAL_NAME}
                        type="text"
                        placeholder={
                          LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_LEGAL_NAME_PLACEHOLDER
                        }
                        helpText=""
                        helpTextLink=""
                        linkUrl=""
                        name="legal_name"
                        onChangeInput={handleChange}
                        onBlurInput={handleBlur}
                        value={values.legal_name}
                        errorText={errors.legal_name ? errors.legal_name : null}
                      />
                    </Col>
                    <Col md={3}>
                      <AppInput
                        label={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_DBA_NAME}
                        type="text"
                        placeholder={
                          LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_DBA_NAME_PLACEHOLDER
                        }
                        helpText=""
                        helpTextLink=""
                        linkUrl=""
                        name="dba_name"
                        onChangeInput={handleChange}
                        onBlurInput={handleBlur}
                        value={values.dba_name}
                        errorText={errors.dba_name ? errors.dba_name : null}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <AppInput
                        label={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_CONTACT_NAME}
                        type="text"
                        placeholder={
                          LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_CONTACT_NAME_PLACEHOLDER
                        }
                        helpText=""
                        helpTextLink=""
                        linkUrl=""
                        name="company_contact_name"
                        onChangeInput={handleChange}
                        onBlurInput={handleBlur}
                        value={values.company_contact_name}
                        // eslint-disable-next-line max-len
                        errorText={errors.company_contact_name ? errors.company_contact_name : null}
                      />
                    </Col>
                    <Col md={3}>
                      <AppInput
                        label={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_EMAIL}
                        type="email"
                        // eslint-disable-next-line max-len
                        placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_EMAIL_PLACEHOLDER}
                        helpText=""
                        helpTextLink=""
                        linkUrl=""
                        name="company_email"
                        onChangeInput={handleChange}
                        onBlurInput={handleBlur}
                        value={values.company_email}
                        errorText={errors.company_email ? errors.company_email : null}
                      />
                    </Col>
                    <Col md={3}>
                      <AppInput
                        label={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_PHONE}
                        type="number"
                        // eslint-disable-next-line max-len
                        placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_PHONE_PLACEHOLDER}
                        helpText=""
                        helpTextLink=""
                        linkUrl=""
                        name="company_phone"
                        onChangeInput={e => {
                          setFieldValue('company_phone', e.target.value.toString());
                        }}
                        onBlurInput={handleBlur}
                        value={values.company_phone}
                        errorText={errors.company_phone ? errors.company_phone : null}
                      />
                    </Col>
                    <Col md={3}>
                      <AppInput
                        label={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_WEBSITE}
                        type="text"
                        placeholder={
                          LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_WEBSITE_PLACEHOLDER
                        }
                        helpText=""
                        helpTextLink=""
                        linkUrl=""
                        name="company_website"
                        onChangeInput={handleChange}
                        onBlurInput={handleBlur}
                        value={values.company_website}
                        errorText={errors.company_website ? errors.company_website : null}
                      />
                    </Col>
                  </Row>
                  <AppInput
                    label={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_NOTES}
                    type="text"
                    // eslint-disable-next-line max-len
                    placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_NOTES_PLACEHOLDER}
                    helpText=""
                    helpTextLink=""
                    linkUrl=""
                    name="note"
                    onChangeInput={handleChange}
                    onBlurInput={handleBlur}
                    value={values.note}
                    errorText={errors.note ? errors.note : null}
                  />
                  <p>{`(${size(values.note)}/2000)`}</p>
                </div>

                <div className="d-flex mt-2 gap-3 steps-buttons">
                  {clickNext && (
                    <AppButton
                      type="button"
                      className="back-btn "
                      onClick={() => handleBackClick()}
                    >
                      {LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_BACK_BUTTON_TEXT}
                    </AppButton>
                  )}
                  {clickNext && (
                    <AppButton
                      fill
                      disabled={
                        !(dirty && isValid) ||
                        (!isEmpty(values.company_phone) && size(values.company_phone) < 9)
                      }
                      type="submit"
                    >
                      {LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_SUBMIT_BUTTON_TEXT}
                    </AppButton>
                  )}
                </div>
                {license.message.type === 'error' && (
                  <ErrorNotification errorMessage={license.message.message} />
                )}
              </div>
            </div>
          </StyleSteps>
        </Form>
      )}
    </Formik>
  );
}
export default AddNewLicesensForm;
