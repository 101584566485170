export default Object.freeze({
  MY_PROFILE_HEADER: 'My Profile',
  MY_PROFILE_SUBHEADER: 'Edit your profile',
  MY_PROFILE_BTN_TEXT: 'Save',
  MY_PROFILE_FORM_EMAIL_LABLE: 'Email',
  MY_PROFILE_FORM_FIRST_NAME_LABLE: 'First Name',
  MY_PROFILE_FORM_LAST_NAME_LABLE: 'Last Name',
  MY_PROFILE_FORM_FIRST_NAME_PLACEHOLDER: 'Enter your first name',
  MY_PROFILE_FORM_LAST_NAME_PLACEHOLDER: 'Enter your last name',
  MY_PROFILE_FORM_EMAIL_PLACEHOLDER: 'jone@simplifya.com',
  MY_PROFILE_FORM_EMAIL_HELP_TEXT: 'Need to change your email? Contact',
  MY_PROFILE_FORM_EMAIL_HELP_TEXT_LINK: 'support@simplifya.com',
  MY_PROFILE_FORM_COMPANY_PLACEHOLDER: 'Enter your company',
  MY_PROFILE_FORM_COMPANY_LABLE: 'Company',
});
