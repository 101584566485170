import React, { useContext } from 'react';
import { Image, Button, ProgressBar } from 'react-bootstrap';
import CommonModal from '../../common/modal/CommonModal';
import { SearchLicencesContext } from '../../../containers/searchLicenses/SearchLicensesContext';
import DragAndDropFileUpload from '../../common/dragAndDropFileUpload/DragAndDropFileUpload';
import AppButton from '../../styles/ButtonLink.styled';
import LicensesHelper from '../../../helpers/features/LicensesHelper';
import AttachIcon from '../../../assets/images/attachment.svg';
import TrashIcon from '../../../assets/images/trash.svg';

function ReordsFileUpload() {
  const {
    handleImportModalClose,
    handleOnDrop,
    handleFileUploadDataVisualModal,
    fileError,
    setImportFile,
    progressCount,
    setProgressCount,
    setFileError,
    setSelectedFile,
    selectedFile,
  } = useContext(SearchLicencesContext);

  const handleRemoveSelectedFile = () => {
    setProgressCount(0);
    setImportFile(false);
    setFileError(false);
    setSelectedFile(null);
  };

  return (
    <CommonModal className="" size="lg" title="" handleClose={() => handleImportModalClose()}>
      <>
        <DragAndDropFileUpload onDrop={handleOnDrop} />
        {progressCount !== 0 && (
          <ProgressBar className="mt-4" variant="success" max={100} animated now={progressCount} />
        )}
        <div className="upload-list-item-container">
          <div className="d-flex justify-content-between mt-2 upload-list-item upload-list-item-error">
            {selectedFile && (
              <>
                <span className="d-flex align-items-center">
                  <Image src={AttachIcon} className="me-1" />
                  <p className="upload-list-item-name m-0">{selectedFile.name}</p>
                </span>
                <Button className="link-btn">
                  <Image src={TrashIcon} onClick={() => handleRemoveSelectedFile()} />
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="d-flex gap-2 popup-btn-row mt-4">
          <AppButton
            fill="true"
            className="popup cancel m-0"
            onClick={() => handleImportModalClose()}
          >
            {LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_CANCEL}
          </AppButton>
          <AppButton
            className="popup m-0"
            fill="true"
            onClick={() => handleFileUploadDataVisualModal()}
            disabled={fileError !== false || !selectedFile}
          >
            {LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_UPLOAD}
          </AppButton>
        </div>
        {/* {fileError !== false && <ErrorNotification errorMessage={fileError} />} */}
      </>
    </CommonModal>
  );
}

export default ReordsFileUpload;
