import { createSlice } from '@reduxjs/toolkit';
import reducerTypes from '../reducerTypes';

const initialState = {
  isTokenExpired: false,
  token: null,
  rolePermissions: null,
  isBackendAuthorized: false,
};

export const authSlice = createSlice({
  name: reducerTypes.auth,
  initialState,
  reducers: {
    SET_TOKEN_EXPIRY_STATUS: (state, data) => {
      state.isTokenExpired = data.payload;
    },
    SET_TOKEN: (state, data) => {
      state.token = data.payload;
    },
    SET_ROLE_PERMISSION: (state, data) => {
      state.rolePermissions = data.payload;
    },
    SET_IS_BACKEND_AUTHORIZED: (state, data) => {
      state.isBackendAuthorized = data.payload;
    },
  },
});

export const {
  SET_TOKEN_EXPIRY_STATUS,
  SET_TOKEN,
  SET_ROLE_PERMISSION,
  SET_IS_BACKEND_AUTHORIZED,
} = authSlice.actions;

export default authSlice.reducer;
