import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
<style>
  @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,900&family=Poppins:wght@300;400;700;900&family=Raleway:wght@300;400;700;900&family=Roboto:wght@300;400;700;900&display=swap');
</style>

    *{
        box-sizing: border-box;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
    body{
        background: ${props => props.theme?.general?.backgroundColor};
        font-family: ${props => props.theme?.general?.generalFontFamily};
        color: ${props => props.theme?.general?.generalFontColor};
        font-size: 14px;
    }
    .color-red, .red{
        color: red;
    }
    a{  
        font-size: 14px;
        color: ${props => props.theme?.general?.generalLinkColor};
        &:hover{
            opacity: 0.8;
            cursor: pointer;
        }
    }
    h3{
        font-size: 20px;
        font-weight: 900;
    }
    h4{
        font-size: 18px;
        font-weight: 900;
    }
    span.btn{
        border: none;
        &:hover{
            border: none;
        }
    }
    .css-tj5bde-Svg{
        path{fill:black !important;}
    }
    .app-link{
        color: ${props => props.theme?.general?.secondaryColor};
        font-weight: 900;
        text-decoration: none;
        &:hover{
        color:  ${props => props.theme?.general?.secondaryColor};
        opacity: .8;
        }
        &-green{
            cursor: pointer;
            color: ${props => props.theme?.general?.primaryColor};
        }
    }
    .text-muted{
        font-size: 14px;
        font-weight: 400;
        color: black !important;
    }
    .bg-success{
        background-color: ${props => props.theme?.general?.primaryColor} !important;
    }
    .width-auto{width: auto;}
    .full-width{width: 100%;}
    /* ====================  Font - Styles  ====================  */
    .font-12{font-size:12px;}
    .font-14{font-size:14px;}
    .font-16{font-size:16px;}
    .font-18{font-size:18px;}
    .font-20{font-size:20;}
    .font-24{font-size:24;}

    /* ====================  Buttons - Styles  ====================  */
    .link-btn, .link-btn:hover{
        background: transparent;
        border:none;
        padding: 0;
        color:  ${props => props.theme?.general?.generalLinkColor}; 
        &:hover{
            text-decoration: underline;
        }
        &:focus, &:active{
            box-shadow: none;
            background: transparent;
            text-decoration: underline;
            color:  ${props => props.theme?.general?.generalLinkColor}; 
        }
        cursor: pointer;
    }
    &.cancel{
        background: black !important;
        border-color: black !important;
        &:hover, &:focus, &:active, &:hover:focus{
            opacity: 0.65 !important;
            color: white !important;
        }
    }
    .popup{
        min-width: 125px;
        font-size: 15px;
        padding: 13px 25px;
        border-radius: 8px;
        font-weight: 900;
        box-shadow: none !important;
        color: white !important;
        text-decoration: none;
        &:hover{
            color: white !important;
            opacity: 0.85 !important;
        }
        &.cancel{
            background: black !important;
            border-color: black !important;
            &:hover, &:focus, &:active, &:hover:focus{
                opacity: 0.65 !important;
                color: white !important;
            }
        }
        &.submit{
            background:  #FF0007 !important;
            border-color: #FF0007 !important;
            &:hover, &:focus, &:active, &:hover:focus{
                opacity: 1;
            }
        }
        &.confirm{
            background: ${props => props.theme?.general?.secondaryColor} !important;
            border-color: ${props => props.theme?.general?.secondaryColor} !important;
        }
    }
    .table-action-btn {
        border-radius: 5px;
        height: 40px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        box-shadow: 0 4px 6px rgb(96 129 58 / 9%), 0 1px 3px rgb(0 0 0 / 9%), 0px -3px 0px rgb(0 0 0 / 5%) inset;
        transition: all 0.25s ease;
        &:hover{
            transform: translateY(-1px);
            box-shadow: 0 18px 17px 0 rgb(23 28 33 / 3%);
        }
        &.fill-btn{
            background: ${props => props.theme?.general?.primaryColor}; 
            border: 1px solid ${props => props.theme?.general?.primaryColor}; 
        }
        &.outline-btn{
            background: white;
            border: 1px solid ${props => props.theme?.general?.primaryColor};
            &:hover{
                border: 1px solid ${props => props.theme?.general?.primaryColor};
            } 
        }
    }
    .popup-btn-row{
        button{
            padding: 15px 25px;
        }
    }
    .add-new-field{
        button{padding: 8px 25px;}
        font-weight: 700;
    }
    /* ====================  Common - Styles  ====================  */
    .ac-item:last-child .accordion{
        border-bottom: none;
     }
     
     ::-webkit-scrollbar {
        background-color: #f9f9f9;
        width: 5px;
        height: 5px;
        -webkit-appearance: none;
      }
     ::-webkit-scrollbar-thumb {
        background-color: #C3C3C3;
        border-radius: 10px;
      }

    /* ==================== Sweet Alert ====================  */
      .sweet-alert{
        .sweet-alert-p{
            font-weight: 400;
            margin-bottom: 1.5rem;
        }
        .btn-lg{
            padding: 13px 25px;
            min-width: 125px;
            font-size: 15px;
            font-weight: 900;
            border-radius: 8px;
            color: white;
            transition: 0.2s cubic-bezier(0.39, 0.58, 0.57, 1);
            box-shadow: none !important;
        }
        .btn-warning{
            background:${props => props.theme?.general?.secondaryColor};
            border-color : ${props => props.theme?.general?.secondaryColor} !important;
            &:hover {
                opacity: 0.85;
            }
        }
        .btn-light{
            background: black;
            border-color: black;
            &:disabled {
            opacity: 0.25;
            }
            &:hover {
                opacity: 0.65;
            }
        }
      }
      /* ==================== common input ====================  */
      .css-1okebmr-indicatorSeparator, .css-1u9des2-indicatorSeparator {
        display: none;
      }
      .react-datepicker__close-icon::after {
        background-color: transparent;
        height: 22px;
        width: 22px;
        font-size: 19px;
        color: black;
      }
      .d-v-edit{
        .form-control {
          border: 1px solid ${props => props.theme?.general?.generalBorderColor};
        }
      }
      .custom-checkbox-green {
        .form-check-input {
          border-color: ${props => props.theme?.general?.primaryColor};
          width: 1.1875em;
          height: 1.1875em;
          &:checked {
            background-color: ${props => props.theme?.general?.primaryColor};
          }
          &:focus {
            box-shadow: none;
          }
        }
      }
      /* ==================== Upload file component ====================  */
      .upload-list-item {
        border-radius: 10px;
        padding: 8px 14px;
        border: 1px solid ${props => props.theme?.general?.primaryColor};
        .u-l-i-name{color: #000000;}
        .u-l-i-size{color: #C3C3C3;}
        }
      .thumbnail-wrap{
        background-color: #7ECDC4;
        height: 45px;
        width: 45px;
        border-radius: 10px; 
        align-items: center;
            display: flex;
            justify-content: center;
        }
      }
`;

export default GlobalStyles;
