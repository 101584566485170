import React, { useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import isNull from 'lodash/isNull';
import size from 'lodash/size';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';

import CommonModal from '../common/modal/CommonModal';
import ThemeSelect from '../common/input/ThemeSelect';
import AppButton from '../styles/ButtonLink.styled';
import { UsersHelper } from '../../helpers/usersHelper';
import SearchLicenseHelper from '../../helpers/features/SearchLicensesHelper';
import ConfirmIcon from '../../assets/images/confirme-icon.svg';

export const SingleUserFieldEditModal = ({
  setInlineEditModalOpen,
  editFieldData,
  setEditFieldData,
  handleInlineEdit,
}) => {
  const { title, fieldData, fieldType, dataName, maxLength } = editFieldData;
  const [textFieldValue, setTextFieldValue] = useState(fieldData);
  const [selectedValue, setSelectedValue] = useState(
    dataName === 'role'
      ? UsersHelper.USER_TYPE_SELECT_OPTIONS.find(option => option.value === fieldData)
      : UsersHelper.USER_STATUS_FILTER_OPTIONS.find(option => option.value === Number(fieldData)),
  );
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [warnings, setWarnings] = useState({});
  const [hasCharLimitExceeded, setHasCharLimitExceeded] = useState(false);

  const validateCharLimit = (text, fieldName) => {
    if (size(text) === 100) {
      setWarnings({ ...warnings, [fieldName]: UsersHelper.USER_TEXT_FIELDS_CHAR_LIMIT_WARNING });
    }
    if (size(text) > 100) {
      setErrors({
        ...errors,
        [fieldName]: UsersHelper.USER_TEXT_FIELDS_CHAR_LIMIT_ERROR,
      });
    }
    if (size(text) !== 100 && !isNil(warnings[fieldName])) {
      const copyWarningObj = { ...warnings };
      delete copyWarningObj[fieldName];
      setWarnings(copyWarningObj);
    }
    if (!(size(text) > 100)) {
      const copyErrorsObj = { ...errors };
      delete copyErrorsObj[fieldName];
      setErrors(copyErrorsObj);
    }
  };

  const validateOtherTextFields = fieldValue => {
    if (dataName === 'email') {
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (isEmpty(fieldValue.trim())) {
        setErrors({ ...errors, [dataName]: 'Email is required.' });
      } else if (!regex.test(fieldValue)) {
        setErrors({ ...errors, [dataName]: 'Invalid input for Email. Please check the format.' });
      } else {
        const copyErrorsObj = { ...errors };
        delete copyErrorsObj[dataName];
        setErrors(copyErrorsObj);
      }
    } else {
      validateCharLimit(fieldValue, 'company');
    }
  };

  const validateNameFields = (txtVal, fieldName) => {
    validateCharLimit(txtVal, fieldName);
    if (isEmpty(txtVal)) {
      setErrors({
        ...errors,
        [fieldName]: `${fieldName === 'first_name' ? 'First Name' : 'Last Name'} is required.`,
      });
    }
  };
  return (
    <>
      <CommonModal
        className=""
        size="xl"
        title={`Edit ${title}`}
        handleClose={() => {
          setInlineEditModalOpen(false);
        }}
      >
        {!isNull(editFieldData) && (
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              {fieldType === 'text' ? (
                <>
                  {!isObject(fieldData) ? (
                    <>
                      <Form.Label>{title}</Form.Label>
                      <Form.Control
                        onChange={e => {
                          setTextFieldValue(e.target.value);
                          validateOtherTextFields(e.target.value);
                          if (size(e.target.value) > 100) {
                            setHasCharLimitExceeded(true);
                          } else {
                            setHasCharLimitExceeded(false);
                          }
                        }}
                        value={textFieldValue}
                        className="mb-3"
                      />
                      <p>{`${size(textFieldValue)} / ${maxLength}`}</p>
                      {!isEmpty(warnings) && <p>{warnings[dataName]}</p>}
                      {hasCharLimitExceeded && dataName !== 'company' && (
                        <small className="red d-flex mb-2">
                          {UsersHelper.USER_TEXT_FIELDS_CHAR_LIMIT_ERROR}
                        </small>
                      )}
                      {!isEmpty(errors) && <small className="red">{errors[dataName]}</small>}
                    </>
                  ) : (
                    <>
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        onChange={e => {
                          validateNameFields(e.target.value, 'first_name');
                          setTextFieldValue({
                            ...textFieldValue,
                            first_name: e.target.value,
                          });
                          // validateTextField();
                        }}
                        value={textFieldValue?.first_name}
                        className="mb-3"
                      />
                      <p>{`${size(textFieldValue?.first_name)} / ${maxLength}`}</p>
                      {!isEmpty(warnings) && <p>{warnings.first_name}</p>}
                      {!isEmpty(errors) && (
                        <small className="red d-flex mb-3">{errors.first_name}</small>
                      )}
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        onChange={e => {
                          validateNameFields(e.target.value, 'last_name');
                          setTextFieldValue({
                            ...textFieldValue,
                            last_name: e.target.value,
                          });
                        }}
                        value={textFieldValue?.last_name}
                        className="mb-3"
                      />
                      <p>{`${size(textFieldValue?.last_name)} / ${maxLength}`}</p>
                      {!isEmpty(warnings) && <p>{warnings.last_name}</p>}
                      {!isEmpty(errors) && (
                        <small className="red d-flex mb-3">{errors.last_name}</small>
                      )}
                    </>
                  )}
                </>
              ) : (
                <ThemeSelect
                  name={dataName}
                  label={title}
                  // placeholder={UsersHelper.USER_FORM.STATUS.PLACEHOLDER}
                  options={
                    dataName === 'role'
                      ? UsersHelper.USER_TYPE_SELECT_OPTIONS
                      : UsersHelper.USER_STATUS_FILTER_OPTIONS
                  }
                  value={selectedValue}
                  onChangeInput={value => setSelectedValue(value)}
                />
              )}
            </Form.Group>
          </Form>
        )}
        <div className="d-flex gap-2 popup-btn-row mt-4">
          {/* <Col sm={1} className="model-button-col"> */}
          {/* </Col> */}
          {/* <Col sm={2}> */}
          <AppButton
            className="popup cancel m-0"
            onClick={() => {
              setInlineEditModalOpen(false);
              setEditFieldData(null);
            }}
          >
            Cancel
          </AppButton>
          <AppButton
            fill="true"
            className="popup m-0"
            onClick={() => {
              setWarningModalOpen(true);
            }}
            disabled={!isEmpty(errors) || hasCharLimitExceeded}
          >
            Save
          </AppButton>
          {/* </Col> */}
        </div>
      </CommonModal>
      {isWarningModalOpen && (
        <SweetAlert
          custom
          showCancel
          confirmBtnText={SearchLicenseHelper.INLINE_SAVE_CONFIRMATION_ALERT_TOST_SUCCESS_BUTTON}
          confirmBtnCssClass="popup confirm"
          cancelBtnText={SearchLicenseHelper.INLINE_SAVE_CONFIRMATION_ALERT_TOST_CANCEL_BUTTON}
          cancelBtnCssClass="popup cancel"
          confirmBtnBsStyle="success"
          customIcon={ConfirmIcon}
          show={isWarningModalOpen}
          title={SearchLicenseHelper.INLINE_SAVE_CONFIRMATION_ALERT_TITLE}
          onConfirm={() => {
            handleInlineEdit(dataName, fieldType === 'text' ? textFieldValue : selectedValue);
            setWarningModalOpen(false);
          }}
          onCancel={() => setWarningModalOpen(false)}
        >
          {SearchLicenseHelper.INLINE_SAVE_CONFIRMATION_ALERT_DESCRIPTION}
        </SweetAlert>
      )}
    </>
  );
};
