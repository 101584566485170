import React from 'react';
import { components } from 'react-select';
import PropTypes from 'prop-types';

function MultiValue({ data, ...props }) {
  <components.MultiValue {...props}>
    <span>{data.label}</span>
  </components.MultiValue>;
}

MultiValue.propTypes = {
  data: PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
};

export default MultiValue;
