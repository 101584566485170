import React from 'react';

import DetailsListView from '../../../../components/dataValidation/detailsListView/DetailsListView';

import ExecutionDetailsProvider from '../ExecutionDetailsContext';

const DetailsTable = props => {
  return (
    <ExecutionDetailsProvider>
      <DetailsListView />
    </ExecutionDetailsProvider>
  );
};

export default DetailsTable;
