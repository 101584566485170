/* eslint-disable no-lonely-if */
/* eslint-disable array-callback-return */
/* eslint-disable no-else-return */
/* eslint-disable function-paren-newline */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/require-default-props */
/* eslint-disable no-use-before-define */

import React, { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import {
  getAllSources,
  getUploadURL,
  uploadFile,
  createSource,
  updateSource,
  deleteSingleSource,
  getDownloadURL,
  getPdfNanonetModels,
} from '../../apis/sourcesApi';
import { showSuccessAlert, showErrorAlert } from '../../store/actions/common';

import SourcesHelper from '../../helpers/features/SourcesHelper';

const SourcesContext = createContext();

const SourcesProvider = ({ children, componentProps }) => {
  /* CONSTANTS */
  const { SOURCE_TABLE } = SourcesHelper;
  const dispatch = useDispatch();

  /* STATES */
  // Sources Table Functional States
  const [currentPage, setCurrentPage] = useState(SOURCE_TABLE.DEFAULTS.STARTING_PAGE);
  const [nextPage, setNextPage] = useState(SOURCE_TABLE.DEFAULTS.STARTING_PAGE);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentSortedTitle, setCurrentSortedTitle] = useState(SOURCE_TABLE.DEFAULTS.SORT_BY);
  const [sortingOrder, setSortingOrder] = useState(SOURCE_TABLE.DEFAULTS.SORT_ORDER);
  const [recordsPerPage, setRecordsPerPage] = useState(SOURCE_TABLE.DEFAULTS.PER_PAGE);
  const [isLoadingSourcesTableData, setIsLoadingSourcesTableData] = useState(false);

  // Sources Table Contents
  const [sourcesTableData, setSourcesTableData] = useState([]);

  // Source Table Search & Filter
  const [searchParameter, setSearchParameter] = useState('');
  const [selectedStateFilter, setSelectedStateFilter] = useState([]);

  // Create/Edit Source
  const [showCreateSourceModal, setShowCreateSourceModal] = useState({
    data: null,
    isCreate: true,
    show: false,
  });
  const [isCreatingSource, setIsCreatingSource] = useState(false);
  const [showSourceTypeChangeAlert, setShowSourceTypeChangeAlert] = useState({
    show: false,
    payload: null,
  });
  const [pdfModelOptions, setPdfModelOptions] = useState([]);

  // DeleteSource
  const [showDeleteSourceAlert, setShowDeleteSourceAlert] = useState({ id: null, show: false });

  /* USE-EFFECTS */
  useEffect(() => {
    fetchPdfModelOptions();
    handleFetchSourcesData(SOURCE_TABLE.DEFAULTS.PARAMS);
    setSearchParameter('');
    setSelectedStateFilter([]);
    setCurrentPage(SOURCE_TABLE.DEFAULTS.STARTING_PAGE);
    setNextPage(0);
    setRecordsPerPage(SOURCE_TABLE.DEFAULTS.PER_PAGE);
  }, []);

  /* FUNCTIONS */
  const fetchPdfModelOptions = async () => {
    const response = await getPdfNanonetModels();

    if (response?.success && response?.data?.length > 0) {
      const options = response.data.map(model => {
        return { label: model.name, value: model.id };
      });
      setPdfModelOptions(options);
    }
  };

  const handleFetchSourcesData = async parameters => {
    setIsLoadingSourcesTableData(true);

    const response = await getAllSources(parameters);
    if (response.success && response.data) {
      setSourcesTableData(response.data);
      if (response.meta) {
        setTotalPages(Math.ceil(response.meta.total / response.meta.per_page));
        setTotalRecords(response.meta.total);
      }
    }

    setIsLoadingSourcesTableData(false);
  };

  const handleSearch = searchParam => {
    const trimmedSearchParam = searchParam.trim();
    let parameters = {
      page: 1,
      per_page: recordsPerPage,
      type: 2,
    };

    if (!isEmpty(selectedStateFilter)) {
      const selectedStateValues = [];
      // eslint-disable-next-line array-callback-return
      selectedStateFilter.map(state => {
        selectedStateValues.push(state.label);
      });
      parameters = { ...parameters, state: selectedStateValues };
    }

    if (!isEmpty(sortingOrder)) {
      parameters.sort_by = currentSortedTitle;
      parameters.sort_order = sortingOrder;
    }

    if (searchParam) {
      parameters = { ...parameters, name: trimmedSearchParam };
      handleFetchSourcesData(parameters);
      setSearchParameter(searchParam);
      setCurrentPage(SOURCE_TABLE.DEFAULTS.STARTING_PAGE);
    } else {
      setCurrentPage(SOURCE_TABLE.DEFAULTS.STARTING_PAGE);
      setNextPage(0);
      setSearchParameter('');
      handleFetchSourcesData(parameters);
    }
  };

  const handleStateFilterChange = selectedState => {
    const selectedStateValues = [];
    let parameters = {
      page: 1,
      per_page: recordsPerPage,
      type: 2,
    };

    // eslint-disable-next-line array-callback-return
    selectedState.map(state => {
      selectedStateValues.push(state.label);
    });

    if (!isEmpty(searchParameter)) parameters = { ...parameters, name: searchParameter.trim() };

    if (!isEmpty(sortingOrder)) {
      parameters.sort_by = currentSortedTitle;
      parameters.sort_order = sortingOrder;
    }

    if (isEmpty(selectedState)) {
      handleFetchSourcesData(parameters);
    } else {
      parameters = { ...parameters, state: selectedStateValues };
      setCurrentPage(SOURCE_TABLE.DEFAULTS.STARTING_PAGE);
      setNextPage(0);
      handleFetchSourcesData(parameters);
    }
  };

  const handleRecordsPerPage = records => {
    let parameters = {
      page: 1,
      per_page: records,
      type: 2,
    };

    if (!isEmpty(selectedStateFilter)) {
      const selectedStateValues = [];
      // eslint-disable-next-line array-callback-return
      selectedStateFilter.map(state => {
        selectedStateValues.push(state.label);
      });
      parameters = { ...parameters, state: selectedStateValues };
    }

    if (!isEmpty(searchParameter)) parameters = { ...parameters, name: searchParameter.trim() };

    if (!isEmpty(sortingOrder)) {
      parameters.sort_by = currentSortedTitle;
      parameters.sort_order = sortingOrder;
    }

    setRecordsPerPage(records);
    setCurrentPage(1);
    setNextPage(0);
    handleFetchSourcesData(parameters);
  };

  const handleTablePageNavigation = nPage => {
    let parameters = {
      per_page: recordsPerPage,
      type: 2,
    };

    if (!isEmpty(selectedStateFilter)) {
      const selectedStateValues = [];
      // eslint-disable-next-line array-callback-return
      selectedStateFilter.map(state => {
        selectedStateValues.push(state.label);
      });
      parameters = { ...parameters, state: selectedStateValues };
    }

    if (!isEmpty(searchParameter)) parameters = { ...parameters, name: searchParameter.trim() };

    if (!isEmpty(sortingOrder)) {
      parameters.sort_by = currentSortedTitle;
      parameters.sort_order = sortingOrder;
    }

    if (!isEqual(nPage, currentPage) && nPage > currentPage) {
      setCurrentPage(nPage);
      setNextPage(nPage + 1);
      handleFetchSourcesData({
        ...parameters,
        page: nPage,
      });
    } else if (!isEqual(nPage, currentPage) && nPage < currentPage && currentPage > 0) {
      setCurrentPage(nPage);
      setNextPage(nPage + 1);
      handleFetchSourcesData({
        ...parameters,
        page: nPage,
      });
    }
  };

  const handleFetchSortedSourcesData = async title => {
    let parameters = {};

    if (isEqual(title.db_key, currentSortedTitle) && isEqual(sortingOrder, 'asc')) {
      setSortingOrder('desc');
      parameters = { sort_order: 'desc', sort_by: title.db_key };
    } else if (isEqual(sortingOrder, 'desc')) {
      setSortingOrder('asc');
      parameters = { sort_order: 'asc', sort_by: title.db_key };
    } else {
      setSortingOrder('asc');
      parameters = { sort_order: 'asc', sort_by: title.db_key };
    }

    if (!isEmpty(selectedStateFilter)) {
      const selectedStateValues = [];
      // eslint-disable-next-line array-callback-return
      selectedStateFilter.map(state => {
        selectedStateValues.push(state.label);
      });
      parameters = { ...parameters, state: selectedStateValues };
    }

    if (!isEmpty(searchParameter)) parameters = { ...parameters, name: searchParameter.trim() };

    const queryParameters = {
      page: currentPage,
      per_page: recordsPerPage,
      type: 2,
      ...parameters,
    };

    handleFetchSourcesData(queryParameters);
  };

  const handleFetchUploadUrl = async filename => {
    const payload = { file_name: filename };
    const response = await getUploadURL(payload);

    if (response?.success && response?.signUrl) {
      return response.signUrl;
    } else {
      dispatch(
        showErrorAlert({
          type: 'error',
          delay: 4000,
          body: response.message,
          message: 'Something went wrong!',
        }),
      );

      return '';
    }
  };

  const handleUploadFile = async (url, payload, config) => {
    const response = await uploadFile(url, payload, config);
    if (response?.status !== 200) {
      dispatch(
        showErrorAlert({
          type: 'error',
          delay: 4000,
          body: response.message,
          message: 'Something went wrong!',
        }),
      );
    }
    return response;
  };

  const handleCreateSource = async payload => {
    const fetchTableDataParams = {
      page: currentPage || SOURCE_TABLE.DEFAULTS.STARTING_PAGE,
      per_page: recordsPerPage || SOURCE_TABLE.DEFAULTS.PER_PAGE,
      type: 2,
      state: selectedStateFilter ? selectedStateFilter.map(state => state.label) : undefined,
      sort_by: currentSortedTitle || SOURCE_TABLE.DEFAULTS.SORT_BY,
      sort_order: sortingOrder || SOURCE_TABLE.DEFAULTS.SORT_ORDER,
    };

    const response = showCreateSourceModal.isCreate
      ? await createSource(payload)
      : await updateSource(payload, showCreateSourceModal.data.id);
    if (response?.success) {
      dispatch(
        showSuccessAlert({
          type: 'success',
          delay: 3000,
          message: response.message,
          body: '',
        }),
      );
      setShowCreateSourceModal({ data: null, isCreate: true, show: false });
      await handleFetchSourcesData(fetchTableDataParams);
    } else {
      dispatch(
        showErrorAlert({
          type: 'error',
          delay: 4000,
          body: response.message,
          message: 'Something went wrong!',
        }),
      );
    }
  };

  const handleCreateSourceFormSubmit = async values => {
    setIsCreatingSource(true);

    const payload = {
      license_issuer: values.licenseIssuer,
      license_type: values.licenseType,
      state: values.state.value.code,
      state_name: values.state.value.name,
      country_name: values.country.value.name,
      country_code: values.country.value.code,
      source_format: values.sourceFormat.value,
      model_id: isEqual(values.sourceFormat.value, 'PDF') ? values.sourceModel.value : undefined,
      source: isEqual(values.sourceType, 'link') ? values.sourceLink : undefined,
      file_name: !isEqual(values.sourceType, 'link') ? values.uploadData.name : undefined,
    };

    if (isEqual(values.sourceType, 'link')) {
      handleCreateSource(payload);
    } else {
      const { uploadData } = values;
      const uploadResponse = await handleUploadFile(uploadData.url, uploadData.file, {
        headers: {
          'Content-Type': uploadData.file.type,
        },
      });
      if (uploadResponse?.status === 200) handleCreateSource(payload);
      else {
        dispatch(
          showErrorAlert({
            type: 'error',
            delay: 4000,
            body: uploadResponse?.message,
            message: 'Something went wrong!',
          }),
        );
      }
    }

    setIsCreatingSource(false);
  };

  const handleEditSourceFormSubmit = async (values, initialData) => {
    setIsCreatingSource(true);
    const payload = {};

    const getPayloadKey = key => {
      switch (key) {
        case 'licenseIssuer':
          return 'license_issuer';
        case 'licenseType':
          return 'license_type';
        case 'sourceLink':
          return 'source';
        default:
          return '';
      }
    };

    Object.keys(values).map(key => {
      const payloadKey = getPayloadKey(key);

      switch (key) {
        case 'state':
          if (values[key].value.code !== initialData[key].value.code) {
            payload.state = values[key].value.code;
            payload.state_name = values[key].value.name;
          }
          break;
        case 'country':
          if (values[key].value.code !== initialData[key].value.code) {
            payload.country_code = values[key].value.code;
            payload.country_name = values[key].value.name;
          }
          break;
        case 'sourceFormat':
          if (values[key].value !== initialData[key].value) {
            payload.source_format = values[key].value;
          }
          break;
        case 'sourceModel':
          if (
            values.sourceFormat.value === 'PDF' &&
            ((!initialData[key]?.value && values[key].value) ||
              values[key].value !== initialData[key].value)
          ) {
            payload.model_id = values[key].value;
          }
          break;
        case 'uploadData':
          if (!initialData[key] && values[key]) payload.file_name = values[key].name;
          else {
            if (values[key] && values[key]?.file.name !== initialData[key]?.file.name) {
              payload.file_name = values[key].name;
            }
          }
          break;
        default:
          if (values[key] !== initialData[key] && payloadKey) {
            payload[payloadKey] = values[key];
          }
          break;
      }
    });

    if (isEqual(values.sourceType, 'link')) delete payload.file_name;
    if (isEqual(values.sourceType, 'custom')) delete payload.source;

    if (values.sourceType !== initialData.sourceType && (values.sourceLink || values.uploadData)) {
      setShowSourceTypeChangeAlert({ show: true, payload });
    } else {
      if (payload.file_name) {
        const { uploadData } = values;
        const uploadResponse = await handleUploadFile(uploadData.url, uploadData.file, {
          headers: {
            'Content-Type': uploadData.file.type,
          },
        });
        if (uploadResponse?.status === 200) handleCreateSource(payload);
        else {
          dispatch(
            showErrorAlert({
              type: 'error',
              delay: 4000,
              body: uploadResponse?.message,
              message: 'Something went wrong!',
            }),
          );
        }
      } else {
        handleCreateSource(payload);
      }
    }

    setIsCreatingSource(false);
  };

  const handleDeleteSource = async () => {
    const sourceId = showDeleteSourceAlert.id;
    setShowDeleteSourceAlert({ id: null, show: false });

    const fetchTableDataParams = {
      page: currentPage || SOURCE_TABLE.DEFAULTS.STARTING_PAGE,
      per_page: recordsPerPage || SOURCE_TABLE.DEFAULTS.PER_PAGE,
      type: 2,
      state: selectedStateFilter ? selectedStateFilter.map(state => state.label) : undefined,
      sort_by: currentSortedTitle || SOURCE_TABLE.DEFAULTS.SORT_BY,
      sort_order: sortingOrder || SOURCE_TABLE.DEFAULTS.SORT_ORDER,
    };

    const response = await deleteSingleSource(sourceId);
    if (response?.success) {
      dispatch(
        showSuccessAlert({
          type: 'success',
          delay: 3000,
          message: response.message,
          body: '',
        }),
      );
      await handleFetchSourcesData(fetchTableDataParams);
    } else {
      dispatch(
        showErrorAlert({
          type: 'error',
          delay: 4000,
          body: response.message,
          message: 'Something went wrong!',
        }),
      );
    }
  };

  const handleViewUploadedSourceFile = async fileName => {
    const payload = {
      files: [
        {
          name: fileName,
        },
      ],
      source_type: 1,
    };

    const response = await getDownloadURL(payload);
    if (response?.success && response?.signUrl?.[fileName]) {
      window.open(response.signUrl[fileName], '_blank');
    } else {
      showErrorAlert({
        type: 'error',
        delay: 4000,
        body: '',
        message: 'Something went wrong!',
      });
    }
  };

  const handleCancelSourceTypeAlert = () => {
    setShowSourceTypeChangeAlert({ show: false, payload: null });
    setIsCreatingSource(false);
  };

  const handleConfirmSourceTypeAlert = () => {
    handleCreateSource(showSourceTypeChangeAlert.payload);
    setShowSourceTypeChangeAlert({ show: false, payload: null });
    setIsCreatingSource(false);
  };

  return (
    <SourcesContext.Provider
      value={{
        totalPages,
        totalRecords,
        currentPage,
        nextPage,
        currentSortedTitle,
        setCurrentSortedTitle,
        searchParameter,
        setSearchParameter,
        selectedStateFilter,
        setSelectedStateFilter,
        handleSearch,
        handleStateFilterChange,
        handleRecordsPerPage,
        handleTablePageNavigation,
        handleFetchSortedSourcesData,
        sourcesTableData,
        isLoadingSourcesTableData,
        showCreateSourceModal,
        setShowCreateSourceModal,
        isCreatingSource,
        handleFetchUploadUrl,
        handleCreateSourceFormSubmit,
        handleEditSourceFormSubmit,
        showDeleteSourceAlert,
        setShowDeleteSourceAlert,
        handleDeleteSource,
        handleViewUploadedSourceFile,
        showSourceTypeChangeAlert,
        setShowSourceTypeChangeAlert,
        handleCancelSourceTypeAlert,
        handleConfirmSourceTypeAlert,
        pdfModelOptions,
      }}
    >
      {children}
    </SourcesContext.Provider>
  );
};

SourcesProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

export { SourcesContext };
export default SourcesProvider;
