import React, { useContext } from 'react';
import { Breadcrumb } from 'react-bootstrap';

import StyleBreadcrumb from './Breadcrumb.styled';
import { SearchLicencesContext } from '../../../containers/searchLicenses/SearchLicensesContext';

function AppBreadcrumb() {
  const { viewedLicenseId } = useContext(SearchLicencesContext);
  return (
    <StyleBreadcrumb>
      <Breadcrumb>
        <Breadcrumb.Item href="/">Dashboard</Breadcrumb.Item>
        <Breadcrumb.Item href="/search-licenses">Hemp Licenses</Breadcrumb.Item>
        <Breadcrumb.Item active>{viewedLicenseId}</Breadcrumb.Item>
      </Breadcrumb>
    </StyleBreadcrumb>
  );
}

export default AppBreadcrumb;
