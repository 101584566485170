import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Form, Image } from 'react-bootstrap';
import isNull from 'lodash/isNull';

import { StyleSearch } from '../../layouts/header/Header.styled';
import { SearchLicencesContext } from '../../../containers/searchLicenses/SearchLicensesContext';
import SearchIcon from '../../../assets/images/search.svg';

function PageHeader(props) {
  const { customClass, handleSearch, setSearchParameter, searchParameter, disabled, placeholder } =
    props;

  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <StyleSearch>
        <Image src={SearchIcon} />
        <Form.Control
          type="search"
          // eslint-disable-next-line react/destructuring-assignment, no-nested-ternary, no-unneeded-ternary
          placeholder={placeholder ? placeholder : props.reportType === 1 ? 'Search' : 'User Name'}
          className={`mb-3 ${customClass}`}
          aria-label="Search"
          value={searchParameter}
          onChange={e => {
            handleSearch(e.target.value);
            if (isNull(e.target.value)) {
              setSearchParameter('');
            }
          }}
          disabled={disabled}
        />
      </StyleSearch>
    </Form>
  );
}

PageHeader.propTypes = {
  customClass: PropTypes.string,
  reportType: PropTypes.number,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

PageHeader.defaultProps = {
  customClass: '',
  reportType: 1,
  disabled: false,
  placeholder: '',
};

export default PageHeader;
