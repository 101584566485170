/* eslint-disable arrow-body-style */
import React, { useContext, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Formik } from 'formik';
import SweetAlert from 'react-bootstrap-sweetalert';

import WarningIcon from '../../../assets/images/warning-icon.svg';
import AppInput from '../../common/input/AppInput';
import { UsersHelper } from '../../../helpers/usersHelper';
import ThemeSelect from '../../common/input/ThemeSelect';
import AppButton from '../../styles/ButtonLink.styled';
import { UsersContext } from '../../../containers/users/UsersContext';

export const AddEditUserForm = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const isEditView = params?.crudType === UsersHelper.USER_CRUD_TYPES.EDIT;
  const { singleUserDetails, handleUserFormSubmission } = useContext(UsersContext);

  const initailValues = {
    firstName: isEditView ? singleUserDetails?.first_name : '',
    lastName: isEditView ? singleUserDetails?.last_name : '',
    email: isEditView ? singleUserDetails?.email : '',
    companyName: isEditView ? singleUserDetails?.company : '',
    status: isEditView ? { value: singleUserDetails?.status, label: 'Active' } : '',
    userType: isEditView
      ? singleUserDetails?.role &&
        UsersHelper.USER_TYPE_SELECT_OPTIONS.filter(opt => opt.value === singleUserDetails?.role)[0]
      : '',
  };

  const userFormValidationSchema = yup.object().shape({
    firstName: yup
      .string()
      .max(100, 'You have exceeded the character limit')
      .required('First Name is required.'),
    lastName: yup
      .string()
      .max(100, 'You have exceeded the character limit')
      .required('Last Name is required.'),
    email: yup
      .string()
      .email('Please check the format')
      .max(100, 'You have exceeded the character limit')
      .required('User email is required'),
    companyName: yup
      .string()
      .max(100, 'You have exceeded the character limit')
      .required('Company Name is required.'),
    status: yup.object().required('Status is required.'),
    userType: yup.object().required('User role is required.'),
  });
  return (
    <>
      <Formik
        initialValues={initailValues}
        onSubmit={values => handleUserFormSubmission(values, isEditView)}
        validationSchema={userFormValidationSchema}
      >
        {({ values, errors, handleSubmit, handleChange, handleBlur, setFieldValue, touched }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={3}>
                <AppInput
                  label={UsersHelper.USER_FORM.FIRST_NAME.LABEL}
                  requied="*"
                  type="text"
                  placeholder={UsersHelper.USER_FORM.FIRST_NAME.PLACEHOLDER}
                  name={UsersHelper.USER_FORM.FIRST_NAME.NAME}
                  onChangeInput={handleChange}
                  onBlurInput={handleBlur}
                  value={values.firstName}
                  errorText={errors.firstName && touched.firstName ? errors.firstName : null}
                />
              </Col>
              <Col md={3}>
                <AppInput
                  label={UsersHelper.USER_FORM.LAST_NAME.LABEL}
                  requied="*"
                  type="text"
                  placeholder={UsersHelper.USER_FORM.LAST_NAME.PLACEHOLDER}
                  name={UsersHelper.USER_FORM.LAST_NAME.NAME}
                  onChangeInput={handleChange}
                  onBlurInput={handleBlur}
                  value={values.lastName}
                  errorText={errors.lastName && touched.lastName ? errors.lastName : null}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <AppInput
                  label={UsersHelper.USER_FORM.EMAIL.LABEL}
                  requied="*"
                  type="text"
                  placeholder={UsersHelper.USER_FORM.EMAIL.PLACEHOLDER}
                  name={UsersHelper.USER_FORM.EMAIL.NAME}
                  onChangeInput={handleChange}
                  onBlurInput={handleBlur}
                  value={values.email}
                  errorText={errors.email && touched.email ? errors.email : null}
                />
              </Col>
              <Col md={3}>
                <AppInput
                  label={UsersHelper.USER_FORM.COMPANY_NAME.LABEL}
                  requied="*"
                  type="text"
                  placeholder={UsersHelper.USER_FORM.COMPANY_NAME.PLACEHOLDER}
                  name={UsersHelper.USER_FORM.COMPANY_NAME.NAME}
                  onChangeInput={handleChange}
                  onBlurInput={handleBlur}
                  value={values.companyName}
                  errorText={errors.companyName && touched.companyName ? errors.companyName : null}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <ThemeSelect
                  name={UsersHelper.USER_FORM.STATUS.NAME}
                  label={UsersHelper.USER_FORM.STATUS.LABEL}
                  placeholder={UsersHelper.USER_FORM.STATUS.PLACEHOLDER}
                  options={UsersHelper.USER_STATUS_SELECT_OPTIONS}
                  requied="*"
                  value={values.status}
                  onChangeInput={selectedValue =>
                    setFieldValue(UsersHelper.USER_FORM.STATUS.NAME, selectedValue)
                  }
                  errorText={errors.status && touched.status ? errors.status : null}
                />
              </Col>
              <Col md={3}>
                <ThemeSelect
                  name={UsersHelper.USER_FORM.USER_TYPE.NAME}
                  label={UsersHelper.USER_FORM.USER_TYPE.LABEL}
                  placeholder={UsersHelper.USER_FORM.USER_TYPE.PLACEHOLDER}
                  options={UsersHelper.USER_TYPE_SELECT_OPTIONS}
                  requied="*"
                  value={values.userType}
                  onChangeInput={selectedValue =>
                    setFieldValue(UsersHelper.USER_FORM.USER_TYPE.NAME, selectedValue)
                  }
                  errorText={errors.userType && touched.userType ? errors.userType : null}
                />
              </Col>
            </Row>
            <div className="d-flex gap-2 popup-btn-row mt-4">
              <AppButton
                fill="true"
                className="popup cancel m-0"
                type="button"
                onClick={() => setIsWarningModalOpen(true)}
              >
                {UsersHelper.LABELS.BACK}
              </AppButton>
              <AppButton disabled={Object.keys(errors).length > 0} fill className="popup m-0">
                {isEditView ? UsersHelper.LABELS.EDIT_USER : UsersHelper.LABELS.ADD_USER}
              </AppButton>
            </div>
          </Form>
        )}
      </Formik>
      {isWarningModalOpen && (
        <SweetAlert
          custom
          showCancel
          confirmBtnText={UsersHelper.LABELS.OK}
          confirmBtnCssClass="popup confirm"
          cancelBtnText={UsersHelper.LABELS.CANCEL}
          cancelBtnCssClass="popup cancel"
          confirmBtnBsStyle="success"
          customIcon={WarningIcon}
          show={isWarningModalOpen}
          title={UsersHelper.LABELS.GO_BACK_WARNING_MSG}
          onConfirm={() => {
            navigate('/users');
          }}
          onCancel={() => setIsWarningModalOpen(false)}
        />
      )}
    </>
  );
};
