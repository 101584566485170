import React from 'react';
import { Navbar, Nav, Container, Image } from 'react-bootstrap';

import { StyleHeader } from './Header.styled';
import HeaderNavigation from '../../common/mainNavBar/HeaderNavigation';
import UserAction from '../../common/userAction/UserAction';

import HeaderLogo from '../../../assets/images/header-logo.png';

function Header() {
  return (
    <StyleHeader>
      <Navbar bg="" variant="dark" className="main-nav p-0" expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <Image src={HeaderLogo} alt="Simplifya Verified Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="m-auto">
              <HeaderNavigation />
            </Nav>
            <div className="user-action">
              <UserAction />
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </StyleHeader>
  );
}

export default Header;
