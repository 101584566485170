import React from 'react';
import PropTypes from 'prop-types';

function TableBody({ children, className }) {
  return <tbody className={className}>{children}</tbody>;
}

TableBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

TableBody.defaultProps = {
  className: '',
};

export default TableBody;
