/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
import React, { useState, useContext } from 'react';
import { Row, Col, Button, Image } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import size from 'lodash/size';
import isEqual from 'lodash/isEqual';

import SearchLicensesHelper from '../../../helpers/features/SearchLicensesHelper';
import StateHelper from '../../../helpers/stateHelper';
import { arrayRemove, getDropdownValue } from '../../../helpers/commonHelper';
import { SearchLicencesContext } from '../../../containers/searchLicenses/SearchLicensesContext';
import 'react-datepicker/dist/react-datepicker.css';
import ThemeSelect from '../../common/input/ThemeSelect';
import ThemeDatePicker from '../../common/input/ThemeDatePicker';
import ThemeTextArea from '../../common/input/ThemeTextArea';
import AppInput from '../../common/input/AppInput';
import AppButton from '../../styles/ButtonLink.styled';
import WarningIcon from '../../../assets/images/warning-icon.svg';
import BinIcon from '../../../assets/images/bin-modal.svg';

function BulkEditFormFeild() {
  const {
    setIsBulkEditModalOpen,
    handleBulkEdit,
    isSaving,
    setIsSaving,
    bulkIdList,
    validateUserInputs,
  } = useContext(SearchLicencesContext);
  const [feilds, setFeilds] = useState([{ key: '', value: '', other: {} }]);
  const [isPhoneNumberNotValid, setIsPhoneNumberNotValid] = useState(false);
  const [selectedFeilds, setSelectedFeilds] = useState(
    // eslint-disable-next-line no-use-before-define
    SearchLicensesHelper.HEMP_LICENSES_ADMIN_BULK_EDITABLE_FEILDS.sort((a, b) =>
      a.key > b.key ? 1 : b.key > a.key ? -1 : 0,
    ).map(opt => ({ ...opt, value: opt.key })),
  );
  const [errors, setErrors] = useState(false);

  const createKeyValuePair = (index, obj, key, value) => {
    if (feilds[index].key === 'company_phone' && size(value) < 9) {
      setIsPhoneNumberNotValid(true);
    } else {
      setIsPhoneNumberNotValid(false);
    }
    if (!isEqual(feilds[index].key, key.db_key) && !isEmpty(feilds[index].other) && !isEmpty(key)) {
      feilds[index].value = '';
      selectedFeilds.push(feilds[index].other);
      selectedFeilds.sort((a, b) => (a.key > b.key ? 1 : b.key > a.key ? -1 : 0));
    }
    if (!isEmpty(key)) {
      obj.key = key.db_key;
      obj.other = key;
      setSelectedFeilds([...arrayRemove(selectedFeilds, key)]);
    }
    if (!isEmpty(value) && isEmpty(key)) {
      obj.value = value;
    } else if (isEmpty(value) && isEmpty(key)) {
      obj.value = '';
    }
    feilds[index] = obj;
    setFeilds([...feilds]);
  };

  const addNewFeild = () => {
    setFeilds([...feilds, { key: '', value: '', other: {} }]);
  };

  const reomoveFeild = index => {
    selectedFeilds.push(feilds[index].other);
    selectedFeilds.sort((a, b) => (a.key > b.key ? 1 : b.key > a.key ? -1 : 0));
    feilds.splice(index, 1);
    setFeilds([...feilds]);
  };

  const handleEdting = () => {
    handleBulkEdit(feilds.filter(feild => feild.key));
    setIsBulkEditModalOpen(false);
    setFeilds([]);
    setIsSaving(false);
  };

  const handleCancel = () => {
    setIsBulkEditModalOpen(false);
    setFeilds([]);
    setIsSaving(false);
  };

  const checkFeildIsSelected = objectsArray => {
    let wasNull = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const i in objectsArray) {
      if (objectsArray[i].key === '') wasNull = true;
    }
    if (wasNull) return true;
    return false;
  };

  const feildSelectByValue = (key, item, index) => {
    const checkOpation = () => {
      return key === 'country_name'
        ? SearchLicensesHelper.AVAILABLE_COUNTRIES
        : key === 'state_name'
        ? StateHelper.STATE_AS_LABEL
        : key === 'country_code'
        ? SearchLicensesHelper.COUNTRY_CODES
        : [];
    };
    if (key === 'license_expiration_date' || key === 'license_issue_date') {
      return (
        <ThemeDatePicker
          format="yyyy/MM/dd"
          label={SearchLicensesHelper.VALUES_TEXT}
          selected={!isEmpty(item.value) ? new Date(item.value) : new Date()}
          value={item.value}
          errorText={
            isEmpty(item.value) && !isEmpty(feilds[index].key)
              ? SearchLicensesHelper.BULK_EDIT_MODAL_EMPTY_VALUES_ERROR
              : validateUserInputs(feilds[index].key, item.value, true)
          }
          maxDate={key === 'license_issue_date' ? new Date() : null}
          onChangeInput={date => createKeyValuePair(index, item, '', moment(date).format('L'))}
        />
      );
    }
    if (key === 'note' || key === 'license_details') {
      return (
        <ThemeTextArea
          className="license-edit-row-textarea"
          height={1}
          onChangeInput={e => createKeyValuePair(index, item, '', e.target.value)}
          value={item.value}
          charLimit={
            key === 'note'
              ? SearchLicensesHelper.INLINE_SAVE_EDIT_CHAR_LIMIT
              : SearchLicensesHelper.INLINE_SAVE_LICENSE_DETAILS_CHAR_LIMIT
          }
          errorText={
            isEmpty(item.value) && !isEmpty(feilds[index].key)
              ? SearchLicensesHelper.BULK_EDIT_MODAL_EMPTY_VALUES_ERROR
              : validateUserInputs(feilds[index].key, item.value, true)
          }
          errorSet={setErrors}
        />
      );
    }
    if (key === 'state_name' || key === 'country_code' || key === 'country_name') {
      return (
        <ThemeSelect
          label={SearchLicensesHelper.VALUES_TEXT}
          options={checkOpation(key)}
          onChangeInput={val => createKeyValuePair(index, item, '', val.value)}
          value={getDropdownValue(item.value, checkOpation(key))}
          errorText={
            isEmpty(item.value) && !isEmpty(feilds[index].key)
              ? SearchLicensesHelper.BULK_EDIT_MODAL_EMPTY_VALUES_ERROR
              : validateUserInputs(feilds[index].key, item.value, true)
          }
          errorSet={setErrors}
        />
      );
    }
    return (
      <AppInput
        label={SearchLicensesHelper.VALUES_TEXT}
        name="bulk_edit_text_feild"
        type={key === 'company_phone' ? 'number' : 'text'}
        value={item.value}
        onChangeInput={e => createKeyValuePair(index, item, '', e.target.value)}
        index={index}
        errorText={
          isEmpty(item.value) && !isEmpty(feilds[index].key)
            ? SearchLicensesHelper.BULK_EDIT_MODAL_EMPTY_VALUES_ERROR
            : validateUserInputs(feilds[index].key, item.value, true)
        }
        errorSet={setErrors}
      />
    );
  };

  return (
    <>
      {!isSaving &&
        feilds.map((item, i) => (
          <div>
            <Row className="align-items-center">
              <Col md={11}>
                <Row>
                  <Col md={6}>
                    <ThemeSelect
                      label={SearchLicensesHelper.FIELD_TEXT}
                      options={selectedFeilds.filter(feild => feild.value)}
                      onChangeInput={createKeyValuePair}
                      value={item.other}
                      selectedObject={item}
                      index={i}
                      isBulk
                      key={item.key}
                      errorText={
                        isEmpty(feilds[i].key)
                          ? SearchLicensesHelper.BULK_EDIT_MODAL_EMPTY_FIELDS_ERROR
                          : validateUserInputs(feilds[i].key, item.value, true)
                      }
                      errorSet={setErrors}
                    />
                  </Col>
                  <Col md={6}>{feildSelectByValue(feilds[i].key, item, i)}</Col>
                </Row>
              </Col>
              <Col md={1}>
                {size(feilds) > 1 && (
                  <button
                    type="button"
                    className="link-btn"
                    onClick={() => {
                      reomoveFeild(i);
                    }}
                  >
                    <Image src={BinIcon} />
                  </button>
                )}
              </Col>
            </Row>
          </div>
        ))}
      {isSaving && (
        <Row>
          <p>
            <b>{`This will update ${size(bulkIdList)} Total Records. `}</b>
            {SearchLicensesHelper.BULK_EDIT_CONFIRM_CHGANGES_MESSAGE_END}
          </p>
        </Row>
      )}
      {!isSaving && (
        <div className="add-new-field">
          <AppButton
            onClick={() => addNewFeild()}
            disabled={
              errors || size(feilds) >= 24 || checkFeildIsSelected(feilds) || isPhoneNumberNotValid
            }
          >
            {SearchLicensesHelper.ADD_NEW_FEILD_TEXT}
          </AppButton>
        </div>
      )}
      <div className="d-flex gap-2 popup-btn-row">
        <Button className="popup cancel" onClick={() => handleCancel()}>
          {SearchLicensesHelper.CANCEL_TEXT}
        </Button>
        {isSaving && (
          <Button className="popup cancel" onClick={() => setIsSaving(false)}>
            {SearchLicensesHelper.BACK_TEXT}
          </Button>
        )}
        <AppButton
          fill
          className="m-0"
          disabled={
            errors ||
            (size(feilds) === 1 && feilds[0].key === '') ||
            checkFeildIsSelected(feilds) ||
            isPhoneNumberNotValid
          }
          onClick={() => {
            if (isSaving) {
              handleEdting();
            }
            if (!isSaving) {
              setIsSaving(true);
            }
          }}
        >
          {SearchLicensesHelper.MAKE_CHANGES_TEXT}
        </AppButton>
      </div>
      {!isSaving && (
        <p className="color-red warnin-text mt-4 d-flex">
          <Image src={WarningIcon} className="me-2" />
          <b>{SearchLicensesHelper.BULK_EDIT_MODAL_WARNING}</b>
        </p>
      )}
    </>
  );
}

export default BulkEditFormFeild;
