import React from 'react';
import { Container } from 'react-bootstrap';

import { StylePage } from '../../components/styles/Page.styled';

function Map() {
  return (
    <Container>
      <StylePage>Map here</StylePage>
    </Container>
  );
}

export default Map;
