export const UsersHelper = Object.freeze({
  PAGE_HEADER: 'Users',
  LABELS: {
    ADD_USER: 'Add New User',
    EDIT_USER: 'Edit User',
    ADD_USER_SUB_HEADER: 'Complete this to add a new user',
    EDIT_USER_SUB_HEADER: 'Complete this to edit user',
    DELETE_USER_BODY_TEXT: 'Are you sure you want to delete this record?',
    DELETE_USER_CANCEL_BTN: 'No, Cancel',
    DELETE_USER_CONFIRM_BTN: 'Yes, Delete',
    BACK: 'Back',
    GO_BACK_WARNING_MSG: 'Changes you made will not be saved.',
    CANCEL: 'Cancel',
    OK: 'Ok',
    NO_RECORDS_FOUND: 'No records found',
    YES: 'Yes',
    NO: 'No',
    RESET_PASSWORD_MODAL_HEADER: 'Reset password',
  },
  USER_TABLE_HEADERS: [
    { LABEL: 'Name', KEY: 'first_name' },
    { LABEL: 'Email', KEY: 'email' },
    { LABEL: 'Company', KEY: 'company' },
    { LABEL: 'User Type', KEY: 'role' },
    { LABEL: 'Status', KEY: 'status' },
    { LABEL: 'Last Login', KEY: 'last_login' },
    { LABEL: 'Reset Password', KEY: 'resetPassword' },
    { LABEL: '', KEY: 'actions' },
  ],
  USER_FORM: {
    FIRST_NAME: {
      PLACEHOLDER: 'Type User First Name',
      LABEL: 'First Name',
      NAME: 'firstName',
    },
    LAST_NAME: {
      PLACEHOLDER: 'Type User Last Name',
      LABEL: 'Last Name',
      NAME: 'lastName',
    },
    EMAIL: {
      PLACEHOLDER: 'Type User Email',
      LABEL: 'User Email',
      NAME: 'email',
    },
    COMPANY_NAME: {
      PLACEHOLDER: 'Type Company Name',
      LABEL: 'Company Name',
      NAME: 'companyName',
    },
    STATUS: {
      PLACEHOLDER: 'Select Status',
      LABEL: 'Status',
      NAME: 'status',
    },
    USER_TYPE: {
      PLACEHOLDER: 'Select Type',
      LABEL: 'User Type',
      NAME: 'userType',
    },
  },
  USER_CRUD_TYPES: {
    EDIT: 'edit',
    ADD: 'add',
  },
  USER_TYPE_SELECT_OPTIONS: [
    { value: 'ADMIN', label: 'Admin' },
    { value: 'STANDARD', label: 'Standard' },
  ],
  USER_STATUS_SELECT_OPTIONS: [
    { value: true, label: 'Active' },
    { value: false, label: 'Inactive' },
  ],
  USER_STATUS_FILTER_OPTIONS: [
    { value: 1, label: 'Active' },
    { value: 0, label: 'Inactive' },
  ],
  INLINE_EDITOR_COLUMNS: [
    {
      name: 'name',
      dataName: 'first_name',
      title: 'First Name and Last Name',
      isEditable: true,
      type: 'text',
      maxlength: 100,
    },
    {
      name: 'email',
      dataName: 'email',
      title: 'Email',
      isEditable: true,
      type: 'text',
      maxlength: 100,
    },
    {
      name: 'company',
      dataName: 'company',
      title: 'Company',
      isEditable: true,
      type: 'text',
      maxlength: 100,
    },
    { name: 'userType', dataName: 'role', title: 'User Type', isEditable: true, type: 'select' },
    { name: 'status', dataName: 'status', title: 'Status', isEditable: true, type: 'select' },
    { name: 'lastLogin', dataName: 'last_login', isEditable: false },
    { name: 'resetPassword', isEditable: false },
    { name: 'actions', isEditable: false },
  ],
  USER_TEXT_FIELDS_CHAR_LIMIT_ERROR: 'You have exceeded the character limit',
  USER_TEXT_FIELDS_CHAR_LIMIT_WARNING: 'You have reached the character limit',
});
