/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';
import { Form, Image } from 'react-bootstrap';

import ToolTip from '../toolTips/ToolTip';
import StyleForm from '../input/Form.styled';

const ColorPicker = ({
  label,
  icon,
  defaultColor,
  colorProperty,
  mainSection,
  handleColorPickerChnage,
  toolTipContent,
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const [color, setColor] = useState('#000000');

  useEffect(() => {
    if (defaultColor) {
      setColor(defaultColor);
    }
  }, [defaultColor]);

  const onClick = () => {
    setShowPicker(!showPicker);
  };

  const onClose = () => {
    setShowPicker(false);
  };

  const onChange = newColor => {
    setColor(newColor.hex);
    handleColorPickerChnage(mainSection, colorProperty, newColor.hex);
  };

  const styles = reactCSS({
    default: {
      color: {
        height: '35.5px',
        borderRadius: '0',
        width: 'calc(100% - 50px)',
        border: '1px solid #f0f0f0',
        background: color,
      },
      popover: {
        position: 'absolute',
        zIndex: '3',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
      swatch: {
        background: 'transparent',
        borderRadius: '0',
        cursor: 'pointer',
        display: 'inline-block',
        boxShadow: 'none',
        width: '100%',
      },
      svg: {
        position: 'absolute',
        right: '14px',
        zIndex: '-1',
      },
    },
  });

  return (
    <div className="color-picker-wrapper">
      <StyleForm>
        <Form.Group className="form-group" controlId="">
          {' '}
          <Form.Label className="form-label d-flex align-items-center">
            {label}
            <ToolTip icon={icon} toolTipContent={toolTipContent} />
          </Form.Label>
          <div className="d-flex align-items-center position-relative">
            <div style={styles.swatch} onClick={onClick}>
              <div style={styles.color} />
            </div>
            {showPicker ? (
              <div style={styles.popover}>
                <div style={styles.cover} onClick={onClose} />
                <SketchPicker color={color} onChange={onChange} />
              </div>
            ) : null}
            <svg
              style={styles.svg}
              height="20"
              width="20"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              className="css-tj5bde-Svg"
            >
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
            </svg>
          </div>
        </Form.Group>
      </StyleForm>
    </div>
  );
};
ColorPicker.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};
export default ColorPicker;
