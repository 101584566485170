import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import { AddEditUserForm } from './AddEditUserForm';
import UsersProvider from '../../../containers/users/UsersContext';
import PageHeader from '../../common/pageHeader/PageHeader';
import { StylePage } from '../../styles/Page.styled';
import { UsersHelper } from '../../../helpers/usersHelper';

export const AddEditUsers = props => {
  const { crudType } = useParams();

  const returnPageHeaderProps = () => {
    if (UsersHelper.USER_CRUD_TYPES.EDIT === crudType) {
      return {
        title: UsersHelper.LABELS.EDIT_USER,
        subheader: UsersHelper.LABELS.EDIT_USER_SUB_HEADER,
      };
    }
    return {
      title: UsersHelper.LABELS.ADD_USER,
      subheader: UsersHelper.LABELS.ADD_USER_SUB_HEADER,
    };
  };

  return (
    <StylePage>
      <Container>
        <UsersProvider componentProps={props}>
          <PageHeader {...returnPageHeaderProps()} />
          <AddEditUserForm />
        </UsersProvider>
      </Container>
    </StylePage>
  );
};
