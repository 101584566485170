import React from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { Image, Form } from 'react-bootstrap';
import StyleUpoload from './Upload.styled';
import UploadIcon from '../../../assets/images/upload.svg';
import ToolTip from '../toolTips/ToolTip';

function DragAndDropFileUpload(props) {
  const { onDrop, label, requied, className, icon, toolTipContent } = props;
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'jpg/png': ['.jpg', '.png', '.jpeg'],
    },
    onDrop,
  });
  return (
    <StyleUpoload>
      <Form.Label>
        {label}
        <span className="red"> {requied}</span>
        <ToolTip icon={icon} toolTipContent={toolTipContent} />
      </Form.Label>
      <div {...getRootProps({ className: 'dropzone' })} className={className}>
        <input className="input-zone" {...getInputProps()} />
        <div className="upload-drag-container text-center">
          <Image src={UploadIcon} className="mb-2" />
          {isDragActive ? (
            <p className="dropzone-content m-0">Release to drop the files here</p>
          ) : (
            <p className="dropzone-content m-0">Drag and drop or browse file to import</p>
          )}
        </div>
      </div>
    </StyleUpoload>
  );
}

DragAndDropFileUpload.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  requied: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  open: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
};

export default DragAndDropFileUpload;
