// development | testing | production
export const ENV = process.env.NODE_ENV;

// development | qa | staging | production
export const USER_ENV = process.env.REACT_APP_USER_ENV;

// true | false
export const LOGS_ENABLED = process.env.REACT_APP_LOGS_ENABLED || false;

// backend API
// ex: https://billing-dev-api.empyreallogistics.com
export const BACKEND_API_BASE_URL = process.env.REACT_APP_BACKEND_API_BASE_URL;

export const BACKEND_API_BASE_URL_NEW = process.env.REACT_APP_BACKEND_API_BASE_URL_2;

export const FIREBASE = {};
