import axios from 'axios';
import ResponseCodes from '../../helpers/responseCodes';

import store from '../../store/store';
import * as authActions from '../../store/actions/auth';
import * as logs from '../../utils/logs';

const BEARER = 'Bearer';

const axiosInstance = axios.create();

const detachAccessToken = (config = {}) =>
  !!(Object.prototype.hasOwnProperty.call(config, 'detachAccessToken') && config.detachAccessToken);

const detachRoleToken = (config = {}) =>
  !!(Object.prototype.hasOwnProperty.call(config, 'detachRoleToken') && config.detachRoleToken);

const requestHandler = request => {
  if (!detachAccessToken(request)) {
    logs.debug('access token attached');
    if (localStorage.getItem('token')) {
      request.headers.Authorization = `${BEARER} ${localStorage.getItem('token')}`;
    }
  }
  if (!detachRoleToken(request)) {
    // logs.debug('Role token attached');
    // request.headers['role-token'] = `${BEARER} ${localStorage.getItem('token')}`;
  }
  return request;
};

axiosInstance.interceptors.request.use(request => requestHandler(request));

const successHandler = response => response;

const errorHandler = error => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (
      error.response?.status === ResponseCodes.ERROR.UNAUTHORIZED.CODE ||
      error.response?.status === ResponseCodes.ERROR.FORBIDDEN.CODE
    ) {
      logs.info('User is unauthorized');
      store.dispatch(authActions.setIsBackendAuthorized(false));
      localStorage.removeItem('token');
      window.location.replace('/login');
    } else if (error.response?.status === ResponseCodes.ERROR.INTERNAL_SERVER_ERROR.CODE) {
      logs.info('Internal server error');
      // store.dispatch(
      //   commonActions.showErrorAlert({ message: ResponseCodes.ERROR.INTERNAL_SERVER_ERROR.MSG }),
      // );
    } else if (error.response?.status === ResponseCodes.ERROR.VALIDATION_ERROR.CODE) {
      logs.info('Validation error');
      // store.dispatch(commonActions.showErrorAlert({ message: extractErrorMsg(error) }));
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
  } else {
    // Something happened in setting up the request that triggered an Error
  }
  return Promise.reject(error);
};

axiosInstance.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error),
);

export const get = (url, config) => axiosInstance.get(url, config);

export const post = (url, body, config) => axiosInstance.post(url, body, config);

export const put = (url, body, config) => axiosInstance.put(url, body, config);

export const patch = (url, body, config) => axiosInstance.patch(url, body, config);

export const del = (url, config) => axiosInstance.delete(url, config);

export const APIReqCancelSource = () => axios.CancelToken.source();

/**
 * put method without authorization header
 * @param {*} url
 * @param {*} body
 * @param {*} config
 */
const unAuthInstance = axios.create();
export const putWithoutAuth = (url, body, config) => unAuthInstance.put(url, body, config);

delete unAuthInstance.defaults.headers.common.Authorization;
