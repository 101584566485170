/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';

import CommonModal from '../../common/modal/CommonModal';
import { SearchLicencesContext } from '../../../containers/searchLicenses/SearchLicensesContext';
import AppButton from '../../styles/ButtonLink.styled';
import LicensesHelper from '../../../helpers/features/LicensesHelper';
import TableBody from '../../common/table/TableBody';
import TableComponet from '../../common/table/Table';
import TableHeader from '../../common/table/TableHeader';

function UploadedDataVisual() {
  const {
    handleFileUploadDataVisualModalClose,
    importData,
    handleFileImport,
    setBulkUploadSuccessModalOpen,
  } = useContext(SearchLicencesContext);

  return (
    <CommonModal
      className=""
      size="lg"
      title=""
      handleClose={() => handleFileUploadDataVisualModalClose()}
    >
      <Row>
        <Row>
          <TableComponet className="admin-licenes">
            <TableHeader>
              {importData.columns &&
                importData.columns.map((item, index) => <th key={`${index + 1}-head`}>{item}</th>)}
            </TableHeader>
            <TableBody>
              {importData.data &&
                importData.data.map((item, index) => (
                  <tr key={index}>
                    <td key={index + 1}>{item.license_number ? item.license_number : '-'}</td>
                    <td key={index + 2}>{item.legal_name ? item.legal_name : '-'}</td>
                    <td key={index + 3}>{item.dba_name ? item.dba_name : '-'}</td>
                    <td key={index + 4}>{item.street ? item.street : '-'}</td>
                    <td key={index + 5}>{item.city ? item.city : '-'}</td>
                    <td key={index + 6}>{item.state ? item.state : '-'}</td>
                    <td key={index + 7}>{item.zip ? item.zip : '-'}</td>
                    <td key={index + 8}>{item.state_name ? item.state_name : '-'}</td>
                    <td key={index + 9}>{item.country_code ? item.country_code : '-'}</td>
                    <td key={index + 10}>{item.country_name ? item.country_name : '-'}</td>
                    <td key={index + 11}>{item.license_issuer ? item.license_issuer : '-'}</td>
                    <td key={index + 12}>
                      {item.license_designation ? item.license_designation : '-'}
                    </td>
                    <td key={index + 13}>{item.license_type ? item.license_type : '-'}</td>
                    <td key={index + 14}>{item.license_details ? item.license_details : '-'}</td>
                    <td key={index + 15}>
                      {item.license_issue_date ? item.license_issue_date : '-'}
                    </td>
                    <td key={index + 16}>
                      {item.license_expiration_date ? item.license_expiration_date : '-'}
                    </td>
                    <td key={index + 17}>{item.county_name ? item.county_name : '-'}</td>
                    <td key={index + 18}>{item.parent_name ? item.parent_name : '-'}</td>
                    <td key={index + 19}>{item.company_website ? item.company_website : '-'}</td>
                    <td key={index + 20}>
                      {item.company_contact_name ? item.company_contact_name : '-'}
                    </td>
                    <td key={index + 21}>{item.company_email ? item.company_email : '-'}</td>
                    <td key={index + 22}>{item.company_phone ? item.company_phone : '-'}</td>
                    <td key={index + 23}>{item.note ? item.note : '-'}</td>
                    <td key={index + 24}>{item.source_name ? item.source_name : '-'}</td>
                    <td key={index + 26}>{item.action ? item.action : '-'}</td>
                    <td key={index + 25}>{item.status ? item.status : '-'}</td>
                  </tr>
                ))}
            </TableBody>
          </TableComponet>
        </Row>
        <div className="d-flex gap-2 popup-btn-row mt-4">
          <AppButton
            fill="true"
            className="popup cancel m-0"
            onClick={() => handleFileUploadDataVisualModalClose()}
          >
            {LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_CANCEL}
          </AppButton>
          <AppButton
            fill="true"
            className="popup m-0"
            onClick={() => {
              setBulkUploadSuccessModalOpen(true);
              handleFileImport();
            }}
          >
            {LicensesHelper.PREVIEW_CSV_MODAL_IMPORT_BTN}
          </AppButton>
        </div>
      </Row>
    </CommonModal>
  );
}

export default UploadedDataVisual;
