import styled from 'styled-components';

const StyleFooter = styled.footer`
  background: ${props => props.theme?.footer?.footerBackground};
  padding: 80px 0;
  border-top: 1px solid #00000029;

  img {
    height: fit-content;
  }
  p {
    margin-left: 20px;
  }
  @media (max-width: 575.98px) {
    .col-md-2 {
      margin-bottom: 30px;
    }
    .col-md-3 {
      margin-bottom: 30px;
      img {
        width: 35px;
      }
    }
  }
`;

export default StyleFooter;
