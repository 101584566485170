import PropTypes from 'prop-types';
import { Container, Row, Col, Image } from 'react-bootstrap';
import moment from 'moment';

import StyleLogin from '../../../containers/auth/Login.styled';
import Logo from '../../../assets/images/login-logo.png';

function Authlayout(props) {
  const { children, mainHeader, subHeader } = props;
  return (
    <StyleLogin className="w-100 px-4 py-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={4} lg={4} xl={4}>
            <div className="text-center">
              <Image src={Logo} alt="Simplify Verified Logo" />
              <h1>{mainHeader}</h1>
              <p>{subHeader}</p>
            </div>
            {children}
            <p className="text-center">© {moment().format('yy')} Simplifya, LLC</p>
          </Col>
        </Row>
      </Container>
    </StyleLogin>
  );
}

Authlayout.propTypes = {
  children: PropTypes.node.isRequired,
  mainHeader: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
};

export default Authlayout;
