import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, matchPath } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import Footer from '../footer/Footer';
import Header from '../header/Header';

function Main() {
  const location = useLocation();
  const navigate = useNavigate();

  const theme = JSON.parse(localStorage.getItem('theme'));

  useEffect(() => {
    if (location.state) {
      if (!isEmpty(location.state?.from.pathname)) {
        navigate(location.state.from.pathname, { replace: true });
      } else {
        navigate('/');
      }
    }
  }, []);
  return (
    <>
      <div>
        <Header />
        <Outlet />
      </div>
      <Footer
        address={theme?.footer?.companyAddress}
        email={theme?.footer?.companyEmail}
        contact={theme?.footer?.companyContact}
      />
    </>
  );
}

export default Main;
