import replaceParameters from '../helpers/parameterHelper';
import { get, put, post, del } from '../libs/api/apiWrapper';
import apiRoutes from '../helpers/apiRoutes';

/**
 * User Dashboard
 */

// eslint-disable-next-line consistent-return
export const getLicenseRecords = async body => {
  try {
    const url = apiRoutes.SEARCH_LICENCES.USER_DASHBOARD.GET_ALL_LICENCES;
    const { data } = await post(url, body);
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`error ${error}`);
  }
};

// eslint-disable-next-line consistent-return
export const getSingleLicenseRecord = async licenseId => {
  try {
    const url = replaceParameters(apiRoutes.SEARCH_LICENCES.USER_DASHBOARD.GET_LICENCE, {
      licenseId,
    });
    const { data } = await get(url);
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`error ${error}`);
  }
};

/**
 * Admin Dashbaord
 */

// eslint-disable-next-line consistent-return
export const createNewLicense = async body => {
  try {
    const url = apiRoutes.SEARCH_LICENCES.ADMIN_DASHBOARD.CREATE_LICENCE;
    const { data } = await post(url, body);
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`error ${error}`);
    return error.response.data;
  }
};

// eslint-disable-next-line consistent-return
export const updateSingleLicense = async (licenseId, body) => {
  try {
    const url = replaceParameters(apiRoutes.SEARCH_LICENCES.ADMIN_DASHBOARD.UPDATE_LICENCE, {
      licenseId,
    });
    const { data } = await put(url, body);
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`error ${error}`);
    return error.response.data;
  }
};

// eslint-disable-next-line consistent-return
export const deleteSingleLicense = async licenseId => {
  try {
    const url = replaceParameters(apiRoutes.SEARCH_LICENCES.ADMIN_DASHBOARD.DELETE_LICENCE, {
      licenseId,
    });
    const { data } = await del(url);
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`error ${error}`);
  }
};

// eslint-disable-next-line consistent-return
export const deleteBulkLicenses = async body => {
  try {
    const url = apiRoutes.SEARCH_LICENCES.ADMIN_DASHBOARD.DELETE_LICENCE_BULK;
    const { data } = await post(url, { ids: body });
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`error ${error}`);
  }
};

// eslint-disable-next-line consistent-return
export const editBulkLicenses = async (idList, valueList) => {
  try {
    const url = apiRoutes.SEARCH_LICENCES.ADMIN_DASHBOARD.EDIT_LICENCE_BULK;
    const { data } = await put(url, { ids: idList, values: valueList });
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`error ${error}`);
  }
};

// eslint-disable-next-line consistent-return
export const importBulkLicenses = async (file, updateProgress) => {
  try {
    const formData = new FormData();
    formData.append('hemp_data', file);
    const config = {
      onUploadProgress: progressEvent => {
        updateProgress(progressEvent);
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const url = apiRoutes.SEARCH_LICENCES.ADMIN_DASHBOARD.LICENCE_BULK_UPLOAD;
    const { data } = await post(url, formData, config);
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`error ${error}`);
    return error;
  }
};

// eslint-disable-next-line consistent-return
export const getBulkUploadFailureReportURL = async id => {
  try {
    const url = replaceParameters(
      apiRoutes.SEARCH_LICENCES.ADMIN_DASHBOARD.BULK_UPLOAD_FAILURE_REPORT_DOWNLOAD,
      {
        id,
      },
    );
    const { data } = await get(url);
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`error ${error}`);
  }
};
