import styled from 'styled-components';

const StyleModal = styled.div`
  .modal-header,
  .modal-body {
    border: none;
    padding: 35px;
    .table-responsive {
      height: auto !important;
    }
  }
  .modal-header {
    padding-bottom: 25px;
  }
  .h4 {
    font-size: 20px;
    font-weight: 900;
  }
  .modal-content-item {
    b {
      font-size: 14px;
      font-weight: 900;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      word-break: break-word;
    }
  }
  .model-button-row {
    display: flex;
    justify-content: right;
  }
  .model-button-col {
    margin-right: 20px;
  }
  .modal-tab {
    border-radius: 10px;
    .css-1pahdxg-control,
    .css-1insrsq-control,
    .css-16xfy0z-control {
      background: transparent;
      box-shadow: none;
    }
    ul {
      &.nav-tabs {
        border-bottom: 1px solid #cbdedf;
        margin-bottom: 0 !important;
      }
      li {
        background: transparent;
        button {
          padding: 16px;
          border: none;
          font-size: 16px;
          font-weight: bold;
          color: #000000;
          &.active {
            color: ${props => props.theme?.general?.primaryColor};
            background: transparent;
            border-bottom: 2px solid ${props => props.theme?.general?.primaryColor};
          }
        }
      }
    }
    .tab-content {
      padding: 20px 0;
    }
  }
`;

export default StyleModal;
