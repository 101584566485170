import React, { useState } from 'react';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

const DateInput = props => {
  const { id, name, value, handleUpdate, getInitialValue, edited, disabled } = props;

  const [fieldValue, setFieldValue] = useState(value ? new Date(value) : null);

  return (
    <Form.Group controlId={id}>
      <DatePicker
        className={`form-control ${edited ? 'edited' : ''}`}
        format="MM/DD/YYYY"
        onKeyDown={e => {
          e.preventDefault();
        }}
        selected={fieldValue}
        onChange={date => {
          const initDate = getInitialValue(id, name);
          setFieldValue(date || initDate);
          handleUpdate(date ? moment(date).format('YYYY-MM-DD') : initDate);
        }}
        placeholderText={fieldValue ? '' : '-'}
        disabled={disabled}
        isClearable={!disabled}
      />
    </Form.Group>
  );
};

export default DateInput;
