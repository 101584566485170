/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable arrow-body-style */
/* eslint-disable array-callback-return */
import React, { useContext, useState } from 'react';
import { Container, Row, Col, Image, Form } from 'react-bootstrap';
import makeAnimated from 'react-select/animated';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useNavigate } from 'react-router-dom';

import DetailsModal from './detailsModal/DetailsModal';
import SearchLicenseRow from './searchLicenseRow/SearchLicenseRow';
import SearchLicenseInlineEditRow from './searchLicenseInlineEditRow/SearchLicenseInlineEditRow';
import BulkRecordTableHeader from './bulkRecordTableHeader/BulkRecordTableHeader';
import BulkEditModal from './bulkEditModal/BulkEditModal';
import ReordsFileUpload from './recordsFileUpload/recordsFileUpload';
import UploadedDataVisual from './uploadedDataVisual/UploadedDataVisual';
import EditModal from './editModal/EditModal';
import { BulkUploadSuccessModal } from './uploadedDataVisual/BulkUploadSuccessModal';
import { StylePage } from '../styles/Page.styled';
import TableComponet from '../common/table/Table';
import PaginationComponet from '../common/pagination/Pagination';
import PaginationDropdown from '../common/input/PaginationDropdown';
import PageHeader from '../common/pageHeader/PageHeader';
import Search from '../common/search/Search';
import TableHeader from '../common/table/TableHeader';
import TableBody from '../common/table/TableBody';
import SingleTableHeader from '../common/table/SingleTableHeader';
import FilterDropDown from '../common/input/stateFilterDropDown/FilterDropDown';
import MultiValue from '../common/input/stateFilterDropDown/MultiValue';
import Option from '../common/input/stateFilterDropDown/Options';

import SearchLicenseHelper from '../../helpers/features/SearchLicensesHelper';
import { genarateIdList, clearIdList, arrayRemove } from '../../helpers/commonHelper';
import { SearchLicencesContext } from '../../containers/searchLicenses/SearchLicensesContext';
import StateHelper from '../../helpers/stateHelper';
import StyleForm from '../common/input/Form.styled';

import FilterIcon from '../../assets/images/filter.svg';
import LodingIcon from '../../assets/images/spinner.gif';

import ConfirmIcon from '../../assets/images/confirme-icon.svg';
import DeleteIcon from '../../assets/images/delete-icon.svg';
import AppButton from '../styles/ButtonLink.styled';
import LicensesHelper from '../../helpers/features/LicensesHelper';
import Permissions from '../auth/Permissions';
import GlobalStyles from '../styles/Global';

function SearchLicenses() {
  const {
    licenseData,
    totalLicenseRecords,
    isOpenDetailsModal,
    isLoadingLicenseData,
    selectedOption,
    setSelectedOption,
    handleStateFilter,
    totalPages,
    isInlineEditing,
    editingRowId,
    isAdmin,
    isSaveWarningPopUpOpen,
    editedValues,
    handleInlineEdit,
    setIsSaveWarningPopUpOpen,
    isDeleteWarningPopUpOpen,
    setIsDeleteWarningPopUpOpen,
    handleSingleDelete,
    bulkIdList,
    setBulkIdList,
    isBulkDelete,
    handleBulkDelete,
    isBulkEditModalOpen,
    handleImportModalOpen,
    isOpenImportModal,
    isOpenImportDataVisualModal,
    setIsBulkDelete,
    isSelectAll,
    setIsSelectAll,
    isEditModalOpen,
    openEditModalError,
    setOpenEditModalError,
    isBulkUploadSuccessModalOpen,
    setBulkUploadSuccessModalOpen,
    bulkUploadProgress,
    reordsPerPage,
    currentPage,
    allSelectedPages,
    setAllSelectedPages,
    setCurrentSortedTitle,
    handleFetchSortedLicenseData,
    currentSortedTitle,
    handleSearch,
    setSearchParameter,
    searchParameter,
    handleRecordsPerPage,
    handleLicenceTablePageNavigation,
    nextPage,
  } = useContext(SearchLicencesContext);

  /* -------------------------------------------------------------------------- */
  /*                                   Router                                   */
  /* -------------------------------------------------------------------------- */
  const navigate = useNavigate();

  const animatedComponents = makeAnimated();

  const [recordCount, setRecordCount] = useState(0);

  const createLicense = () => {
    navigate('/licenses/create');
  };

  return (
    <Container>
      <StylePage>
        <Row>
          <Col md={6}>
            <PageHeader
              title={SearchLicenseHelper.PAGE_HEADER}
              subheader={`${totalLicenseRecords} Records`}
            />
          </Col>
          <Col md={6} className="d-flex justify-content-end">
            <Permissions permissionRole="STANDARD">
              <Search
                handleSearch={handleSearch}
                setSearchParameter={setSearchParameter}
                searchParameter={searchParameter}
              />
            </Permissions>
            <div className="text-right">
              <Permissions permissionRole="ADMIN">
                <AppButton
                  className="me-2 secondary-btn"
                  fill="true"
                  onClick={() => handleImportModalOpen()}
                >
                  {LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_IMPORT_TEXT}
                </AppButton>

                <AppButton fill="true" onClick={() => createLicense()}>
                  {LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_SUBMIT_BUTTON_TEXT}
                </AppButton>
              </Permissions>
            </div>
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col md={2} className="mb-3">
            <StyleForm className="state-dropdown">
              <Image src={FilterIcon} />
              <FilterDropDown
                options={StateHelper.STATES}
                isMulti
                isSearchable={false}
                isClearable={false}
                controlShouldRenderValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={value => {
                  setSelectedOption(value);
                  handleStateFilter(value);
                }}
                value={selectedOption}
                placeholder="State"
              />
            </StyleForm>
          </Col>
          <Col md={2} className="admin-sarch">
            <Permissions permissionRole="ADMIN">
              <Search
                customClass="admin-search"
                handleSearch={handleSearch}
                setSearchParameter={setSearchParameter}
                searchParameter={searchParameter}
              />
            </Permissions>
          </Col>
          <Col md={8} className="mb-3 d-flex justify-content-end">
            <PaginationDropdown handleRecordsPerPage={handleRecordsPerPage} />
            {totalPages > 1 && (
              <PaginationComponet
                totalPages={totalPages}
                currentPage={currentPage}
                handleTablePageNavigation={handleLicenceTablePageNavigation}
                nextPage={nextPage}
              />
            )}
          </Col>
        </Row>
        <TableComponet
          className={isAdmin ? 'admin-licenes' : 'user-licences'}
          isSelectAll={isSelectAll}
          isInlineEditing={isInlineEditing}
        >
          <TableHeader>
            {isAdmin && (
              <th>
                <StyleForm>
                  <Form.Group className="custom-checkbox-green">
                    <Form.Check
                      className={`${
                        !(isSelectAll || allSelectedPages.includes(currentPage)) && 'among-slected'
                      } position-relative`}
                      readOnly
                      type="checkbox"
                      id="disabledFieldsetCheck"
                      checked={
                        (bulkIdList.length !== 0 && size(allSelectedPages) === 0) ||
                        isSelectAll ||
                        allSelectedPages.includes(currentPage)
                      }
                      onChange={() => {
                        if (isSelectAll || allSelectedPages.includes(currentPage)) {
                          setBulkIdList(
                            clearIdList(genarateIdList(licenseData.docs, []), bulkIdList),
                          );
                          setIsSelectAll(false);
                          setAllSelectedPages(arrayRemove(allSelectedPages, currentPage));
                        } else {
                          setAllSelectedPages([...allSelectedPages, currentPage]);
                          setIsSelectAll(true);
                          setBulkIdList(genarateIdList(licenseData.docs, bulkIdList));
                        }
                      }}
                    />
                  </Form.Group>
                </StyleForm>
              </th>
            )}
            {isAdmin && bulkIdList.length === 0 && !isSelectAll ? (
              SearchLicenseHelper.HEMP_LICENSES_ADMIN_TABLE_HEADERS.map(item => {
                return (
                  <SingleTableHeader
                    title={item}
                    key={item.key}
                    setCurrentSortedTitle={setCurrentSortedTitle}
                    handleFetchSortedData={handleFetchSortedLicenseData}
                    currentSortedTitle={currentSortedTitle}
                  />
                );
              })
            ) : bulkIdList.length === 0 && !isSelectAll ? (
              SearchLicenseHelper.HEMP_LICENSES_TABLE_HEADERS.map(item => {
                return (
                  <SingleTableHeader
                    title={item}
                    key={item.key}
                    setCurrentSortedTitle={setCurrentSortedTitle}
                    handleFetchSortedData={handleFetchSortedLicenseData}
                    currentSortedTitle={currentSortedTitle}
                  />
                );
              })
            ) : bulkIdList.length > 0 || isSelectAll ? (
              <th colSpan={27} className="th-bulk-action">
                <BulkRecordTableHeader />{' '}
              </th>
            ) : (
              ''
            )}
          </TableHeader>
          <TableBody>
            {licenseData.docs &&
              licenseData.docs.map((item, i) => {
                if (isInlineEditing && item.id === editingRowId) {
                  return <SearchLicenseInlineEditRow data={item} key={item.id} index={i} />;
                }
                return (
                  <SearchLicenseRow
                    data={item}
                    key={item.id}
                    countRecords={setRecordCount}
                    count={recordCount}
                    index={i}
                  />
                );
              })}
            {isEmpty(licenseData.docs) && !isLoadingLicenseData && (
              <tr className="without-data">
                <td colSpan="24">
                  <p>{SearchLicenseHelper.NO_RECORDS_FOUND}</p>
                </td>
              </tr>
            )}
            {isLoadingLicenseData && (
              <tr className="loding-data">
                <td colSpan="24">
                  <Image src={LodingIcon} sizes="50px" />
                </td>
              </tr>
            )}
          </TableBody>
        </TableComponet>
        {isOpenDetailsModal && <DetailsModal />}
        {isOpenImportModal && <ReordsFileUpload />}
        {isOpenImportDataVisualModal && <UploadedDataVisual />}
        {isBulkUploadSuccessModalOpen && (
          <BulkUploadSuccessModal
            setBulkUploadSuccessModalOpen={setBulkUploadSuccessModalOpen}
            bulkUploadProgress={bulkUploadProgress}
          />
        )}
        {isSaveWarningPopUpOpen && (
          <SweetAlert
            custom
            showCancel
            confirmBtnText={SearchLicenseHelper.INLINE_SAVE_CONFIRMATION_ALERT_TOST_SUCCESS_BUTTON}
            confirmBtnCssClass="popup confirm"
            cancelBtnText={SearchLicenseHelper.INLINE_SAVE_CONFIRMATION_ALERT_TOST_CANCEL_BUTTON}
            cancelBtnCssClass="popup cancel"
            confirmBtnBsStyle="success"
            customIcon={ConfirmIcon}
            show={isSaveWarningPopUpOpen}
            title={SearchLicenseHelper.INLINE_SAVE_CONFIRMATION_ALERT_TITLE}
            onConfirm={() => {
              handleInlineEdit(editingRowId, editedValues);
            }}
            onCancel={() => setIsSaveWarningPopUpOpen(false)}
          >
            {SearchLicenseHelper.INLINE_SAVE_CONFIRMATION_ALERT_DESCRIPTION}
          </SweetAlert>
        )}
        {isDeleteWarningPopUpOpen && (
          <SweetAlert
            custom
            showCancel
            title={
              isBulkDelete
                ? SearchLicenseHelper.INLINE_DELETE_CONFIRMATION_ALERT_DESCRIPTION_BULK
                : SearchLicenseHelper.INLINE_DELETE_CONFIRMATION_ALERT_DESCRIPTION
            }
            confirmBtnText={
              SearchLicenseHelper.INLINE_DELETE_CONFIRMATION_ALERT_TOST_SUCCESS_BUTTON
            }
            confirmBtnCssClass="popup submit"
            cancelBtnText={SearchLicenseHelper.INLINE_DELETE_CONFIRMATION_ALERT_TOST_CANCEL_BUTTON}
            cancelBtnCssClass="popup cancel"
            confirmBtnBsStyle="danger"
            customIcon={DeleteIcon}
            show={isDeleteWarningPopUpOpen}
            onConfirm={() => {
              if (isBulkDelete) {
                handleBulkDelete();
              } else {
                handleSingleDelete(editingRowId);
              }
            }}
            onCancel={() => {
              setIsDeleteWarningPopUpOpen(false);
              setIsBulkDelete(false);
            }}
          />
        )}
        {isBulkEditModalOpen && <BulkEditModal />}
        {isEditModalOpen && <EditModal />}
        {openEditModalError && (
          <SweetAlert
            danger
            confirmBtnText="OK"
            confirmBtnBsStyle="danger"
            title="Error!"
            onConfirm={() => setOpenEditModalError(false)}
          >
            {SearchLicenseHelper.MANDATORY_FEILD_EDIT_ERROR_ALERT}
          </SweetAlert>
        )}
      </StylePage>
    </Container>
  );
}

export default SearchLicenses;
