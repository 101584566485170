import React from 'react';
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';
import noop from 'lodash/noop';

import DeleteIcon from '../../../assets/images/delete-icon.svg';

const DangerAlert = ({ show, onConfirm, onCancel, title, confirmButtonText, cancelButtonText }) => {
  return (
    <SweetAlert
      custom
      showCancel
      showCloseButton
      confirmBtnText={confirmButtonText}
      confirmBtnCssClass="popup"
      cancelBtnText={cancelButtonText}
      cancelBtnCssClass="popup cancel"
      confirmBtnBsStyle="danger"
      customIcon={DeleteIcon}
      show={show}
      title={title}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
};

DangerAlert.propTypes = {
  show: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
};

DangerAlert.defaultProps = {
  show: false,
  onConfirm: noop,
  onCancel: noop,
  title: '',
  confirmButtonText: 'Confirm',
  cancelButtonText: 'Cancel',
};

export default DangerAlert;
