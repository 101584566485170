import React from 'react';
import { Button, Col, Container, Image, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import makeAnimated from 'react-select/animated';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

import UsersProvider, { UsersContext } from './UsersContext';
import Permissions from '../../components/auth/Permissions';
import StyleForm from '../../components/common/input/Form.styled';
import Option from '../../components/common/input/stateFilterDropDown/Options';
import MultiValue from '../../components/common/input/stateFilterDropDown/MultiValue';
import FilterDropDown from '../../components/common/input/stateFilterDropDown/FilterDropDown';
import PageHeader from '../../components/common/pageHeader/PageHeader';
import AppButton from '../../components/styles/ButtonLink.styled';
import TableHeader from '../../components/common/table/TableHeader';
import { StylePage } from '../../components/styles/Page.styled';
import { StyleTable } from '../../components/styles/Table.styled';
import UserTableBody from '../../components/users/UserTableBody';
import { UsersHelper } from '../../helpers/usersHelper';
import FilterIcon from '../../assets/images/filter.svg';
import SortIcon from '../../assets/images/sort-icon.svg';
import { PaginationV2 } from '../../components/common/pagination/PaginationV2';
import { UserSearch } from '../../components/users/UserSearch';
import { PaginationDropDownV2 } from '../../components/common/pagination/PaginationDropDownV2';

export const Users = props => {
  const navigate = useNavigate();
  const animatedComponents = makeAnimated();
  return (
    <StylePage>
      <Container>
        <UsersProvider componentProps={props}>
          <Row>
            <Col md={6}>
              <UsersContext.Consumer>
                {({ userDataList }) => (
                  <PageHeader
                    title={UsersHelper.PAGE_HEADER}
                    subheader={`${userDataList?.count || 0} records`}
                  />
                )}
              </UsersContext.Consumer>
            </Col>
            <Col md={6} className="d-flex justify-content-end">
              <Permissions permissionRole="ADMIN">
                <AppButton onClick={() => navigate('add')} fill="true">
                  {UsersHelper.LABELS.ADD_USER}
                </AppButton>
              </Permissions>
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col md={2} className="mb-3">
              <StyleForm className="state-dropdown">
                <Image src={FilterIcon} />
                <UsersContext.Consumer>
                  {({
                    setFilterObj,
                    filterObj,
                    statusFilterValue,
                    setStatusFilterValue,
                    setCurrentPage,
                  }) => (
                    <FilterDropDown
                      options={UsersHelper.USER_STATUS_FILTER_OPTIONS}
                      isSearchable={false}
                      isClearable={false}
                      controlShouldRenderValue={false}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      components={{ Option, animatedComponents }}
                      onChange={value => {
                        if (isEqual(statusFilterValue, value)) {
                          setStatusFilterValue(null);
                          const copyOfFilterObj = cloneDeep(filterObj);
                          delete copyOfFilterObj?.status;
                          copyOfFilterObj.page = 1;
                          setFilterObj(copyOfFilterObj);
                        } else {
                          setStatusFilterValue(value);
                          setFilterObj({ ...filterObj, status: value?.value, page: 1 });
                          setCurrentPage(1);
                        }
                      }}
                      value={statusFilterValue}
                      placeholder="Status"
                    />
                  )}
                </UsersContext.Consumer>
              </StyleForm>
            </Col>
            <Col md={2} className="admin-sarch">
              <Permissions permissionRole="ADMIN">
                <UserSearch customClass="admin-search" />
              </Permissions>
            </Col>
            <Col md={8} className="mb-3 d-flex justify-content-end">
              {/* <PaginationDropdown /> */}
              <UsersContext.Consumer>
                {({
                  totalPages,
                  currentPage,
                  nextPage,
                  handleNavigation,
                  handleRecordsPerPage,
                }) => (
                  <>
                    <PaginationDropDownV2 handleRecordsPerPage={handleRecordsPerPage} />
                    {totalPages > 1 && (
                      <PaginationV2
                        totalPages={totalPages}
                        currentPage={currentPage}
                        nextPage={nextPage}
                        handleNavigation={handleNavigation}
                      />
                    )}
                  </>
                )}
              </UsersContext.Consumer>
            </Col>
          </Row>
          <StyleTable>
            <Table responsive>
              <TableHeader>
                <UsersContext.Consumer>
                  {({ handleSorting, sortParams }) => (
                    <>
                      {UsersHelper.USER_TABLE_HEADERS.map((user, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <th key={index + 1}>
                          {user.LABEL}
                          {user.LABEL !== '' && user.KEY !== 'resetPassword' && (
                            <Button
                              className={
                                sortParams.sortBy === user.KEY ? 'sort-btn active' : 'sort-btn'
                              }
                              variant=""
                              onClick={() => handleSorting(user.KEY)}
                            >
                              <Image src={SortIcon} />
                            </Button>
                          )}
                        </th>
                      ))}
                    </>
                  )}
                </UsersContext.Consumer>
              </TableHeader>
              <UserTableBody />
            </Table>
          </StyleTable>
        </UsersProvider>
      </Container>
    </StylePage>
  );
};
