import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getHomeCardDetails } from '../../apis/homeApi';

const HomeContext = createContext();

function HomeContextProvider({ children }) {
  // user details
  const [cardDetails, setCardDetails] = useState([]);

  const fetchCardDetails = async () => {
    try {
      const response = await getHomeCardDetails();
      if (response.success) {
        setCardDetails(response.data);
      }
    } catch (e) {
      console.error('Unable to authorize user from backend', e);
    }
  };

  useEffect(() => {
    fetchCardDetails();
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                                Return values                               */
  /* -------------------------------------------------------------------------- */
  const value = useMemo(
    () => ({
      cardDetails,
      fetchCardDetails,
    }),
    [cardDetails, fetchCardDetails],
  );

  return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>;
}

function useHomeContext() {
  return useContext(HomeContext);
}

HomeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { HomeContextProvider, useHomeContext };
