/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-quotes */
import React, { useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';

export default function TextEditor({
  handleEditorChange,
  content,
  error,
  limit,
  initialValue,
  section,
  handleErrors,
}) {
  const [count, setCount] = useState(0);
  const [errors, setErrors] = useState(error);

  const characterCount = editor =>
    editor
      .getContent({ format: 'text' })
      .trim()
      .replace(/(\r\n|\n|\r)/gm, '').length;

  const validateEmptyContent = (text, charCount) => {
    if (charCount === 0) {
      setErrors(`${section} can not be empty`);
      handleErrors(true);
    } else if (charCount > 0) {
      setErrors('');
      handleErrors(false);
    }
  };

  const handleUpdate = (value, editor) => {
    const cCount = characterCount(editor);
    validateEmptyContent(value, cCount);
    handleEditorChange(value);
    setCount(cCount);
  };

  const handleBeforeAddUndo = (evt, editor) => {
    if (characterCount(editor) > limit) {
      evt.preventDefault();
    }
  };

  return (
    <div>
      <Editor
        // eslint-disable-next-line no-return-assign
        // value={value}
        initialValue={initialValue}
        init={{
          auto_focus: true,
          smart_paste: true,
          forced_root_block: 'p',
          newline_behavior: 'linebreak',
          cleanup: true,
          remove_linebreaks: true,
          convert_newlines_to_brs: false,
          inline_styles: false,
          apply_source_formatting: true,
          entity_encoding: 'raw',
          height: 250,
          width: '100%',
          menubar: false,
          branding: false, // Remove TinyMCE logo
          plugins:
            'powerpaste advcode code casechange searchreplace autolink directionality advcode visualblocks visualchars image link media mediaembed codesample table charmap pagebreak nonbreaking anchor tableofcontents insertdatetime advlist lists checklist wordcount tinymcespellchecker editimage help formatpainter permanentpen charmap linkchecker emoticons advtable export autosave',
          toolbar:
            'code | undo redo formatpainter | visualblocks | alignleft aligncenter alignright alignjustify | blocks fontfamily fontsize | bold italic underline forecolor backcolor | lineheight | removeformat',
          placeholder: { content },
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px, padding: 0px }',
        }}
        onEditorChange={handleUpdate}
        onBeforeAddUndo={handleBeforeAddUndo}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <small style={{ color: '#d32f2f', fontSize: '12px', marginTop: '4px' }}>{errors}</small>
        <p style={{ fontSize: '12px', marginTop: 8, color: '#677E94' }}>
          <span style={count > limit ? { color: '#d32f2f' } : { color: '#677E94' }}>{count}</span> /
          {limit}
        </p>
      </div>
    </div>
  );
}
