import PropTypes from 'prop-types';
import Accordion from 'react-bootstrap/Accordion';
import StyleAccoudion from './Accoudion.styled';

function AccourdionComponet({ name, description }) {
  return (
    <StyleAccoudion className="ac-item">
      <Accordion>
        <Accordion.Item eventKey="{props.id}">
          <Accordion.Header>{name}</Accordion.Header>
          <Accordion.Body>{description}</Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </StyleAccoudion>
  );
}

AccourdionComponet.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default AccourdionComponet;

// const AccourdionItem = {
//   name: '1 Lorem ipsum dolor sit amet, consectetur adipiscing elit #1',
//   eventKey: '',
// eslint-disable-next-line max-len
//   des: 'Lorem ipsum dolor sit amet, consectetur adipiscing eli Lorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing eliLorem ipsum dolor sit amet, consectetur adipiscing eli',
// };
