/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unused-vars */
import React, { createContext, useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';

import * as commonActions from '../../store/actions/common';
import {
  addUser,
  deleteUser,
  getSingleUserData,
  getUserData,
  resetPassword,
  updateUser,
} from '../../apis/userApi';
import SearchLicensesHelper from '../../helpers/features/SearchLicensesHelper';
import { useAuth } from '../auth/AuthContext';

export const UsersContext = createContext();

const defaultFilterObj = {
  page: 1,
  limit: 10,
  asc: 'first_name',
  // status: 1,
  search: '',
};

const UsersProvider = ({ children }) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const auth = useAuth();

  const [userDataList, setUserDataList] = useState(null);
  const [singleUserDetails, setSingleUserDetails] = useState({});

  const [reordsPerPage, setReordsPerPage] = useState(
    SearchLicensesHelper.LICENSE_RECORDS_DEFAULT_LIMIT,
  );
  const [totalPages, setTotalPages] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [nextPage, setNextPage] = useState(SearchLicensesHelper.LICENSE_RECORDS_STARTING_PAGE);
  const [currentPage, setCurrentPage] = useState(
    SearchLicensesHelper.LICENSE_RECORDS_STARTING_PAGE,
  );

  const [filterObj, setFilterObj] = useState(defaultFilterObj);
  const [statusFilterValue, setStatusFilterValue] = useState(null);
  const [isDeleteUserModalOpen, setDeleteUserModalOpen] = useState({ id: null, isOpen: false });
  const [isUserDataLoading, setIsUserDataLoading] = useState(false);
  const [isResetPasswordModalOpen, setResetPasswordModalOpen] = useState(null);
  const [selectedRowForEdit, setSelectedRowForEdit] = useState(null);
  const [isInlineEditModalOpen, setInlineEditModalOpen] = useState(false);
  const [sortParams, setSortParams] = useState({ sortOrder: 'asc', sortBy: 'name' });

  const fetchUserData = async filterParams => {
    setIsUserDataLoading(true);
    const response = await getUserData(filterParams);
    if (response && response.code === 200) {
      setUserDataList(response.data);
      if (isEmpty(userDataList)) {
        setTotalPages(Math.ceil(response.data.count / recordsPerPage));
      }
    } else {
      setUserDataList([]);
      dispatch(
        commonActions.showErrorAlert({
          type: 'error',
          delay: 5000,
          message: SearchLicensesHelper.ERROR_TOAST_TEXT,
          body: response?.message,
        }),
      );
      auth.handleLogout();
    }
    setIsUserDataLoading(false);
  };

  useEffect(() => {
    if (params && params.userId) {
      (async () => {
        const response = await getSingleUserData(params.userId);
        setSingleUserDetails(response.data);
      })();
    }
  }, [params && params?.userId]);

  useEffect(() => {
    fetchUserData(filterObj);
  }, [filterObj]);

  const handleUserFormSubmission = async (values, isEditView) => {
    const paramObj = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      status: values.status.value,
      role: values.userType.value,
      company: values.companyName,
    };
    let response;
    if (isEditView) {
      response = await updateUser(singleUserDetails?.id, paramObj);
    } else {
      response = await addUser(paramObj);
    }
    if (response?.success) {
      dispatch(
        commonActions.showSuccessAlert({
          type: 'success',
          delay: 2000,
          message: SearchLicensesHelper.SUCCESS_TOAST_TEXT,
          body: response?.message,
        }),
      );
      navigate('/users');
    } else {
      dispatch(
        commonActions.showErrorAlert({
          type: 'error',
          delay: 2000,
          message: SearchLicensesHelper.ERROR_TOAST_TEXT,
          body: response?.message,
        }),
      );
    }
  };

  const handleNavigation = npage => {
    if (npage !== currentPage && npage > currentPage) {
      setCurrentPage(npage);
      setNextPage(npage + 1);
      setFilterObj({
        ...filterObj,
        page: npage,
      });
    } else if (npage !== currentPage && npage < currentPage && currentPage > 0) {
      setCurrentPage(npage);
      setNextPage(npage + 1);
      setFilterObj({
        ...filterObj,
        page: npage,
      });
    }
  };

  const handleSearchFilter = debounce(value => {
    if (isEmpty(value)) {
      fetchUserData(filterObj);
    }
    setFilterObj({
      ...filterObj,
      search: !isNil(value) ? encodeURIComponent(value) : '',
      page: 1,
    });
    setCurrentPage(1);
  }, 1000);

  const handleDeleteUser = async () => {
    const response = await deleteUser(isDeleteUserModalOpen.id);
    setDeleteUserModalOpen({ id: null, isOpen: false });
    if (response?.success) {
      dispatch(
        commonActions.showSuccessAlert({
          type: 'success',
          delay: 2000,
          message: SearchLicensesHelper.SUCCESS_TOAST_TEXT,
          body: response?.message,
        }),
      );
      fetchUserData(filterObj);
    } else {
      dispatch(
        commonActions.showErrorAlert({
          type: 'error',
          delay: 2000,
          message: SearchLicensesHelper.ERROR_TOAST_TEXT,
          body: response?.message,
        }),
      );
    }
  };

  const handleRecordsPerPage = selectedLimit => {
    setFilterObj({
      ...filterObj,
      limit: selectedLimit,
    });
    setRecordsPerPage(selectedLimit);
    setTotalPages(Math.ceil(userDataList.count / selectedLimit));
  };

  const handleResetPassword = async () => {
    const response = await resetPassword({
      email: isResetPasswordModalOpen.email,
    });
    setResetPasswordModalOpen(null);
    if (response?.success) {
      dispatch(
        commonActions.showSuccessAlert({
          type: 'success',
          delay: 2000,
          message: SearchLicensesHelper.SUCCESS_TOAST_TEXT,
          body: response?.message,
        }),
      );
    } else {
      dispatch(
        commonActions.showErrorAlert({
          type: 'error',
          delay: 2000,
          message: SearchLicensesHelper.ERROR_TOAST_TEXT,
          body: response?.message,
        }),
      );
    }
  };

  const handleSorting = column => {
    const clonedFilterObj = { ...filterObj };
    if (sortParams.sortOrder === 'asc' && sortParams.sortBy === column) {
      setSortParams({ ...sortParams, sortOrder: 'desc' });
      if (!isNil(filterObj.asc)) {
        delete clonedFilterObj.asc;
      }
      setFilterObj({
        ...clonedFilterObj,
        desc: column,
      });
    } else {
      if (!isNil(filterObj.desc)) {
        delete clonedFilterObj.desc;
      }
      setSortParams({ sortOrder: 'asc', sortBy: column });
      setFilterObj({
        ...clonedFilterObj,
        asc: column,
      });
    }
    // if (filterObj.asc && filterObj.asc === column) {
    //   setFilterObj({
    //     ...filterObj,
    //     desc: column
    //   });
    // }
    // if (filterObj)
  };

  const handleInlineEdit = async (propKey, value) => {
    let paramObj;
    if (propKey === 'first_name') {
      paramObj = value;
    } else if (propKey === 'role' || propKey === 'status') {
      paramObj = { [propKey]: value.value };
    } else {
      paramObj = { [propKey]: value };
    }
    const response = await updateUser(selectedRowForEdit?.id, paramObj);
    if (response?.success) {
      dispatch(
        commonActions.showSuccessAlert({
          type: 'success',
          delay: 2000,
          message: SearchLicensesHelper.SUCCESS_TOAST_TEXT,
          body: response?.message,
        }),
      );
      setSelectedRowForEdit(null);
      setInlineEditModalOpen(false);
      fetchUserData(filterObj);
    } else {
      dispatch(
        commonActions.showErrorAlert({
          type: 'error',
          delay: 2000,
          message: SearchLicensesHelper.ERROR_TOAST_TEXT,
          body: response?.message,
        }),
      );
    }
  };

  return (
    <UsersContext.Provider
      value={{
        userDataList,
        singleUserDetails,
        handleUserFormSubmission,
        handleNavigation,
        reordsPerPage,
        totalPages,
        nextPage,
        currentPage,
        setCurrentPage,
        setNextPage,
        setReordsPerPage,
        setTotalPages,
        handleSearchFilter,
        filterObj,
        setFilterObj,
        setStatusFilterValue,
        statusFilterValue,
        isDeleteUserModalOpen,
        setDeleteUserModalOpen,
        handleDeleteUser,
        isUserDataLoading,
        setResetPasswordModalOpen,
        isResetPasswordModalOpen,
        handleRecordsPerPage,
        handleResetPassword,
        handleSorting,
        handleInlineEdit,
        selectedRowForEdit,
        setSelectedRowForEdit,
        isInlineEditModalOpen,
        setInlineEditModalOpen,
        sortParams,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export default UsersProvider;
