import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import { Image } from 'react-bootstrap';

export default function ToolTip({ icon, toolTipContent }) {
  return (
    <Tooltip placement="right" trigger={['click', 'hover']} overlay={toolTipContent}>
      <Image src={icon} className="ms-1" />
    </Tooltip>
  );
}
