import { BACKEND_API_BASE_URL, BACKEND_API_BASE_URL_NEW } from '../config/config';

export const BACKEND_SERVICE_PATHS = {
  USER_SERVICE: `${BACKEND_API_BASE_URL}users-service/v1/`,
  LICENSE_SERVICE: `${BACKEND_API_BASE_URL}hemp-service/v1/`,
  DASHBOARD: `${BACKEND_API_BASE_URL}hemp-service/v1/dashboard/`,
  THEME: `${BACKEND_API_BASE_URL}hemp-service/v1/themes/`,
  LICENSE_SERVICE_V2: `${BACKEND_API_BASE_URL_NEW}license-service/v1/`,
};

export const BACKEND_SERVICE_RESOURCE_PATHS = {
  AUTHENTICATION: 'authentication',
  PERMISSIONS: 'permission',
  ROLES: 'role',
};

const apiRoutes = Object.freeze({
  COMMON: {},
  AUTH: {
    SIGN_IN: `${BACKEND_SERVICE_PATHS.USER_SERVICE}login`,
    FORGET_PASSWORD: `${BACKEND_SERVICE_PATHS.USER_SERVICE}forgot-password`,
    VEIRFY_REST_PASSWORD_TOKEN: `${BACKEND_SERVICE_PATHS.USER_SERVICE}validate-token/{code}`,
    VEIRFY_SET_PASSWORD: `${BACKEND_SERVICE_PATHS.USER_SERVICE}verify-reset-password-token`,
    GET_PERMISSIONS: `${BACKEND_SERVICE_PATHS.USER_SERVICE}role-permissions`,
    LOGOUT: `${BACKEND_SERVICE_PATHS.USER_SERVICE}logout`,
  },
  SEARCH_LICENCES: {
    USER_DASHBOARD: {
      GET_ALL_LICENCES: `${process.env.REACT_APP_BACKEND_API_BASE_URL}hemp-service/v1/hemp-licenses/filter`,
      GET_LICENCE: `${process.env.REACT_APP_BACKEND_API_BASE_URL}hemp-service/v1/hemp-licenses/{licenseId}`,
    },
    ADMIN_DASHBOARD: {
      CREATE_LICENCE: `${process.env.REACT_APP_BACKEND_API_BASE_URL}hemp-service/v1/hemp-licenses`,
      UPDATE_LICENCE: `${process.env.REACT_APP_BACKEND_API_BASE_URL}hemp-service/v1/hemp-licenses/{licenseId}`,
      DELETE_LICENCE: `${process.env.REACT_APP_BACKEND_API_BASE_URL}hemp-service/v1/hemp-licenses/{licenseId}`,
      DELETE_LICENCE_BULK: `${process.env.REACT_APP_BACKEND_API_BASE_URL}hemp-service/v1/hemp-licenses/bulk/delete`,
      EDIT_LICENCE_BULK: `${process.env.REACT_APP_BACKEND_API_BASE_URL}hemp-service/v1/hemp-licenses/bulk/edit`,
      LICENCE_BULK_UPLOAD: `${process.env.REACT_APP_BACKEND_API_BASE_URL}hemp-service/v1/hemp-licenses/bulk`,
      BULK_UPLOAD_FAILURE_REPORT_DOWNLOAD: `${process.env.REACT_APP_BACKEND_API_BASE_URL}hemp-service/v1/hemp-licenses/download/{id}`,
    },
  },
  USER: {
    GET_USER_PROFILE_DETAILS: `${BACKEND_SERVICE_PATHS.USER_SERVICE}profile`,
    UPDATE_USER_PROFILE_DETAILS: `${BACKEND_SERVICE_PATHS.USER_SERVICE}user/{id}`,
    USER_DATA: `${BACKEND_SERVICE_PATHS.USER_SERVICE}user`,
    signup: `${BACKEND_SERVICE_PATHS.USER_SERVICE}sign-up`,
    resetPassword: `${BACKEND_SERVICE_PATHS.USER_SERVICE}reset-password`,
    GET_USERS: `${BACKEND_SERVICE_PATHS.USER_SERVICE}user`,
    GET_USER_BY_ID: `${BACKEND_SERVICE_PATHS.USER_SERVICE}user/{id}`,
    CREATE_USER: `${BACKEND_SERVICE_PATHS.USER_SERVICE}sign-up`,
    DELETE_USER: `${BACKEND_SERVICE_PATHS.USER_SERVICE}user/{id}`,
  },
  HOME: {
    GET_HOME_CARD_DETAILS: `${BACKEND_SERVICE_PATHS.DASHBOARD}`,
  },
  REPORTS: {
    USER_LOGIN_REPORT: `${BACKEND_SERVICE_PATHS.DASHBOARD}login-stats`,
    USER_COMPANY_LIST: `${BACKEND_SERVICE_PATHS.DASHBOARD}company-list`,
    LICENSE_LOGS: `${BACKEND_SERVICE_PATHS.DASHBOARD}license-stats`,
    LICENSE_LOGS_V2: `${BACKEND_SERVICE_PATHS.LICENSE_SERVICE_V2}hemp/license-stats`,
  },
  SOURCES: {
    GET_ALL_SOURCES: `${process.env.REACT_APP_BACKEND_API_BASE_URL_2}license-service/v1/hemp/uploads`,
    CREATE_SOURCE: `${process.env.REACT_APP_BACKEND_API_BASE_URL_2}license-service/v1/uploads/upload`,
    UPDATE_SOURCE: `${process.env.REACT_APP_BACKEND_API_BASE_URL_2}license-service/v1/uploads/upload/{uploadId}`,
    PRE_SIGN_URL: `${process.env.REACT_APP_BACKEND_API_BASE_URL_2}license-service/v1/uploads/pre-sign-url`,
    SIGN_URL: `${process.env.REACT_APP_BACKEND_API_BASE_URL_2}license-service/v1/uploads/sign-url`,
    GET_PDF_MODELS: `${process.env.REACT_APP_BACKEND_API_BASE_URL_2}license-service/v1/uploads/nanonets-model`,
  },
  DATA_VALIDATION: {
    GET_ENTRY_COUNTS: `${process.env.REACT_APP_BACKEND_API_BASE_URL_2}license-service/v1/hemp/execution_history/count`,
    GET_ALL_HISTORY_ENTRIES: `${process.env.REACT_APP_BACKEND_API_BASE_URL_2}license-service/v1/hemp/execution_history`,
    GET_HISTORY_ENTRY_DETAILS: `${process.env.REACT_APP_BACKEND_API_BASE_URL_2}license-service/v1/hemp/uploads/{uploadId}/execution/{executionId}`,
    UPDATE_HISTORY_ENTRY_DETAILS: `${process.env.REACT_APP_BACKEND_API_BASE_URL_2}license-service/v1/hemp/uploads/{uploadId}/execution/{executionId}/records`,
    APPROVE_EXECUTION: `${process.env.REACT_APP_BACKEND_API_BASE_URL_2}license-service/v1/hemp/uploads/{uploadId}/execution/{executionId}/approve`,
    REJECT_EXECUTION: `${process.env.REACT_APP_BACKEND_API_BASE_URL_2}license-service/v1/hemp/uploads/{uploadId}/execution/{executionId}/reject`,
    SIGN_URL: `${process.env.REACT_APP_BACKEND_API_BASE_URL_2}license-service/v1/uploads/sign-url`,
  },
  THEME: {
    GET_THEME_DATA: `${BACKEND_SERVICE_PATHS.THEME}get-theme/{id}`,
    UPDATE_THEME_DATA: `${BACKEND_SERVICE_PATHS.THEME}update-theme/{id}`,
  },
  SETTINGS: {
    UPDATE_SESSION_TIME: `${BACKEND_SERVICE_PATHS.LICENSE_SERVICE}config-settings`,
  },
});

export default apiRoutes;
