import React from 'react';
import { Container } from 'react-bootstrap';
import { StyleDashboard, StyleCover } from './Home.styled';
import { HomeContextProvider } from './HomeContext';
import PageHeader from '../../components/common/pageHeader/PageHeader';
import DashboardContent from '../../components/dashboard/DashboardContent';

function Home(props) {
  return (
    <StyleDashboard>
      <StyleCover>
        <Container>
          <PageHeader className="text-white" title="Dashboard" subheader="" />
        </Container>
      </StyleCover>
      <Container>
        <HomeContextProvider componentProps={props}>
          <DashboardContent />
        </HomeContextProvider>
      </Container>
    </StyleDashboard>
  );
}

export default Home;
