import { get, patch, del, post } from '../libs/api/apiConnector';
import apiRoutes from '../helpers/apiRoutes';
import replaceParameters from '../helpers/parameterHelper';

export const getUserDetails = async () => {
  try {
    const url = apiRoutes.USER.GET_USER_PROFILE_DETAILS;
    const config = {
      detachAccessToken: true,
      detachRoleToken: true,
    };
    const { data } = await get(url, { config });
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error;
  }
};

export const updateUserDetails = async (body, id) => {
  try {
    const url = replaceParameters(apiRoutes.USER.UPDATE_USER_PROFILE_DETAILS, { id });
    const config = {
      detachAccessToken: true,
      detachRoleToken: true,
    };
    const { data } = await patch(url, body, { config });
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error.response.data;
  }
};

export const getUserData = async params => {
  try {
    const config = {
      detachAccessToken: true,
      detachRoleToken: true,
    };
    const { data } = await get(apiRoutes.USER.USER_DATA, { params }, { config });
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error.response.data;
  }
};

export const getSingleUserData = async id => {
  try {
    const config = {
      detachAccessToken: true,
      detachRoleToken: true,
    };
    const url = replaceParameters(apiRoutes.USER.UPDATE_USER_PROFILE_DETAILS, { id });
    const { data } = await get(url, { config });
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error.response.data;
  }
};

export const updateUser = async (id, params) => {
  try {
    const config = {};
    const url = replaceParameters(apiRoutes.USER.UPDATE_USER_PROFILE_DETAILS, { id });
    const { data } = await patch(url, params, { config });
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error.response.data;
  }
};

export const deleteUser = async id => {
  try {
    const config = {};
    const url = replaceParameters(apiRoutes.USER.UPDATE_USER_PROFILE_DETAILS, { id });
    const { data } = await del(url, { config });
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error.response.data;
  }
};

export const addUser = async params => {
  try {
    const config = {};
    const { data } = await post(apiRoutes.USER.signup, params, { config });
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error.response.data;
  }
};

export const resetPassword = async params => {
  try {
    const config = {};
    const { data } = await get(apiRoutes.USER.resetPassword, { params }, { config });
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error.response.data;
  }
};
