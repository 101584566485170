const StateValueHelper = Object.freeze({
  STATES: [
    {
      value: 'Alabama',
      label: 'AL',
      key: 1,
    },
    {
      value: 'Alaska',
      label: 'AK',
      key: 2,
    },
    {
      value: 'Arizona',
      label: 'AZ',
      key: 4,
    },
    {
      value: 'Arkansas',
      label: 'AR',
      key: 5,
    },
    {
      value: 'California',
      label: 'CA',
      key: 6,
    },
    {
      value: 'Colorado',
      label: 'CO',
      key: 8,
    },
    {
      value: 'Connecticut',
      label: 'CT',
      key: 9,
    },
    {
      value: 'Delaware',
      label: 'DE',
      key: 10,
    },
    {
      value: 'District of Columbia',
      label: 'DC',
      key: 11,
    },
    {
      value: 'Florida',
      label: 'FL',
      key: 12,
    },
    {
      value: 'Georgia',
      label: 'GA',
      key: 13,
    },
    {
      value: 'Hawaii',
      label: 'HI',
      key: 15,
    },
    {
      value: 'Idaho',
      label: 'ID',
      key: 16,
    },
    {
      value: 'Illinois',
      label: 'IL',
      key: 17,
    },
    {
      value: 'Indiana',
      label: 'IN',
      key: 18,
    },
    {
      value: 'Iowa',
      label: 'IA',
      key: 19,
    },
    {
      value: 'Kansas',
      label: 'KS',
      key: 20,
    },
    {
      value: 'Kentucky',
      label: 'KY',
      key: 21,
    },
    {
      value: 'Louisiana',
      label: 'LA',
      key: 22,
    },
    {
      value: 'Maine',
      label: 'ME',
      key: 23,
    },
    {
      value: 'Maryland',
      label: 'MD',
      key: 24,
    },
    {
      value: 'Massachusetts',
      label: 'MA',
      key: 25,
    },
    {
      value: 'Michigan',
      label: 'MI',
      key: 26,
    },
    {
      value: 'Minnesota',
      label: 'MN',
      key: 27,
    },
    {
      value: 'Mississippi',
      label: 'MS',
      key: 28,
    },
    {
      value: 'Missouri',
      label: 'MO',
      key: 29,
    },
    {
      value: 'Montana',
      label: 'MT',
      key: 30,
    },
    {
      value: 'Nebraska',
      label: 'NE',
      key: 31,
    },
    {
      value: 'Nevada',
      label: 'NV',
      key: 32,
    },
    {
      value: 'New Hampshire',
      label: 'NH',
      key: 33,
    },
    {
      value: 'New Jersey',
      label: 'NJ',
      key: 34,
    },
    {
      value: 'New Mexico',
      label: 'NM',
      key: 35,
    },
    {
      value: 'New York',
      label: 'NY',
      key: 36,
    },
    {
      value: 'North Carolina',
      label: 'NC',
      key: 37,
    },
    {
      value: 'North Dakota',
      label: 'ND',
      key: 38,
    },
    {
      value: 'Ohio',
      label: 'OH',
      key: 39,
    },
    {
      value: 'Oklahoma',
      label: 'OK',
      key: 40,
    },
    {
      value: 'Oregon',
      label: 'OR',
      key: 41,
    },
    {
      value: 'Pennsylvania',
      label: 'PA',
      key: 42,
    },
    {
      value: 'Puerto Rico',
      label: 'PR',
      key: 72,
    },
    {
      value: 'Rhode Island',
      label: 'RI',
      key: 44,
    },
    {
      value: 'South Carolina',
      label: 'SC',
      key: 45,
    },
    {
      value: 'South Dakota',
      label: 'SD',
      key: 46,
    },
    {
      value: 'Tennessee',
      label: 'TN',
      key: 47,
    },
    {
      value: 'Texas',
      label: 'TX',
      key: 48,
    },
    {
      value: 'Utah',
      label: 'UT',
      key: 49,
    },
    {
      value: 'Vermont',
      label: 'VT',
      key: 50,
    },
    {
      value: 'Virginia',
      label: 'VA',
      key: 51,
    },
    {
      value: 'Virgin Islands',
      label: 'VI',
      key: 78,
    },
    {
      value: 'Washington',
      label: 'WA',
      key: 53,
    },
    {
      value: 'West Virginia',
      label: 'WV',
      key: 54,
    },
    {
      value: 'Wisconsin',
      label: 'WI',
      key: 55,
    },
    {
      value: 'Wyoming',
      label: 'WY',
      key: 56,
    },
  ],
  STATE_SHORT: [
    'AL',
    'AK',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FL',
    'GA',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'OH',
    'OK',
    'OR',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VA',
    'VI',
    'WA',
    'WV',
    'WI',
    'WY',
  ],
  STATE_AS_LABEL: [
    {
      label: 'Alabama',
      value: 'Alabama',
      key: 1,
    },
    {
      label: 'Alaska',
      value: 'Alaska',
      key: 2,
    },
    {
      label: 'Arizona',
      value: 'Arizona',
      key: 4,
    },
    {
      label: 'Arkansas',
      value: 'Arkansas',
      key: 5,
    },
    {
      label: 'California',
      value: 'California',
      key: 6,
    },
    {
      label: 'Colorado',
      value: 'Colorado',
      key: 8,
    },
    {
      label: 'Connecticut',
      value: 'Connecticut',
      key: 9,
    },
    {
      label: 'Delaware',
      value: 'Delaware',
      key: 10,
    },
    {
      label: 'District of Columbia',
      value: 'District of Columbia',
      key: 11,
    },
    {
      label: 'Florida',
      value: 'Florida',
      key: 12,
    },
    {
      label: 'Georgia',
      value: 'Georgia',
      key: 13,
    },
    {
      label: 'Hawaii',
      value: 'Hawaii',
      key: 15,
    },
    {
      label: 'Idaho',
      value: 'Idaho',
      key: 16,
    },
    {
      label: 'Illinois',
      value: 'Illinois',
      key: 17,
    },
    {
      label: 'Indiana',
      value: 'Indiana',
      key: 18,
    },
    {
      label: 'Iowa',
      value: 'Iowa',
      key: 19,
    },
    {
      label: 'Kansas',
      value: 'Kansas',
      key: 20,
    },
    {
      label: 'Kentucky',
      value: 'Kentucky',
      key: 21,
    },
    {
      label: 'Louisiana',
      value: 'Louisiana',
      key: 22,
    },
    {
      label: 'Maine',
      value: 'Maine',
      key: 23,
    },
    {
      label: 'Maryland',
      value: 'Maryland',
      key: 24,
    },
    {
      label: 'Massachusetts',
      value: 'Massachusetts',
      key: 25,
    },
    {
      label: 'Michigan',
      value: 'Michigan',
      key: 26,
    },
    {
      label: 'Minnesota',
      value: 'Minnesota',
      key: 27,
    },
    {
      label: 'Mississippi',
      value: 'Mississippi',
      key: 28,
    },
    {
      label: 'Missouri',
      value: 'Missouri',
      key: 29,
    },
    {
      label: 'Montana',
      value: 'Montana',
      key: 30,
    },
    {
      label: 'Nebraska',
      value: 'Nebraska',
      key: 31,
    },
    {
      label: 'Nevada',
      value: 'Nevada',
      key: 32,
    },
    {
      label: 'New Hampshire',
      value: 'New Hampshire',
      key: 33,
    },
    {
      label: 'New Jersey',
      value: 'New Jersey',
      key: 34,
    },
    {
      label: 'New Mexico',
      value: 'New Mexico',
      key: 35,
    },
    {
      label: 'New York',
      value: 'New York',
      key: 36,
    },
    {
      label: 'North Carolina',
      value: 'North Carolina',
      key: 37,
    },
    {
      label: 'North Dakota',
      value: 'North Dakota',
      key: 38,
    },
    {
      label: 'Ohio',
      value: 'Ohio',
      key: 39,
    },
    {
      label: 'Oklahoma',
      value: 'Oklahoma',
      key: 40,
    },
    {
      label: 'Oregon',
      value: 'Oregon',
      key: 41,
    },
    {
      label: 'Pennsylvania',
      value: 'Pennsylvania',
      key: 42,
    },
    {
      label: 'Puerto Rico',
      value: 'Puerto Rico',
      key: 72,
    },
    {
      label: 'Rhode Island',
      value: 'Rhode Island',
      key: 44,
    },
    {
      label: 'South Carolina',
      value: 'South Carolina',
      key: 45,
    },
    {
      label: 'South Dakota',
      value: 'South Dakota',
      key: 46,
    },
    {
      label: 'Tennessee',
      value: 'Tennessee',
      key: 47,
    },
    {
      label: 'Texas',
      value: 'Texas',
      key: 48,
    },
    {
      label: 'Utah',
      value: 'Utah',
      key: 49,
    },
    {
      label: 'Vermont',
      value: 'Vermont',
      key: 50,
    },
    {
      label: 'Virginia',
      value: 'Virginia',
      key: 51,
    },
    {
      label: 'Virgin Islands',
      value: 'Virgin Islands',
      key: 78,
    },
    {
      label: 'Washington',
      value: 'Washington',
      key: 53,
    },
    {
      label: 'West Virginia',
      value: 'West Virginia',
      key: 54,
    },
    {
      label: 'Wisconsin',
      value: 'Wisconsin',
      key: 55,
    },
    {
      label: 'Wyoming',
      value: 'Wyoming',
      key: 56,
    },
  ],
});

export default StateValueHelper;
