import { get, patch, del, post } from '../libs/api/apiConnector';
import apiRoutes from '../helpers/apiRoutes';
import replaceParameters from '../helpers/parameterHelper';

export const getThemeData = async id => {
  try {
    const config = {};
    const url = replaceParameters(apiRoutes.THEME.GET_THEME_DATA, { id });
    const { data } = await get(url, { config });
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error.response.data;
  }
};

export const updateTheme = async (id, body) => {
  try {
    const config = {};
    const url = replaceParameters(apiRoutes.THEME.UPDATE_THEME_DATA, { id });
    const { data } = await post(url, body, { config });
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error.response.data;
  }
};

export const updateSessionTime = async body => {
  try {
    const url = apiRoutes.SETTINGS.UPDATE_SESSION_TIME;
    const { data } = await post(url, body);
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error.response.data;
  }
};

export const getSessionTime = async params => {
  try {
    const url = apiRoutes.SETTINGS.UPDATE_SESSION_TIME;
    const { data } = await get(url, { params });
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error.response.data;
  }
};
