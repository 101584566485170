/* eslint-disable no-lonely-if */
/* eslint-disable func-names */

import React, { useContext, useState, useEffect } from 'react';
import { Formik } from 'formik';
import moment from 'moment';
import { Row, Col, Form, Button, Image, Tab, Tabs } from 'react-bootstrap';
import isEqual from 'lodash/isEqual';
import * as yup from 'yup';

import CreateSourceScheduler from './CreateSourceScheduler';
import AppButton from '../../styles/ButtonLink.styled';
import Dropzone from '../../common/dropzone/Dropzone';

import { SourcesContext } from '../../../containers/sources/SourcesContext';
import SourcesHelper from '../../../helpers/features/SourcesHelper';
import StateValueHelper from '../../../helpers/stateHelper';
import { bytesToSize, removeTimestampFromFileName } from '../../../utils/common';

import FileIcon from '../../../assets/images/file-icon.svg';
import CloseIcon from '../../../assets/images/close.svg';
import AppInput from '../../common/input/AppInput';
import ThemeSelect from '../../common/input/ThemeSelect';

const CreateSourceForm = () => {
  const {
    showCreateSourceModal,
    setShowCreateSourceModal,
    isCreatingSource,
    handleFetchUploadUrl,
    handleCreateSourceFormSubmit,
    handleEditSourceFormSubmit,
    pdfModelOptions,
  } = useContext(SourcesContext);
  const { show, isCreate, data } = showCreateSourceModal;

  const stateOptions = StateValueHelper.STATES.map(state => {
    return { label: state.label, value: { code: state.label, name: state.value } };
  });
  const countryOptions = [
    { label: 'US', value: { code: 'US', name: 'United States of America' } },
    { label: 'CA', value: { code: 'CA', name: 'Canada' } },
  ];

  const [formInitValues, setFormInitValues] = useState(SourcesHelper.CREATE_SOURCE.FORM_INIT);
  const [dropzoneFileType, setDropzoneFileType] = useState(SourcesHelper.FILE_UPLOADER_CONFIGS.PDF);

  useEffect(() => {
    if (!isCreate) {
      setFormInitValues({
        ...formInitValues,
        licenseIssuer: data?.licensing_agency || '',
        licenseType: data?.license_type || '',
        state: data?.state
          ? stateOptions.filter(state => state.value.code === data.state)[0]
          : null,
        country: data?.country_code
          ? countryOptions.filter(code => code.value.code === data.country_code)[0]
          : null,
        sourceType: data?.source ? 'link' : 'custom',
        sourceFormat: data?.type
          ? SourcesHelper.CREATE_SOURCE.SOURCE_FORMAT_OPTIONS.filter(
              format => format.value === data.type,
            )[0]
          : null,
        sourceModel: data?.model_id
          ? pdfModelOptions.filter(option => option.value === data.model_id)[0]
          : null,
        sourceLink: data?.source || '',
        uploadData: data?.file_name
          ? { file: { name: removeTimestampFromFileName(data.file_name) } }
          : null,
        frequency: data?.frequency || '',
      });

      setDropzoneFileType(SourcesHelper.FILE_UPLOADER_CONFIGS[data.type]);
    }
  }, [isCreate, data]);

  const getFilenameWithTimestamp = filename => {
    const extensionIndex = filename.lastIndexOf('.');
    const timestamp = moment().unix();

    return `${filename.substring(0, extensionIndex)}_${timestamp}${filename.substring(
      extensionIndex,
    )}`;
  };

  const handleFileUpload = async (file, selectedFileFormat, setFieldValue, setFieldError) => {
    if (selectedFileFormat.value === 'XLS') {
      const selectedFileType = Object.keys(
        SourcesHelper.FILE_UPLOADER_CONFIGS[selectedFileFormat.value],
      );

      if (!file || !selectedFileType.includes(file.type)) {
        setFieldError('uploadData', 'Source format and file type does not match.');
      } else {
        const name = getFilenameWithTimestamp(file.name);
        const signUrl = await handleFetchUploadUrl(name);
        if (signUrl) {
          setFieldValue('uploadData', { url: signUrl, file, name });
        }
      }
    } else {
      const selectedFileType = Object.keys(
        SourcesHelper.FILE_UPLOADER_CONFIGS[selectedFileFormat.value],
      )[0];

      if (!file || file.type !== selectedFileType) {
        setFieldError('uploadData', 'Source format and file type does not match.');
      } else {
        const name = getFilenameWithTimestamp(file.name);
        const signUrl = await handleFetchUploadUrl(name);
        if (signUrl) {
          setFieldValue('uploadData', { url: signUrl, file, name });
        }
      }
    }
  };

  const handleRemoveSelectedFile = setFieldValue => {
    setFieldValue('uploadData', null);
  };
  return (
    <Formik
      enableReinitialize
      validationSchema={yup.object().shape({
        licenseIssuer: yup.string().required('License Issuer Required'),
        licenseType: yup.string(),
        state: yup.object().required('State Required').nullable(),
        country: yup.object().required('Country Required').nullable(),
        sourceFormat: yup.object().required('Source Format Required').nullable(),
        sourceModel: yup
          .object()
          .test('checkModelRequired', 'Model Required', function (value) {
            const { parent } = this;
            if (parent.sourceFormat && parent.sourceFormat.value === 'PDF') {
              if (!value) return false;
              return true;
            }
            return true;
          })
          .nullable(),
        sourceType: yup.string(),
        sourceLink: yup
          .string()
          .test('invalidLink', 'Please enter a valid web link.', function (value) {
            const { parent } = this;
            const regex = /^(https:\/\/|http:\/\/)([\w.-]+\.[a-zA-Z]{2,})(\/\S*)?$/;
            if (parent.sourceType === 'link') {
              if (value) return regex.test(value);
            }
            return true;
          })
          .test('checkSourceLinkRequired', 'Source Link Required', function (value) {
            const { parent } = this;
            if (parent.sourceType === 'link') {
              if (!value) return false;
              return true;
            }
            return true;
          }),
        uploadData: yup
          .object()
          .test('checkFilenameRequired', 'Upload a file', function (value) {
            const { parent } = this;
            if (parent.sourceType !== 'link') {
              if (!value) return false;
              return true;
            }
            return true;
          })
          .nullable(),
        // frequency: yup.string().required('Please configure the scheduler'),
      })}
      initialValues={formInitValues}
      onSubmit={values => {
        if (isCreate) handleCreateSourceFormSubmit(values);
        else handleEditSourceFormSubmit(values, formInitValues);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldError,
        isValid,
        dirty,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="modal-tab">
            <Tabs
              defaultActiveKey="sourceFormat"
              id="create-source-modal-tabs"
              className="mb-3"
              fill
            >
              <Tab eventKey="sourceFormat" title="Source Format">
                <Row>
                  <Col md={6}>
                    <AppInput
                      label="License Issuer"
                      requied="*"
                      type="text"
                      name="licenseIssuer"
                      value={values.licenseIssuer}
                      onChangeInput={handleChange}
                      onBlurInput={handleBlur}
                      placeholder="Type License Issuer"
                      errorText={
                        errors?.licenseIssuer || touched?.licenseIssuer ? errors.licenseIssuer : ''
                      }
                    />
                  </Col>
                  <Col md={6}>
                    <AppInput
                      label="License Type"
                      requied=""
                      type="text"
                      name="licenseType"
                      value={values.licenseType}
                      onChangeInput={handleChange}
                      onBlurInput={handleBlur}
                      placeholder="Type License Type"
                      errorText={
                        errors?.licenseType || touched?.licenseType ? errors.licenseType : ''
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <ThemeSelect
                      label="State"
                      requied="*"
                      name="state"
                      options={stateOptions}
                      onChangeInput={selected => setFieldValue('state', selected)}
                      onBlurInput={handleBlur}
                      value={values.state}
                      placeholder="Select State"
                      errorText={errors?.state || touched?.state ? errors.state : ''}
                      isSearchable
                    />
                  </Col>
                  <Col md={6}>
                    <ThemeSelect
                      label="Country Code"
                      requied="*"
                      name="country"
                      options={countryOptions}
                      onChangeInput={selected => setFieldValue('country', selected)}
                      onBlurInput={handleBlur}
                      value={values.country}
                      placeholder="Select Country Code"
                      errorText={errors?.country || touched?.country ? errors.country : ''}
                      isSearchable
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group className="custom-checkbox-green">
                      <Form.Label>Source Type</Form.Label>
                      <div className="d-flex justify-content-start gap-5">
                        <Form.Check
                          type="radio"
                          label="Link"
                          onChange={e => setFieldValue('sourceType', 'link')}
                          checked={isEqual(values.sourceType, 'link')}
                        />
                        <Form.Check
                          type="radio"
                          label="Custom Upload"
                          onChange={e => setFieldValue('sourceType', 'custom')}
                          checked={isEqual(values.sourceType, 'custom')}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={6}>
                        <ThemeSelect
                          label="Source Format"
                          requied="*"
                          name="sourceFormat"
                          options={SourcesHelper.CREATE_SOURCE.SOURCE_FORMAT_OPTIONS}
                          onChangeInput={selected => {
                            setFieldValue('sourceFormat', selected);
                            setDropzoneFileType(
                              SourcesHelper.FILE_UPLOADER_CONFIGS[selected.value],
                            );
                            handleRemoveSelectedFile(setFieldValue);
                          }}
                          onBlurInput={handleBlur}
                          value={values.sourceFormat}
                          placeholder="Select Source Format"
                          errorText={
                            errors?.sourceFormat || touched?.sourceFormat ? errors.sourceFormat : ''
                          }
                          isSearchable
                        />
                      </Col>
                      <Col md={6}>
                        <ThemeSelect
                          label="Model"
                          requied={values.sourceFormat.value === 'PDF' ? '*' : ''}
                          name="sourceModel"
                          options={pdfModelOptions}
                          onChangeInput={selected => setFieldValue('sourceModel', selected)}
                          onBlurInput={handleBlur}
                          value={values.sourceFormat.value === 'PDF' ? values.sourceModel : null}
                          placeholder="Select Model"
                          errorText={
                            values.sourceFormat.value === 'PDF' &&
                            (errors?.sourceModel || touched?.sourceModel)
                              ? errors.sourceModel
                              : ''
                          }
                          isSearchable
                          disabled={values.sourceFormat.value !== 'PDF'}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    {isEqual(values.sourceType, 'link') ? (
                      <AppInput
                        label="Source Link"
                        requied="*"
                        type="text"
                        name="sourceLink"
                        value={values.sourceLink}
                        onChangeInput={handleChange}
                        onBlurInput={handleBlur}
                        placeholder="Enter Source Link"
                        errorText={
                          errors?.sourceLink || touched?.sourceLink ? errors.sourceLink : ''
                        }
                      />
                    ) : (
                      <Form.Group>
                        {values.uploadData ? (
                          <UploadedFile
                            name={values.uploadData.file.name}
                            size={values.uploadData.file.size}
                            handleRemoveFile={() => handleRemoveSelectedFile(setFieldValue)}
                          />
                        ) : (
                          <>
                            <Dropzone
                              label="Upload File"
                              maxFiles={1}
                              multiple={false}
                              onDrop={uploadedFIles =>
                                handleFileUpload(
                                  uploadedFIles[0],
                                  values.sourceFormat,
                                  setFieldValue,
                                  setFieldError,
                                )
                              }
                              config={{ accept: dropzoneFileType }}
                            />
                            <small className="red">
                              {errors?.uploadData || touched?.uploadData ? errors.uploadData : ''}
                            </small>
                          </>
                        )}
                      </Form.Group>
                    )}
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="scheduled" title="Scheduled" disabled>
                {/* <CreateSourceScheduler formProps={{ values, errors, setFieldValue }} /> */}
              </Tab>
            </Tabs>
          </div>
          <div className="d-flex gap-2 popup-btn-row mt-4">
            <AppButton
              fill="true"
              className="popup cancel m-0"
              onClick={() => setShowCreateSourceModal({ data: null, isCreate: true, show: false })}
            >
              Cancel
            </AppButton>
            <AppButton
              fill="true"
              className="popup m-0"
              type="submit"
              disabled={!isValid || !dirty || isCreatingSource}
            >
              Save
            </AppButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const UploadedFile = ({ name, size, handleRemoveFile }) => {
  return (
    <ul className="m-0 p-0">
      <li className="mt-2 mb-3 upload-list-item d-flex align-items-center justify-content-between">
        <div className="d-flex gap-2">
          <div className="thumbnail-wrap">
            <Image src={FileIcon} alt="File Thumbnail" />
          </div>
          <div>
            <p className="u-l-i-name m-0">{name}</p>
            {size && <p className="u-l-i-size m-0">{bytesToSize(size)}</p>}
          </div>
        </div>
        <Button className="link-btn">
          <Image src={CloseIcon} onClick={handleRemoveFile} />
        </Button>
      </li>
    </ul>
  );
};

export default CreateSourceForm;
