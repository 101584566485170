import WeeklyLicenseReportProvider from './WeeklyLicenseReportContext';
import WeeklyLicenseReportTable from '../../../components/reports/weeklyLicenseReport/WeeklyLicenseReportTable';

const WeeklyLicenseReport = props => {
  return (
    <WeeklyLicenseReportProvider componentProps={props}>
      <WeeklyLicenseReportTable />
    </WeeklyLicenseReportProvider>
  );
};

export default WeeklyLicenseReport;
