/* eslint-disable function-paren-newline */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unused-vars */
/* eslint-disable func-call-spacing */
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import size from 'lodash/size';
import Papa from 'papaparse';
import isNil from 'lodash/isNil';

import * as commonActions from '../../store/actions/common';
import store from '../../store/store';

import {
  getLicenseRecords,
  getSingleLicenseRecord,
  updateSingleLicense,
  deleteSingleLicense,
  deleteBulkLicenses,
  editBulkLicenses,
  importBulkLicenses,
  getBulkUploadFailureReportURL,
} from '../../apis/searchLicensesApi';
import SearchLicensesHelper from '../../helpers/features/SearchLicensesHelper';
import StateHelper from '../../helpers/stateHelper';
import { arrayRemove } from '../../helpers/commonHelper';
import LicensesHelper from '../../helpers/features/LicensesHelper';

const SearchLicencesContext = createContext();

function SearchLicencesProvider({ children }) {
  const navigate = useNavigate();

  const initialBulkProgressState = { progress: 0, message: null };
  const currentUserPermissions = useSelector(state => state.AUTH.rolePermissions);

  // Licenses
  const [licenseData, setLicenseData] = useState([]);
  const [totalLicenseRecords, setTotalLicenseRecords] = useState(0);
  const [isLoadingLicenseData, setIsLoadingLicenseData] = useState(false);

  // License Details
  const [isOpenDetailsModal, setIsOpenDetailsModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [singleLicenseData, setSingleLicenseData] = useState([]);
  const [viewedLicenseId, setViewedLicenseId] = useState('');

  // Pagination
  const [currentPage, setCurrentPage] = useState(
    SearchLicensesHelper.LICENSE_RECORDS_STARTING_PAGE,
  );
  const [nextPage, setNextPage] = useState(SearchLicensesHelper.LICENSE_RECORDS_STARTING_PAGE);
  const [totalPages, setTotalPages] = useState(0);

  // Sorting
  const [currentSortedTitle, setCurrentSortedTitle] = useState(
    SearchLicensesHelper.DEFAULT_SORTING_PARAMETER,
  );
  const [sortingOrder, setSortingOrder] = useState('asc');

  // Filters
  const [reordsPerPage, setReordsPerPage] = useState(
    SearchLicensesHelper.LICENSE_RECORDS_DEFAULT_LIMIT,
  );
  const [stateList, setStateList] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);

  // Search
  const [searchParameter, setSearchParameter] = useState('');

  // Admin Identifier
  const [isAdmin, setIsAdmin] = useState(false);

  // Inline Edit
  const [isInlineEditing, setIsInlineEditing] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(-1);
  const [editingRowId, setEditingRowId] = useState('');
  const [isSaveWarningPopUpOpen, setIsSaveWarningPopUpOpen] = useState(false);
  const [editedValues, setEditedValues] = useState({});

  // Delete Record
  const [isDeleteWarningPopUpOpen, setIsDeleteWarningPopUpOpen] = useState(false);

  // Bulk Record IDs
  const [bulkIdList, setBulkIdList] = useState([]);

  // Bulk Delete
  const [isBulkDelete, setIsBulkDelete] = useState(false);

  // Bulk Edit
  const [isBulkEditModalOpen, setIsBulkEditModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // Import file modal
  const [isOpenImportModal, setIsOpenImportModal] = useState(false);
  const [isOpenImportDataVisualModal, setIsOpenImportDataVisualModal] = useState(false);
  const [importData, setImportData] = useState({
    columns: [],
    data: [],
  });
  const [importFile, setImportFile] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  // Select all
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [progressCount, setProgressCount] = useState(0);
  const [bulkUploadProgress, setBulkUploadProgress] = useState(initialBulkProgressState);
  const [isBulkUploadSuccessModalOpen, setBulkUploadSuccessModalOpen] = useState(false);
  const [allSelectedPages, setAllSelectedPages] = useState([]);

  // License Edit Modal
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [openEditModalError, setOpenEditModalError] = useState(false);
  const [isInlineStatusFieldDisabled, setInlineStatusFieldDisabled] = useState(false);
  const [isLStatusFieldDisabled, setLStatusFieldDisabled] = useState(false);

  // Bulk upload failure report
  const [bulkUploadFailureReportDownloadURL, setBulkUploadFailureReportDownloadURL] = useState('');

  /**
   * User dashboard
   */

  // Handle google map open
  const showOnMap = (address, lat, lang, zoom) => {
    window.open(
      `https://www.google.com/maps/search/${address
        .join(' ')
        .replace(/[^a-zA-Z0-9 ]/g, '')}/@${lat},${lang},${zoom}z`,
    );
  };

  // Fetch total count and license data
  const handleFetchLicenseData = async parameters => {
    setIsLoadingLicenseData(true);
    const response = await getLicenseRecords(parameters);
    if (response.success && response.data) {
      setIsLoadingLicenseData(false);
      setLicenseData(response.data);
      // setLicenseData([]);
      if (response.data.meta) {
        setTotalLicenseRecords(response.data.meta.totalRecords);
        setTotalPages(response.data.meta.totalPages);
      }
    }
  };

  // Handle import data modal
  const handleImportModalOpen = () => {
    setIsOpenImportModal(true);
  };

  // get selected file and data
  const handleOnDrop = useCallback(acceptedFiles => {
    acceptedFiles.map(file => {
      const reader = new FileReader();
      if (file.type !== 'text/csv') {
        setFileError('invalid file format. Only accept text/csv file format');
        return false;
      }
      reader.onprogress = data => {
        if (data.lengthComputable) {
          const progress = parseInt((data.loaded / data.total) * 100, 10);
          setProgressCount(progress);
        }
      };
      reader.onerror = () => {
        setFileError('An error occurred reading this file.');
        store.dispatch(
          commonActions.showErrorAlert({
            type: SearchLicensesHelper.TOST_TYPE_ERROR,
            delay: 5000,
            message: 'Bulk import unsuccessful.',
            body: 'Please use the designated template to bulk import licenses.',
          }),
        );
      };
      reader.onload = async ({ target }) => {
        const csv = Papa.parse(target.result, { header: true, skipEmptyLines: true });
        const parsedData = csv?.data;
        const columns = !isEmpty(csv.data) ? Object.keys(parsedData[0]) : [];
        const allRequiredColFound = LicensesHelper.UPDATED_CSV_HEADERS.every(stCols =>
          columns.includes(stCols),
        );
        const notMatchingColumns = columns.filter(
          stCols => !LicensesHelper.UPDATED_CSV_HEADERS.includes(stCols),
        );
        const isOptionalFieldIncluded = LicensesHelper.UPDATED_OPTIONAL_CSV_HEADERS.map(stCols =>
          columns.includes(stCols),
        );
        if (
          (notMatchingColumns.length > 0 && !isOptionalFieldIncluded.includes(true)) ||
          !allRequiredColFound
        ) {
          setFileError('Invalid csv format');
          if (
            (csv.errors[0]?.code === 'TooFewFields' && csv.errors[0]?.row === 0) ||
            isEmpty(csv.data)
          ) {
            store.dispatch(
              commonActions.showErrorAlert({
                type: 'danger',
                delay: 5000,
                message: 'Bulk import unsuccessful.',
                body: 'Bulk data CSV must contain at least 1 record',
              }),
            );
          } else {
            store.dispatch(
              commonActions.showErrorAlert({
                type: 'danger',
                delay: 5000,
                message: 'Bulk import unsuccessful.',
                body: 'Please use the designated template to bulk import licenses.',
              }),
            );
          }
        } else {
          setImportData({
            columns: isOptionalFieldIncluded.includes(true)
              ? LicensesHelper.CSV_VISUAL_HEADER_NAMES.concat(
                  LicensesHelper.CSV_OPTIONAL_VISUAL_HEADER_NAMES,
                )
              : LicensesHelper.CSV_VISUAL_HEADER_NAMES,
            data: parsedData,
          });
          setFileError(false);
        }
      };
      reader.onloadend = () => {
        setProgressCount(0);
      };
      reader.readAsText(file);
      setImportFile(file);
      setSelectedFile(file);
      return file;
    });
  }, []);

  // handle file upload visual data modal
  const handleFileUploadDataVisualModal = () => {
    setIsOpenImportModal(false);
    setIsOpenImportDataVisualModal(true);
  };

  // handle file upload visual data modal
  const handleFileUploadDataVisualModalClose = () => {
    setIsOpenImportDataVisualModal(false);
  };

  // Handle import modal close
  const handleImportModalClose = () => {
    setIsOpenImportModal(false);
  };

  const updateProgress = progressEvent => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setBulkUploadProgress({ ...bulkUploadProgress, progress: 80 });
  };

  const handleFileImport = async () => {
    setIsOpenImportDataVisualModal(false);
    const response = await importBulkLicenses(importFile, updateProgress);
    if (response.code === 'ERR_NETWORK') {
      setBulkUploadSuccessModalOpen(false);
      store.dispatch(
        commonActions.showErrorAlert({
          type: SearchLicensesHelper.TOST_TYPE_ERROR,
          delay: 3000,
          message: 'Import Failed. Please try again.',
          body: SearchLicensesHelper.TOST_TYPE_ERROR,
        }),
      );
      return;
    }
    if (response.code === 200 && response.data) {
      setBulkUploadProgress({ progress: 100, message: response.message });
      // store.dispatch(
      //   commonActions.showSuccessAlert({
      //     type: SearchLicensesHelper.TOST_TYPE_SUCCESS,
      //     delay: 3000,
      //     message: response.message,
      //     body: SearchLicensesHelper.LICENSE_BULK_EDIT_SUCCESS_TOAST,
      //   }),
      // );
    } else {
      setBulkUploadSuccessModalOpen(false);
      store.dispatch(
        commonActions.showErrorAlert({
          type: SearchLicensesHelper.TOST_TYPE_ERROR,
          delay: 3000,
          message: 'Bulk import unsuccessful.',
          body: response?.response?.data?.message,
        }),
      );
    }

    setImportFile(false);
    setSelectedFile(null);
  };

  // Handle details modal open
  const handleDetailsModalOpen = data => {
    if (!isNil(data.license_expiration_date) || !isEmpty(data.license_expiration_date)) {
      setLStatusFieldDisabled(true);
    }
    setSelectedRow(data);
    setIsOpenDetailsModal(true);
  };

  // Handle details modal close
  const handleDetailsModalClose = () => {
    setSelectedRow({});
    setIsOpenDetailsModal(false);
  };

  // Handle edit modal open
  const handleEditModalOpen = (data, index) => {
    if (!isNil(data.license_expiration_date) || !isEmpty(data.license_expiration_date)) {
      setLStatusFieldDisabled(true);
    }
    setSelectedRow(data);
    setIsEditModalOpen(true);
    setEditingRowIndex(index);
  };

  // Handle edit modal close
  const handleEditModalClose = () => {
    setSelectedRow({});
    setIsEditModalOpen(false);
    setEditingRowIndex(-1);
  };

  // Handle error alert open
  const handleErrorAlertOpen = () => {
    setOpenEditModalError(true);
  };

  // Handle error alert close
  const handleErrorAlertClose = () => {
    setOpenEditModalError(false);
  };

  // Handle search reocrds
  const handleSearch = searchParam => {
    let parameters = {
      page: SearchLicensesHelper.LICENSE_RECORDS_STARTING_PAGE,
      limit: reordsPerPage,
    };

    const trimmedSearchParam = searchParam.trim();

    if (!isEmpty(selectedOption)) {
      const selectedStateValues = [];
      // eslint-disable-next-line array-callback-return
      selectedOption.map(state => {
        selectedStateValues.push({
          code: state.label,
          name: state.value,
        });
      });
      parameters = { ...parameters, state: selectedStateValues };
    }
    if (!isEmpty(sortingOrder)) {
      if (isArray(currentSortedTitle)) {
        parameters[sortingOrder] = currentSortedTitle;
      } else {
        parameters[sortingOrder] = [currentSortedTitle];
      }
    }

    if (searchParam) {
      parameters = { ...parameters, key: trimmedSearchParam };
      handleFetchLicenseData(parameters);
      setSearchParameter(searchParam);
      setCurrentPage(1);
    } else {
      setCurrentPage(SearchLicensesHelper.LICENSE_RECORDS_STARTING_PAGE);
      setNextPage(0);
      setSearchParameter('');
      setCurrentPage(1);
      handleFetchLicenseData(parameters);
    }
  };

  // Handle navigation between pages
  const handleLicenceTablePageNavigation = npage => {
    let parameters = {
      limit: reordsPerPage,
    };

    if (!isEmpty(selectedOption)) {
      const selectedStateValues = [];
      // eslint-disable-next-line array-callback-return
      selectedOption.map(state => {
        selectedStateValues.push({
          code: state.label,
          name: state.value,
        });
      });
      parameters = { ...parameters, state: selectedStateValues };
    }

    if (!isEmpty(searchParameter)) {
      parameters = { ...parameters, key: searchParameter.trim() };
    }

    if (!isEmpty(sortingOrder)) {
      if (isArray(currentSortedTitle)) {
        parameters[sortingOrder] = currentSortedTitle;
      } else {
        parameters[sortingOrder] = [currentSortedTitle];
      }
    }

    if (npage !== currentPage && npage > currentPage) {
      setCurrentPage(npage);
      setNextPage(npage + 1);
      handleFetchLicenseData({
        ...parameters,
        page: npage,
      });
      setIsSelectAll(false);
    } else if (npage !== currentPage && npage < currentPage && currentPage > 0) {
      setCurrentPage(npage);
      setNextPage(npage + 1);
      handleFetchLicenseData({
        ...parameters,
        page: npage,
      });
      setIsSelectAll(false);
    }
  };

  // Get single license record using lisense number
  const handleFetchSingleLicenseData = async licenseId => {
    const response = await getSingleLicenseRecord(licenseId);
    if (response.success && response.data) {
      setViewedLicenseId(response.data.license_number);
      setSingleLicenseData(response.data);
    }
  };

  // Handle sorting of the table
  const handleFetchSortedLicenseData = async title => {
    let parameters = {};
    if (title.db_key === currentSortedTitle && sortingOrder === 'asc') {
      setSortingOrder('desc');
      parameters = isArray(title.db_key) ? { desc: title.db_key } : { desc: [title.db_key] };
    } else if (sortingOrder === 'desc') {
      setSortingOrder('asc');
      parameters = isArray(title.db_key) ? { asc: title.db_key } : { asc: [title.db_key] };
    } else {
      setSortingOrder('asc');
      parameters = isArray(title.db_key) ? { asc: title.db_key } : { asc: [title.db_key] };
    }

    if (!isEmpty(selectedOption)) {
      const selectedStateValues = [];
      // eslint-disable-next-line array-callback-return
      selectedOption.map(state => {
        selectedStateValues.push({
          code: state.label,
          name: state.value,
        });
      });
      parameters = { ...parameters, state: selectedStateValues };
    }

    if (!isEmpty(searchParameter)) {
      parameters = { ...parameters, key: searchParameter.trim() };
    }

    const queryParameters = {
      page: currentPage,
      limit: reordsPerPage,
      ...parameters,
    };

    handleFetchLicenseData(queryParameters);
  };

  // Handle reords per page filter
  const handleRecordsPerPage = records => {
    let params = {
      page: SearchLicensesHelper.LICENSE_RECORDS_STARTING_PAGE,
      limit: records,
    };

    if (!isEmpty(selectedOption)) {
      const selectedStateValues = [];
      // eslint-disable-next-line array-callback-return
      selectedOption.map(state => {
        selectedStateValues.push({
          code: state.label,
          name: state.value,
        });
      });
      params = { ...params, state: selectedStateValues };
    }

    if (!isEmpty(searchParameter)) {
      params = { ...params, key: searchParameter.trim() };
    }

    if (!isEmpty(sortingOrder)) {
      if (isArray(currentSortedTitle)) {
        params[sortingOrder] = currentSortedTitle;
      } else {
        params[sortingOrder] = [currentSortedTitle];
      }
    }

    setReordsPerPage(records);
    setCurrentPage(SearchLicensesHelper.LICENSE_RECORDS_STARTING_PAGE);
    setNextPage(0);
    handleFetchLicenseData(params);
  };

  // Handle State Filter
  const handleStateFilter = selectedState => {
    const selectedStateValues = [];

    let params = {
      page: SearchLicensesHelper.LICENSE_RECORDS_STARTING_PAGE,
      limit: reordsPerPage,
    };

    // eslint-disable-next-line array-callback-return
    selectedState.map(state => {
      selectedStateValues.push({
        code: state.label,
        name: state.value,
      });
    });

    if (!isEmpty(searchParameter)) {
      params = { ...params, key: searchParameter.trim() };
    }

    if (!isEmpty(sortingOrder)) {
      if (isArray(currentSortedTitle)) {
        params[sortingOrder] = currentSortedTitle;
      } else {
        params[sortingOrder] = [currentSortedTitle];
      }
    }

    if (isEmpty(selectedState)) {
      handleFetchLicenseData(params);
    } else {
      params = { ...params, state: selectedStateValues };
      setCurrentPage(SearchLicensesHelper.LICENSE_RECORDS_STARTING_PAGE);
      setNextPage(0);
      handleFetchLicenseData(params);
    }
  };

  /**
   * Admin Functionalities
   */

  // Toast message
  const showToastAlert = (alertType, alertMessage, alertBody) => {
    if (alertType === SearchLicensesHelper.TOST_TYPE_SUCCESS) {
      store.dispatch(
        commonActions.showSuccessAlert({
          type: alertType,
          delay: 1500,
          message: alertMessage,
          body: alertBody,
        }),
      );
    } else if (alertType === 'error') {
      store.dispatch(
        commonActions.showErrorAlert({
          type: alertType,
          delay: 1500,
          message: alertMessage,
          body: alertBody,
        }),
      );
    }
  };

  const isValidState = state => {
    return StateHelper.STATE_SHORT.includes(state);
  };

  // Validate inlin edit user inputs
  // eslint-disable-next-line consistent-return
  const validateUserInputs = (feild, value, isBulk) => {
    const emailValidation = email => {
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (regex.test(email)) {
        return false;
      }
      return true;
    };

    if (
      !isEmpty(value) &&
      feild === 'state' &&
      (size(value) > 2 || !isValidState(value) || !/^[A-Z]+$/i.test(value))
    ) {
      return 'Please check the format';
    }

    if (!isEmpty(value) && feild === 'zip' && (size(value) > 10 || !/^[0-9-]+$/i.test(value))) {
      return 'Please check the format';
    }

    if (
      !isEmpty(value) &&
      feild === 'company_phone' &&
      (size(value) > 10 || !/^[0-9]+$/i.test(value))
    ) {
      return 'Please check the format';
    }

    if (!isEmpty(value) && feild === 'company_email' && emailValidation(value)) {
      return 'Please check the format';
    }

    if (!isEmpty(value) && feild === 'note' && size(value) > 2000) {
      return 'You have reached the character limit';
    }

    if (!isEmpty(value) && feild === 'license_details' && size(value) > 500) {
      return SearchLicensesHelper.INLINE_EDIT_ON_PASTE_CHAR_LIMIT_ERRORR_TEXT;
    }

    // eslint-disable-next-line max-len
    const textOnlyFeild = SearchLicensesHelper.INLINE_SAVE_VALIDATION_TEXT_ONLY_FEILDS.some(
      feildName => {
        return feild.includes(feildName) && feildName === feild;
      },
    );

    // eslint-disable-next-line max-len
    const longTextOnlyFeild = SearchLicensesHelper.INLINE_SAVE_VALIDATION_LONG_TEXT_FEILDS.some(
      feildName => {
        return feild.includes(feildName) && feildName === feild;
      },
    );

    if (
      !isEmpty(value) &&
      !longTextOnlyFeild &&
      textOnlyFeild &&
      // eslint-disable-next-line no-useless-escape
      !/^\D+$/g.test(value)
    ) {
      return 'Numbers are not allowed';
    }

    if (
      !isEmpty(value) &&
      !longTextOnlyFeild &&
      textOnlyFeild &&
      // eslint-disable-next-line no-useless-escape
      size(value) > 100
    ) {
      return 'You have reached the character limit';
    }

    if (!longTextOnlyFeild && !textOnlyFeild && size(value) > 100) {
      return 'You have reached the character limit';
    }
  };

  // Check user is admin
  const checkIsAdmin = () => {
    if (currentUserPermissions.includes(SearchLicensesHelper.USER_PERMISSION_ROLE_ADMIN)) {
      setIsAdmin(true);
    }
  };

  // Create bulk Id list
  const createBulkIdList = id => {
    if (bulkIdList.includes(id)) {
      setBulkIdList(arrayRemove(bulkIdList, id));
    } else {
      bulkIdList.push(id);
    }
  };

  // Handle inline edit functionality
  const handleInlineEdit = async (recordId, data) => {
    if (data.meta) {
      delete data.meta;
    }

    const response = await updateSingleLicense(recordId, data);
    if (!isEmpty(response) && response.code === 200 && response.data) {
      Object.keys(data).forEach(key => {
        const current = licenseData.docs[editingRowIndex];
        current[key] = data[key];
      });
      licenseData.docs[editingRowIndex].license_number = response.data.license_number;
      licenseData.docs[editingRowIndex].updated_at = response.data.updated_at;
      setIsInlineEditing(false);
      setIsSaveWarningPopUpOpen(false);
      handleEditModalClose();
      setSelectedRow({});
      setEditedValues({});
      store.dispatch(
        commonActions.showSuccessAlert({
          type: SearchLicensesHelper.TOST_TYPE_SUCCESS,
          delay: 5000,
          message: response.message,
          body: SearchLicensesHelper.INLINE_SAVE_CONFIRMATION_ALERT_TOST_SUCCESS,
        }),
      );
      let fetchParams = {
        page: currentPage,
        limit: reordsPerPage,
      };

      if (!isEmpty(searchParameter)) {
        fetchParams = { ...fetchParams, key: searchParameter.trim() };
      }

      if (!isEmpty(sortingOrder)) {
        if (isArray(currentSortedTitle)) {
          fetchParams[sortingOrder] = currentSortedTitle;
        } else {
          fetchParams[sortingOrder] = [currentSortedTitle];
        }
      }

      if (!isEmpty(selectedOption)) {
        const selectedStateValues = [];
        // eslint-disable-next-line array-callback-return
        selectedOption.map(state => {
          selectedStateValues.push({
            code: state.label,
            name: state.value,
          });
        });
        fetchParams = { ...fetchParams, state: selectedStateValues };
      }
      handleFetchLicenseData(fetchParams);
    } else {
      store.dispatch(
        commonActions.showErrorAlert({
          type: SearchLicensesHelper.TOST_TYPE_ERROR,
          delay: 6000,
          message: 'UNSUCESSFULL UPDATE',
          body: response.message,
        }),
      );
    }
  };

  // Handle single record delete functionality
  const handleSingleDelete = async recordId => {
    const response = await deleteSingleLicense(recordId);
    if (response.code === 200 && response.data) {
      store.dispatch(
        commonActions.showSuccessAlert({
          type: SearchLicensesHelper.TOST_TYPE_SUCCESS,
          delay: 3000,
          message: response.message,
          body: SearchLicensesHelper.INLINE_DELETE_CONFIRMATION_ALERT_TOST_SUCCESS,
        }),
      );
      setIsDeleteWarningPopUpOpen(false);
      let fetchParams = {
        page: currentPage,
        limit: reordsPerPage,
      };

      if (!isEmpty(searchParameter)) {
        fetchParams = { ...fetchParams, key: searchParameter.trim() };
      }

      if (!isEmpty(sortingOrder)) {
        if (isArray(currentSortedTitle)) {
          fetchParams[sortingOrder] = currentSortedTitle;
        } else {
          fetchParams[sortingOrder] = [currentSortedTitle];
        }
      }

      if (!isEmpty(selectedOption)) {
        const selectedStateValues = [];
        // eslint-disable-next-line array-callback-return
        selectedOption.map(state => {
          selectedStateValues.push({
            code: state.label,
            name: state.value,
          });
        });
        fetchParams = { ...fetchParams, state: selectedStateValues };
      }

      handleFetchLicenseData(fetchParams);
    } else {
      store.dispatch(
        commonActions.showErrorAlert({
          type: SearchLicensesHelper.TOST_TYPE_ERROR,
          delay: 3000,
          message: response.message,
          body: SearchLicensesHelper.COOMON_BACKEND_ERROR_TOATS,
        }),
      );
    }
  };

  // Handle bulk records delete functionality
  const handleBulkDelete = async () => {
    const response = await deleteBulkLicenses(bulkIdList);
    if (response.code === 200 && response.data) {
      store.dispatch(
        commonActions.showSuccessAlert({
          type: SearchLicensesHelper.TOST_TYPE_SUCCESS,
          delay: 3000,
          message: response.message,
          body: `${size(bulkIdList)} record/s deleted successfully`,
        }),
      );
      setIsDeleteWarningPopUpOpen(false);
      setBulkIdList([]);
      setIsBulkDelete(false);
      setIsSelectAll(false);
      setAllSelectedPages([]);
      let fetchParams = {
        page: currentPage,
        limit: reordsPerPage,
      };

      if (!isEmpty(searchParameter)) {
        fetchParams = { ...fetchParams, key: searchParameter.trim() };
      }

      if (!isEmpty(sortingOrder)) {
        if (isArray(currentSortedTitle)) {
          fetchParams[sortingOrder] = currentSortedTitle;
        } else {
          fetchParams[sortingOrder] = [currentSortedTitle];
        }
      }

      if (!isEmpty(selectedOption)) {
        const selectedStateValues = [];
        // eslint-disable-next-line array-callback-return
        selectedOption.map(state => {
          selectedStateValues.push({
            code: state.label,
            name: state.value,
          });
        });
        fetchParams = { ...fetchParams, state: selectedStateValues };
      }

      handleFetchLicenseData(fetchParams);
    } else {
      store.dispatch(
        commonActions.showErrorAlert({
          type: SearchLicensesHelper.TOST_TYPE_ERROR,
          delay: 3000,
          message: response.message,
          body: SearchLicensesHelper.COOMON_BACKEND_ERROR_TOATS,
        }),
      );
    }
  };

  // Bulk Edit
  const handleBulkEdit = async data => {
    const response = await editBulkLicenses(bulkIdList, data);
    if (response.code === 200 && response.data) {
      store.dispatch(
        commonActions.showSuccessAlert({
          type: SearchLicensesHelper.TOST_TYPE_SUCCESS,
          delay: 3000,
          message: response.message,
          body: `You have successfully updated ${response.data[0]} records.`,
        }),
      );
      setIsDeleteWarningPopUpOpen(false);
      setBulkIdList([]);
      setIsSelectAll(false);
      setAllSelectedPages([]);
      let fetchParams = {
        page: currentPage,
        limit: reordsPerPage,
      };

      if (!isEmpty(searchParameter)) {
        fetchParams = { ...fetchParams, key: searchParameter.trim() };
      }

      if (!isEmpty(sortingOrder)) {
        if (isArray(currentSortedTitle)) {
          fetchParams[sortingOrder] = currentSortedTitle;
        } else {
          fetchParams[sortingOrder] = [currentSortedTitle];
        }
      }

      if (!isEmpty(selectedOption)) {
        const selectedStateValues = [];
        // eslint-disable-next-line array-callback-return
        selectedOption.map(state => {
          selectedStateValues.push({
            code: state.label,
            name: state.value,
          });
        });
        fetchParams = { ...fetchParams, state: selectedStateValues };
      }

      handleFetchLicenseData(fetchParams);
    } else {
      store.dispatch(
        commonActions.showErrorAlert({
          type: SearchLicensesHelper.TOST_TYPE_ERROR,
          delay: 3000,
          message: response.message,
          body: SearchLicensesHelper.COOMON_BACKEND_ERROR_TOATS,
        }),
      );
    }
  };

  const hadleBulkUploadFailureReportDownload = async filename => {
    const response = await getBulkUploadFailureReportURL(filename);
    if (response.success && response.data) {
      setBulkUploadFailureReportDownloadURL(response.data);
    } else {
      setBulkUploadFailureReportDownloadURL('');
      store.dispatch(
        commonActions.showErrorAlert({
          type: SearchLicensesHelper.TOST_TYPE_ERROR,
          delay: 5000,
          message: response.message,
          body: SearchLicensesHelper.BULK_UPLOAD_FAILURE_REPORT_INVALID_USER_MESSAGE,
        }),
      );
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    handleFetchLicenseData(SearchLicensesHelper.GET_LICENSE_RECORDS_DEFAULT_PARAMETERS);
    setSearchParameter('');
    setSelectedOption([]);
    setCurrentPage(SearchLicensesHelper.LICENSE_RECORDS_STARTING_PAGE);
    setNextPage(0);
    setReordsPerPage(SearchLicensesHelper.LICENSE_RECORDS_DEFAULT_LIMIT);
    setBulkIdList([]);
    setIsDeleteWarningPopUpOpen(false);
    setIsSaveWarningPopUpOpen(false);
  }, []);

  // To check admin permissions
  useEffect(() => {
    if (!isEmpty(currentUserPermissions)) {
      checkIsAdmin();
    }
  }, [currentUserPermissions]);

  useEffect(() => {
    if (isOpenImportModal) {
      return;
    }
    setSelectedFile(null);
  }, [isOpenImportModal]);

  useEffect(() => {
    if (isBulkUploadSuccessModalOpen) {
      return;
    }
    setBulkUploadProgress(initialBulkProgressState);
  }, [isBulkUploadSuccessModalOpen]);
  return (
    <SearchLicencesContext.Provider
      value={{
        licenseData,
        totalLicenseRecords,
        selectedRow,
        isOpenDetailsModal,
        handleDetailsModalClose,
        handleDetailsModalOpen,
        handleSearch,
        handleLicenceTablePageNavigation,
        totalPages,
        currentPage,
        nextPage,
        handleFetchSingleLicenseData,
        singleLicenseData,
        setCurrentSortedTitle,
        handleFetchSortedLicenseData,
        setCurrentPage,
        reordsPerPage,
        handleRecordsPerPage,
        isLoadingLicenseData,
        handleStateFilter,
        stateList,
        setSelectedOption,
        selectedOption,
        setSearchParameter,
        searchParameter,
        viewedLicenseId,
        setViewedLicenseId,
        isInlineEditing,
        setIsInlineEditing,
        editingRowId,
        setEditingRowId,
        handleInlineEdit,
        isAdmin,
        isSaveWarningPopUpOpen,
        setIsSaveWarningPopUpOpen,
        editedValues,
        setEditedValues,
        isDeleteWarningPopUpOpen,
        setIsDeleteWarningPopUpOpen,
        handleSingleDelete,
        validateUserInputs,
        createBulkIdList,
        bulkIdList,
        isBulkDelete,
        setIsBulkDelete,
        handleBulkDelete,
        isBulkEditModalOpen,
        setIsBulkEditModalOpen,
        isOpenImportModal,
        handleImportModalOpen,
        handleOnDrop,
        handleImportModalClose,
        handleFileUploadDataVisualModal,
        handleFileUploadDataVisualModalClose,
        isOpenImportDataVisualModal,
        importData,
        fileError,
        importFile,
        handleFileImport,
        handleBulkEdit,
        isSaving,
        setIsSaving,
        showOnMap,
        currentSortedTitle,
        isSelectAll,
        setIsSelectAll,
        editingRowIndex,
        setEditingRowIndex,
        setImportFile,
        progressCount,
        setProgressCount,
        setFileError,
        isEditModalOpen,
        setIsEditModalOpen,
        handleEditModalClose,
        handleEditModalOpen,
        openEditModalError,
        setOpenEditModalError,
        isValidState,
        selectedFile,
        setSelectedFile,
        isBulkUploadSuccessModalOpen,
        setBulkUploadSuccessModalOpen,
        bulkUploadProgress,
        setSelectedRow,
        setInlineStatusFieldDisabled,
        isInlineStatusFieldDisabled,
        isLStatusFieldDisabled,
        setLStatusFieldDisabled,
        setBulkIdList,
        allSelectedPages,
        setAllSelectedPages,
        hadleBulkUploadFailureReportDownload,
        bulkUploadFailureReportDownloadURL,
      }}
    >
      {children}
    </SearchLicencesContext.Provider>
  );
}

SearchLicencesProvider.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

export { SearchLicencesContext };
export default SearchLicencesProvider;
