import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';

import { InlineRowEditor } from './InlineRowEditor';
import { DeleteUserModal } from './DeleteUserModal';
import Delete from '../../assets/images/bin.svg';
import Edit from '../../assets/images/edit.svg';
import LodingIcon from '../../assets/images/spinner.gif';
import { UsersContext } from '../../containers/users/UsersContext';
import { UsersHelper } from '../../helpers/usersHelper';
import { toTitleCase } from '../../helpers/commonHelper';

const UserTableBody = () => {
  const navigate = useNavigate();
  const {
    userDataList,
    isDeleteUserModalOpen,
    setDeleteUserModalOpen,
    isUserDataLoading,
    setResetPasswordModalOpen,
    isResetPasswordModalOpen,
    handleResetPassword,
    handleInlineEdit,
    selectedRowForEdit,
    setSelectedRowForEdit,
    isInlineEditModalOpen,
    setInlineEditModalOpen,
  } = useContext(UsersContext);

  if (isUserDataLoading) {
    return (
      <tbody>
        <tr className="loding-data">
          <td colSpan="24">
            <Image src={LodingIcon} sizes="50px" />
          </td>
        </tr>
      </tbody>
    );
  }
  return (
    <>
      <tbody>
        {userDataList &&
          userDataList.rows &&
          userDataList.rows.map(row => {
            if (!isNull(selectedRowForEdit) && selectedRowForEdit.id === row.id) {
              return (
                <InlineRowEditor
                  rowData={row}
                  setSelectedRowForEdit={setSelectedRowForEdit}
                  handleInlineEdit={handleInlineEdit}
                  isInlineEditModalOpen={isInlineEditModalOpen}
                  setInlineEditModalOpen={setInlineEditModalOpen}
                />
              );
            }
            return (
              <tr key={row.id}>
                <td>{`${row.first_name} ${row.last_name}`}</td>
                <td>{row.email}</td>
                <td>{row.company}</td>
                <td>{toTitleCase(row.role)}</td>
                <td>{row.status ? 'Active' : 'Inactive'}</td>
                <td>
                  {row.last_login
                    ? moment(new Date(row.last_login).toLocaleDateString('en-US')).format(
                        'MM/DD/YYYY',
                      )
                    : '-'}
                </td>
                <td
                  onClick={() =>
                    setResetPasswordModalOpen({
                      id: row.id,
                      userName: row.first_name.concat(' ', row.last_name),
                      email: row.email,
                    })
                  }
                >
                  <strong className="app-link-green">Reset Password</strong>
                </td>
                <td>
                  <div className="d-flex">
                    <button
                      // onClick={() => navigate(`edit/${row.id}`)}
                      onClick={() => setSelectedRowForEdit(row)}
                      type="button"
                      className="table-action-btn fill-btn"
                    >
                      <Image src={Edit} />
                    </button>
                    <button type="button" className="table-action-btn outline-btn">
                      <Image
                        onClick={() => setDeleteUserModalOpen({ id: row.id, isOpen: true })}
                        src={Delete}
                      />
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        {userDataList?.rows && isEmpty(userDataList.rows) && !isUserDataLoading && (
          <tr className="without-data">
            <td colSpan="24">
              <p>{UsersHelper.LABELS.NO_RECORDS_FOUND}</p>
            </td>
          </tr>
        )}
      </tbody>
      {isDeleteUserModalOpen.isOpen && <DeleteUserModal />}
      {!isNull(isResetPasswordModalOpen) && (
        <SweetAlert
          custom
          showCancel
          showCloseButton
          confirmBtnText={UsersHelper.LABELS.YES}
          confirmBtnCssClass="popup submit"
          cancelBtnText={UsersHelper.LABELS.NO}
          cancelBtnCssClass="popup cancel"
          confirmBtnBsStyle="danger"
          // customIcon={WarningIcon}
          show={!isNull(isResetPasswordModalOpen)}
          title={UsersHelper.LABELS.RESET_PASSWORD_MODAL_HEADER}
          onConfirm={() => {
            // navigate('/users');
            handleResetPassword();
          }}
          onCancel={() => setResetPasswordModalOpen(null)}
        >
          <p>{`Do you want to send the password reset link to ${isResetPasswordModalOpen.userName}?`}</p>
        </SweetAlert>
      )}
    </>
  );
};

export default UserTableBody;
