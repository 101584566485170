import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import {
  updateTheme,
  getThemeData,
  getSessionTime,
  updateSessionTime,
} from '../../apis/settingsApi';
import SettingsHelper from '../../helpers/features/SettingsHelper';

import * as commonActions from '../../store/actions/common';
import store from '../../store/store';

const SettingsContext = createContext();

function SettingsContextProvider({ children }) {
  const [themeObject, setThemeObject] = useState({});
  const [theme, setTheme] = useState({});
  const [errors, setErrors] = useState(false);

  const [sessionTime, setSessionTime] = useState('');
  const [loadingSessionTime, setLoadingSessionTime] = useState(false);
  const [updatingSessionTime, setUpdatingSessionTime] = useState(false);

  const handleThemeProperties = (section, property, value) => {
    if (isEmpty(themeObject[section])) {
      // Create the nested object if it doesn't exist
      themeObject[section] = {
        [property]: value,
      };
    } else {
      themeObject[section][property] = value;
    }
  };

  const showToastAlert = (alertType, alertMessage, alertBody) => {
    if (alertType === SettingsHelper.TOST_TYPE_SUCCESS) {
      store.dispatch(
        commonActions.showSuccessAlert({
          type: alertType,
          delay: 1500,
          message: alertMessage,
          body: alertBody,
        }),
      );
    } else if (alertType === 'error') {
      store.dispatch(
        commonActions.showErrorAlert({
          type: alertType,
          delay: 1500,
          message: alertMessage,
          body: alertBody,
        }),
      );
    }
  };

  const fetchThemeData = async (id = 0) => {
    try {
      const response = await getThemeData(id);
      if (response.success) {
        setTheme(response.data.theme_data);
      }
    } catch (e) {
      showToastAlert(
        SettingsHelper.TOST_TYPE_ERROR,
        SettingsHelper.ERROR,
        SettingsHelper.UPDATE_FAILED,
      );
      console.error('Unable to fetch', e);
    }
  };

  // eslint-disable-next-line default-param-last
  const updateThemeData = async (id = 0) => {
    try {
      const payload = { newValue: themeObject };

      const response = await updateTheme(id, payload);
      if (response.success) {
        fetchThemeData();
        showToastAlert(
          SettingsHelper.TOST_TYPE_SUCCESS,
          SettingsHelper.SUCCESS,
          SettingsHelper.UPDATE_SUCCESS,
        );
        window.location.reload();
      }
    } catch (e) {
      showToastAlert(
        SettingsHelper.TOST_TYPE_ERROR,
        SettingsHelper.ERROR,
        SettingsHelper.UPDATE_FAILED,
      );
    }
  };

  const handleDiscardChanges = () => {
    setThemeObject({});
    window.location.reload();
  };

  const fetchSessionTime = async params => {
    setLoadingSessionTime(true);

    const response = await getSessionTime(params);
    if (response?.success) {
      setSessionTime(parseInt(response.data.setting_value, 10) / 60);
    }

    setLoadingSessionTime(false);
  };

  const handleUpdateSessionTime = async values => {
    setUpdatingSessionTime(true);

    if (values.time) {
      // convert minutes -> milliseconds
      const time = (values.time * 60).toString();
      const payload = {
        setting_key: 'jwt_ttl',
        setting_value: time,
      };

      try {
        const response = await updateSessionTime(payload);
        if (response?.success) {
          showToastAlert(
            SettingsHelper.TOST_TYPE_SUCCESS,
            SettingsHelper.SUCCESS,
            response.message || SettingsHelper.UPDATE_SUCCESS,
          );
          fetchSessionTime({ key: 'jwt_ttl' });
        } else {
          showToastAlert(
            SettingsHelper.TOST_TYPE_ERROR,
            SettingsHelper.ERROR,
            response?.message || SettingsHelper.UPDATE_FAILED,
          );
        }
      } catch (error) {
        showToastAlert(
          SettingsHelper.TOST_TYPE_ERROR,
          SettingsHelper.ERROR,
          SettingsHelper.UPDATE_FAILED,
        );
      }
    }

    setUpdatingSessionTime(false);
  };

  useEffect(() => {
    fetchThemeData();
    fetchSessionTime({ key: 'jwt_ttl' });
  }, []);

  /* -------------------------------------------------------------------------- */
  /*                                Return values                               */
  /* -------------------------------------------------------------------------- */
  const value = useMemo(
    () => ({
      updateThemeData,
      handleThemeProperties,
      fetchThemeData,
      theme,
      handleDiscardChanges,
      themeObject,
      errors,
      setErrors,
      sessionTime,
      loadingSessionTime,
      updatingSessionTime,
      handleUpdateSessionTime,
    }),
    [
      updateThemeData,
      handleThemeProperties,
      fetchThemeData,
      theme,
      handleDiscardChanges,
      themeObject,
      errors,
      setErrors,
      sessionTime,
      loadingSessionTime,
      updatingSessionTime,
      handleUpdateSessionTime,
    ],
  );

  return <SettingsContext.Provider value={value}>{children}</SettingsContext.Provider>;
}

function useSettingsContext() {
  return useContext(SettingsContext);
}

SettingsContextProvider.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

export default SettingsContextProvider;
export { SettingsContext, useSettingsContext };
