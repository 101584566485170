import styled from 'styled-components';

const StyleForm = styled.div`
  .form-group {
    margin-bottom: 15px;
  }
  label {
    font-size: 14px;
  }
  
  .css-26l3qy-menu, .css-1nmdiq5-menu{
    z-index: 2;
  }
  .form-control {
    font-size: 15px;
    font-weight: 700;
    background: white;
    border: none;
    border-bottom: 1px solid ${props => props.theme?.general?.generalBorderColor};
    border-radius: 0;
    &::placeholder {
      color: #C3C3C3;
    }
    &:focus {
      box-shadow: none;
      border-bottom: 2px solid #68c4c4;
      -webkit-box-shadow: 200px 200px 100px white inset;
      box-shadow: 200px 200px 100px white inset;
    }
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 200px 200px 100px white inset;
    box-shadow: 200px 200px 100px white inset;
  }
  .text-muted {
    margin-top: 5px;
    display: block;
  }
  .error-text {
    color: red;
    font-size: 12px;
    margin-top: 10px;
  }

  .license-edit-row-textarea{
    min-height: 44px;
  }
  /* ====================  Select Drop Down and Theme Select ====================  */
  .css-1s2u09g-control, .css-13cymwt-control {
    border-color: ${props => props.theme?.general?.generalBorderColor};
    min-height: 40px;
    &:hover {
      border-color: ${props => props.theme?.general?.generalBorderColor};
    }
    .css-14el2xx-placeholder, .css-1jqq78o-placeholder{
      color: #C3C3C3;
    }
  }
  .css-13cymwt-control, .css-t3ipsp-control, {
   .css-1jqq78o-placeholder{
      color: #C3C3C3;
    }
  }
  .css-1pahdxg-control, .css-t3ipsp-control {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    &:hover {
      border-color: #86b7fe;
    }
  }
  .theme-select {
    .css-1s2u09g-control, .css-13cymwt-control, .css-t3ipsp-control, .css-1insrsq-control, .css-16xfy0z-control{
      border: none;
      min-height: auto;
    }
    .css-1okebmr-indicatorSeparator, .css-1u9des2-indicatorSeparator, .css-894a34-indicatorSeparator, .css-109onse-indicatorSeparator {
      display: none;
    }
    .css-6j8wv5-Input,
    .css-ackcql, .css-19bb58m, .css-jzldcf-Input, .css-1mkvw8y{
      padding: 0;
      margin: 0;
    }
    .css-319lph-ValueContainer,
    .css-tlfecz-indicatorContainer,
    .css-1gtu0rj-indicatorContainer,
    .css-1xc3v61-indicatorContainer,
    .css-1d8n9bt, .css-tlfecz-indicatorContainer, 
    .css-hlgwow, .css-15lsz6c-indicatorContainer {
      padding: 0;
    }
    .css-1pahdxg-control, .css-t3ipsp-control  {
      box-shadow: none;
      border: none;
      min-height: auto;
    }
  }
  .among-slected {
    .form-check-input:checked[type='checkbox'] {
      background-image: none;
      &::before {
        height: 2px;
        width: 10px;
        content: '';
        background: white;
        z-index: 9;
        position: absolute;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        left: 50%;
        top: 54%;
      }
    }
  }
  /* ====================  Single Select ====================  */
  .single-select{
    .css-6j8wv5-Input, .css-qc6sy-singleValue, .css-1dimb5e-singleValue, .css-qbdosj-Input{
      padding-left: 30px;
    }

  }

  /* ====================  Custom Checkbox - Styles  ====================  */
  .custom-checkbox {
    input {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
    }
    label {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    label:before {
      content: '';
      -webkit-appearance: none;
      background-color: transparent;
      border: 2px solid ${props => props.theme?.general?.secondaryColor};
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 8px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 10px;
      border-radius: 5px;
    }
    input:checked + label::before {
      background: ${props => props.theme?.general?.secondaryColor};
    }
    input:checked + label:after {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      left: 8px;
      width: 5px;
      height: 14px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  /* ====================  Filter Drop Down - Styles  ====================  */

  .filterDdMenu {
    width: 100%;
  }
  .css-14el2xx-placeholder {
    &:after {
      height: 10px;
      width: 10px;
      background: red;
    }
  }
  .css-1n7v3ny-option, .css-1n7v3ny-option:active {
    background-color: #EAF7F6};
  }
  &.state-dropdown {
    position: relative;
    .css-4ljt47-MenuList {
      &::-webkit-scrollbar {
        background-color: white;
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #C3C3C3;
        border-radius: 10px;
      }
    }
    .css-yt9ioa-option input,
    .css-1n7v3ny-option input {
      margin-right: 5px;
    }
    .css-1n7v3ny-option {
      border-bottom: 1px solid #ddd;
    }
    .css-9gakcf-option,
    .css-yt9ioa-option {
      border-bottom: 1px solid #ddd;
      background-color: white;
      &:hover {
        border-bottom: 1px solid #ddd;
      }
      input {
        margin-right: 5px;
      }
      label {
        color: black;
      }
    }
    img {
      transform: translateY(65%);
      position: absolute;
      z-index: 1;
      margin-left: 15px;
    }
    .css-14el2xx-placeholder, .css-1jqq78o-placeholder {
      color: black;
      font-weight: 700;
      font-size: 16px;
      padding-left: 30px;
      background-image: url('../../../../assets/images/filter.svg');
    }
    .css-b62m3t-container span {
      display: none;
    }
  }
  /* ====================  Toat - Styles  ====================  */
  .toast {
    background-color: white !important;
    border: none;
    margin-top: 10px;
    padding: 15px;
    &.bg-danger{
      img{
        width: 30px;
      }
      .toast-header {
        color: red !important;
      }
    }
    .tost-wrp {
      margin-left: 10px;
      .toast-header {
        color: black;
        font-weight: bold;
        font-size: 14px;
        padding: 0;
        border: none;
        display: flex;
        justify-content: space-between;
        .strong {
          text-transform: ;
        }
      }
      .toast-body {
        padding: 0;
      }
    }
  }
  /* ====================  Date Picker - Styles  ====================  */
  .react-datepicker-popper{
   z-index: 2;
  }
  
  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 90px !important;
  }
  .react-datepicker-wrapper{
    input{
      cursor: pointer;
    }
  }
  .react-datepicker {
    border: none;
    box-shadow: 0 -2px 35px -8px rgba(161, 176, 205, 0.25);
    background: white;
    position: relative;
    .react-datepicker__header {
      background: white;
      border: none;
      .react-datepicker__current-month {
        margin: 0.3125rem 0;
      }
    }
    .react-datepicker__navigation-icon {
      &::before {
        border-color: ${props => props.theme?.general?.primaryColor};
      }
    }
    .react-datepicker__navigation--next {
      margin-top: 12px;
    }
    .react-datepicker__navigation--previous {
      left: 10px;
      margin-top: 12px;
    }
  }
  .react-datepicker__day-name {
    font-weight: 600;
  }
  .react-datepicker__month {
    background: white;
  }
  .react-datepicker__month-container {
    box-shadow: 0 -2px 35px -8px rgba(161, 176, 205, 0.25);
    background: white;
  }
  .react-datepicker__year-read-view--down-arrow::before,
  .react-datepicker__month-read-view--down-arrow::before,
  .react-datepicker__month-year-read-view--down-arrow::before,
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
    border-top-color: #ffffff !important;
  }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    border-bottom-color: #ffffff !important;
  }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    border-top: none !important;
    border-bottom-color: #ffffff !important;
  }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    border-bottom-color: #ffffff !important;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range {
    border-radius: 50%;
    background-color: ${props => props.theme?.general?.secondaryColor};
    color: #fff;
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover {
    border-radius: 50%;
  }
  .mdi-calendar-blank {
    position: absolute;
    right: 1.5625rem;
    top: 2.1875rem;
    color: #b3bdc9;
  }

  &.horizontal-datepicker {
    .mdi-calendar-blank {
      top: 0.625rem;
    }
  }

  /* ====================  Filter Date Picker - Styles  ====================  */
  .admin-filter-datepicke{
    position: relative;
  input  {
    border: 1px solid ${props => props.theme?.general?.generalBorderColor};
    border-radius: 5px;
    padding: 0.438rem 3rem 0.438rem 0.75rem;
    background: white; 
    
    &:focus{
      border: 1px solid ${props => props.theme?.general?.generalBorderColor};
      border-color: #86b7fe;
      -webkit-box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
      box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
    }
  }
  img{
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    pointer-events: all;
    z-index: 0;
  }
}

`;

export default StyleForm;
