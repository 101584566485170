import React from 'react';
import { useAuth } from './AuthContext';

import ResetPasswordForm from '../../components/auth/ResetPassword';
import Authlayout from '../../components/layouts/auth/AuthLayout';
import LoginHelper from '../../helpers/features/LoginHelper';

function ResetPassword() {
  const auth = useAuth();

  return (
    <Authlayout
      mainHeader={
        auth.isAccountSetup ? LoginHelper.SETUP_PASSWORD_HEADER : LoginHelper.RESET_PASSWORD_HEADER
      }
      subHeader={
        auth.isAccountSetup
          ? LoginHelper.SETUP_PASSOWORD_SUBHEADER
          : LoginHelper.RESET_PASSOWORD_SUBHEADER
      }
    >
      <ResetPasswordForm />
    </Authlayout>
  );
}

export default ResetPassword;
