import { get, post } from '../libs/api/apiConnector';
import apiRoutes from '../helpers/apiRoutes';
import replaceParameters from '../helpers/parameterHelper';

export const login = async body => {
  try {
    const url = apiRoutes.AUTH.SIGN_IN;
    const config = {
      detachAccessToken: true,
      detachRoleToken: true,
    };
    const { data } = await post(url, body, { config });
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error;
  }
};

export const forgetPassword = async params => {
  try {
    const url = apiRoutes.AUTH.FORGET_PASSWORD;
    const config = {
      detachAccessToken: true,
      detachRoleToken: true,
      params,
    };
    const { data } = await get(url, config);
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error.response.data;
  }
};

export const resetPassword = async code => {
  try {
    const url = replaceParameters(apiRoutes.AUTH.VEIRFY_REST_PASSWORD_TOKEN, { code });
    const { data } = await get(url);
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error.response.data;
  }
};

export const verifyPassword = async body => {
  try {
    const url = apiRoutes.AUTH.VEIRFY_SET_PASSWORD;
    const { data } = await post(url, body);
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error.response.data;
  }
};

export const getUserPermissions = async () => {
  try {
    const url = apiRoutes.AUTH.GET_PERMISSIONS;
    const { data } = await get(url);
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error.response.data;
  }
};

export const logout = async () => {
  try {
    const url = apiRoutes.AUTH.LOGOUT;
    const { data } = await post(url);
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error.response.data;
  }
};
