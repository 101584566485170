export default Object.freeze({
  SELECTED_ENTRY_KEY: 'selectedDataValidationEntry',
  HISTORY_LIST: {
    PAGE_HEADER: 'Data Validation',
    HISTORY_TABLE: {
      DEFAULTS: {
        STARTING_PAGE: 1,
        PER_PAGE: 10,
        SORT_ORDER: 'asc',
        SORT_BY: 'source_identifier',
        PARAMS: {
          page: 1,
          per_page: 10,
        },
      },
      STATUS_FILTER_OPTIONS: [
        { label: 'Pending', value: 0 },
        { label: 'Approved', value: 1 },
        { label: 'Automatic Approved', value: 4 },
        { label: 'Rejected', value: 2 },
      ],
      HEADERS: [
        { label: 'Source Identifier', db_key: 'source_identifier' },
        { label: 'Execution Date', db_key: 'execution_date' },
        { label: 'State', db_key: 'state' },
        { label: 'Status', db_key: 'status' },
        { label: 'Approved Date', db_key: 'approved_date' },
        { label: 'Approved By', db_key: 'approved_by' },
        { label: '' },
      ],
      NO_DATA_TEXT: 'No Records Found',
    },
  },
  ENTRY_DETAILS_LIST: {
    PAGE_HEADER: 'Data Validation',
    BACK: 'Back',
    UPDATE: 'Update',
    APPROVE: 'Approve',
    REJECT: 'Reject',
    EDIT_TOGGLE_TEXT: 'Only Show Unsaved Changes',
    DETAILS_TABLE: {
      DEFAULTS: {
        STARTING_PAGE: 1,
        PER_PAGE: 10,
        SORT_ORDER: 'asc',
        SORT_BY: 'license_number',
        PARAMS: {
          page: 1,
          per_page: 10,
          sort_order: 'asc',
          sort_by: 'license_number',
        },
      },
      COLUMNS_FILTER_OPTIONS: [
        { label: 'License Number', value: 'license_number' },
        { label: 'License Type', value: 'license_type' },
        { label: 'License Issuer', value: 'license_issuer' },
        { label: 'License Issue Date', value: 'license_issue_date' },
        { label: 'License Expiration Date', value: 'license_expiration_date' },
        { label: 'Status', value: 'status' },
        { label: 'Legal Name', value: 'legal_name' },
        { label: 'DBA Name', value: 'dba_name' },
        { label: 'Contact Name', value: 'company_contact_name' },
        { label: 'Email', value: 'company_email' },
        { label: 'Phone', value: 'company_phone' },
        { label: 'Street', value: 'street' },
        { label: 'City', value: 'city' },
        { label: 'County', value: 'county_name' },
        { label: 'State Name', value: 'state_name' },
        { label: 'State Code', value: 'state' },
        { label: 'Country Name', value: 'country_name' },
        { label: 'Country Code', value: 'country_code' },
        { label: 'Zip', value: 'zip' },
        { label: 'Website', value: 'company_website' },
        { label: 'Note', value: 'note' },
        { label: 'License Details', value: 'license_details' },
      ],
      HEADERS: [
        { label: 'License Number', db_key: 'license_number' },
        { label: 'Legal Name', db_key: 'legal_name' },
        { label: 'DBA Name', db_key: 'dba_name' },
        { label: 'Street', db_key: 'street' },
        { label: 'City', db_key: 'city' },
        { label: 'State', db_key: 'state' },
        { label: 'ZIP', db_key: 'zip' },
        { label: 'Country Code', db_key: 'country_code' },
        { label: 'License Issuer', db_key: 'license_issuer' },
        { label: 'License Type', db_key: 'license_type' },
        { label: 'License Issue Date', db_key: 'license_issue_date' },
        { label: 'License Expiration Date', db_key: 'license_expiration_date' },
        { label: 'County Name', value: 'county_name' },
        { label: 'Company Website', db_key: 'company_website' },
        { label: 'Company Contact Name', db_key: 'company_contact_name' },
        { label: 'Company Email', db_key: 'company_email' },
        { label: 'Company Phone', db_key: 'company_phone' },
        { label: 'Note', db_key: 'note' },
        { label: 'License Status', db_key: 'status' },
        { label: 'License Details', db_key: 'license_details' },
        { label: 'Country Name', db_key: 'country_name' },
        { label: 'State Name', db_key: 'state_name' },
      ],
      NO_DATA_TEXT: 'No Records Found',
    },
    UNSAVED_CHANGES_ALERT: {
      TITLE: 'There are unsaved changes',
      BODY: 'Continuing this action will revert any unsaved changes you have made. Do you wish to proceed?',
      BUTTONS: {
        CONFIRM: 'Confirm',
        CANCEL: 'Cancel',
      },
    },
  },
});
