/* eslint-disable no-console */

import { get, post, put, putWithoutAuth, del } from '../libs/api/apiConnector';
import apiRoutes from '../helpers/apiRoutes';
import replaceParameters from '../helpers/parameterHelper';

export const getAllSources = async params => {
  try {
    const { data } = await get(apiRoutes.SOURCES.GET_ALL_SOURCES, { params });
    return data;
  } catch (error) {
    console.error(`get-all-sources-api -> ${error}`);
    return error;
  }
};

export const getUploadURL = async payload => {
  try {
    const { data } = await post(apiRoutes.SOURCES.PRE_SIGN_URL, payload);
    return data;
  } catch (error) {
    console.error(`get-upload-url-api -> ${error}`);
    return error;
  }
};

export const uploadFile = async (url, payload, config) => {
  try {
    const { data, status, statusText } = await putWithoutAuth(url, payload, config);
    return { data, status, statusText };
  } catch (error) {
    console.error(`upload-file-api -> ${error}`);
    return error;
  }
};

export const getDownloadURL = async payload => {
  try {
    const { data } = await post(apiRoutes.SOURCES.SIGN_URL, payload);
    return data;
  } catch (error) {
    console.error(`get-download-url-api -> ${error}`);
    return error;
  }
};

export const createSource = async payload => {
  try {
    const { data } = await post(apiRoutes.SOURCES.CREATE_SOURCE, payload);
    return data;
  } catch (error) {
    console.error(`get-all-create-source-api -> ${error}`);
    return error;
  }
};

export const updateSource = async (payload, uploadId) => {
  try {
    const url = replaceParameters(apiRoutes.SOURCES.UPDATE_SOURCE, { uploadId });
    const { data } = await put(url, payload);
    return data;
  } catch (error) {
    console.error(`update-source-api -> ${error}`);
    return error;
  }
};

export const deleteSingleSource = async uploadId => {
  try {
    const url = replaceParameters(apiRoutes.SOURCES.UPDATE_SOURCE, { uploadId });
    const { data } = await del(url);
    return data;
  } catch (error) {
    console.error(`delete-source-api -> ${error}`);
    return error;
  }
};

export const getPdfNanonetModels = async () => {
  try {
    const { data } = await get(apiRoutes.SOURCES.GET_PDF_MODELS);
    return data;
  } catch (error) {
    console.error(`get-pdf-nanonets-models-api -> ${error}`);
    return error;
  }
};
