import styled from 'styled-components';

export const StyleTable = styled.div`
  background-color: white;
  font-size: 14px;
  color: #000000;
  width: 100%;
  thead {
    th {
      font-size: ${props => props.theme?.table?.tableHeaderFontSize};
      color: ${props => props.theme?.table?.tableHeaderFontColor};
      font-weight: ${props => props.theme?.table?.tableHeaderFontWeight};
      border: none;
      background: #eaf7f6;
      padding: 16px;
      box-shadow: none;
      &:last-child {
        border-radius: 0 10px 10px 0;
        .sort-btn {
          img {
            display: none;
          }
        }
      }
      &:first-child {
        border-radius: 10px 0 0 10px;
      }
      &.th-bulk-action {
        font-size: 14px;
        font-weight: 900;
        button {
          font-size: 14px;
          color: ${props => props.theme?.general?.primaryColor};
          font-weight: 900;
          margin-left: 20px;
          line-height: initial;
          vertical-align: baseline;
          display: inline-flex;
          img {
            margin-right: 5px;
          }
          &:first-child {
            margin-left: 30px;
          }
          &:hover,
          &:hover:active,
          &:active {
            text-decoration: none;
            color: ${props => props.theme?.general?.primaryColor};
          }
        }
      }
    }
    tr:first-child {
      white-space: nowrap;
      vertical-align: middle;
    }
  }
  td {
    font-size: ${props => props.theme?.table?.tableContentFontSize};
    color: ${props => props.theme?.table?.tableContentFontColor};
    font-weight: ${props => props.theme?.table?.tableContentFontWeight};
    vertical-align: middle;
    padding: 16px;
    position: relative;
    overflow: auto;
  }
  .sort-btn {
    border: none;
    padding: 0 10px;
    vertical-align: unset;
    &:hover,
    &:focus,
    &:active {
      border: none;
      opacity: 0.7;
      img {
      }
    }
    &.active {
      border: none;
      img {
        filter: invert(0.5) sepia(1) saturate(2) hue-rotate(140deg);
      }
    }
  }
  .user-licences {
    tr {
      cursor: pointer;
      vertical-align: middle;
      th,
      td {
        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(7) {
          width: 11%;
        }
        &:nth-child(2),
        &:nth-child(5) {
          width: 15%;
        }
      }
      td {
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          line-height: 16px;
          max-height: 32px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          word-break: break-word;
        }
        &:nth-child(4) {
          width: 19%;
          span {
            -webkit-line-clamp: 1;
            &:first-child {
              white-space: nowrap;
            }
          }
        }
        &:nth-child(8) {
          width: 7%;
        }
      }
      &:hover {
        transition: 0.25s ease-in;
        td span {
          -webkit-line-clamp: inherit;
          max-height: initial;
        }
      }
    }
  }

  .without-data,
  .loding-data {
    @media (min-width: 992px) {
      height: calc(100vh - 500px);
    }
    @media (min-width: 1700px) {
      height: calc(100vh - 660px);
    }
    text-align: center;
    vertical-align: middle;
    border: none;
    &:hover {
      td {
        background-color: white;
      }
    }
    img {
      width: 100px;
    }
  }
  .table-responsive {
    overflow: auto;
    max-height: calc(100vh - 137px);
  }
  .admin-licenes {
    border-collapse: collapse;
    width: 100%;

    .isPermanent {
      font-weight: bold;
      color: ${props => props.theme?.general?.secondaryColor};
    }

    thead {
      z-index: 1;
    }
    tr {
      th {
        &:first-child {
          width: 20px;
        }
        position: sticky;
        top: 0;
        z-index: 1;
      }
      td {
        vertical-align: middle;
        .form-control {
          width: auto;
        }
        span {
          width: max-content;
          display: block;
        }
        p {
          display: content;
        }
      }
      &.without-data,
      &.loding-data {
        td {
          height: calc(100vh - 660px);
        }
        p,
        img {
          position: absolute;
          @media (min-width: 992px) {
            left: 30vw;
            transform: translate(100%, -50%);
          }
          @media (min-width: 1700px) {
            left: 25vw;
            transform: translate(100%, -50%);
          }
        }
      }
      &:hover,
      &.selected-row {
        td {
          background-color: #f7fafa;
        }
      }
      &.without-data:hover,
      &.loding-data:hover {
        td {
          background-color: white;
        }
      }
    }
  }
  .data-validation {
    border-collapse: collapse;
    width: 100%;
    thead {
      z-index: 1;
    }
    tr {
      th {
        &:first-child {
          width: 20px;
        }
        position: sticky;
        top: 0;
        z-index: 1;
      }
      td {
        vertical-align: middle;
        overflow: inherit;
        position: relative;
        padding: 20px 16px;
        .form-control {
          width: auto;
        }
        .edited {
          border-color: #fdc126;
        }
        span {
          width: max-content;
          display: block;
          font-size: 10px;
          position: absolute;
          top: 60px;
          color: #9e9e9e;
          right: 16px;
          &:last-child {
            position: absolute;
            top: 6px;
            color: red;
          }
        }
        p {
          display: content;
        }
      }
      &.without-data,
      &.loding-data {
        td {
          height: calc(100vh - 660px);
        }
        p,
        img {
          position: absolute;
          @media (min-width: 992px) {
            left: 30vw;
            transform: translate(100%, -50%);
          }
          @media (min-width: 1700px) {
            left: 25vw;
            transform: translate(100%, -50%);
          }
        }
      }
      &:hover,
      &.selected-row {
        td {
          background-color: #f7fafa;
        }
      }
      &.without-data:hover,
      &.loding-data:hover {
        td {
          background-color: white;
        }
      }
    }
  }
  .note-td {
    position: relative;
    display: inherit;
    input:disabled {
      background: white;
      min-width: 150px;
    }
    button {
      padding: 0;
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }
  .d-v-edit {
    min-height: calc(100vh - 500px);
    display: table-caption;
  }
`;

export const StylePagination = styled.div`
  text-align:right;
  .page-item{
    &:not(:first-child) .page-link{border-radius: 4px; margin-left: 5px !important; padding: 7px 16px;}
    a{
      border: 1px solid  ${props => props.theme?.general?.secondaryColor};
      font-weight: bold;
      color:  ${props => props.theme?.general?.secondaryColor};
      border-radius: 4px;
      margin-left: 5px !important;
      padding: 7px 16px;
      &:hover{
        background: rgba(253, 193, 38, .16);
      }
      &:focus{
        background: none;
        box-shadow: none;
        color:  ${props => props.theme?.general?.secondaryColor};
      }
    }
    &.disabled {
      cursor: not-allowed;
      span.page-link{
        border: 1px solid  ${props => props.theme?.general?.secondaryColor};
        font-weight: bold;
        color:  ${props => props.theme?.general?.secondaryColor};
        border-radius: 4px;
        margin-left: 5px !important;
        padding: 7px 16px;
      }
    }
    
    &.active .page-link{
      background:  ${props => props.theme?.general?.secondaryColor};
      border-color:  ${props => props.theme?.general?.secondaryColor};
      border-radius: 4px;
      margin-left: 5px !important;
      padding: 7px 16px;
    }
  }
  }
`;
