import React from 'react';

import HistoryListView from '../../../../components/dataValidation/historyListView/HistoryListView';

import ExecutionHistoryProvider from '../ExecutionHistoryContext';

const HistoryTable = props => {
  return (
    <ExecutionHistoryProvider>
      <HistoryListView />
    </ExecutionHistoryProvider>
  );
};

export default HistoryTable;
