/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-lonely-if */
/* eslint-disable radix */
/* eslint-disable consistent-return */

import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';

import {
  approveExecution,
  rejectExecution,
  getHistoryEntryDetails,
  updateHistoryEntryDetails,
} from '../../../apis/dataValidationApi';
import { showSuccessAlert, showErrorAlert } from '../../../store/actions/common';
import { setEntryDetailsTableFilters } from '../../../store/actions/dataValidation';

import DataValidationHelper from '../../../helpers/features/DataValidationHelper';

const ExecutionDetailsContext = createContext();

const ExecutionDetailsProvider = ({ children, componentProps }) => {
  /* CONSTANTS */
  const { DETAILS_TABLE } = DataValidationHelper.ENTRY_DETAILS_LIST;
  const { uploadId, executionId } = useParams();
  const entryDetailsTableFilters = useSelector(
    state => state.DATA_VALIDATION.entryDetailsTableFilters,
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  /* STATES */
  // History Details Table Functional States
  const [isApproved, setIsApproved] = useState(false);
  const [pageHeaderData, setPageHeaderData] = useState({});
  const [currentPage, setCurrentPage] = useState(
    entryDetailsTableFilters.page || DETAILS_TABLE.DEFAULTS.STARTING_PAGE,
  );
  const [nextPage, setNextPage] = useState(
    entryDetailsTableFilters.page
      ? parseInt(entryDetailsTableFilters.page) + 1
      : DETAILS_TABLE.DEFAULTS.STARTING_PAGE,
  );
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(
    entryDetailsTableFilters.per_page
      ? parseInt(entryDetailsTableFilters.per_page)
      : DETAILS_TABLE.DEFAULTS.PER_PAGE,
  );
  const [isLoadingEntryTableData, setIsLoadingEntryTableData] = useState(false);

  // History Details Table Contents
  const [entryTableData, setEntryTableData] = useState([]);
  const [initialEntryTableData, setInitialEntryTableData] = useState([]);
  const [editedEntryTableData, setEditedEntryTableData] = useState([]);
  const [entryTableDataErrors, setEntryTableDataErrors] = useState({});

  // History Details Table Search & Filter
  const [searchParameter, setSearchParameter] = useState(
    entryDetailsTableFilters.search_text || '',
  );
  const [selectedColumnNameFilter, setSelectedColumnNameFilter] = useState(
    !isNil(entryDetailsTableFilters.search_column)
      ? DETAILS_TABLE.COLUMNS_FILTER_OPTIONS.filter(
          option => option.value == entryDetailsTableFilters.search_column,
        )[0]
      : null,
  );

  // History Details Table Editor
  const [editedEntriesInitData, setEditedEntriesInitData] = useState([]);
  const [editedEntryData, setEditedEntryData] = useState({});
  const [showEditedEntries, setShowEditedEntries] = useState(false);
  const [updatingEntries, setUpdatingEntries] = useState(false);
  const [showDiscardUnsavedChangesAlert, setShowDiscardUnsavedChangesAlert] = useState(false);

  // Approve Execution States
  const [isApproving, setIsApproving] = useState(false);

  useEffect(() => {
    handleFetchExecutionEntries(entryDetailsTableFilters);
  }, [entryDetailsTableFilters]);

  useEffect(() => {
    if (showEditedEntries) {
      updateEditedEntryTableData();
    }
  }, [showEditedEntries]);

  useEffect(() => {
    updateEntryTableData();
    updateEditedEntryTableData();
  }, [editedEntryData]);

  useEffect(() => {
    updateEntryTableData();
    updateEditedEntryTableData();
  }, [initialEntryTableData]);

  /* FUNCTIONS */
  const handleFetchExecutionEntries = async parameters => {
    setIsLoadingEntryTableData(true);

    const response = await getHistoryEntryDetails(parameters, uploadId, executionId);
    if (response?.success && response?.data) {
      setInitialEntryTableData(response.data);
      if (response.meta) {
        setTotalPages(Math.ceil(response.meta.total / response.meta.per_page));
        setTotalRecords(response.meta.total);
      }
      if (!isEmpty(pageHeaderData && response.source)) {
        setPageHeaderData({
          ...response.source,
          execution_date: response.source.execution_date?.split('T')[0] || '',
        });
        if (response?.source?.status && response?.source?.status != 'PENDING') {
          setIsApproved(true);
        }
      }
    }
    setIsLoadingEntryTableData(false);
  };

  const handleUpdateExecutionEntry = async payload => {
    setUpdatingEntries(true);
    const response = await updateHistoryEntryDetails({ data: payload }, uploadId, executionId);
    if (response.success) {
      setEditedEntryData({});
      setEditedEntryTableData([]);
      setEditedEntriesInitData([]);
      dispatch(
        showSuccessAlert({
          type: 'success',
          delay: 3000,
          message: response.message,
          body: '',
        }),
      );
      await handleFetchExecutionEntries(entryDetailsTableFilters);
      if (response.meta) {
        setTotalPages(Math.ceil(response.meta.total / response.meta.per_page));
      }
    } else {
      dispatch(
        showErrorAlert({
          type: 'error',
          delay: 4000,
          body: response.message,
          message: 'Something went wrong!',
        }),
      );
    }
    setUpdatingEntries(false);
  };

  const handleApproveExecution = async approve => {
    setIsApproving(true);
    const response = approve
      ? await approveExecution(uploadId, executionId)
      : await rejectExecution(uploadId, executionId);
    if (response.success) {
      setEditedEntryData({});
      setEditedEntryTableData([]);
      setEditedEntriesInitData([]);
      setIsApproved(true);
      dispatch(
        showSuccessAlert({
          type: 'success',
          delay: 3000,
          message: response.message,
          body: '',
        }),
      );
    } else {
      dispatch(
        showErrorAlert({
          type: 'error',
          delay: 4000,
          body: response.message,
          message: 'Something went wrong!',
        }),
      );
    }
    setIsApproving(false);
  };

  const handleNavigateBack = () => {
    dispatch(setEntryDetailsTableFilters(DETAILS_TABLE.DEFAULTS.PARAMS));
    navigate(-1);
  };

  const handleUpdate = async () => {
    const payload = [];
    Object.keys(editedEntryData).map(id => {
      const dataObj = {};
      Object.keys(editedEntryData[id].values).map(key => {
        dataObj[key] = editedEntryData[id].values[key];
      });
      payload.push({ ...dataObj, id });
    });
    await handleUpdateExecutionEntry(payload);
  };

  const handleApprove = async approve => {
    await handleApproveExecution(approve);
  };

  const handleEntryFieldUpdate = (rowId, rowData, updatedData) => {
    let currentRowInitialData = {};
    const editedIds = editedEntriesInitData.map(entry => entry.id);
    if (rowData) {
      currentRowInitialData = rowData;
      if (isEmpty(editedEntriesInitData)) setEditedEntriesInitData([rowData]);
      else {
        if (!editedEntriesInitData.includes(rowData.id)) {
          setEditedEntriesInitData([...editedEntriesInitData, rowData]);
        }
      }
    } else {
      currentRowInitialData = editedEntriesInitData[editedIds.indexOf(rowId)];
    }

    let editData = { ...editedEntryData };
    if (isEmpty(editedEntryData)) {
      editData = {
        [currentRowInitialData.id]: {
          values: {
            [updatedData.key]: updatedData.value,
          },
          initialValues: {
            [updatedData.key]: currentRowInitialData[updatedData.key],
          },
        },
      };
    } else {
      if (!showEditedEntries) {
        editData = {
          ...editData,
          [currentRowInitialData.id]: {
            values: {
              ...editData[currentRowInitialData.id]?.values,
              [updatedData.key]: updatedData.value,
            },
            initialValues: {
              ...editData[currentRowInitialData.id]?.initialValues,
              [updatedData.key]: currentRowInitialData[updatedData.key],
            },
          },
        };
      } else {
        if (
          Object.keys(editData[currentRowInitialData.id].initialValues).includes(updatedData.key)
        ) {
          editData = {
            ...editData,
            [currentRowInitialData.id]: {
              ...editData[currentRowInitialData.id],
              values: {
                ...editData[currentRowInitialData.id]?.values,
                [updatedData.key]: updatedData.value,
              },
            },
          };
        } else {
          editData = {
            ...editData,
            [currentRowInitialData.id]: {
              values: {
                ...editData[currentRowInitialData.id]?.values,
                [updatedData.key]: updatedData.value,
              },
              initialValues: {
                ...editData[currentRowInitialData.id]?.initialValues,
                [updatedData.key]: currentRowInitialData[updatedData.key],
              },
            },
          };
        }
      }
    }

    Object.keys(editData).map(id => {
      Object.keys(editData[id].values).map(key => {
        if (editData[id].values[key] == editData[id].initialValues[key]) {
          delete editData[id].values[key];
          delete editData[id].initialValues[key];
        }

        if (isEmpty(editData[id].values)) delete editData[id].values;
        if (isEmpty(editData[id].initialValues)) delete editData[id].initialValues;
      });

      if (isEmpty(editData[id])) delete editData[id];
    });
    setEditedEntryData({ ...editData });
  };

  const handleEntryTableFieldErrors = (rowId, error) => {
    if (isEmpty(entryTableDataErrors)) {
      if (error.value) {
        setEntryTableDataErrors({
          [rowId]: {
            [error.key]: error.value,
          },
        });
      }
    } else {
      if (entryTableDataErrors[rowId]) {
        if (error.value) {
          setEntryTableDataErrors({
            ...entryTableDataErrors,
            [rowId]: {
              ...entryTableDataErrors[rowId],
              [error.key]: error.value,
            },
          });
        } else {
          const errorData = { ...entryTableDataErrors };
          if (Object.keys(entryTableDataErrors[rowId]).includes(error.key)) {
            delete errorData[rowId][error.key];
          }
          if (isEmpty(entryTableDataErrors[rowId])) {
            delete errorData[rowId];
          }

          setEntryTableDataErrors({ ...errorData });
        }
      } else {
        if (error.value) {
          setEntryTableDataErrors({
            ...entryTableDataErrors,
            [rowId]: {
              [error.key]: error.value,
            },
          });
        }
      }
    }
  };

  const updateEntryTableData = () => {
    const normalTableData = [];

    if (!isEmpty(editedEntryData)) {
      initialEntryTableData.map(entry => {
        if (Object.keys(editedEntryData).includes(entry.id.toString())) {
          normalTableData.push({ ...entry, ...editedEntryData[entry.id].values });
        } else normalTableData.push({ ...entry });
      });
      if (isEmpty(normalTableData)) setEntryTableData(initialEntryTableData);
      else setEntryTableData([...normalTableData]);
    } else {
      setEntryTableDataErrors({});
      setEntryTableData(initialEntryTableData);
    }
  };

  const updateEditedEntryTableData = () => {
    const editedIds = isEmpty(editedEntriesInitData)
      ? []
      : editedEntriesInitData.map(entry => entry.id);

    const data = Object.keys(editedEntryData).map(id => {
      if (!isEmpty(editedIds) && editedIds.includes(parseInt(id))) {
        return {
          ...editedEntriesInitData[editedIds.indexOf(parseInt(id))],
          ...editedEntryData[id].values,
        };
      }
    });
    setEditedEntryTableData(data);
  };

  const handleColumnNameFilterChange = value => {
    const parameters = {
      ...entryDetailsTableFilters,
      page: 1,
    };

    setSelectedColumnNameFilter(value);
    setSearchParameter('');

    if (!value) {
      dispatch(
        setEntryDetailsTableFilters({
          ...parameters,
          search_column: undefined,
          search_text: undefined,
        }),
      );
      setNextPage(0);
      setCurrentPage(DETAILS_TABLE.DEFAULTS.STARTING_PAGE);
    }
  };

  const handleSearch = searchParam => {
    const trimmedSearchParam = searchParam.trim();
    const parameters = {
      ...entryDetailsTableFilters,
      page: 1,
      search_column: undefined,
      search_text: undefined,
    };

    if (searchParam) {
      dispatch(
        setEntryDetailsTableFilters({
          ...parameters,
          search_column: selectedColumnNameFilter.value,
          search_text: trimmedSearchParam,
        }),
      );
      setSearchParameter(searchParam);
    } else {
      setNextPage(0);
      setSearchParameter('');
      dispatch(setEntryDetailsTableFilters(parameters));
    }
    setCurrentPage(DETAILS_TABLE.DEFAULTS.STARTING_PAGE);
  };

  const handleRecordsPerPage = records => {
    const parameters = {
      ...entryDetailsTableFilters,
      page: 1,
      per_page: records,
    };

    setRecordsPerPage(records);
    setCurrentPage(1);
    setNextPage(0);
    dispatch(setEntryDetailsTableFilters(parameters));
  };

  const handleTablePageNavigation = nPage => {
    if (!isEqual(nPage, currentPage) && nPage > currentPage) {
      setCurrentPage(nPage);
      setNextPage(nPage + 1);
      dispatch(
        setEntryDetailsTableFilters({
          ...entryDetailsTableFilters,
          page: nPage,
        }),
      );
    } else if (!isEqual(nPage, currentPage) && nPage < currentPage && currentPage > 0) {
      setCurrentPage(nPage);
      setNextPage(nPage + 1);
      dispatch(
        setEntryDetailsTableFilters({
          ...entryDetailsTableFilters,
          page: nPage,
        }),
      );
    }
  };

  return (
    <ExecutionDetailsContext.Provider
      value={{
        isApproved,
        pageHeaderData,
        totalPages,
        totalRecords,
        recordsPerPage,
        currentPage,
        nextPage,
        selectedColumnNameFilter,
        setSelectedColumnNameFilter,
        handleColumnNameFilterChange,
        searchParameter,
        setSearchParameter,
        handleSearch,
        handleNavigateBack,
        handleUpdate,
        handleApprove,
        handleEntryFieldUpdate,
        handleEntryTableFieldErrors,
        handleRecordsPerPage,
        handleTablePageNavigation,
        entryTableData,
        entryTableDataErrors,
        initialEntryTableData,
        editedEntryTableData,
        isLoadingEntryTableData,
        showEditedEntries,
        setShowEditedEntries,
        editedEntryData,
        updatingEntries,
        isApproving,
        editedEntriesInitData,
        showDiscardUnsavedChangesAlert,
        setShowDiscardUnsavedChangesAlert,
      }}
    >
      {children}
    </ExecutionDetailsContext.Provider>
  );
};

ExecutionDetailsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

export { ExecutionDetailsContext };
export default ExecutionDetailsProvider;
