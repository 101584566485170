import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
// import { useSelector } from 'react-redux';

import Login from './containers/auth/Login';
import ForgotPassword from './containers/auth/ForgotPassword';
import ResetPassword from './containers/auth/ResetPassword';
import Home from './containers/home/Home';
import SearchLicenses from './containers/searchLicenses/licenseTable/LicenseTable';
import LicenseUpdateLogs from './containers/reports/lisenseUpdateLogs/LicenseUpdateLogs';
import UserLoginsReport from './containers/reports/userLoginsReport/UserLoginsReport';
import WeeklyLicenseReport from './containers/reports/weeklyLicenseReport/WeeklyLicenseReport';
import Settings from './containers/settings/Settings';
import LicensesDetails from './containers/searchLicenses/licensesDetails/LicensesDetails';
import UploadFailureReport from './containers/searchLicenses/uploadFailureReport/UploadFailureReport';
import DataDictionary from './containers/dataDictionary/DataDictionary';
import Faq from './containers/faq/Faq';
import MyProfile from './containers/myProfile/MyProfile';
import Map from './containers/map/Map';
import UnAuthenticatedWrapper from './containers/auth/unAuthenticatedWrapper/UnAuthenticated';
import AuthenticatedWrapper from './containers/auth/authenticatedWrapper/Authenticated';
import Main from './components/layouts/main/Main';
import routes from './helpers/routes';
import ToastAlerts from './components/common/toastAlerts/ToastAlerts';
import AddNewlicenses from './containers/licenses/AddNewLicenses/AddNewLicenses';
import SourcesTable from './containers/sources/sourcesTable/SourcesTable';
import ExecutionHistoryList from './containers/dataValidation/executionHistory/historyTable/HistoryTable';
import ExecutionDetailedView from './containers/dataValidation/executionDetails/detailsTable/DetailsTable';
import { Users } from './containers/users/Users';
import { AddEditUsers } from './components/users/addEditUsers/AddEditUsers';

function AppRoutes() {
  const location = useLocation();
  return (
    <>
      <UnAuthenticatedWrapper>
        <Routes>
          <Route path={routes.UN_AUTHENTICATED.LOGIN.PATH} element={<Login />} />
          <Route path={routes.UN_AUTHENTICATED.FORGET_PASSWORD.PATH} element={<ForgotPassword />} />
          <Route path={routes.UN_AUTHENTICATED.RESET_PASSWORD.PATH} element={<ResetPassword />} />
          <Route
            path="*"
            element={
              <Navigate
                to={routes.UN_AUTHENTICATED.LOGIN.PATH}
                replace
                state={{ from: location }}
              />
            }
          />
          <Route path={routes.UN_AUTHENTICATED.SETUP_PASSWORD.PATH} element={<ResetPassword />} />
          <Route path="*" element={<Navigate to={routes.UN_AUTHENTICATED.LOGIN.PATH} />} />
        </Routes>
      </UnAuthenticatedWrapper>
      <AuthenticatedWrapper>
        <Routes>
          <Route path={routes.AUTHENTICATED.ROOT.PATH} element={<Main />}>
            <Route index element={<Home />} />
            <Route path={routes.AUTHENTICATED.SEARCH_LICENSES.PATH} element={<SearchLicenses />} />
            <Route
              path={routes.AUTHENTICATED.LICENSES_DETAILS.PATH}
              element={<LicensesDetails />}
            />
            <Route path={routes.AUTHENTICATED.DATA_DICTIONARY.PATH} element={<DataDictionary />} />
            <Route path={routes.AUTHENTICATED.FAQ.PATH} element={<Faq />} />
            <Route path={routes.AUTHENTICATED.MY_PROFILE.PATH} element={<MyProfile />} />
            <Route path={routes.AUTHENTICATED.MAP.PATH} element={<Map />} />
            <Route
              path={routes.AUTHENTICATED.ADMIN_ADD_NEW_LICENSES.PATH}
              element={<AddNewlicenses />}
            />
            <Route
              path={routes.AUTHENTICATED.CSV_BULK_UPLOAD_FAILURE.PATH}
              element={<UploadFailureReport />}
            />
            <Route path={routes.AUTHENTICATED.USERS.PATH} element={<Users />} />
            <Route path={routes.AUTHENTICATED.EDIT_USERS.PATH} element={<AddEditUsers />} />
            <Route path={routes.AUTHENTICATED.ADD_USER.PATH} element={<AddEditUsers />} />
            <Route
              path={routes.AUTHENTICATED.LICENSES_UPDATE_LOGS.PATH}
              element={<LicenseUpdateLogs reportType={1} />}
            />
            <Route
              path={routes.AUTHENTICATED.USER_LOGINS_REPORT.PATH}
              element={<UserLoginsReport reportType={2} />}
            />
            <Route
              path={routes.AUTHENTICATED.WEEKLY_LICENSE_REPORT.PATH}
              element={<WeeklyLicenseReport />}
            />
            <Route path={routes.AUTHENTICATED.SOURCES.PATH} element={<SourcesTable />} />
            <Route
              path={routes.AUTHENTICATED.DATA_VALIDATION.PATH}
              element={<ExecutionHistoryList />}
            />
            <Route
              path={routes.AUTHENTICATED.EXECUTION_DETAILS.PATH}
              element={<ExecutionDetailedView />}
            />
            <Route path={routes.AUTHENTICATED.SETTINGS.PATH} element={<Settings />} />
            <Route
              path="*"
              element={
                <Navigate to={routes.AUTHENTICATED.ROOT.PATH} replace state={{ from: location }} />
              }
            />
          </Route>
        </Routes>
      </AuthenticatedWrapper>
      <ToastAlerts />
    </>
  );
}

export default AppRoutes;
