import React from 'react';
import { Container } from 'react-bootstrap';

import SearchLicencesProvider from '../SearchLicensesContext';
import AppBreadcrumb from '../../../components/common/breadcrumb/AppBreadcrumb';
import { PageWrapper } from '../../../components/styles/Page.styled';
import DetailsView from '../../../components/searchLicenses/detailsView/DetailsView';

function LicensesDetails(props) {
  return (
    <SearchLicencesProvider componentProps={props}>
      <Container>
        <AppBreadcrumb />
        <PageWrapper>
          <DetailsView />
        </PageWrapper>
      </Container>
    </SearchLicencesProvider>
  );
}

export default LicensesDetails;
