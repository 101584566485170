import React from 'react';
import { Row, Col } from 'react-bootstrap';

import FeaturedCard from './FeaturedCard';

import PastWeek from '../../assets/images/past-week.svg';
import PastMonth from '../../assets/images/past-30days.svg';
import PastThreeMonth from '../../assets/images/past-90days.svg';
import AllRecords from '../../assets/images/all-records.svg';
import { useHomeContext } from '../../containers/home/HomeContext';
import homeHelper from '../../helpers/features/homeHelper';

function DashboardContent() {
  const homeContext = useHomeContext();
  const records = homeContext.cardDetails;

  return (
    <Row className="item-row">
      {/* {records.map(record => ( */}
      <Col md={3}>
        <FeaturedCard
          key={1}
          title={homeHelper.HOME_CARD_TITLE}
          src={PastWeek}
          text={homeHelper.HOME_CARD_07_DAYS_TEXT}
          recordsCount={records.license_7_days}
        />
      </Col>
      <Col md={3}>
        <FeaturedCard
          key={2}
          title={homeHelper.HOME_CARD_TITLE}
          src={PastMonth}
          text={homeHelper.HOME_CARD_30_DAYS_TEXT}
          recordsCount={records.license_30_days}
        />
      </Col>
      <Col md={3}>
        <FeaturedCard
          key={3}
          title={homeHelper.HOME_CARD_TITLE}
          src={PastThreeMonth}
          text={homeHelper.HOME_CARD_90_DAYS_TEXT}
          recordsCount={records.license_90_days}
        />
      </Col>
      <Col md={3}>
        <FeaturedCard
          key={4}
          title={homeHelper.HOME_CARD_TITLE_TOTAL_TEXT}
          src={AllRecords}
          text={homeHelper.HOME_CARD_TOTAL_TEXT}
          recordsCount={records.total}
          textClassName="total-text"
        />
      </Col>
      {/* ))} */}
    </Row>
  );
}

export default DashboardContent;
