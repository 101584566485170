import styled from 'styled-components';

const StyleAccoudion = styled.div`
  .accordion {
    border-bottom: 1px solid ${props => props.theme?.general?.generalBorderColor};
    padding-bottom: 25px;
    margin-bottom: 25px;
  }
  .accordion-item {
    border: none;
    background: transparent;
    h2 button {
      font-size: ${props => props.theme?.qna?.quizFontSize};
      font-weight: ${props => props.theme?.qna?.quizFontWeight};
      color: ${props => props.theme?.qna?.quizFontColor};
      padding: 0;
      background: none;
      border: none;
      box-shadow: none;
    }
    .accordion-body {
      margin-top: 30px;
      padding: 0;
      font-size: ${props => props.theme?.qna?.ansFontSize};
      font-weight: ${props => props.theme?.qna?.ansFontWeight};
      color: ${props => props.theme?.qna?.ansFontColor};
    }
  }
  .accordion-button::after {
    --b: 2.3px;
    aspect-ratio: 1;
    display: inline-block;
    background: conic-gradient(
        from 90deg at var(--b) var(--b),
        #fff 90deg,
        ${props => props.theme?.general?.secondaryColor} 0
      )
      calc(100% + var(--b) / 2) calc(100% + var(--b) / 2) / calc(50% + var(--b))
      calc(50% + var(--b));
  }
  .accordion-button:not(.collapsed):after {
    background: conic-gradient(
        from 90deg at var(--b) var(--b),
        #fff 90deg,
        ${props => props.theme?.general?.secondaryColor} 0
      )
      calc(100% + var(--b) / 2) calc(100% + var(--b) / 2) / calc(110% + var(--b))
      calc(50% + var(--b));
  }

  @media (max-width: 575.98px) {
  }
`;

export default StyleAccoudion;
