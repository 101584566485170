/* eslint-disable no-nested-ternary */

import React, { useContext } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import makeAnimated from 'react-select/animated';
import uniqueId from 'lodash/uniqueId';

import SourcesListRow from './SourcesListRow';
import { StylePage } from '../../styles/Page.styled';
import StyleForm from '../../common/input/Form.styled';
import PageHeader from '../../common/pageHeader/PageHeader';
import Permissions from '../../auth/Permissions';
import Search from '../../common/search/Search';
import AppButton from '../../styles/ButtonLink.styled';
import FilterDropDown from '../../common/input/stateFilterDropDown/FilterDropDown';
import MultiValue from '../../common/input/stateFilterDropDown/MultiValue';
import Option from '../../common/input/stateFilterDropDown/Options';
import PaginationDropdown from '../../common/input/PaginationDropdown';
import PaginationComponent from '../../common/pagination/Pagination';
import TableComponent from '../../common/table/Table';
import TableHeader from '../../common/table/TableHeader';
import SingleTableHeader from '../../common/table/SingleTableHeader';
import TableBody from '../../common/table/TableBody';
import WarningAlert from '../../common/alerts/WarningAlert';
import DangerAlert from '../../common/alerts/DangerAlert';
import CreateSourceModal from '../createEditSource/CreateSourceModal';

import { SourcesContext } from '../../../containers/sources/SourcesContext';
import SourcesHelper from '../../../helpers/features/SourcesHelper';
import StateHelper from '../../../helpers/stateHelper';

import FilterIcon from '../../../assets/images/filter.svg';
import LoadingIcon from '../../../assets/images/spinner.gif';

const SourcesListView = props => {
  const {
    totalPages,
    totalRecords,
    currentPage,
    nextPage,
    currentSortedTitle,
    setCurrentSortedTitle,
    searchParameter,
    setSearchParameter,
    selectedStateFilter,
    setSelectedStateFilter,
    handleSearch,
    handleStateFilterChange,
    handleRecordsPerPage,
    handleTablePageNavigation,
    handleFetchSortedSourcesData,
    sourcesTableData,
    isLoadingSourcesTableData,
    setShowCreateSourceModal,
    showDeleteSourceAlert,
    setShowDeleteSourceAlert,
    handleDeleteSource,
    showSourceTypeChangeAlert,
    setShowSourceTypeChangeAlert,
    handleCancelSourceTypeAlert,
    handleConfirmSourceTypeAlert,
  } = useContext(SourcesContext);

  const animatedComponents = makeAnimated();
  const { PAGE_HEADER, ADD_NEW_SOURCE, SOURCE_TABLE, DELETE_SOURCE, EDIT_SOURCE } = SourcesHelper;

  return (
    <Container>
      <StylePage>
        <Row>
          <Col md={6}>
            <PageHeader title={PAGE_HEADER} subheader={`${totalRecords} Records`} />
          </Col>
          <Col md={6} className="d-flex justify-content-end">
            <Permissions permissionRole="STANDARD">
              <Search
                handleSearch={handleSearch}
                setSearchParameter={setSearchParameter}
                searchParameter={searchParameter}
              />
            </Permissions>
            <Permissions permissionRole="ADMIN">
              <div style={{ marginRight: '8px' }}>
                <Search
                  handleSearch={handleSearch}
                  setSearchParameter={setSearchParameter}
                  searchParameter={searchParameter}
                />
              </div>
              <AppButton
                fill="true"
                onClick={() => setShowCreateSourceModal({ data: null, isCreate: true, show: true })}
              >
                {ADD_NEW_SOURCE.BUTTON_TEXT}
              </AppButton>
            </Permissions>
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col md={2} className="mb-3">
            <StyleForm className="state-dropdown">
              <Image src={FilterIcon} />
              <FilterDropDown
                options={StateHelper.STATES}
                isMulti
                isSearchable={false}
                isClearable={false}
                controlShouldRenderValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={value => {
                  setSelectedStateFilter(value);
                  handleStateFilterChange(value);
                }}
                value={selectedStateFilter}
                placeholder="State"
              />
            </StyleForm>
          </Col>
          <Col md={10} className="mb-3 d-flex justify-content-end">
            <PaginationDropdown handleRecordsPerPage={handleRecordsPerPage} />
            {totalPages > 1 && (
              <PaginationComponent
                totalPages={totalPages}
                currentPage={currentPage}
                handleTablePageNavigation={handleTablePageNavigation}
                nextPage={nextPage}
              />
            )}
          </Col>
        </Row>
        <TableComponent className="admin-licenes">
          <TableHeader>
            {SOURCE_TABLE.HEADERS.map(header => {
              return (
                <SingleTableHeader
                  title={header}
                  key={uniqueId()}
                  currentSortedTitle={currentSortedTitle}
                  setCurrentSortedTitle={setCurrentSortedTitle}
                  handleFetchSortedData={handleFetchSortedSourcesData}
                />
              );
            })}
          </TableHeader>
          <TableBody>
            {isLoadingSourcesTableData ? (
              <tr className="loding-data">
                <td colSpan="24">
                  <Image src={LoadingIcon} sizes="50px" />
                </td>
              </tr>
            ) : sourcesTableData?.length > 0 ? (
              sourcesTableData.map(item => <SourcesListRow data={item} />)
            ) : (
              <tr className="without-data">
                <td colSpan="24">
                  <p>{SOURCE_TABLE.NO_DATA_TEXT}</p>
                </td>
              </tr>
            )}
          </TableBody>
        </TableComponent>
        {showDeleteSourceAlert.show && (
          <DangerAlert
            show={showDeleteSourceAlert.show}
            onConfirm={() => handleDeleteSource()}
            onCancel={() => setShowDeleteSourceAlert({ id: null, show: false })}
            title={DELETE_SOURCE.ALERT.TITLE}
            confirmButtonText={DELETE_SOURCE.ALERT.BUTTONS.CONFIRM}
            cancelButtonText={DELETE_SOURCE.ALERT.BUTTONS.CANCEL}
          />
        )}
        {showSourceTypeChangeAlert.show && (
          <WarningAlert
            show={showSourceTypeChangeAlert.show}
            onConfirm={handleConfirmSourceTypeAlert}
            onCancel={handleCancelSourceTypeAlert}
            title={EDIT_SOURCE.CHANGE_SOURCE_TYPE_ALERT.TITLE}
            body={EDIT_SOURCE.CHANGE_SOURCE_TYPE_ALERT.BODY}
            confirmButtonText={EDIT_SOURCE.CHANGE_SOURCE_TYPE_ALERT.BUTTONS.CONFIRM}
            cancelButtonText={EDIT_SOURCE.CHANGE_SOURCE_TYPE_ALERT.BUTTONS.CANCEL}
          />
        )}
        <CreateSourceModal />
      </StylePage>
    </Container>
  );
};

export default SourcesListView;
