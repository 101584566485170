export default Object.freeze({
  LOGIN_HEADER: 'Welcome',
  LOGIN_SUBHEADER: 'Enter your credentials to access your account.',
  LOGIN_BTN_TEXT: 'Sign In',
  FORGET_PASSOWORD_HEADER: 'Forgot Password',
  FORGET_PASSOWORD_SUBHEADER: 'Enter your email and we’ll send you a link to reset your password',
  FORGET_BACK_BTN_TEXT: 'Back to Sign In',
  RESET_PASSWORD_HEADER: 'Reset Password',
  RESET_PASSOWORD_SUBHEADER: 'Enter your new password',
  LOGIN_FORM_EMAIL_LABLE: 'Email',
  LOGIN_FORM_PASSWORD_LABLE: 'Password',
  LOGIN_FORM_EMAIL_PLACEHOLDER: 'jone@simplifya.com',
  LOGIN_FORM_PASSWORD_PLACEHOLDER: 'Enter your password',
  FORGET_PASSWORD_BTN_TEXT: 'Reset Password',
  FORGET_PASSWORD_BACK_LINK_TEXT: 'Back to Sign In',
  RESET_PASSWORD_NEW_LABLE: 'New Password',
  SETUP_PASSWORD_NEW_LABLE: 'Password',
  RESET_PASSWORD_NEW_PLACEHOLDER: 'Enter new password',
  RESET_PASSWORD_CONFIRM_LABLE: 'Confim Password',
  RESET_PASSWORD_CONFIRM_PLACEHOLDER: 'Enter confirm password',
  SETUP_PASSOWORD_SUBHEADER: 'Enter password for your new account',
  SETUP_PASSWORD_HEADER: 'Setup Password',
});
