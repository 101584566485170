/* eslint-disable no-useless-escape */
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
// import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LoginHelper from '../../helpers/features/LoginHelper';
import AppInput from '../common/input/AppInput';
import AppButton from '../styles/ButtonLink.styled';
import { useAuth } from '../../containers/auth/AuthContext';
import ErrorNotification from '../common/errorNotification/ErrorNotification';

function ResetPasswordForm() {
  const auth = useAuth();
  const { code } = useParams();

  useEffect(() => {
    auth.checkIsAccountSetup();
    auth.handleResetPassword(code);
  }, []);

  const loginSchema = yup.object().shape({
    password: yup
      .string()
      .required('Please enter your password')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
      ),
    confirmPassword: yup
      .string()
      .required('Please re-enter your password')
      .oneOf([yup.ref('password'), null], "Passwords dosen't match"),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{ token: code || '', password: '', confirmPassword: '' }}
      onSubmit={async values => {
        delete values.confirmPassword;
        auth.handleUpdatePassword(values);
      }}
      validationSchema={loginSchema}
    >
      {({ values, errors, touched, handleSubmit, handleChange, handleBlur, isValid, dirty }) => (
        <Form onSubmit={handleSubmit} noValidate>
          <AppInput
            label={
              auth.isAccountSetup
                ? LoginHelper.SETUP_PASSWORD_NEW_LABLE
                : LoginHelper.RESET_PASSWORD_NEW_LABLE
            }
            requied="*"
            type="password"
            name="password"
            placeholder={LoginHelper.RESET_PASSWORD_NEW_PLACEHOLDER}
            helpText=""
            helpTextLink=""
            linkUrl=""
            onChangeInput={handleChange}
            onBlurInput={handleBlur}
            value={values.password}
            errorText={errors.password ? errors.password : null}
          />
          <AppInput
            label={LoginHelper.RESET_PASSWORD_CONFIRM_LABLE}
            requied="*"
            type="password"
            name="confirmPassword"
            placeholder={LoginHelper.RESET_PASSWORD_CONFIRM_PLACEHOLDER}
            helpText=""
            helpTextLink=""
            linkUrl=""
            onChangeInput={handleChange}
            onBlurInput={handleBlur}
            value={values.confirmPassword}
            errorText={
              errors.confirmPassword && touched.confirmPassword ? errors.confirmPassword : null
            }
          />
          <div className="d-grid">
            <AppButton type="submit" disabled={!(dirty && isValid)}>
              {auth.isAccountSetup
                ? LoginHelper.SETUP_PASSWORD_HEADER
                : LoginHelper.RESET_PASSWORD_HEADER}
            </AppButton>
          </div>
          {auth.message.type === 'error' && (
            <ErrorNotification errorMessage={auth.message.message} />
          )}
        </Form>
      )}
    </Formik>
  );
}

export default ResetPasswordForm;
