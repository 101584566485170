import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import size from 'lodash/size';

import StyleForm from './Form.styled';

function ThemeTextArea(props) {
  const {
    label,
    requied,
    className,
    type,
    placeholder,
    errorText,
    helpText,
    name,
    value,
    onChangeInput,
    disabled,
    height,
    charLimit,
    errorSet,
  } = props;

  if (errorText) {
    errorSet(true);
  } else {
    errorSet(false);
  }

  return (
    <StyleForm>
      <Form.Group className="form-group" controlId="">
        <Form.Label>
          {label}
          <span className="red"> {requied}</span>
        </Form.Label>
        <Form.Control
          className={className}
          type={type}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChangeInput}
          disabled={disabled}
          as="textarea"
          rows={height}
        />
        <Form.Text className="text-muted">{`${size(value)} / ${charLimit}`}</Form.Text>
        <Form.Text className="error-text">{errorText}</Form.Text>
        <Form.Text className="text-muted">{helpText}</Form.Text>
      </Form.Group>
    </StyleForm>
  );
}

ThemeTextArea.propTypes = {
  label: PropTypes.string.isRequired,
  requied: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  helpText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  height: PropTypes.func.isRequired,
  charLimit: PropTypes.number.isRequired,
  errorSet: PropTypes.func,
};

ThemeTextArea.defaultProps = {
  errorText: null,
  disabled: false,
  errorSet: () => {},
};

export default ThemeTextArea;
