import React from 'react';
import { Container } from 'react-bootstrap';

import { StylePage } from '../../components/styles/Page.styled';
import PageHeader from '../../components/common/pageHeader/PageHeader';
import FAQHelper from '../../helpers/faqHelper';

import AccourdionComponet from '../../components/common/accordion/AccourdionComponet';
import CommonHelper from '../../helpers/common';

function Faq() {
  const accourdion = FAQHelper.FAQ_LIST.map(item => (
    <AccourdionComponet key={item.id} name={item.question} description={item.answer} id={item.id} />
  ));
  return (
    <Container>
      <StylePage>
        <PageHeader title={CommonHelper.NAVIGATIN_HEADER_TEXT.FAQ} subheader="" />
        {accourdion}
      </StylePage>
    </Container>
  );
}

export default Faq;
