/* eslint-disable react/no-danger */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import TableRow from '../../common/table/TableRow';
import SearchLicenseHelper from '../../../helpers/features/SearchLicensesHelper';
import { formatAddress, trimString, formatPhoneNumber } from '../../../helpers/commonHelper';

const WeeklyLicenseReportTableRow = ({ isAdmin, data, index }) => {
  return (
    <TableRow>
      <td>
        <span className={data?.meta?.license_number ? 'isPermanent' : ''}>
          {data.license_number ? data.license_number : data?.temp_id}
        </span>
      </td>
      <td>
        <span className={data?.meta?.legal_name ? 'isPermanent' : ''}>
          {data.legal_name || SearchLicenseHelper.NO_VALUES}
        </span>
      </td>
      <td>
        <span className={data?.meta?.dba_name ? 'isPermanent' : ''}>
          {data.dba_name || SearchLicenseHelper.NO_VALUES}
        </span>
      </td>
      {!isAdmin && (
        <td>
          {!data.street && !data.city && !data.state && !data.zip ? (
            SearchLicenseHelper.NO_VALUES
          ) : (
            <>
              {data.street && <span>{data.street}</span>}
              {(data.state || data.zip || data.city) && (
                <span
                  className={
                    data?.meta?.city || data?.meta?.state || data?.meta?.zip ? 'isPermanent' : ''
                  }
                >
                  {formatAddress(data.city, data.state, data.zip)}
                </span>
              )}
            </>
          )}
        </td>
      )}
      {isAdmin && (
        <>
          <td>
            <span className={data?.meta?.street ? 'isPermanent' : ''}>
              {data.street || SearchLicenseHelper.NO_VALUES}
            </span>
          </td>
          <td>
            <span className={data?.meta?.city ? 'isPermanent' : ''}>
              {data.city || SearchLicenseHelper.NO_VALUES}
            </span>
          </td>
          <td>
            <span className={data?.meta?.state ? 'isPermanent' : ''}>
              {data.state || SearchLicenseHelper.NO_VALUES}
            </span>
          </td>
          <td>
            <span className={data?.meta?.state_name ? 'isPermanent' : ''}>
              {data.state_name || SearchLicenseHelper.NO_VALUES}
            </span>
          </td>
          <td>
            <span className={data?.meta?.zip ? 'isPermanent' : ''}>
              {data.zip || SearchLicenseHelper.NO_VALUES}
            </span>
          </td>
          <td>
            <span className={data?.meta?.country_name ? 'isPermanent' : ''}>
              {data.country_name || SearchLicenseHelper.NO_VALUES}
            </span>
          </td>
          <td>
            <span className={data?.meta?.country_code ? 'isPermanent' : ''}>
              {data.country_code || SearchLicenseHelper.NO_VALUES}
            </span>
          </td>
        </>
      )}
      <td>
        <span className={data?.meta?.license_issuer ? 'isPermanent' : ''}>
          {data.license_issuer || SearchLicenseHelper.NO_VALUES}
        </span>
      </td>
      <td>
        <span className={data?.meta?.license_type ? 'isPermanent' : ''}>
          {data.license_type || SearchLicenseHelper.NO_VALUES}
        </span>
      </td>
      {isAdmin && (
        <>
          <td
            aria-labelledby="license_details"
            className={data?.meta?.license_details ? 'isPermanent' : ''}
            dangerouslySetInnerHTML={{
              __html: data.license_details || SearchLicenseHelper.NO_VALUES,
            }}
          />
          <td>
            <span className={data?.meta?.license_designation ? 'isPermanent' : ''}>
              {trimString(15, data.license_designation) || SearchLicenseHelper.NO_VALUES}
            </span>
          </td>
          <td>
            <span className={data?.meta?.license_issue_date ? 'isPermanent' : ''}>
              {data.license_issue_date
                ? moment(data.license_issue_date).format('MM/DD/YYYY')
                : SearchLicenseHelper.NO_VALUES}
            </span>
          </td>
          <td>
            <span className={data?.meta?.license_expiration_date ? 'isPermanent' : ''}>
              {data.license_expiration_date
                ? moment(data.license_expiration_date).format('MM/DD/YYYY')
                : SearchLicenseHelper.NO_VALUES}
            </span>
          </td>
          <td>
            <span className={data?.meta?.county_name ? 'isPermanent' : ''}>
              {data.county_name || SearchLicenseHelper.NO_VALUES}
            </span>
          </td>
          <td>
            <span className={data?.meta?.parent_name ? 'isPermanent' : ''}>
              {data.parent_name || SearchLicenseHelper.NO_VALUES}
            </span>
          </td>
          <td>
            <span className={data?.meta?.company_website ? 'isPermanent' : ''}>
              {data.company_website || SearchLicenseHelper.NO_VALUES}
            </span>
          </td>
          <td>
            <span className={data?.meta?.company_contact_name ? 'isPermanent' : ''}>
              {data.company_contact_name || SearchLicenseHelper.NO_VALUES}
            </span>
          </td>
          <td>
            <span className={data?.meta?.company_email ? 'isPermanent' : ''}>
              {data.company_email || SearchLicenseHelper.NO_VALUES}
            </span>
          </td>
          <td>
            <span className={data?.meta?.company_phone ? 'isPermanent' : ''}>
              {formatPhoneNumber(data.company_phone) || SearchLicenseHelper.NO_VALUES}
            </span>
          </td>
          <td
            aria-labelledby="note"
            className={data?.meta?.note ? 'isPermanent' : ''}
            dangerouslySetInnerHTML={{
              __html: data.note || SearchLicenseHelper.NO_VALUES,
            }}
          />
          <td>
            <span
              className={data?.meta?.updated_at || data?.meta?.last_update ? 'isPermanent' : ''}
            >
              {data.updated_at || data.last_update || data.created_at
                ? moment(data.updated_at || data.last_update || data.created_at).format(
                    'MM/DD/YYYY',
                  )
                : SearchLicenseHelper.NO_VALUES}
            </span>
          </td>
        </>
      )}
      <td>
        <span className={data?.meta?.status ? 'isPermanent' : ''}>
          {data.status || SearchLicenseHelper.NO_VALUES}
        </span>
      </td>
    </TableRow>
  );
};

WeeklyLicenseReportTableRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    legal_name: PropTypes.string,
    license_number: PropTypes.string,
    dba_name: PropTypes.string,
    street: PropTypes.string,
    country_name: PropTypes.string,
    license_issuer: PropTypes.string,
    license_details: PropTypes.string,
    license_type: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    status: PropTypes.string,
    title8: PropTypes.string,
    license_issue_date: PropTypes.string,
    license_expiration_date: PropTypes.string,
    parent_name: PropTypes.string,
    company_contact_name: PropTypes.string,
    company_email: PropTypes.string,
    company_website: PropTypes.string,
    company_phone: PropTypes.string,
    county_name: PropTypes.string,
    last_update: PropTypes.string,
    user_updated_at: PropTypes.string,
    note: PropTypes.string,
    state_name: PropTypes.string,
    license_designation: PropTypes.string,
    country_code: PropTypes.string,
    updated_at: PropTypes.string,
    created_at: PropTypes.string,
    temp_id: PropTypes.string,
  }).isRequired,
  temp_id: PropTypes.string,
  license_number: PropTypes.string,
  index: PropTypes.number.isRequired,
};

WeeklyLicenseReportTableRow.defaultProps = {
  temp_id: 'id',
  license_number: 'id',
};

export default WeeklyLicenseReportTableRow;
