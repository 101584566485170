import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { Form } from 'react-bootstrap';
import StyleForm from './Form.styled';

function ThemeDatePicker(props) {
  const {
    label,
    requied,
    placeholder,
    errorText,
    helpText,
    name,
    value,
    onChangeInput,
    format,
    disabled,
    maxDate,
    minDate,
    selected,
  } = props;
  return (
    <StyleForm>
      <Form.Group className="form-group" controlId="">
        <Form.Label>
          {label}
          <span className="red"> {requied}</span>
        </Form.Label>
        <DatePicker
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChangeInput}
          disabled={disabled}
          className="form-control date-picker"
          selected={selected}
          format={format}
          onKeyDown={e => {
            e.preventDefault();
          }}
          maxDate={maxDate}
          minDate={minDate}
        />
        <Form.Text className="error-text">{errorText}</Form.Text>
        <Form.Text className="text-muted">{helpText}</Form.Text>
      </Form.Group>
    </StyleForm>
  );
}

ThemeDatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  requied: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  helpText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  format: PropTypes.func.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  maxDate: PropTypes.string.isRequired,
  minDate: PropTypes.string.isRequired,
  selected: PropTypes.node,
};

ThemeDatePicker.defaultProps = {
  errorText: null,
  disabled: false,
  selected: '',
};

export default ThemeDatePicker;
