/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { components } from 'react-select';
import PropTypes from 'prop-types';

function Options(props) {
  return (
    <div>
      <components.Option className="custom-checkbox" {...props}>
        <input type="checkbox" checked={props.isSelected} onChange={() => null} />{' '}
        <label>{props.data.label}</label>
      </components.Option>
    </div>
  );
}

Options.propTypes = {
  isFocused: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
};

export default Options;
