/* eslint-disable import/order */

import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col, Image, Form } from 'react-bootstrap';
import makeAnimated from 'react-select/animated';
import isEmpty from 'lodash/isEmpty';

import { useWeeklyLicenseReportContext } from '../../../containers/reports/weeklyLicenseReport/WeeklyLicenseReportContext';
import ReportsHelper from '../../../helpers/features/ReportsHelper';
import StateHelper from '../../../helpers/stateHelper';

import PageHeader from '../../common/pageHeader/PageHeader';
import { StylePage } from '../../styles/Page.styled';
import StyleForm from '../../common/input/Form.styled';
import FilterDropDown from '../../common/input/stateFilterDropDown/FilterDropDown';
import MultiValue from '../../common/input/stateFilterDropDown/MultiValue';
import Option from '../../common/input/stateFilterDropDown/Options';
import Permissions from '../../auth/Permissions';
import Search from '../../common/search/Search';
import PaginationDropdown from '../../common/input/PaginationDropdown';
import PaginationComponent from '../../common/pagination/Pagination';
import TableComponent from '../../common/table/Table';
import TableHeader from '../../common/table/TableHeader';
import SingleTableHeader from '../../common/table/SingleTableHeader';
import TableBody from '../../common/table/TableBody';
import WeeklyLicenseReportTableRow from './WeeklyLicenseReportTableRow';

import FilterIcon from '../../../assets/images/filter.svg';
import LoadingIcon from '../../../assets/images/spinner.gif';

const WeeklyLicenseReportTable = () => {
  const {
    isAdmin,
    licenseData,
    isLoadingLicenseData,
    totalLicenseRecords,
    selectedOption,
    setSelectedOption,
    handleStateFilter,
    searchParameter,
    setSearchParameter,
    handleSearch,
    handleRecordsPerPage,
    totalPages,
    currentPage,
    handleLicenseTablePageNavigation,
    nextPage,
    currentSortedTitle,
    setCurrentSortedTitle,
    handleFetchSortedLicenseData,
  } = useWeeklyLicenseReportContext();

  const animatedComponents = makeAnimated();

  return (
    <Container>
      <StylePage>
        <Row>
          <Col md={6}>
            <PageHeader
              title={ReportsHelper.WEEKLY_LICENSE_REPORT_TITLE}
              subheader={`${totalLicenseRecords} Records`}
            />
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col md={2} className="mb-3">
            <StyleForm className="state-dropdown">
              <Image src={FilterIcon} />
              <FilterDropDown
                options={StateHelper.STATES}
                isMulti
                isSearchable={false}
                isClearable={false}
                controlShouldRenderValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={value => {
                  setSelectedOption(value);
                  handleStateFilter(value);
                }}
                value={selectedOption}
                placeholder="State"
              />
            </StyleForm>
          </Col>
          <Col md={2} className="admin-sarch">
            <Permissions permissionRole="ADMIN">
              <Search
                customClass="admin-search"
                handleSearch={handleSearch}
                setSearchParameter={setSearchParameter}
                searchParameter={searchParameter}
              />
            </Permissions>
          </Col>
          <Col md={8} className="mb-3 d-flex justify-content-end">
            <PaginationDropdown handleRecordsPerPage={handleRecordsPerPage} />
            {totalPages > 1 && (
              <PaginationComponent
                totalPages={totalPages}
                currentPage={currentPage}
                handleTablePageNavigation={handleLicenseTablePageNavigation}
                nextPage={nextPage}
              />
            )}
          </Col>
        </Row>
        <TableComponent
          className={isAdmin ? 'admin-licenes' : 'user-licences'}
          isSelectAll={false}
          isInlineEditing={false}
        >
          <TableHeader>
            {ReportsHelper.WEEKLY_LICENSES_TABLE.TABLE_HEADERS.map(item => {
              return (
                <SingleTableHeader
                  title={item}
                  key={item.key}
                  setCurrentSortedTitle={setCurrentSortedTitle}
                  handleFetchSortedData={handleFetchSortedLicenseData}
                  currentSortedTitle={currentSortedTitle}
                />
              );
            })}
          </TableHeader>
          <TableBody>
            {licenseData.docs &&
              licenseData.docs.map((item, i) => {
                return (
                  <WeeklyLicenseReportTableRow
                    isAdmin={isAdmin}
                    data={item}
                    key={item.id}
                    index={i}
                  />
                );
              })}
            {isEmpty(licenseData.docs) && !isLoadingLicenseData && (
              <tr className="without-data">
                <td colSpan="24">
                  <p>{ReportsHelper.WEEKLY_LICENSES_TABLE.NO_RECORDS_FOUND}</p>
                </td>
              </tr>
            )}
            {isLoadingLicenseData && (
              <tr className="loding-data">
                <td colSpan="24">
                  <Image src={LoadingIcon} sizes="50px" />
                </td>
              </tr>
            )}
          </TableBody>
        </TableComponent>
      </StylePage>
    </Container>
  );
};

export default WeeklyLicenseReportTable;
