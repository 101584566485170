/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

function DataPreview({
  label,
  data,
  addressLineOne,
  isAddress,
  onClickPreview,
  isLicenseDetails,
  isNote,
}) {
  return (
    <div className="modal-content-item">
      <b>{label}</b>
      {isAddress && (
        <p style={{ marginBottom: '2px' }} onClick={onClickPreview}>
          {addressLineOne}
        </p>
      )}
      {isLicenseDetails || isNote ? (
        <p dangerouslySetInnerHTML={{ __html: data }} />
      ) : (
        <p onClick={onClickPreview}>{data}</p>
      )}
    </div>
  );
}

DataPreview.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  addressLineOne: PropTypes.string,
  isAddress: PropTypes.bool,
  isLicenseDetails: PropTypes.bool,
  onClickPreview: PropTypes.func,
  isNote: PropTypes.bool,
};

DataPreview.defaultProps = {
  isAddress: false,
  isLicenseDetails: false,
  addressLineOne: '',
  isNote: false,
  onClickPreview: () => {},
};

export default DataPreview;
