import React from 'react';

import SourcesListView from '../../../components/sources/sourcesListView/SourcesListView';

import SourcesProvider from '../SourcesContext';

const SourcesTable = props => {
  return (
    <SourcesProvider componentProps={props}>
      <SourcesListView />
    </SourcesProvider>
  );
};

export default SourcesTable;
