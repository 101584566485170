/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

import StyleForm from './Form.styled';
import SearchLicenseHelper from '../../../helpers/features/SearchLicensesHelper';

function PaginationDropdown({ selectedOption, handleRecordsPerPage }) {
  const [selected, setSelected] = useState(
    SearchLicenseHelper.RECORDS_PER_PAGES_VALUES.filter(
      option => option.value === selectedOption,
    )[0],
  );

  return (
    <StyleForm>
      <Form.Select
        onChange={e => {
          handleRecordsPerPage(e.target.value);
          setSelected(e.target.value);
        }}
        value={selected.value}
      >
        {SearchLicenseHelper.RECORDS_PER_PAGES_VALUES.map((item, i) => (
          <option value={item.value} key={item.key}>
            {item.value}
          </option>
        ))}
      </Form.Select>
    </StyleForm>
  );
}

PaginationDropdown.propTypes = {
  selectedOption: PropTypes.number,
};

PaginationDropdown.defaultProps = {
  selectedOption: 10,
};

export default PaginationDropdown;
