import { get } from '../libs/api/apiConnector';
import apiRoutes from '../helpers/apiRoutes';

export const getHomeCardDetails = async () => {
  try {
    const url = apiRoutes.HOME.GET_HOME_CARD_DETAILS;
    const { data } = await get(url);
    return data;
  } catch (error) {
    console.error(`error ${error}`);
    return error.response.data;
  }
};

export const test = async () => {};
