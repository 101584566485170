import React from 'react';
import { Container } from 'react-bootstrap';

import { StylePage } from '../../components/styles/Page.styled';
import PageHeader from '../../components/common/pageHeader/PageHeader';

import AccourdionComponet from '../../components/common/accordion/AccourdionComponet';
import DictonaryHelper from '../../helpers/dataDictonaryHelper';

function DataDictionary() {
  const accourdion = DictonaryHelper.TERMS_LIST.map(item => (
    <AccourdionComponet key={item.id} name={item.term} description={item.definition} id={item.id} />
  ));
  return (
    <Container>
      <StylePage>
        <PageHeader title="Data Dictionary" subheader="" />
        {accourdion}
      </StylePage>
    </Container>
  );
}

export default DataDictionary;
