import styled from 'styled-components';

export const StyleDashboard = styled.div`
  h2 {
    padding: 30px 0 120px 0;
  }
  .item-row{
    margin-top: -120px;
    }
  }
  .card {
    padding: 24px;
    border-radius: 20px;
    border: none;
    box-shadow: 0 20px 35px -8px rgb(161 176 205 / 25%);
    margin-bottom: 80px;
    img {
      margin-bottom: 15px;
    }
    .card-title {
      font-size: 18px;
      font-weight: 600;
    }
    .card-text {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 5px;
    }
    h1 {
      font-size: 40px;
      font-weight: 900;
      margin-bottom: 0;
      color: ${props => props.theme?.general?.primaryColor};
    }
  }
  @media (max-width: 575.98px) {
    .card{
        box-shadow: 0 30px 35px 22px rgb(161 176 205 / 25%);
        margin-bottom: 20px;
    }
}
.total-text {
  color: #FFFFFF;
}

`;

export const StyleCover = styled.div`
  background: ${props => props.theme?.general?.primaryColor};
`;
