/* eslint-disable operator-linebreak */
/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useRef } from 'react';
import propTypes, { element } from 'prop-types';
import { Form } from 'react-bootstrap';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Select from 'react-select';
import isNull from 'lodash/isNull';
import isNil from 'lodash/isNil';
import StyleForm from '../../common/input/Form.styled';
import CommonModal from '../../common/modal/CommonModal';
import AppButton from '../../styles/ButtonLink.styled';
import { getDropdownValue, isValidState } from '../../../helpers/commonHelper';
import { SearchLicencesContext } from '../../../containers/searchLicenses/SearchLicensesContext';
import SearchLicenseHelper from '../../../helpers/features/SearchLicensesHelper';

function InlineEditModal(props) {
  const { validateUserInputs, setIsSaveWarningPopUpOpen, selectedRow } =
    useContext(SearchLicencesContext);

  // eslint-disable-next-line max-len
  const {
    closeModal,
    title,
    charLimit,
    onChangeInput,
    type,
    inputType,
    data,
    feild,
    minimumDate,
    maximumDate,
    options,
    placeholder,
  } = props;
  const [editedData, setEditedData] = useState(data);
  const [errors, setErrors] = useState('');
  const [isPasted, setIsPasted] = useState(false);
  const [isCharLimitReached, setIsCharLimitReached] = useState(false);
  const [isPermanent, setIsPermanent] = useState(
    !isNull(data.meta) ? !isNil(data?.meta[feild]) : false,
  );

  const isPermanentOrigin = !isNull(data.meta) ? !isNil(data?.meta[feild]) : false;
  const errorRef = useRef('');

  const handlePaste = () => {
    setIsPasted(true);
  };

  return (
    <CommonModal
      className=""
      size="md"
      title={`Edit ${title}`}
      handleClose={() => {
        closeModal();
        setEditedData({});
      }}
    >
      <StyleForm>
        <Form>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Label>{title}</Form.Label>
            {type === 1 ? (
              <Form.Control
                as="textarea"
                rows={1}
                onChange={e => {
                  if (isEmpty(e.target.value)) {
                    setEditedData({ ...editedData, [feild]: '' });
                    onChangeInput({ [feild]: '', is_permanent: isPermanent });
                  } else {
                    setEditedData({ ...editedData, [feild]: e.target.value });
                    onChangeInput({ [feild]: e.target.value, is_permanent: isPermanent });
                    setErrors(validateUserInputs(feild, e.target.value));
                    if (isPasted && size(e.target.value) > charLimit) {
                      setErrors(SearchLicenseHelper.INLINE_EDIT_ON_PASTE_CHAR_LIMIT_ERRORR_TEXT);
                      setIsCharLimitReached(false);
                    } else if (!isPasted && size(e.target.value) === charLimit) {
                      setIsCharLimitReached(true);
                    } else {
                      setEditedData({ ...editedData, [feild]: e.target.value });
                      onChangeInput({ [feild]: e.target.value, is_permanent: isPermanent });
                      setErrors(validateUserInputs(feild, e.target.value));
                      setIsPasted(false);
                      setIsCharLimitReached(false);
                    }
                  }
                }}
                value={editedData[feild]}
                onPaste={handlePaste}
              />
            ) : type === 2 ? (
              <Form.Control
                controlId="textinput"
                type={inputType}
                value={editedData[feild]}
                onChange={e => {
                  if (isEmpty(e.target.value)) {
                    setEditedData({ ...editedData, [feild]: null });
                    onChangeInput({ [feild]: null, is_permanent: isPermanent });
                  } else {
                    setEditedData({ ...editedData, [feild]: e.target.value });
                    onChangeInput({ [feild]: e.target.value, is_permanent: isPermanent });
                    if (isPasted && size(e.target.value) > charLimit) {
                      setErrors(SearchLicenseHelper.INLINE_EDIT_ON_PASTE_CHAR_LIMIT_ERRORR_TEXT);
                      setIsCharLimitReached(false);
                    } else if (!isPasted && size(e.target.value) > charLimit) {
                      setIsCharLimitReached(true);
                      setErrors(validateUserInputs(feild, e.target.value));
                    } else {
                      setErrors(validateUserInputs(feild, e.target.value));
                      setIsPasted(false);
                      setIsCharLimitReached(false);
                    }
                  }
                }}
                // eslint-disable-next-line no-useless-escape
                pattern={inputType === 'number' && '/^-?d+.?d*$/'}
                onPaste={handlePaste}
                disabled={
                  feild === 'status' &&
                  !isNull(data.license_expiration_date) &&
                  !isEmpty(data.license_expiration_date)
                }
              />
            ) : type === 3 ? (
              <DatePicker
                className="form-control"
                format="yyyy/MM/dd"
                onKeyDown={e => {
                  e.preventDefault();
                }}
                selected={editedData[feild] ? new Date(editedData[feild]) : new Date()}
                onChange={date => {
                  setEditedData({
                    ...editedData,
                    [feild]: isNull(date) ? '' : moment(date).format('L'),
                  });
                  onChangeInput({
                    [feild]: isNull(date) ? '' : moment(date).format('L'),
                    is_permanent: isPermanent,
                  });
                  if (isNull(date)) {
                    setErrors('');
                    return;
                  }
                  if (
                    !isEmpty(selectedRow.license_issue_date) &&
                    new Date(date) < new Date(selectedRow.license_issue_date)
                  ) {
                    setErrors(SearchLicenseHelper.DATE_SELECTION_ERROR_INLINE_EDIT);
                  }
                }}
                minDate={minimumDate ? new Date(data.license_issue_date) : new Date()}
                value={editedData[feild]}
                isClearable={!isEmpty(editedData[feild])}
              />
            ) : type === 4 ? (
              <DatePicker
                className="form-control"
                format="yyyy/MM/dd"
                onKeyDown={e => {
                  e.preventDefault();
                }}
                selected={editedData[feild] ? new Date(editedData[feild]) : new Date()}
                onChange={date => {
                  setEditedData({ ...editedData, [feild]: moment(date).format('L') });
                  onChangeInput({
                    [feild]: moment(date).format('L'),
                    is_permanent: isPermanent,
                  });
                }}
                maxDate={maximumDate ? new Date() : ''}
              />
            ) : type === 5 ? (
              <Select
                className="form-control theme-select"
                options={options}
                onChange={val => {
                  setEditedData({ ...editedData, [feild]: val.label });
                  onChangeInput({
                    [feild]: val.label,
                    is_permanent: isPermanent,
                  });
                }}
                value={getDropdownValue(editedData[feild], options)}
                placeholder={placeholder}
                isSearchable
              />
            ) : (
              ''
            )}
          </Form.Group>
          <small className="red" ref={errorRef}>
            {errors}
          </small>
          <p className="mt-2">
            {(type === 1 || type === 2) && `${size(editedData[feild])} / ${charLimit}`}
          </p>
          <Form.Group controlId="exampleForm.isPermanent">
            <Form.Check
              type="checkbox"
              label="Mark as permanent"
              checked={isPermanent}
              onChange={e => {
                setIsPermanent(e.target.checked);
                onChangeInput({ [feild]: editedData[feild], is_permanent: e.target.checked });
              }}
            />
          </Form.Group>
        </Form>
      </StyleForm>
      <div className="d-flex gap-2 popup-btn-row mt-4">
        <AppButton className="popup cancel" onClick={() => closeModal()}>
          {SearchLicenseHelper.INLINE_EDIT_CANCEL_BUTTON_TEXT}
        </AppButton>
        <AppButton
          className="popup"
          fill="true"
          onClick={() => {
            if (!isEmpty(editedData)) {
              setIsSaveWarningPopUpOpen(true);
            }
          }}
          disabled={
            /* errors ||
            (isCharLimitReached && errors) || */
            (!isEmpty(errors) &&
              (!isCharLimitReached || (isPasted && charLimit >= size(editedData[feild])))) ||
            (inputType === 'number' && charLimit > size(editedData[feild])) ||
            errors ||
            (data[feild] === editedData[feild] && isPermanentOrigin === isPermanent)
          }
        >
          {SearchLicenseHelper.INLINE_EDIT_UPDATE_BUTTON_TEXT}
        </AppButton>
      </div>
    </CommonModal>
  );
}
InlineEditModal.propTypes = {
  onChangeInput: propTypes.func.isRequired,
  closeModal: propTypes.func.isRequired,
  title: propTypes.string.isRequired,
  charLimit: propTypes.number.isRequired,
  type: propTypes.number.isRequired,
  inputType: propTypes.string,
  data: propTypes.shape({
    id: propTypes.number,
    license_number: propTypes.string,
    legal_name: propTypes.string,
    dba_name: propTypes.string,
    street: propTypes.string,
    license_issuer: propTypes.string,
    license_type: propTypes.string,
    city: propTypes.string,
    state: propTypes.string,
    zip: propTypes.string,
    status: propTypes.string,
    title8: propTypes.string,
    license_issue_date: propTypes.string,
  }).isRequired,
  feild: propTypes.string.isRequired,
  minimumDate: propTypes.string,
  maximumDate: propTypes.string,
  options: propTypes.node,
  placeholder: propTypes.string,
};

InlineEditModal.defaultProps = {
  inputType: 'text',
  minimumDate: false,
  maximumDate: false,
  options: [],
  placeholder: '',
};

export default InlineEditModal;
