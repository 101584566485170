/* eslint-disable no-else-return */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-mixed-operators */
import React, { useContext } from 'react';
import { Pagination } from 'react-bootstrap';

import { StylePagination } from '../../styles/Table.styled';

function PaginationComponet({ totalPages, currentPage, handleTablePageNavigation, nextPage }) {
  const pages = [];

  // eslint-disable-next-line consistent-return
  const handlePages = visibleCount => {
    if (currentPage <= totalPages - 3) {
      // eslint-disable-next-line no-plusplus, max-len
      for (
        let i = currentPage;
        i <= currentPage + visibleCount && currentPage <= totalPages - 2;
        // eslint-disable-next-line no-plusplus
        i++
      ) {
        pages.push(
          <Pagination.Item
            onClick={() => handleTablePageNavigation(i)}
            active={currentPage === i}
            key={i}
          >
            {i}
          </Pagination.Item>,
        );
      }
    } else if (currentPage > totalPages - 3) {
      pages.push(
        <Pagination.Item
          onClick={() => handleTablePageNavigation(totalPages - 1)}
          active={currentPage === totalPages - 1}
          key={totalPages - 1}
        >
          {totalPages - 1}
        </Pagination.Item>,
        <Pagination.Item
          onClick={() => handleTablePageNavigation(totalPages)}
          active={currentPage === totalPages}
          key={totalPages}
        >
          {totalPages}
        </Pagination.Item>,
      );
      if (totalPages === 3) {
        pages.unshift(
          <Pagination.Item
            onClick={() => handleTablePageNavigation(totalPages - 2)}
            active={currentPage === totalPages - 2}
            key={totalPages - 2}
          >
            {totalPages - 2}
          </Pagination.Item>,
        );
      }
      if (totalPages > 3) {
        pages.unshift(
          <Pagination.Item
            onClick={() => handleTablePageNavigation(totalPages - 3)}
            active={currentPage === totalPages - 3}
            key={totalPages - 3}
          >
            {totalPages - 3}
          </Pagination.Item>,
          <Pagination.Item
            onClick={() => handleTablePageNavigation(totalPages - 2)}
            active={currentPage === totalPages - 2}
            key={totalPages - 2}
          >
            {totalPages - 2}
          </Pagination.Item>,
        );
      }
    }
  };
  handlePages(3);

  return (
    <StylePagination>
      <Pagination className="d-inline-flex m-0">
        {totalPages > 4 && currentPage !== 1 && (
          <Pagination.First
            disabled={currentPage === 1}
            onClick={() => handleTablePageNavigation(1)}
          />
        )}
        {totalPages > 4 && currentPage !== 1 && (
          <Pagination.Prev
            disabled={currentPage === 1}
            onClick={() => handleTablePageNavigation(currentPage > 1 ? currentPage - 1 : 1)}
          />
        )}
        {pages}
        {totalPages > 4 && currentPage !== totalPages && (
          <Pagination.Next
            disabled={currentPage === totalPages}
            onClick={() =>
              handleTablePageNavigation(nextPage < totalPages ? currentPage + 1 : totalPages)
            }
          />
        )}
        {totalPages > 4 && currentPage !== totalPages && (
          <Pagination.Last
            disabled={currentPage === totalPages}
            onClick={() => handleTablePageNavigation(totalPages)}
          />
        )}
      </Pagination>
    </StylePagination>
  );
}

export default PaginationComponet;
