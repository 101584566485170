import styled from 'styled-components';

const StyleUpload = styled.div`
  .upload-drag-container {
    background: white;
    border: 1px dashed #707070;
    padding: 35px;
    &:hover {
      border-color: ${props => props.theme?.general?.primaryColor};
      cursor: pointer;
    }
    p {
      font-size: 18px;
    }
  }
`;

export default StyleUpload;
