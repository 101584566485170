import React from 'react';
import { Container } from 'react-bootstrap';

import { LicensesContextProvider } from '../LicensesContext';
import { StylePage } from '../../../components/styles/Page.styled';
import PageHeader from '../../../components/common/pageHeader/PageHeader';
import LicensesHelper from '../../../helpers/features/LicensesHelper';
import AddNewLicesensForm from '../../../components/licenses/AddNewLicensesForm';
import AddLicenseAlerts from '../../../components/licenses/alerts/AddLicenseAlerts';

function AddNewlicenses(props) {
  return (
    <StylePage>
      <Container>
        <LicensesContextProvider componentProps={props}>
          <PageHeader
            title={LicensesHelper.LICENSES_HEADER}
            subheader={LicensesHelper.LICENSES_SUBHEADER}
          />
          <AddNewLicesensForm />
          <AddLicenseAlerts />
        </LicensesContextProvider>
      </Container>
    </StylePage>
  );
}

export default AddNewlicenses;
