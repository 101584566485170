import React, { useContext } from 'react';
import { Row } from 'react-bootstrap';

import AllFeildsEditForm from './AllFeildsEditForm';
import CommonModal from '../../common/modal/CommonModal';

import SearchLicensesHelper from '../../../helpers/features/SearchLicensesHelper';
import { SearchLicencesContext } from '../../../containers/searchLicenses/SearchLicensesContext';

function DetailsModal() {
  const { handleEditModalClose } = useContext(SearchLicencesContext);

  return (
    <CommonModal
      className=""
      size="lg"
      title={SearchLicensesHelper.LICENSE_EDIT_MODAL_TITLE}
      handleClose={() => handleEditModalClose()}
    >
      <Row>
        <AllFeildsEditForm />
      </Row>
    </CommonModal>
  );
}

export default DetailsModal;
