/* eslint-disable radix */
/* eslint-disable eqeqeq */

import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

export const TextInput = props => {
  const {
    id,
    name,
    value,
    placeholder,
    handleUpdate,
    edited,
    disabled,
    showCounter,
    maxLength,
    error,
  } = props;

  const [fieldValue, setFieldValue] = useState(value);

  return (
    <Form>
      <Form.Group controlId={id}>
        <Form.Control
          type="text"
          name={name}
          value={fieldValue}
          placeholder={placeholder}
          onChange={e => setFieldValue(e.target.value || null)}
          onBlur={e => {
            handleUpdate(e.target.value || null);
            setFieldValue(e.target.value || null);
          }}
          style={{ borderColor: edited ? '#fdc126' : '' }}
          disabled={disabled}
        />
        {showCounter && (
          <span>
            {fieldValue?.length || 0}/{maxLength}
          </span>
        )}
        <span>{error}</span>
      </Form.Group>
    </Form>
  );
};

export const TextAreaInput = props => {
  const { value, handleUpdate, edited } = props;

  const [fieldValue, setFieldValue] = useState(value);

  return (
    <Form.Group>
      <Form.Control
        as="textarea"
        rows={1}
        value={fieldValue}
        onChange={e => setFieldValue(e.target.value)}
        onBlur={e => {
          if (!isEmpty(e.target.value) && !isEqual(e.target.value, value)) {
            handleUpdate(e.target.value);
          } else {
            handleUpdate(value);
            setFieldValue(value);
          }
        }}
        style={{ borderColor: edited ? '#5dadae' : '' }}
      />
    </Form.Group>
  );
};

export const NumberInput = props => {
  const { value, handleUpdate, edited } = props;

  const [fieldValue, setFieldValue] = useState(parseInt(value));

  return (
    <Form>
      <Form.Group>
        <Form.Control
          type="number"
          value={fieldValue}
          onChange={e => setFieldValue(e.target.value)}
          onBlur={e => {
            if (!isEmpty(e.target.value) && e.target.value == value) {
              handleUpdate(parseInt(e.target.value));
            } else {
              handleUpdate(value);
              setFieldValue(value);
            }
          }}
          style={{ borderColor: edited ? 'red' : '' }}
        />
      </Form.Group>
    </Form>
  );
};
