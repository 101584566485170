import styled from 'styled-components';

export const StyleHeader = styled.header`
  background-color: ${props => props.theme?.general?.primaryColor};
  padding: 11px 0;
  img {
    padding: 0;
  }
  .navbar-nav {
    a {
      font-size: ${props => props.theme?.navigation?.navigationFontSize};
      color: white;
      font-weight: ${props => props.theme?.navigation?.navigationFontWeight};
      margin: 0 22px;
      text-decoration: none;
      border-bottom: 2px solid transparent;
      transition: 0.2s cubic-bezier(0.39, 0.58, 0.57, 1);
      &:hover {
        font-weight: 800;
        transition: 0.2s cubic-bezier(0.39, 0.58, 0.57, 1);
      }
    }
    .sub-menu {
      a {
        padding: 0;
      }
      .dropdown-menu {
        border: none;
        margin-bottom: 10px;
        box-shadow: 0 5px 35px rgba(161, 176, 205, 0.25);
        a {
          padding: 5px 10px;
          font-size: 14px;
          margin: 0;
          color: #000000;
          min-width: 145px;
          transition: 0.2s cubic-bezier(0.39, 0.58, 0.57, 1);
          &:hover {
            font-weight: 400;
          }
          &:active {
            background: #e9ecef;
          }
        }
      }
    }
    @media (max-width: 575.98px) {
      a,
      .sub-menu a {
        margin: 0;
        padding: 10px 0;
        font-size: 16px;
      }
    }
  }
  button {
    border: none;
    &:focus {
      box-shadow: none;
    }
    &:hover {
      &:focus {
        box-shadow: none;
      }
    }
  }
`;
export const AppNav = styled.nav`
  a {
    color: white;
    font-weight: 400;
    text-decoration: none;
    font-size: 16px;
  }
  button {
    border: none;
    background-color: inherit;
    color: white;
    font-size: 16px;
    cursor: pointer;
    display: inline-block;
  }
`;

export const StyleSearch = styled.div`
  position: relative;
  img {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  input {
    padding: 0.75rem 3rem 0.75rem 0.75rem;
    border-color: ${props => props.theme?.general?.generalBorderColor};
    border-radius: 5px;
    &::placeholder {
      color: #c3c3c3;
      font-weight: 700;
    }
  }
  input.admin-search {
    padding: 0.438rem 3rem 0.438rem 0.75rem;
  }
`;
