import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button, Image } from 'react-bootstrap';

import SortIcon from '../../../assets/images/sort-icon.svg';

function SingleTableHeader({
  title,
  setCurrentSortedTitle,
  handleFetchSortedData,
  currentSortedTitle,
  key,
  enableSorting,
}) {
  return (
    <th key={key}>
      {title.label}
      {enableSorting && (
        <Button
          key={key}
          variant=""
          className={currentSortedTitle === title.db_key ? 'sort-btn active' : 'sort-btn'}
          onClick={() => {
            setCurrentSortedTitle(title.db_key);
            handleFetchSortedData(title);
          }}
        >
          <Image key={key} src={SortIcon} />
        </Button>
      )}
    </th>
  );
}

SingleTableHeader.propTypes = {
  title: PropTypes.shape({
    key: PropTypes.number,
    db_key: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    label: PropTypes.string,
  }).isRequired,
  enableSorting: PropTypes.bool,
};

SingleTableHeader.defaultProps = {
  enableSorting: true,
};

export default SingleTableHeader;
