import React from 'react';
import { Form } from 'react-bootstrap';
import common from '../../../helpers/common';
import StyleForm from '../input/Form.styled';

export const PaginationDropDownV2 = ({ handleRecordsPerPage }) => {
  return (
    <StyleForm>
      <Form.Select onChange={e => handleRecordsPerPage(e.target.value)}>
        {common.PAGINATION_DROPDOWN_VALUES.map((item, i) => (
          <option value={item.value} key={item.key}>
            {item.value}
          </option>
        ))}
      </Form.Select>
    </StyleForm>
  );
};
