/* eslint-disable no-console */

import { get, post, put } from '../libs/api/apiConnector';
import apiRoutes from '../helpers/apiRoutes';
import replaceParameters from '../helpers/parameterHelper';

export const getAllHistoryEntries = async params => {
  try {
    const { data } = await get(apiRoutes.DATA_VALIDATION.GET_ALL_HISTORY_ENTRIES, { params });
    return data;
  } catch (error) {
    console.error(`get-all-history-entries-api -> ${error}`);
    return error;
  }
};

export const getHistoryEntryDetails = async (params, uploadId, executionId) => {
  const url = replaceParameters(apiRoutes.DATA_VALIDATION.GET_HISTORY_ENTRY_DETAILS, {
    uploadId,
    executionId,
  });

  try {
    const { data } = await get(url, { params });
    return data;
  } catch (error) {
    console.error(`get-history-entry-details-api -> ${error}`);
    return error;
  }
};

export const updateHistoryEntryDetails = async (payload, uploadId, executionId) => {
  const url = replaceParameters(apiRoutes.DATA_VALIDATION.UPDATE_HISTORY_ENTRY_DETAILS, {
    uploadId,
    executionId,
  });

  try {
    const { data } = await put(url, payload);
    return data;
  } catch (error) {
    console.error(`update-history-entry-details-api -> ${error}`);
    return error;
  }
};

export const approveExecution = async (uploadId, executionId) => {
  const url = replaceParameters(apiRoutes.DATA_VALIDATION.APPROVE_EXECUTION, {
    uploadId,
    executionId,
  });

  try {
    const { data } = await put(url);
    return data;
  } catch (error) {
    console.error(`approve-execution-api -> ${error}`);
    return error;
  }
};

export const rejectExecution = async (uploadId, executionId) => {
  const url = replaceParameters(apiRoutes.DATA_VALIDATION.REJECT_EXECUTION, {
    uploadId,
    executionId,
  });

  try {
    const { data } = await put(url);
    return data;
  } catch (error) {
    console.error(`reject-execution-api -> ${error}`);
    return error;
  }
};

export const downloadExecution = async payload => {
  try {
    const { data } = await post(apiRoutes.DATA_VALIDATION.SIGN_URL, payload);
    return data;
  } catch (error) {
    console.error(`download-execution-api -> ${error}`);
    return error;
  }
};

export const getEntryCounts = async () => {
  try {
    const { data } = await get(apiRoutes.DATA_VALIDATION.GET_ENTRY_COUNTS);
    return data;
  } catch (error) {
    console.error(`get-entry-counts-api -> ${error}`);
    return error;
  }
};
