import PropTypes from 'prop-types';

import { useAuth } from '../AuthContext';

function Authenticated(props) {
  /**
   * Children components will be rendered only for backend authorized user
   */

  const { children } = props;

  const auth = useAuth();
  if (auth.isBackendAuthorized) {
    return children;
  }

  return null;
}

Authenticated.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Authenticated;
