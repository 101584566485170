import React, { useContext } from 'react';
import { Row } from 'react-bootstrap';

import CommonModal from '../../common/modal/CommonModal';
import DetailsView from '../detailsView/DetailsView';

import SearchLicensesHelper from '../../../helpers/features/SearchLicensesHelper';
import { SearchLicencesContext } from '../../../containers/searchLicenses/SearchLicensesContext';

function DetailsModal() {
  const { handleDetailsModalClose } = useContext(SearchLicencesContext);

  return (
    <CommonModal
      className=""
      size="xl"
      title={SearchLicensesHelper.LICENSE_DETAILS_MODAL_TITLE}
      handleClose={() => handleDetailsModalClose()}
    >
      <Row>
        <DetailsView />
      </Row>
    </CommonModal>
  );
}

export default DetailsModal;
