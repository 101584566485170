import React from 'react';
import PropTypes from 'prop-types';
import { Card, Image } from 'react-bootstrap';

function FeaturedCard(props) {
  const { title, text, src, recordsCount, textClassName } = props;
  return (
    <Card>
      <Card.Body>
        <Image src={src} />
        <Card.Title>{title}</Card.Title>
        <Card.Text className={textClassName}>{text}</Card.Text>
        <h1>{recordsCount ? recordsCount.toLocaleString() : ''}</h1>
      </Card.Body>
    </Card>
  );
}

FeaturedCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  recordsCount: PropTypes.number.isRequired,
};

export default FeaturedCard;
