export default Object.freeze({
  LICENSES_HEADER: 'Add New License Records',
  LICENSES_SUBHEADER: 'Complete these two steps to add a license',
  ADD_NEW_LICENSES_FORM_LICESEN_NUMBER: 'License Number',
  ADD_NEW_LICENSES_FORM_LICESEN_ISSUER: 'License Issuer',
  ADD_NEW_LICENSES_FORM_LICESEN_RECORDS: 'License Records',
  ADD_NEW_LICENSES_FORM_LICESEN_DESIGNATION: 'License Designation',
  ADD_NEW_LICENSES_FORM_LICESEN_TYPE: 'License Type',
  ADD_NEW_LICENSES_FORM_LICESEN_DETAIL: 'License Details',
  ADD_NEW_LICENSES_FORM_LICESEN_STATUS: 'License Status',
  ADD_NEW_LICENSES_FORM_LICESEN_ISSUE_DATE: 'License Issue Date',
  ADD_NEW_LICENSES_FORM_LICESEN_EXPIRATION_DATE: 'License Expiration Date',
  ADD_NEW_LICENSES_FORM_LICENSE_LAST_UPDATED_DATE: 'Last Updated',
  ADD_NEW_LICENSES_FORM_LICESEN_STATE: 'State',
  ADD_NEW_LICENSES_FORM_LICESEN_COUNTRY_CODE: 'Country Code',
  ADD_NEW_LICENSES_FORM_LICESEN_STATE_NAME: 'State Name',
  ADD_NEW_LICENSES_FORM_LICESEN_CITY: 'City',
  ADD_NEW_LICENSES_FORM_LICESEN_STREET: 'Street',
  ADD_NEW_LICENSES_FORM_LICESEN_ZIP: 'ZIP',
  ADD_NEW_LICENSES_FORM_LICESEN_COUNTRY: 'Country',
  ADD_NEW_LICENSES_FORM_LICESEN_NUMBER_PLACEHOLDER: 'Type License Number',
  ADD_NEW_LICENSES_FORM_LICESEN_ISSUER_PLACEHOLDER: 'Type License Issuer',
  ADD_NEW_LICENSES_FORM_LICESEN_RECORDS_PLACEHOLDER: 'Type License Records',
  ADD_NEW_LICENSES_FORM_LICESEN_DESIGNATION_PLACEHOLDER: 'Type License Designation',
  ADD_NEW_LICENSES_FORM_LICESEN_TYPE_PLACEHOLDER: 'Type License Type',
  ADD_NEW_LICENSES_FORM_LICESEN_DETAIL_PLACEHOLDER: 'Type License Detail',
  ADD_NEW_LICENSES_FORM_LICESEN_STATUS_PLACEHOLDER: 'Type License Status',
  ADD_NEW_LICENSES_FORM_LICESEN_ISSUE_DATE_PLACEHOLDER: 'MM/DD/YYYY',
  ADD_NEW_LICENSES_FORM_LICESEN_EXPIRATION_DATE_PLACEHOLDER: 'MM/DD/YYYY',
  ADD_NEW_LICENSES_FORM_LICENSE_LAST_UPDATED_DATE_PLACEHOLDER: 'MM/DD/YYYY',
  ADD_NEW_LICENSES_FORM_LICESEN_STATE_PLACEHOLDER: 'Type State Name',
  ADD_NEW_LICENSES_FORM_LICESEN_STATE_ABBREVIATION_PLACEHOLDER: 'Type State Abbreviation',
  ADD_NEW_LICENSES_FORM_LICESEN_CITY_PLACEHOLDER: 'Type City Name',
  ADD_NEW_LICENSES_FORM_LICESEN_STREET_PLACEHOLDER: 'Street',
  ADD_NEW_LICENSES_FORM_LICESEN_ZIP_PLACEHOLDER: 'Type ZIP Code',
  ADD_NEW_LICENSES_FORM_LICESEN_COUNTRY_PLACEHOLDER: 'Select Country',
  ADD_NEW_LICENSES_FORM_LICESEN_BUTTON_TEXT: 'Next',
  ADD_NEW_LICENSES_FORM_COMPANY_PARENT_NAME: 'Parent Name',
  ADD_NEW_LICENSES_FORM_COMPANY_LEGAL_NAME: 'Legal Name',
  ADD_NEW_LICENSES_FORM_COMPANY_DBA_NAME: 'DBA Name',
  ADD_NEW_LICENSES_FORM_COMPANY_WEBSITE: 'Company Website',
  ADD_NEW_LICENSES_FORM_COMPANY_CONTACT_NAME: 'Company Contact Name',
  ADD_NEW_LICENSES_FORM_COMPANY_EMAIL: 'Company Email',
  ADD_NEW_LICENSES_FORM_COMPANY_PHONE: 'Company Phone',
  ADD_NEW_LICENSES_FORM_COMPANY_COUNTY: 'County',
  ADD_NEW_LICENSES_FORM_COMPANY_NOTES: 'Notes',
  ADD_NEW_LICENSES_FORM_COMPANY_PARENT_NAME_PLACEHOLDER: 'Type Parent Name',
  ADD_NEW_LICENSES_FORM_COMPANY_LEGAL_NAME_PLACEHOLDER: 'Type Legal Name',
  ADD_NEW_LICENSES_FORM_COMPANY_DBA_NAME_PLACEHOLDER: 'Type DBA Name',
  ADD_NEW_LICENSES_FORM_COMPANY_WEBSITE_PLACEHOLDER: 'Type Company Website',
  ADD_NEW_LICENSES_FORM_COMPANY_CONTACT_NAME_PLACEHOLDER: 'Type Company Contact Name',
  ADD_NEW_LICENSES_FORM_COMPANY_EMAIL_PLACEHOLDER: 'Type Company Email',
  ADD_NEW_LICENSES_FORM_COMPANY_PHONE_PLACEHOLDER: 'Type Company Phone',
  ADD_NEW_LICENSES_FORM_COMPANY_COUNTY_NAME_PLACEHOLDER: 'Type County Name',
  ADD_NEW_LICENSES_FORM_COMPANY_NOTES_PLACEHOLDER: 'Type Notes',
  ADD_NEW_LICENSES_FORM_LICESEN_SUBMIT_BUTTON_TEXT: 'Add New License',
  EDIT_LICENSES_FORM_LICESEN_SUBMIT_BUTTON_TEXT: 'Make Changes',
  ADD_NEW_LICENSES_FORM_LICESEN_IMPORT_TEXT: 'Import Licenses',
  ADD_NEW_LICENSES_FORM_LICESEN_BACK_BUTTON_TEXT: 'Back',
  ADD_NEW_LICENSES_FORM_LICESEN_CANCEL: 'Cancel',
  ADD_NEW_LICENSES_FORM_LICESEN_UPLOAD: 'Upload',
  PREVIEW_CSV_MODAL_IMPORT_BTN: 'Import',
  UPDATED_CSV_HEADERS: [
    'license_number',
    'legal_name',
    'dba_name',
    'street',
    'city',
    'state',
    'zip',
    'country_code',
    'country_name',
    'license_issuer',
    'license_designation',
    'license_type',
    'license_issue_date',
    'license_expiration_date',
    'county_name',
    'parent_name',
    'company_website',
    'company_contact_name',
    'company_email',
    'company_phone',
    'note',
    'license_details',
    'state_name',
    'action',
  ],
  UPDATED_OPTIONAL_CSV_HEADERS: ['status', 'source_name'],
  COUNTRY_CODES: [{ label: 'US', value: 'US', key: 1 }],
  AVAILABLE_COUNTRIES: [
    { label: 'United States of America', value: 'United States of America', key: 1 },
  ],
  WHITELISTED_MANDOTORY_FEILDS: [
    'legal_name',
    'license_issuer',
    'license_type',
    'city',
    'state',
    'county_name',
  ],
  CSV_VISUAL_HEADER_NAMES: [
    'License number',
    'Legal name',
    'DBA',
    'Street',
    'City',
    'State',
    'ZIP',
    'State Name',
    'Country Code',
    'Country Name',
    'License issuer',
    'License Designation',
    'License Type',
    'License Details',
    'License Issue Date',
    'License Expiration Date',
    'County Name',
    'Parent Name',
    'Company Website',
    'Company Contact Name',
    'Company Email',
    'Company Phone',
    'Notes',
    'Source Name',
    'Action',
  ],
  CSV_OPTIONAL_VISUAL_HEADER_NAMES: ['License Status'],
});
