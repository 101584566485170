import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import StyleForm from './Form.styled';

function AppInput(props) {
  const {
    label,
    requied,
    type,
    placeholder,
    errorText,
    helpText,
    linkUrl,
    helpTextLink,
    name,
    value,
    onChangeInput,
    onBlurInput,
    disabled,
    errorSet,
    limit,
  } = props;

  if (errorText) {
    errorSet(true);
  } else {
    errorSet(false);
  }
  return (
    <StyleForm>
      <Form.Group className="form-group" controlId="">
        <Form.Label>
          {label}
          <span className="red"> {requied}</span>
        </Form.Label>
        <Form.Control
          type={type}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChangeInput}
          onBlur={onBlurInput}
          disabled={disabled}
          maxLength={limit}
        />
        <Form.Text className="error-text">{errorText}</Form.Text>
        <Form.Text className="text-muted">
          {helpText} <a href={linkUrl}>{helpTextLink}</a>
        </Form.Text>
      </Form.Group>
    </StyleForm>
  );
}

AppInput.propTypes = {
  label: PropTypes.string.isRequired,
  requied: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  helpText: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  helpTextLink: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  onBlurInput: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  errorSet: PropTypes.func,
  limit: PropTypes.string,
};

AppInput.defaultProps = {
  errorText: null,
  disabled: false,
  errorSet: () => {},
  limit: '',
};

export default AppInput;
