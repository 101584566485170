import React, { useState } from 'react';
import { Container, Row, Col, Navbar, Tab, Nav } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import SweetAlert from 'react-bootstrap-sweetalert';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import * as yup from 'yup';

import StyleCustomTab from './CustomTab.styled';
import { StylePage } from '../styles/Page.styled';
import SettingsHelper from '../../helpers/features/SettingsHelper';

import PageHeader from '../common/pageHeader/PageHeader';
import TextEditor from '../common/tinymceTextEditor/TextEditor';
import { useSettingsContext } from '../../containers/settings/SettingsContext';

import StyleForm from '../common/input/Form.styled';
import AppButton from '../styles/ButtonLink.styled';
import ThemeSelect from '../common/input/ThemeSelect';
import AppInput from '../common/input/AppInput';
import ToolTip from '../common/toolTips/ToolTip';
import ColorPicker from '../common/colorPicker/ColorPicker';
import DragAndDropFileUpload from '../common/dragAndDropFileUpload/DragAndDropFileUpload';
import InfoIcon from '../../assets/images/info.svg';

export default function SettingsPageMain() {
  const settigsContext = useSettingsContext();
  const { sessionTime, loadingSessionTime, updatingSessionTime, handleUpdateSessionTime } =
    settigsContext;

  const [isOpenDiscardWarning, setIsOpenDiscardWarning] = useState(false);
  const [isOpenDiscardWarningWithNoChnages, setIsOpenDiscardWarningWithNoChnages] = useState(false);
  const [isOpenResetWarning, setIsOpenResetWarning] = useState(false);
  const [isSavingWithErrorsWarningOpen, setisSavingWithErrorsWarningOpen] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState('first');

  console.log(settigsContext.errors);

  return (
    <Container>
      <StylePage>
        <Row>
          <Col md={6}>
            <PageHeader title="Settings" subheader="" />
          </Col>
          {!isEqual(activeTabKey, 'seventh') && (
            <Col md={6} className="d-flex justify-content-end">
              <AppButton
                className="me-2 secondary-btn"
                fill="true"
                onClick={() => {
                  setIsOpenResetWarning(true);
                }}
              >
                {SettingsHelper.RESET_BUTTON_TEXT}
              </AppButton>
              <AppButton
                className="me-2 cancel"
                fill="true"
                onClick={() => {
                  if (isEmpty(settigsContext.themeObject)) {
                    setIsOpenDiscardWarningWithNoChnages(true);
                  } else {
                    setIsOpenDiscardWarning(true);
                  }
                }}
              >
                {SettingsHelper.DISCARD_BUTTON_TEXT}
              </AppButton>
              <AppButton
                className="me-2"
                fill="true"
                onClick={() => {
                  if (settigsContext.errors) {
                    setisSavingWithErrorsWarningOpen(true);
                  } else {
                    settigsContext.updateThemeData();
                  }
                }}
              >
                {SettingsHelper.SAVE_BUTTON_TEXT}
              </AppButton>
            </Col>
          )}
        </Row>
        <StyleCustomTab>
          <Tab.Container id="" defaultActiveKey="first" onSelect={key => setActiveTabKey(key)}>
            <Row>
              <Col md={3}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="first">
                      {SettingsHelper.NAVIGATION_PANE.SECTIONS.GENERAL}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">
                      {SettingsHelper.NAVIGATION_PANE.SECTIONS.NAVIGATION}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">
                      {SettingsHelper.NAVIGATION_PANE.SECTIONS.HEADER}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="fourth">
                      {SettingsHelper.NAVIGATION_PANE.SECTIONS.QNA}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="fifth">
                      {SettingsHelper.NAVIGATION_PANE.SECTIONS.TABLE}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="sixth">
                      {SettingsHelper.NAVIGATION_PANE.SECTIONS.FOOTER}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="seventh">
                      {SettingsHelper.NAVIGATION_PANE.SECTIONS.SESSION_TIME}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col md={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <h6 className="mb-3">{SettingsHelper.PAGE_HEADERS.SECTIONS.GENERAL.GENERAL}</h6>
                    <DragAndDropFileUpload
                      icon={InfoIcon}
                      label="Logo"
                      className="mb-4"
                      toolTipContent={<span>Content Goes Here!</span>}
                    />
                    <h6>General Colors</h6>
                    <div className="general-color-box mb-4">
                      <p>
                        If you want to use Simplifya company colors, the following colors are
                        arranged with their corresponding codes so that you can easily enter the
                        correct color.
                      </p>
                      <div className="color-row d-flex justify-content-start gap-4 mb-4">
                        <div className="color-box d-flex">
                          <div className="left primary-color" />
                          <div className="right">
                            <h6>Primary Color </h6>
                            <small>
                              <b className="me-2">RGB:</b>
                              <b className="ms-1">R</b>93
                              <b className="ms-1">G</b>173
                              <b className="ms-1">B</b>174
                            </small>
                            <small>
                              <b className="me-2">Hex:</b>
                              <span className="ms-1">#5DADAE</span>
                            </small>
                          </div>
                        </div>
                        <div className="color-box d-flex">
                          <div className="left secondary-color" />
                          <div className="right">
                            <h6>Secondary Color </h6>
                            <small>
                              <b className="me-2">RGB:</b>
                              <b className="ms-1">R</b>252
                              <b className="ms-1">G</b>193
                              <b className="ms-1">B</b>38
                            </small>
                            <small>
                              <b className="me-2">Hex:</b>
                              <span className="ms-1">#FDC126</span>
                            </small>
                          </div>
                        </div>
                        <div className="color-box d-flex">
                          <div className="left links-color" />
                          <div className="right">
                            <h6>Links Color </h6>
                            <small>
                              <b className="me-2">RGB:</b>
                              <b className="ms-1">R</b>45
                              <b className="ms-1">G</b>142
                              <b className="ms-1">B</b>255
                            </small>
                            <small>
                              <b className="me-2">Hex:</b>
                              <span className="ms-1">#2D8EFF</span>
                            </small>
                          </div>
                        </div>
                        <div className="color-box d-flex">
                          <div className="left background-color" />
                          <div className="right">
                            <h6>Background Color </h6>
                            <small>
                              <b className="me-2">RGB:</b>
                              <b className="ms-1">R</b>255
                              <b className="ms-1">G</b>255
                              <b className="ms-1">B</b>255
                            </small>
                            <small>
                              <b className="me-2">Hex:</b>
                              <span className="ms-1">#FFFFFF</span>
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="color-row d-flex justify-content-start gap-4 ">
                        <div className="color-box d-flex">
                          <div className="left background-color" />
                          <div className="right">
                            <h6>Footer Background Color </h6>
                            <small>
                              <b className="me-2">RGB:</b>
                              <b className="ms-1">R</b>255
                              <b className="ms-1">G</b>255
                              <b className="ms-1">B</b>255
                            </small>
                            <small>
                              <b className="me-2">Hex:</b>
                              <span className="ms-1">#FFFFFF</span>
                            </small>
                          </div>
                        </div>
                        <div className="color-box d-flex">
                          <div className="left general-text-color" />
                          <div className="right">
                            <h6>General Text Color </h6>
                            <small>
                              <b className="me-2">RGB:</b>
                              <b className="ms-1">R</b>0<b className="ms-1">G</b>0
                              <b className="ms-1">B</b>0
                            </small>
                            <small>
                              <b className="me-2">Hex:</b>
                              <span className="ms-1">#000000</span>
                            </small>
                          </div>
                        </div>
                        <div className="color-box d-flex">
                          <div className="left general-border-color" />
                          <div className="right">
                            <h6>General Border Color</h6>
                            <small>
                              <b className="me-2">RGB:</b>
                              <b className="ms-1">R</b>220
                              <b className="ms-1">G</b>226
                              <b className="ms-1">B</b>233
                            </small>
                            <small>
                              <b className="me-2">Hex:</b>
                              <span className="ms-1">#dce2e9</span>
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Row>
                      <Col md={4} className="mb-3">
                        <ColorPicker
                          defaultColor={settigsContext?.theme?.general?.primaryColor}
                          icon={InfoIcon}
                          colorProperty={SettingsHelper.SUB_SECTIONS.GENERAL.PRIMARY}
                          mainSection={SettingsHelper.SECTIONS.GENERAL}
                          handleColorPickerChnage={settigsContext.handleThemeProperties}
                          toolTipContent={<span>Content Goes Here!</span>}
                          label="Set Primary Color"
                        />
                      </Col>
                      <Col md={4} className="mb-3">
                        <ColorPicker
                          defaultColor={settigsContext?.theme?.general?.secondaryColor}
                          colorProperty={SettingsHelper.SUB_SECTIONS.GENERAL.SECONDARY}
                          icon={InfoIcon}
                          mainSection={SettingsHelper.SECTIONS.GENERAL}
                          handleColorPickerChnage={settigsContext.handleThemeProperties}
                          toolTipContent={<span>Content Goes Here!</span>}
                          label="Set Secondary Color"
                        />
                      </Col>
                      <Col md={4} className="mb-3">
                        <ColorPicker
                          defaultColor={settigsContext?.theme?.general?.backgroundColor}
                          colorProperty={SettingsHelper.SUB_SECTIONS.GENERAL.BACKGROUND}
                          icon={InfoIcon}
                          mainSection={SettingsHelper.SECTIONS.GENERAL}
                          handleColorPickerChnage={settigsContext.handleThemeProperties}
                          toolTipContent={<span>Content Goes Here!</span>}
                          label="Background Color"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4} className="mb-3">
                        <ColorPicker
                          defaultColor={settigsContext?.theme?.general?.generalFontColor}
                          colorProperty={SettingsHelper.SUB_SECTIONS.GENERAL.FONT_COLOR}
                          icon={InfoIcon}
                          mainSection={SettingsHelper.SECTIONS.GENERAL}
                          handleColorPickerChnage={settigsContext.handleThemeProperties}
                          toolTipContent={<span>Content Goes Here!</span>}
                          label="General Text Color"
                        />
                      </Col>
                      <Col md={4} className="mb-3">
                        <ColorPicker
                          defaultColor={settigsContext?.theme?.general?.generalBackground}
                          colorProperty={SettingsHelper.SUB_SECTIONS.GENERAL.BORDER}
                          mainSection={SettingsHelper.SECTIONS.GENERAL}
                          handleColorPickerChnage={settigsContext.handleThemeProperties}
                          toolTipContent={<span>Content Goes Here!</span>}
                          icon={InfoIcon}
                          label="General Border Color"
                        />
                      </Col>
                      <Col md={4} className="mb-3">
                        <ColorPicker
                          defaultColor={settigsContext?.theme?.general?.generalLinkColor}
                          colorProperty={SettingsHelper.SUB_SECTIONS.GENERAL.LINK_COLOR}
                          icon={InfoIcon}
                          mainSection={SettingsHelper.SECTIONS.GENERAL}
                          handleColorPickerChnage={settigsContext.handleThemeProperties}
                          toolTipContent={<span>Content Goes Here!</span>}
                          label="Link Color"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <ColorPicker
                          defaultColor={settigsContext?.theme?.footer?.footerBackground}
                          label="Footer Background Color"
                          colorProperty={SettingsHelper.SUB_SECTIONS.FOOTER.BACKGROUND}
                          mainSection={SettingsHelper.SECTIONS.FOOTER}
                          handleColorPickerChnage={settigsContext.handleThemeProperties}
                          toolTipContent={<span>Content Goes Here!</span>}
                          icon={InfoIcon}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <ThemeSelect
                          icon={InfoIcon}
                          label="Font Family"
                          placeholder="Select Font Family"
                          onChangeInput={select => {
                            settigsContext.handleThemeProperties(
                              SettingsHelper.SECTIONS.GENERAL,
                              SettingsHelper.SUB_SECTIONS.GENERAL.FONT_FAMILY,
                              select.value,
                            );
                          }}
                          value={SettingsHelper.DROPDOWN_VALUE_LIST.GENERAL.FONTS.find(
                            obj => obj.value === settigsContext?.theme?.general?.generalFontFamily,
                          )}
                          options={SettingsHelper.DROPDOWN_VALUE_LIST.GENERAL.FONTS}
                          isSettingsDropdown
                          toolTipContent={<span>Content Goes Here!</span>}
                        />
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <h6 className="mb-3">{SettingsHelper.PAGE_HEADERS.SECTIONS.NAVIGATION}</h6>
                    <Row>
                      <Col md="4">
                        <ColorPicker
                          defaultColor={settigsContext?.theme?.navigation?.backgroundColor}
                          colorProperty={SettingsHelper.SUB_SECTIONS.NAVIGATION.BACKGROUND}
                          mainSection={SettingsHelper.SECTIONS.NAVIGATION}
                          handleColorPickerChnage={settigsContext.handleThemeProperties}
                          toolTipContent={<span>Content Goes Here!</span>}
                          icon={InfoIcon}
                          label="Font Color"
                        />
                      </Col>
                      <Col md="4">
                        <ThemeSelect
                          label="Font Size"
                          placeholder="Select Font Size"
                          icon={InfoIcon}
                          onChangeInput={select => {
                            settigsContext.handleThemeProperties(
                              SettingsHelper.SECTIONS.NAVIGATION,
                              SettingsHelper.SUB_SECTIONS.NAVIGATION.FONT_SIZE,
                              select.value,
                            );
                          }}
                          value={SettingsHelper.DROPDOWN_VALUE_LIST.NAVIGATION.FONT_SIZES.find(
                            obj =>
                              obj.value === settigsContext?.theme?.navigation?.navigationFontSize,
                          )}
                          options={SettingsHelper.DROPDOWN_VALUE_LIST.NAVIGATION.FONT_SIZES}
                          isSettingsDropdown
                          toolTipContent={<span>Content Goes Here!</span>}
                        />
                      </Col>
                      <Col md="4">
                        <ThemeSelect
                          icon={InfoIcon}
                          label="Font Weight"
                          placeholder="Select Font Weight"
                          onChangeInput={select => {
                            settigsContext.handleThemeProperties(
                              SettingsHelper.SECTIONS.NAVIGATION,
                              SettingsHelper.SUB_SECTIONS.NAVIGATION.FONT_WEIGHT,
                              select.value,
                            );
                          }}
                          value={SettingsHelper.DROPDOWN_VALUE_LIST.NAVIGATION.FONT_WEIGHTS.find(
                            obj =>
                              obj.value === settigsContext?.theme?.navigation?.navigationFontWeight,
                          )}
                          options={SettingsHelper.DROPDOWN_VALUE_LIST.NAVIGATION.FONT_WEIGHTS}
                          isSettingsDropdown
                          toolTipContent={<span>Content Goes Here!</span>}
                        />
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <h6 className="mb-3">{SettingsHelper.PAGE_HEADERS.SECTIONS.HEADER}</h6>
                    <Row>
                      <Col md="4">
                        <ColorPicker
                          defaultColor={settigsContext?.theme?.header?.headerFontColor}
                          label="Font Color"
                          colorProperty={SettingsHelper.SUB_SECTIONS.HEADER.FONT_COLOR}
                          mainSection={SettingsHelper.SECTIONS.HEADER}
                          handleColorPickerChnage={settigsContext.handleThemeProperties}
                          toolTipContent={<span>Content Goes Here!</span>}
                          icon={InfoIcon}
                        />
                      </Col>
                      <Col md="4">
                        <ThemeSelect
                          label="Font Size"
                          placeholder="Select Font Size"
                          icon={InfoIcon}
                          onChangeInput={select => {
                            settigsContext.handleThemeProperties(
                              SettingsHelper.SECTIONS.HEADER,
                              SettingsHelper.SUB_SECTIONS.HEADER.FONT_SIZE,
                              select.value,
                            );
                          }}
                          value={SettingsHelper.DROPDOWN_VALUE_LIST.PAGE_HEADER.FONT_SIZES.find(
                            obj => obj.value === settigsContext?.theme?.header?.headerFontSize,
                          )}
                          options={SettingsHelper.DROPDOWN_VALUE_LIST.PAGE_HEADER.FONT_SIZES}
                          isSettingsDropdown
                          toolTipContent={<span>Content Goes Here!</span>}
                        />
                      </Col>
                      <Col md="4">
                        <ThemeSelect
                          label="Font Weight"
                          placeholder="Select Font Weight"
                          icon={InfoIcon}
                          onChangeInput={select => {
                            settigsContext.handleThemeProperties(
                              SettingsHelper.SECTIONS.HEADER,
                              SettingsHelper.SUB_SECTIONS.HEADER.FONT_WEIGHT,
                              select.value,
                            );
                          }}
                          value={SettingsHelper.DROPDOWN_VALUE_LIST.PAGE_HEADER.FONT_WEIGHTS.find(
                            obj => obj.value === settigsContext?.theme?.header?.headerFontWeight,
                          )}
                          options={SettingsHelper.DROPDOWN_VALUE_LIST.PAGE_HEADER.FONT_WEIGHTS}
                          isSettingsDropdown
                          toolTipContent={<span>Content Goes Here!</span>}
                        />
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="fourth">
                    <h6 className="mb-3">{SettingsHelper.PAGE_HEADERS.SECTIONS.QNA.QUIZ}s</h6>
                    <Row>
                      <Col md="4">
                        <ColorPicker
                          defaultColor={settigsContext?.theme?.qna?.quizFontColor}
                          label="Font Color"
                          colorProperty={SettingsHelper.SUB_SECTIONS.QNA.QUIZ_FONT_COLOR}
                          mainSection={SettingsHelper.SECTIONS.QNA}
                          handleColorPickerChnage={settigsContext.handleThemeProperties}
                          toolTipContent={<span>Content Goes Here!</span>}
                          icon={InfoIcon}
                        />
                      </Col>
                      <Col md="4">
                        <ThemeSelect
                          label="Font Size"
                          placeholder="Select Font Size"
                          icon={InfoIcon}
                          onChangeInput={select => {
                            settigsContext.handleThemeProperties(
                              SettingsHelper.SECTIONS.QNA,
                              SettingsHelper.SUB_SECTIONS.QNA.QUIZ_FONT_SIZE,
                              select.value,
                            );
                          }}
                          value={SettingsHelper.DROPDOWN_VALUE_LIST.QNA.FONT_SIZES.find(
                            obj => obj.value === settigsContext?.theme?.qna?.quizFontSize,
                          )}
                          options={SettingsHelper.DROPDOWN_VALUE_LIST.QNA.FONT_SIZES}
                          isSettingsDropdown
                          toolTipContent={<span>Content Goes Here!</span>}
                        />
                      </Col>
                      <Col md="4">
                        <ThemeSelect
                          label="Font Weight"
                          placeholder="Select Font Weight"
                          icon={InfoIcon}
                          onChangeInput={select => {
                            settigsContext.handleThemeProperties(
                              SettingsHelper.SECTIONS.QNA,
                              SettingsHelper.SUB_SECTIONS.QNA.QUIZ_FONT_WEIGHT,
                              select.value,
                            );
                          }}
                          value={SettingsHelper.DROPDOWN_VALUE_LIST.QNA.FONT_WEIGHTS.find(
                            obj => obj.value === settigsContext?.theme?.qna?.quizFontWeight,
                          )}
                          options={SettingsHelper.DROPDOWN_VALUE_LIST.QNA.FONT_WEIGHTS}
                          isSettingsDropdown
                          toolTipContent={<span>Content Goes Here!</span>}
                        />
                      </Col>
                    </Row>
                    <h6 className="mt-4 mb-3">{SettingsHelper.PAGE_HEADERS.SECTIONS.QNA.ANS}</h6>
                    <Row>
                      <Col md="4">
                        <ColorPicker
                          defaultColor={settigsContext?.theme?.qna?.ansFontColor}
                          label="Font Color"
                          colorProperty={SettingsHelper.SUB_SECTIONS.QNA.ANS_FONT_COLOR}
                          mainSection={SettingsHelper.SECTIONS.QNA}
                          handleColorPickerChnage={settigsContext.handleThemeProperties}
                          toolTipContent={<span>Content Goes Here!</span>}
                          icon={InfoIcon}
                        />
                      </Col>
                      <Col md="4">
                        <ThemeSelect
                          label="Font Size"
                          placeholder="Select Font Size"
                          icon={InfoIcon}
                          onChangeInput={select => {
                            settigsContext.handleThemeProperties(
                              SettingsHelper.SECTIONS.QNA,
                              SettingsHelper.SUB_SECTIONS.QNA.ANS_FONT_SIZE,
                              select.value,
                            );
                          }}
                          value={SettingsHelper.DROPDOWN_VALUE_LIST.QNA.FONT_SIZES.find(
                            obj => obj.value === settigsContext?.theme?.qna?.ansFontSize,
                          )}
                          options={SettingsHelper.DROPDOWN_VALUE_LIST.QNA.FONT_SIZES}
                          isSettingsDropdown
                          toolTipContent={<span>Content Goes Here!</span>}
                        />
                      </Col>
                      <Col md="4">
                        <ThemeSelect
                          label="Font Weight"
                          placeholder="Select Font Weight"
                          icon={InfoIcon}
                          onChangeInput={select => {
                            settigsContext.handleThemeProperties(
                              SettingsHelper.SECTIONS.QNA,
                              SettingsHelper.SUB_SECTIONS.QNA.ANS_FONT_WEIGHT,
                              select.value,
                            );
                          }}
                          value={SettingsHelper.DROPDOWN_VALUE_LIST.QNA.FONT_WEIGHTS.find(
                            obj => obj.value === settigsContext?.theme?.qna?.ansFontWeight,
                          )}
                          options={SettingsHelper.DROPDOWN_VALUE_LIST.QNA.FONT_WEIGHTS}
                          isSettingsDropdown
                          toolTipContent={<span>Content Goes Here!</span>}
                        />
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="fifth">
                    <h6 className="mb-3">{SettingsHelper.PAGE_HEADERS.SECTIONS.TABLE.HEADER}</h6>
                    <Row>
                      <Col md="4">
                        <ColorPicker
                          defaultColor={settigsContext?.theme?.table?.tableHeaderFontColor}
                          label="Font Color"
                          colorProperty={SettingsHelper.SUB_SECTIONS.TABLE.TABLE_HEADER_FONT_COLOR}
                          mainSection={SettingsHelper.SECTIONS.TABLE}
                          handleColorPickerChnage={settigsContext.handleThemeProperties}
                          toolTipContent={<span>Content Goes Here!</span>}
                          icon={InfoIcon}
                        />
                      </Col>
                      <Col md="4">
                        <ThemeSelect
                          label="Font Size"
                          placeholder="Select Font Size"
                          icon={InfoIcon}
                          onChangeInput={select => {
                            settigsContext.handleThemeProperties(
                              SettingsHelper.SECTIONS.TABLE,
                              SettingsHelper.SUB_SECTIONS.TABLE.TABLE_HEADER_FONT_SIZE,
                              select.value,
                            );
                          }}
                          value={SettingsHelper.DROPDOWN_VALUE_LIST.TABLE.HEAD.FONT_SIZES.find(
                            obj => obj.value === settigsContext?.theme?.table?.tableHeaderFontSize,
                          )}
                          options={SettingsHelper.DROPDOWN_VALUE_LIST.TABLE.HEAD.FONT_SIZES}
                          isSettingsDropdown
                          toolTipContent={<span>Content Goes Here!</span>}
                        />
                      </Col>
                      <Col md="4">
                        <ThemeSelect
                          label="Font Weight"
                          placeholder="Select Font Weight"
                          icon={InfoIcon}
                          onChangeInput={select => {
                            settigsContext.handleThemeProperties(
                              SettingsHelper.SECTIONS.TABLE,
                              SettingsHelper.SUB_SECTIONS.TABLE.TABLE_HEADER_FONT_WEIGHT,
                              select.value,
                            );
                          }}
                          value={SettingsHelper.DROPDOWN_VALUE_LIST.TABLE.HEAD.FONT_WEIGHTS.find(
                            obj =>
                              obj.value === settigsContext?.theme?.table?.tableHeaderFontWeight,
                          )}
                          options={SettingsHelper.DROPDOWN_VALUE_LIST.TABLE.HEAD.FONT_WEIGHTS}
                          isSettingsDropdown
                          toolTipContent={<span>Content Goes Here!</span>}
                        />
                      </Col>
                    </Row>
                    <h6 className="mt-4 mb-3">
                      {SettingsHelper.PAGE_HEADERS.SECTIONS.TABLE.CONTENT}
                    </h6>
                    <Row>
                      <Col md="4">
                        <ColorPicker
                          defaultColor={settigsContext?.theme?.table?.tableContentFontColor}
                          label="Font Color"
                          colorProperty={SettingsHelper.SUB_SECTIONS.TABLE.TABLE_CONTENT_COLOR}
                          mainSection={SettingsHelper.SECTIONS.TABLE}
                          handleColorPickerChnage={settigsContext.handleThemeProperties}
                          toolTipContent={<span>Content Goes Here!</span>}
                          icon={InfoIcon}
                        />
                      </Col>
                      <Col md="4">
                        <ThemeSelect
                          label="Font Size"
                          placeholder="Select Font Size"
                          icon={InfoIcon}
                          onChangeInput={select => {
                            settigsContext.handleThemeProperties(
                              SettingsHelper.SECTIONS.TABLE,
                              SettingsHelper.SUB_SECTIONS.TABLE.TABLE_CONTENT_SIZE,
                              select.value,
                            );
                          }}
                          value={SettingsHelper.DROPDOWN_VALUE_LIST.TABLE.DESCIPTION.FONT_SIZES.find(
                            obj => obj.value === settigsContext?.theme?.table?.tableContentFontSize,
                          )}
                          options={SettingsHelper.DROPDOWN_VALUE_LIST.TABLE.DESCIPTION.FONT_SIZES}
                          isSettingsDropdown
                          toolTipContent={<span>Content Goes Here!</span>}
                        />
                      </Col>
                      <Col md="4">
                        <ThemeSelect
                          label="Font Weight"
                          placeholder="Select Font Weight"
                          icon={InfoIcon}
                          onChangeInput={select => {
                            settigsContext.handleThemeProperties(
                              SettingsHelper.SECTIONS.TABLE,
                              SettingsHelper.SUB_SECTIONS.TABLE.TABLE_CONTENT_WEIGHT,
                              select.value,
                            );
                          }}
                          value={SettingsHelper.DROPDOWN_VALUE_LIST.TABLE.DESCIPTION.FONT_WEIGHTS.find(
                            obj =>
                              obj.value === settigsContext?.theme?.table?.tableContentFontWeight,
                          )}
                          options={SettingsHelper.DROPDOWN_VALUE_LIST.TABLE.DESCIPTION.FONT_WEIGHTS}
                          isSettingsDropdown
                          toolTipContent={<span>Content Goes Here!</span>}
                        />
                      </Col>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey="sixth">
                    <h6 className=" mb-3">{SettingsHelper.PAGE_HEADERS.SECTIONS.FOOTER.STYLES}</h6>
                    <h6 className="mt-4 mb-3">
                      {SettingsHelper.PAGE_HEADERS.SECTIONS.FOOTER.ADDRESS}
                      <ToolTip icon={InfoIcon} toolTipContent={<span>Content Goes Here!</span>} />
                    </h6>
                    <TextEditor
                      handleEditorChange={value => {
                        settigsContext.handleThemeProperties(
                          SettingsHelper.SECTIONS.FOOTER,
                          SettingsHelper.SUB_SECTIONS.FOOTER.ADDRESS,
                          value,
                        );
                      }}
                      limit={2000}
                      initialValue={settigsContext.theme?.footer?.companyAddress}
                      section={SettingsHelper.PAGE_HEADERS.SECTIONS.FOOTER.ADDRESS}
                      handleErrors={settigsContext.setErrors}
                    />

                    <h6 className="mt-5 mb-3">
                      {SettingsHelper.PAGE_HEADERS.SECTIONS.FOOTER.EMAIL}
                      <ToolTip icon={InfoIcon} toolTipContent={<span>Content Goes Here!</span>} />
                    </h6>
                    <TextEditor
                      handleEditorChange={value => {
                        settigsContext.handleThemeProperties(
                          SettingsHelper.SECTIONS.FOOTER,
                          SettingsHelper.SUB_SECTIONS.FOOTER.EMAIL,
                          value,
                        );
                      }}
                      limit={2000}
                      initialValue={settigsContext.theme?.footer?.companyEmail}
                      section={SettingsHelper.PAGE_HEADERS.SECTIONS.FOOTER.EMAIL}
                      handleErrors={settigsContext.setErrors}
                    />

                    <h6 className="mt-5 mb-3">
                      {SettingsHelper.PAGE_HEADERS.SECTIONS.FOOTER.CONTACT}
                      <ToolTip icon={InfoIcon} toolTipContent={<span>Content Goes Here!</span>} />
                    </h6>
                    <TextEditor
                      handleEditorChange={value => {
                        settigsContext.handleThemeProperties(
                          SettingsHelper.SECTIONS.FOOTER,
                          SettingsHelper.SUB_SECTIONS.FOOTER.CONTACT,
                          value,
                        );
                      }}
                      limit={2000}
                      initialValue={settigsContext.theme?.footer?.companyContact}
                      section={SettingsHelper.PAGE_HEADERS.SECTIONS.FOOTER.CONTACT}
                      handleErrors={settigsContext.setErrors}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="seventh">
                    <h6 className="mt-4 mb-3">
                      {SettingsHelper.PAGE_HEADERS.SECTIONS.GENERAL.SESSION}
                    </h6>
                    <Row>
                      <Formik
                        enableReinitialize
                        initialValues={{
                          time: sessionTime,
                        }}
                        validationSchema={yup.object().shape({
                          time: yup
                            .number()
                            .required('Session Time is required')
                            .integer('Session time cannot contain decimal points')
                            .min(1, 'Session Time should be greater than zero'),
                        })}
                        onSubmit={handleUpdateSessionTime}
                      >
                        {({
                          values,
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          errors,
                          isValid,
                          dirty,
                        }) => (
                          <Form onSubmit={handleSubmit}>
                            <Col md="4">
                              <AppInput
                                name="time"
                                label="Session Time (Minutes)"
                                requied="*"
                                type="number"
                                placeholder="Type Session Time in Minutes"
                                onChangeInput={handleChange}
                                onBlurInput={handleBlur}
                                value={values.time}
                                errorText={errors.time ? errors.time : ''}
                                disabled={loadingSessionTime || updatingSessionTime}
                              />
                              <AppButton
                                disabled={
                                  !(isValid && dirty) || updatingSessionTime || loadingSessionTime
                                }
                                type="submit"
                              >
                                Save
                              </AppButton>
                            </Col>
                          </Form>
                        )}
                      </Formik>
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
            {isOpenDiscardWarning && (
              <SweetAlert
                warning
                showCancel
                confirmBtnText={
                  SettingsHelper.ALERTS.DISCARD_WARNING.DISCARD_WARNING_ALERT_TOST_SUCCESS_BUTTON
                }
                confirmBtnCssClass="popup confirm"
                cancelBtnText={
                  SettingsHelper.ALERTS.DISCARD_WARNING.DISCARD_WARNING_ALERT_TOST_CANCEL_BUTTON
                }
                cancelBtnCssClass="popup cancel"
                confirmBtnBsStyle="success"
                show={isOpenDiscardWarning}
                title={SettingsHelper.ALERTS.DISCARD_WARNING.TITLE}
                onConfirm={() => {
                  settigsContext.handleDiscardChanges();
                }}
                onCancel={() => setIsOpenDiscardWarning(false)}
              >
                {SettingsHelper.ALERTS.DISCARD_WARNING.MESSAGE}
              </SweetAlert>
            )}
            {isOpenDiscardWarningWithNoChnages && (
              <SweetAlert
                danger
                confirmBtnText={
                  SettingsHelper.ALERTS.NO_CHANGES_ALERT.NO_CHANGES_ALERT_TOST_SUCCESS_BUTTON
                }
                confirmBtnBsStyle="danger"
                title={SettingsHelper.ALERTS.NO_CHANGES_ALERT.TITLE}
                onConfirm={() => setIsOpenDiscardWarningWithNoChnages(false)}
              >
                {SettingsHelper.ALERTS.NO_CHANGES_ALERT.MESSAGE}
              </SweetAlert>
            )}
            {isSavingWithErrorsWarningOpen && (
              <SweetAlert
                danger
                confirmBtnText={
                  SettingsHelper.ALERTS.SAVE_WARNING_ALERT.SAVE_WARNING_ALERT_TOST_SUCCESS_BUTTON
                }
                confirmBtnBsStyle="danger"
                title={SettingsHelper.ALERTS.SAVE_WARNING_ALERT.TITLE}
                onConfirm={() => setisSavingWithErrorsWarningOpen(false)}
              >
                {SettingsHelper.ALERTS.SAVE_WARNING_ALERT.MESSAGE}
              </SweetAlert>
            )}
            {isOpenResetWarning && (
              <SweetAlert
                warning
                showCancel
                confirmBtnText={
                  SettingsHelper.ALERTS.RESET_WARNING.RESET_WARNING_ALERT_TOST_SUCCESS_BUTTON
                }
                confirmBtnCssClass="popup confirm"
                cancelBtnText={
                  SettingsHelper.ALERTS.RESET_WARNING.RESET_WARNING_ALERT_TOST_CANCEL_BUTTON
                }
                cancelBtnCssClass="popup cancel"
                confirmBtnBsStyle="success"
                show={isOpenResetWarning}
                title={SettingsHelper.ALERTS.RESET_WARNING.TITLE}
                onConfirm={() => {
                  settigsContext.updateThemeData(1);
                }}
                onCancel={() => setIsOpenResetWarning(false)}
              >
                {SettingsHelper.ALERTS.RESET_WARNING.MESSAGE}
              </SweetAlert>
            )}
          </Tab.Container>
        </StyleCustomTab>
      </StylePage>
    </Container>
  );
}
