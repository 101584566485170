import React, { useState, useRef } from 'react';
import Cron, { HEADER } from 'react-cron-generator';

const prettyCron = require('../../../utils/prettyCron');

const CreateSourceScheduler = ({ formProps }) => {
  const cronRef = useRef();
  const options = {
    headers: [HEADER.WEEKLY],
  };

  const [cronDataValue, setCronDataValue] = useState(formProps.values.frequency);

  const setFieldValueCustom = value => {
    formProps.setFieldValue('frequency', value);
    setCronDataValue(value);
  };

  function getEditedCronExpression(cronRule) {
    const ruleSplits = cronRule.split(' ');
    ruleSplits.shift(ruleSplits);
    const readableCronExpression = prettyCron.toString(ruleSplits.join(' '));
    return readableCronExpression;
  }

  return (
    <div>
      <Cron
        name="frequency"
        ref={cronRef}
        onChange={setFieldValueCustom}
        showResultText={false}
        showResultCron={false}
        value={cronDataValue}
        options={options}
      />
      <div className="cron-builder-bg customCronText">
        {cronDataValue ? getEditedCronExpression(cronDataValue) : '-'}
      </div>
      <small>*All scheduled times are displayed in UTC</small>
      <br />
      <small className="red">{formProps.errors?.frequency ? formProps.errors.frequency : ''}</small>
    </div>
  );
};

export default CreateSourceScheduler;
