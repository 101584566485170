import React from 'react';

import UploadFailureReportDownload from '../../../components/searchLicenses/uploadedDataVisual/UploadFailureReportDownload';
import SearchLicencesProvider from '../SearchLicensesContext';

function UploadFailureReport(props) {
  return (
    <SearchLicencesProvider componentProps={props}>
      <UploadFailureReportDownload />
    </SearchLicencesProvider>
  );
}

export default UploadFailureReport;
