/* eslint-disable import/prefer-default-export */
import * as authReducer from '../reducers/auth';

export const tokenExpired = () => dispatch => {
  dispatch(authReducer.SET_TOKEN_EXPIRY_STATUS(true));
};

export const setToken = data => dispatch => {
  dispatch(authReducer.SET_TOKEN(data));
};

export const setRolePermission = data => dispatch => {
  dispatch(authReducer.SET_ROLE_PERMISSION(data));
};

export const setIsBackendAuthorized = data => dispatch => {
  dispatch(authReducer.SET_IS_BACKEND_AUTHORIZED(data));
};
