import React from 'react';
import PropTypes from 'prop-types';
import SweetAlert from 'react-bootstrap-sweetalert';
import noop from 'lodash/noop';

import WarningIcon from '../../../assets/images/warning-icon.svg';

const WarningAlert = ({
  show,
  onConfirm,
  onCancel,
  title,
  body,
  confirmButtonText,
  cancelButtonText,
}) => {
  return (
    <SweetAlert
      custom
      showCancel
      confirmBtnText={confirmButtonText}
      confirmBtnCssClass="popup confirm"
      cancelBtnText={cancelButtonText}
      cancelBtnCssClass="popup cancel"
      confirmBtnBsStyle="success"
      customIcon={WarningIcon}
      show={show}
      title={title}
      onConfirm={onConfirm}
      onCancel={onCancel}
    >
      {body}
    </SweetAlert>
  );
};

WarningAlert.propTypes = {
  show: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.string,
  confirmButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
};

WarningAlert.defaultProps = {
  show: false,
  onConfirm: noop,
  onCancel: noop,
  title: '',
  body: '',
  confirmButtonText: 'Confirm',
  cancelButtonText: 'Cancel',
};

export default WarningAlert;
