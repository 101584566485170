import React from 'react';
import Authlayout from '../../components/layouts/auth/AuthLayout';
import LoginForm from '../../components/auth/LoginForm';
import LoginHelper from '../../helpers/features/LoginHelper';

function Login() {
  return (
    <Authlayout mainHeader={LoginHelper.LOGIN_HEADER} subHeader={LoginHelper.LOGIN_SUBHEADER}>
      <LoginForm />
    </Authlayout>
  );
}

export default Login;
