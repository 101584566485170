/* eslint-disable function-paren-newline */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-vars */
import React, { useState, useContext } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import * as yup from 'yup';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';

import { Formik } from 'formik';
import AppButton from '../../styles/ButtonLink.styled';
import AppInput from '../../common/input/AppInput';
import { StyleSteps } from '../../styles/Page.styled';
import ErrorNotification from '../../common/errorNotification/ErrorNotification';
import ThemeSelect from '../../common/input/ThemeSelect';
import LicensesHelper from '../../../helpers/features/LicensesHelper';
import {
  checkMandotoryFeilds,
  getDropdownValue,
  filterChanges,
  isValidState,
} from '../../../helpers/commonHelper';
import StateHelper from '../../../helpers/stateHelper';
import { SearchLicencesContext } from '../../../containers/searchLicenses/SearchLicensesContext';
import 'react-datepicker/dist/react-datepicker.css';
import StyleForm from '../../common/input/Form.styled';

function AddNewLicesensForm() {
  const {
    selectedRow,
    handleInlineEdit,
    handleEditModalClose,
    setOpenEditModalError,
    isEditModalOpen,
    setIsEditModalOpen,
    isLStatusFieldDisabled,
    setLStatusFieldDisabled,
  } = useContext(SearchLicencesContext);

  const [startDate, setStartDate] = useState({
    license_issue_date: '',
    license_expiration_date: '',
  });
  const [selectedStateName, setSelectedStateName] = useState('');
  const [selectedCountryName, setSelectedCountryName] = useState('');
  const [selectedCountryCode, setSelectedCountryCode] = useState('');

  // const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

  const licenseSchema = yup.object().shape({
    license_number: yup.string().max(100, 'You have reached the character limit').nullable(true),
    legal_name: yup.string().max(100, 'You have reached the character limit').nullable(true),
    dba_name: yup.string().max(100, 'You have reached the character limit').nullable(true),
    license_issuer: yup.string().max(100, 'You have reached the character limit').nullable(true),
    street: yup.string().max(100, 'You have reached the character limit').nullable(true),
    city: yup
      .string()
      .matches(/^\D+$/g, 'Numbers are not allowed')
      .max(100, 'You have reached the character limit')
      .nullable(true),
    state: yup
      .string()
      .test(
        'test-valid-state',
        'Invalid state name or value contains numbers, lowercase letters or special characters',
        value => isValidState(value),
      )
      .matches(/^[A-Z]+$/g, 'Numbers, lowercase letters and special characters are not allowed')
      .max(2, 'should be 2 chars maximum')
      .nullable(true),
    zip: yup
      .string()
      .matches(/^[0-9-]+$/i, 'Invalid character incuded')
      .max(10, 'Should be 10 chars maximum')
      .nullable(true),
    country_code: yup
      .string()
      .matches(/^[A-Z]+$/g, 'Numbers, lowercase letters and special characters are not allowed')
      .max(2, 'Should be 2 chars maximum')
      .nullable(true),
    country_name: yup
      .string()
      .matches(/^\D+$/g, 'Numbers are not allowed')
      .max(100, 'You have reached the character limit')
      .nullable(true),
    license_designation: yup
      .string()
      .max(100, 'You have reached the character limit')
      .nullable(true),
    license_status: yup.string().max(100, 'You have reached the character limit').nullable(true),
    license_type: yup.string().max(100, 'You have reached the character limit').nullable(true),
    license_issue_date: yup
      .string()
      .max(100, 'You have reached the character limit')
      .nullable(true),
    parent_name: yup.string().max(100, 'You have reached the character limit').nullable(true),
    company_contact_name: yup
      .string()
      .max(100, 'You have reached the character limit')
      .nullable(true),
    county_name: yup
      .string()
      .max(100, 'You have reached the character limit')
      .matches(/^\D+$/g, 'Numbers are not allowed')
      .nullable(true),
    company_website: yup.string().max(100, 'You have reached the character limit').nullable(true),
    company_email: yup
      .string()
      .email('Please check the format')
      .max(100, 'You have reached the character limit')
      .nullable(true),
    company_phone: yup.string().max(9, 'should be 9 chars maximum').nullable(true),
    note: yup.string().max(2000, 'You have reached the character limit').nullable(true),
    status: yup.string().max(100, 'You have reached the character limit').nullable(true),
    license_details: yup.string().max(500, 'You have reached the character limit').nullable(true),
    state_name: yup.string().max(100, 'You have reached the character limit').nullable(true),
  });

  const handleOnChange = () => {};

  return (
    <Formik
      enableReinitialize
      initialValues={selectedRow}
      onSubmit={async values => {
        if (!checkMandotoryFeilds(values) && isEditModalOpen) {
          setOpenEditModalError(true);
        } else if (checkMandotoryFeilds(values) && isEditModalOpen) {
          /* todo: temp fix for "modal edit requires 'is_permanent' to successfully update record.
          refactor once a solution is sorted */
          // handleInlineEdit(selectedRow.id, filterChanges(selectedRow, values));
          handleInlineEdit(selectedRow.id, {
            ...filterChanges(selectedRow, values),
            is_permanent: false,
          });
          handleEditModalClose();
        }
      }}
      validationSchema={licenseSchema}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        isValid,
        setFieldValue,
        dirty,
      }) => (
        <Form className="xx" onSubmit={handleSubmit} onChange={handleOnChange} noValidate>
          <StyleSteps>
            <Row>
              <h4 className="mb-3">License Details</h4>
              <Col md={6}>
                <AppInput
                  label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_NUMBER}
                  type="text"
                  requied=""
                  placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_NUMBER_PLACEHOLDER}
                  helpText=""
                  helpTextLink=""
                  linkUrl=""
                  name="license_number"
                  onChangeInput={handleChange}
                  onBlurInput={handleBlur}
                  value={
                    isEmpty(selectedRow.license_number)
                      ? selectedRow.temp_id
                      : values.license_number
                  }
                  errorText={errors.license_number ? errors.license_number : null}
                />
              </Col>
              <Col md={6}>
                <AppInput
                  label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_ISSUER}
                  type="text"
                  placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_ISSUER_PLACEHOLDER}
                  helpText=""
                  helpTextLink=""
                  linkUrl=""
                  name="license_issuer"
                  onChangeInput={handleChange}
                  onBlurInput={handleBlur}
                  value={values.license_issuer}
                  errorText={errors.license_issuer ? errors.license_issuer : null}
                />
              </Col>
              <Col md={6}>
                <AppInput
                  label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_DESIGNATION}
                  type="text"
                  placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_DESIGNATION_PLACEHOLDER}
                  helpText=""
                  helpTextLink=""
                  linkUrl=""
                  name="license_designation"
                  onChangeInput={handleChange}
                  onBlurInput={handleBlur}
                  value={values.license_designation}
                  errorText={errors.license_designation ? errors.license_designation : null}
                />
              </Col>
              <Col md={6}>
                <AppInput
                  label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_TYPE}
                  type="text"
                  placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_TYPE_PLACEHOLDER}
                  helpText=""
                  helpTextLink=""
                  linkUrl=""
                  name="license_type"
                  onChangeInput={handleChange}
                  onBlurInput={handleBlur}
                  value={values.license_type}
                  errorText={errors.license_type ? errors.license_type : null}
                />
              </Col>
              <Col md={6}>
                <AppInput
                  label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_DETAIL}
                  type="text"
                  placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_DETAIL_PLACEHOLDER}
                  helpText=""
                  helpTextLink=""
                  linkUrl=""
                  name="license_details"
                  onChangeInput={handleChange}
                  onBlurInput={handleBlur}
                  value={values.license_details}
                  errorText={errors.license_details ? errors.license_details : null}
                />
                <p>
                  <small>{`(${size(values.license_details)}/500)`}</small>
                </p>
              </Col>
              <Col md={6}>
                <AppInput
                  label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_STATUS}
                  type="text"
                  placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_STATUS_PLACEHOLDER}
                  helpText=""
                  helpTextLink=""
                  linkUrl=""
                  name="status"
                  onChangeInput={handleChange}
                  onBlurInput={handleBlur}
                  value={values.status}
                  errorText={errors.status ? errors.status : null}
                  disabled={isLStatusFieldDisabled}
                />
              </Col>
              <Col md={6}>
                <StyleForm>
                  <Form.Group className="form-group">
                    <label className="form-label" htmlFor="formBasicEmail">
                      {LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_ISSUE_DATE}
                    </label>
                    <DatePicker
                      className="form-control date-picker"
                      format="MM/DD/YYYY"
                      name="license_issue_date"
                      placeholderText={
                        LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_ISSUE_DATE_PLACEHOLDER
                      }
                      maxDate={new Date()}
                      value={
                        startDate.license_issue_date
                          ? moment(startDate.license_issue_date).format('MM/DD/YYYY')
                          : values.license_issue_date
                          ? moment(values.license_issue_date).format('MM/DD/YYYY')
                          : ''
                      }
                      selected={
                        startDate.license_issue_date
                          ? new Date(startDate.license_issue_date)
                          : values.license_issue_date
                          ? new Date(values.license_issue_date)
                          : ''
                      }
                      onChange={date => {
                        setStartDate({
                          license_expiration_date: '',
                          license_issue_date: moment(date).format('MM/DD/YYYY'),
                        });
                        setFieldValue(
                          'license_issue_date',
                          moment(date).format('MM/DD/YYYY'),
                          false,
                        );
                      }}
                      onKeyDown={e => {
                        e.preventDefault();
                      }}
                    />
                    <Form.Text className="error-text">
                      {errors.license_issue_date ? errors.license_issue_date : null}
                    </Form.Text>
                  </Form.Group>
                </StyleForm>
              </Col>
              <Col md={6}>
                <StyleForm>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <label className="form-label" htmlFor="formBasicEmail">
                      {LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_EXPIRATION_DATE}
                    </label>
                    <DatePicker
                      className="form-control date-picker"
                      format="MM/DD/YYYY"
                      name="license_expiration_date"
                      placeholderText={
                        // eslint-disable-next-line max-len
                        LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_EXPIRATION_DATE_PLACEHOLDER
                      }
                      value={
                        startDate.license_expiration_date
                          ? moment(startDate.license_expiration_date).format('MM/DD/YYYY')
                          : values.license_expiration_date
                          ? moment(values.license_expiration_date).format('MM/DD/YYYY')
                          : ''
                      }
                      selected={
                        startDate.license_expiration_date
                          ? new Date(startDate.license_expiration_date)
                          : values.license_expiration_date
                          ? new Date(values.license_expiration_date)
                          : ''
                      }
                      minDate={
                        values.license_issue_date ? new Date(values.license_issue_date) : new Date()
                      }
                      onChange={date => {
                        setStartDate({
                          ...startDate,
                          license_expiration_date: isNull(date)
                            ? ''
                            : moment(date).format('MM/DD/YYYY'),
                        });
                        setFieldValue(
                          'license_expiration_date',
                          isNull(date) ? '' : moment(date).format('MM/DD/YYYY'),
                          false,
                        );
                        if (isNull(date)) {
                          setFieldValue('status', '');
                          setLStatusFieldDisabled(false);
                          return;
                        }
                        if (
                          moment(date).isAfter() ||
                          moment(date).isSame(moment().toDate(), 'day')
                        ) {
                          setFieldValue('status', 'Active');
                        } else {
                          setFieldValue('status', 'Inactive');
                        }
                        setLStatusFieldDisabled(true);
                      }}
                      onKeyDown={e => {
                        e.preventDefault();
                      }}
                      form
                      isClearable={!isEmpty(values.license_expiration_date)}
                    />
                    <Form.Text className="error-text">
                      {errors.license_expiration_date ? errors.license_expiration_date : null}
                    </Form.Text>
                  </Form.Group>
                </StyleForm>
              </Col>
              <Col md={6}>
                <AppInput
                  label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_STREET}
                  type="text"
                  placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_STREET_PLACEHOLDER}
                  helpText=""
                  helpTextLink=""
                  linkUrl=""
                  name="street"
                  onChangeInput={handleChange}
                  onBlurInput={handleBlur}
                  value={values.street}
                  errorText={errors.street ? errors.street : null}
                />
              </Col>
              <Col md={6}>
                <AppInput
                  label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_CITY}
                  type="text"
                  placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_CITY_PLACEHOLDER}
                  helpText=""
                  helpTextLink=""
                  linkUrl=""
                  name="city"
                  onChangeInput={handleChange}
                  onBlurInput={handleBlur}
                  value={values.city}
                  errorText={errors.city ? errors.city : null}
                />
              </Col>
              <Col md={6}>
                <AppInput
                  label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_STATE}
                  type="text"
                  placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_STATE_PLACEHOLDER}
                  helpText=""
                  helpTextLink=""
                  linkUrl=""
                  name="state"
                  onChangeInput={handleChange}
                  onBlurInput={handleBlur}
                  value={values.state}
                  errorText={errors.state ? errors.state : null}
                />
              </Col>
              <Col md={6}>
                <AppInput
                  label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_ZIP}
                  type="text"
                  placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_ZIP_PLACEHOLDER}
                  helpText=""
                  helpTextLink=""
                  linkUrl=""
                  name="zip"
                  onChangeInput={handleChange}
                  onBlurInput={handleBlur}
                  value={values.zip}
                  errorText={errors.zip ? errors.zip : null}
                />
              </Col>
              <Col md={6}>
                <AppInput
                  label={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_COUNTY}
                  type="text"
                  placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_COUNTY_NAME_PLACEHOLDER}
                  helpText=""
                  helpTextLink=""
                  linkUrl=""
                  name="county_name"
                  onChangeInput={handleChange}
                  onBlurInput={handleBlur}
                  value={values.county_name}
                  errorText={errors.county_name ? errors.county_name : null}
                />
              </Col>
              <Col md={6}>
                <ThemeSelect
                  label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_STATE_NAME}
                  name="state_name"
                  placeholder={
                    // eslint-disable-next-line max-len
                    LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_STATE_PLACEHOLDER
                  }
                  options={StateHelper.STATE_AS_LABEL}
                  onChangeInput={val => {
                    setFieldValue('state_name', val.value, false);
                    setSelectedStateName(val);
                  }}
                  value={
                    getDropdownValue(values.state_name, StateHelper.STATE_AS_LABEL) ||
                    selectedStateName
                  }
                  errorText={errors.state_name ? errors.state_name : null}
                  isSearchable
                />
              </Col>
              <Col md={6}>
                <ThemeSelect
                  label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_COUNTRY_CODE}
                  placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_COUNTRY_PLACEHOLDER}
                  options={LicensesHelper.COUNTRY_CODES}
                  onChangeInput={val => {
                    setFieldValue('country_code', val.value, false);
                    setSelectedCountryCode(val);
                  }}
                  value={
                    getDropdownValue(values.country_code, LicensesHelper.COUNTRY_CODES) ||
                    selectedCountryCode
                  }
                  errorText={errors.country_code ? errors.country_code : null}
                />
              </Col>
              <Col md={6}>
                <ThemeSelect
                  label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_COUNTRY}
                  placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_COUNTRY_PLACEHOLDER}
                  options={LicensesHelper.AVAILABLE_COUNTRIES}
                  onChangeInput={val => {
                    setFieldValue('country_name', val.value, false);
                    setSelectedCountryName(val);
                  }}
                  value={
                    getDropdownValue(values.country_name, LicensesHelper.AVAILABLE_COUNTRIES) ||
                    selectedCountryName
                  }
                  errorText={errors.country_name ? errors.country_name : null}
                />
              </Col>
              <Col md={6}>
                <StyleForm>
                  <Form.Group className="form-group" controlId="formBasicEmail">
                    <AppInput
                      label={LicensesHelper.ADD_NEW_LICENSES_FORM_LICENSE_LAST_UPDATED_DATE}
                      type="text"
                      placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_NUMBER_PLACEHOLDER}
                      value={
                        selectedRow.last_update || selectedRow.created_at || selectedRow.updated_at
                          ? moment(
                              selectedRow.last_update ||
                                selectedRow.created_at ||
                                selectedRow.updated_at,
                            ).format('MM/DD/YYYY')
                          : '-'
                      }
                      disabled
                    />
                  </Form.Group>
                </StyleForm>
              </Col>
            </Row>

            <Row>
              <h4 className="mt-4 mb-3">Company Details</h4>
              <Col md={6}>
                <AppInput
                  label={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_PARENT_NAME}
                  type="text"
                  placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_PARENT_NAME_PLACEHOLDER}
                  helpText=""
                  helpTextLink=""
                  linkUrl=""
                  name="parent_name"
                  onChangeInput={handleChange}
                  onBlurInput={handleBlur}
                  value={values.parent_name}
                  errorText={errors.parent_name ? errors.parent_name : null}
                />
              </Col>
              <Col md={6}>
                <AppInput
                  label={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_LEGAL_NAME}
                  type="text"
                  placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_LEGAL_NAME_PLACEHOLDER}
                  helpText=""
                  helpTextLink=""
                  linkUrl=""
                  name="legal_name"
                  onChangeInput={handleChange}
                  onBlurInput={handleBlur}
                  value={values.legal_name}
                  errorText={errors.legal_name ? errors.legal_name : null}
                />
              </Col>
              <Col md={6}>
                <AppInput
                  label={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_DBA_NAME}
                  type="text"
                  placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_DBA_NAME_PLACEHOLDER}
                  helpText=""
                  helpTextLink=""
                  linkUrl=""
                  name="dba_name"
                  onChangeInput={handleChange}
                  onBlurInput={handleBlur}
                  value={values.dba_name}
                  errorText={errors.dba_name ? errors.dba_name : null}
                />
              </Col>
              <Col md={6}>
                <AppInput
                  label={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_WEBSITE}
                  type="text"
                  placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_WEBSITE_PLACEHOLDER}
                  helpText=""
                  helpTextLink=""
                  linkUrl=""
                  name="company_website"
                  onChangeInput={handleChange}
                  onBlurInput={handleBlur}
                  value={values.company_website}
                  errorText={errors.company_website ? errors.company_website : null}
                />
              </Col>
              <Col md={6}>
                <AppInput
                  label={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_CONTACT_NAME}
                  type="text"
                  placeholder={
                    LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_CONTACT_NAME_PLACEHOLDER
                  }
                  helpText=""
                  helpTextLink=""
                  linkUrl=""
                  name="company_contact_name"
                  onChangeInput={handleChange}
                  onBlurInput={handleBlur}
                  value={values.company_contact_name}
                  // eslint-disable-next-line max-len
                  errorText={errors.company_contact_name ? errors.company_contact_name : null}
                />
              </Col>
              <Col md={6}>
                <AppInput
                  label={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_EMAIL}
                  type="email"
                  // eslint-disable-next-line max-len
                  placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_EMAIL_PLACEHOLDER}
                  helpText=""
                  helpTextLink=""
                  linkUrl=""
                  name="company_email"
                  onChangeInput={handleChange}
                  onBlurInput={handleBlur}
                  value={values.company_email}
                  errorText={errors.company_email ? errors.company_email : null}
                />
              </Col>
              <Col md={6}>
                <AppInput
                  label={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_PHONE}
                  type="number"
                  // eslint-disable-next-line max-len
                  placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_PHONE_PLACEHOLDER}
                  helpText=""
                  helpTextLink=""
                  linkUrl=""
                  name="company_phone"
                  onChangeInput={e => {
                    setFieldValue('company_phone', e.target.value.toString());
                  }}
                  onBlurInput={handleBlur}
                  value={
                    values.company_phone ? values.company_phone.toString() : values.company_phone
                  }
                  errorText={errors.company_phone ? errors.company_phone : null}
                />
              </Col>
              <Col md={12}>
                <AppInput
                  label={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_NOTES}
                  type="text"
                  // eslint-disable-next-line max-len
                  placeholder={LicensesHelper.ADD_NEW_LICENSES_FORM_COMPANY_NOTES_PLACEHOLDER}
                  helpText=""
                  helpTextLink=""
                  linkUrl=""
                  name="note"
                  onChangeInput={handleChange}
                  onBlurInput={handleBlur}
                  value={values.note}
                  errorText={errors.note ? errors.note : null}
                />
                <p>
                  <small>{`(${size(values.note)}/2000)`}</small>
                </p>
              </Col>
            </Row>
            <div className="d-flex mt-2 gap-2 steps-buttons">
              <AppButton
                type="button"
                className="popup cancel"
                onClick={() => setIsEditModalOpen(false)}
              >
                {LicensesHelper.ADD_NEW_LICENSES_FORM_LICESEN_CANCEL}
              </AppButton>
              <AppButton
                fill
                disabled={
                  !(dirty && isValid) ||
                  isEqual(values, selectedRow) ||
                  (!isEmpty(values.company_phone) && size(values.company_phone) < 9)
                }
                type="submit"
              >
                {LicensesHelper.EDIT_LICENSES_FORM_LICESEN_SUBMIT_BUTTON_TEXT}
              </AppButton>
            </div>
            {/* {license.message.type === 'error' && (
                    <ErrorNotification errorMessage={license.message.message} />
                  )} */}
            <Row />
          </StyleSteps>
        </Form>
      )}
    </Formik>
  );
}
export default AddNewLicesensForm;
