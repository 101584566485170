import React, { useState, createContext, useMemo, useContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import MD5 from 'md5';
import isEmpty from 'lodash/isEmpty';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  forgetPassword,
  getUserPermissions,
  login,
  logout,
  resetPassword,
  verifyPassword,
} from '../../apis/authApi';
import store from '../../store/store';
import * as authActions from '../../store/actions/auth';
import * as commonActions from '../../store/actions/common';
import { decodeJwt } from '../../helpers/commonHelper';
import LoginHelper from '../../helpers/features/LoginHelper';

import {
  setDataValidationTableFilters,
  setEntryDetailsTableFilters,
} from '../../store/actions/dataValidation';
import DataValidationHelper from '../../helpers/features/DataValidationHelper';

const AuthContext = createContext();

function AuthContextProvider({ children }) {
  const token = localStorage.getItem('token');
  /* -------------------------------------------------------------------------- */
  /*                                   Router                                   */
  /* -------------------------------------------------------------------------- */
  const navigate = useNavigate();
  const location = useLocation();

  /* -------------------------------------------------------------------------- */
  /*                                    Hooks                                   */
  /* -------------------------------------------------------------------------- */
  const [isBackendAuthorized, setIsBackendAuthorized] = useState(!!token);
  const [permissionRole, setPermissionRole] = useState(null);
  const [message, setMessage] = useState({
    type: null,
    message: null,
  });
  const [isAccountSetup, setIsAccountSetup] = useState(false);

  const checkIsAccountSetup = () => {
    if (location.pathname.split('/').includes('setup-account', 'set-password')) {
      setIsAccountSetup(true);
    }
  };

  const resetFilters = () => {
    store.dispatch(
      setDataValidationTableFilters(
        DataValidationHelper.HISTORY_LIST.HISTORY_TABLE.DEFAULTS.PARAMS,
      ),
    );
    store.dispatch(
      setEntryDetailsTableFilters(
        DataValidationHelper.ENTRY_DETAILS_LIST.DETAILS_TABLE.DEFAULTS.PARAMS,
      ),
    );
  };

  const handleLogin = async params => {
    try {
      const payload = {
        email: params.email,
        password: MD5(params.password),
      };
      const response = await login(payload);
      if (response.success && response.data) {
        localStorage.setItem('token', response.data.token);
        // eslint-disable-next-line no-use-before-define
        await handleUserRole();
        store.dispatch(authActions.setToken(response.data.token));
        store.dispatch(
          commonActions.showSuccessAlert({
            type: 'success',
            delay: 3000,
            message: response.message,
            body: 'You are successfully logged in!',
          }),
        );
        setIsBackendAuthorized(true);
        store.dispatch(authActions.setIsBackendAuthorized(true));
      } else {
        store.dispatch(
          commonActions.showErrorAlert({
            type: 'error',
            delay: 4000,
            body: response.message,
            message: 'Something went wrong!',
          }),
        );
      }
    } catch (e) {
      store.dispatch(
        commonActions.showErrorAlert({
          type: 'error',
          delay: 4000,
          body: e,
          message: 'Something went wrong!',
        }),
      );
      console.error('Unable to authorize user from backend', e);
    }
  };

  const handleLogout = useCallback(async () => {
    try {
      await logout();
      localStorage.removeItem('token');
      setIsBackendAuthorized(false);
      store.dispatch(authActions.setIsBackendAuthorized(false));
      resetFilters();
      navigate('/login');
    } catch (e) {
      console.error('Unable to authorize user from backend', e);
    }
  });

  const handleForgetPassword = async params => {
    try {
      const response = await forgetPassword(params);
      if (response.success) {
        store.dispatch(
          commonActions.showSuccessAlert({
            type: 'success',
            delay: 3000,
            message: response.message,
            body: 'Email sent successfully. Click the link to reset your password.',
          }),
        );
        navigate('/login');
      } else {
        setMessage({
          type: 'error',
          message: response.message,
        });
        setTimeout(() => {
          setMessage({
            type: null,
            message: null,
          });
        }, 3000);
      }
    } catch (e) {
      store.dispatch(
        commonActions.showErrorAlert({
          type: 'error',
          delay: 3000,
          message: e,
          body: 'something went wrong!',
        }),
      );
      console.error('Unable to authorize user from backend', e);
    }
  };

  const handleResetPassword = async code => {
    try {
      const response = await resetPassword(code);
      if (!response && !response.success) {
        store.dispatch(
          commonActions.showErrorAlert({
            type: 'error',
            delay: 3000,
            message: response.message,
            body: 'Url invalid or expired!',
          }),
        );
        navigate('/login');
      }
    } catch (e) {
      store.dispatch(
        commonActions.showErrorAlert({
          type: 'error',
          delay: 3000,
          message: e.message,
          body: 'something went wrong!',
        }),
      );
      navigate('/login');
      console.error('Unable to authorize user from backend', e);
    }
  };

  const handleUpdatePassword = async body => {
    try {
      const payload = {
        token: body.token,
        password: MD5(body.password),
      };
      const response = await verifyPassword(payload);
      if (response.success && response.code === 201) {
        store.dispatch(
          commonActions.showSuccessAlert({
            type: 'success',
            delay: 3000,
            message: response.message,
            body: 'Password reset success! Please login.',
          }),
        );
        navigate('/login');
      } else {
        store.dispatch(
          commonActions.showErrorAlert({
            type: 'error',
            delay: 3000,
            message: 'Something went wrong!',
            body: response.message,
          }),
        );
      }
    } catch (e) {
      store.dispatch(
        commonActions.showErrorAlert({
          type: 'error',
          delay: 3000,
          message: e,
          body: 'Something went wrong!',
        }),
      );
      console.error('Unable to authorize user from backend', e);
    }
  };

  /**
   * user role permission api call
   */
  const handleUserRole = async () => {
    try {
      const response = await getUserPermissions();
      if (response.success && response.data) {
        store.dispatch(authActions.setRolePermission(response.data));
        setPermissionRole(response.data);
      }
    } catch (error) {
      store.dispatch(
        commonActions.showErrorAlert({
          type: 'error',
          delay: 3000,
          message: error,
          body: 'something went wrong!',
        }),
      );
      console.error('Unable to authorize user from backend', error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setIsBackendAuthorized(true);
      store.dispatch(authActions.setIsBackendAuthorized(true));
      handleUserRole();
    }
  }, [setIsBackendAuthorized]);

  /* -------------------------------------------------------------------------- */
  /*                                Return values                               */
  /* -------------------------------------------------------------------------- */
  const value = useMemo(
    () => ({
      isBackendAuthorized,
      message,
      handleLogin,
      handleLogout,
      handleForgetPassword,
      handleResetPassword,
      handleUpdatePassword,
      permissionRole,
      checkIsAccountSetup,
      isAccountSetup,
    }),
    [
      isBackendAuthorized,
      message,
      handleLogin,
      handleLogout,
      handleForgetPassword,
      handleResetPassword,
      handleUpdatePassword,
      permissionRole,
      checkIsAccountSetup,
      isAccountSetup,
    ],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return useContext(AuthContext);
}
AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthContextProvider, useAuth };
