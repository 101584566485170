import styled from 'styled-components';

const StyleEntryCard = styled.div`
  .entry-count-card {
    background: #f5fbfb;
    margin-bottom: 2rem;
    border: 1px solid ${props => props.theme?.general?.primaryColor};
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 10px 15px -8px rgba(161, 176, 205, 0.25);
    &:hover {
      box-shadow: 0 10px 15px -8px rgba(161, 176, 205, 0.5);
    }
    .name {
      font-size: 1rem;
      font-weight: 700;
    }
    .count {
      font-size: 2.5rem;
      font-weight: 900;
      color: ${props => props.theme?.general?.primaryColor};
    }
    cursor: pointer;
  }

  .active {
    box-shadow: 0 10px 15px -8px rgba(161, 176, 205, 0.6);
  }
`;

export default StyleEntryCard;
