import React, { useContext } from 'react';
import { Form, Image } from 'react-bootstrap';

import { StyleSearch } from '../layouts/header/Header.styled';
import SearchIcon from '../../assets/images/search.svg';
import { UsersContext } from '../../containers/users/UsersContext';

export const UserSearch = ({ customClass }) => {
  const { handleSearchFilter, filterObj } = useContext(UsersContext);
  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <StyleSearch>
        <Image src={SearchIcon} />
        <Form.Control
          type="search"
          placeholder="Search"
          className={`mb-3 ${customClass}`}
          aria-label="Search"
          value={filterObj?.searchValue}
          onChange={e => {
            handleSearchFilter(e.target.value);
          }}
        />
      </StyleSearch>
    </Form>
  );
};
