/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';

import TableRow from '../../common/table/TableRow';

import { SourcesContext } from '../../../containers/sources/SourcesContext';

import Edit from '../../../assets/images/edit.svg';
import Delete from '../../../assets/images/bin.svg';

const SourcesListRow = ({ data }) => {
  const {
    id,
    source_identifier,
    licensing_agency,
    license_type,
    state,
    country_code,
    source,
    file_name,
    type,
  } = data;

  const { setShowCreateSourceModal, setShowDeleteSourceAlert, handleViewUploadedSourceFile } =
    useContext(SourcesContext);

  return (
    <TableRow>
      <td>
        <span>{source_identifier || '-'}</span>
      </td>
      <td>
        <span>{licensing_agency || '-'}</span>
      </td>
      <td>
        <span>{license_type || '-'}</span>
      </td>
      <td>
        <span>{state || '-'}</span>
      </td>
      <td>
        <span>{country_code || '-'}</span>
      </td>
      <td>
        {source && !file_name ? (
          <span className="link-btn" onClick={() => window.open(source, '_blank')}>
            Link
          </span>
        ) : !source && file_name ? (
          <span className="link-btn" onClick={e => handleViewUploadedSourceFile(file_name)}>
            Link
          </span>
        ) : (
          <span>-</span>
        )}
      </td>
      <td>
        <span>{type || '-'}</span>
      </td>
      <td>
        <div className="d-flex">
          <button
            type="button"
            className="table-action-btn fill-btn"
            onClick={() => setShowCreateSourceModal({ data, isCreate: false, show: true })}
          >
            <Image src={Edit} />
          </button>
          <button
            type="button"
            className="table-action-btn outline-btn"
            onClick={() => setShowDeleteSourceAlert({ id, show: true })}
          >
            <Image src={Delete} />
          </button>
        </div>
      </td>
    </TableRow>
  );
};

SourcesListRow.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SourcesListRow;
