import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useNavigate } from 'react-router-dom';

import { useLicenses } from '../../../containers/licenses/LicensesContext';

export default function AddLicenseAlerts() {
  const license = useLicenses();
  const navigate = useNavigate();

  const handleCancelClick = () => {
    license.closeCancelAlert();
    navigate('/search-licenses');
  };

  return (
    <div>
      {license.isAllMandotoryFeilds && (
        <SweetAlert
          danger
          confirmBtnText="OK"
          confirmBtnBsStyle="danger"
          title="Error!"
          onConfirm={() => license.closeErrorAlert()}
        >
          To add a new record, please enter a value for one or more of the following fields: Legal
          Name, License Issuer, License Type, License Details, DBA Name, City, County, and State
          Name.
        </SweetAlert>
      )}
      {license.isFormCancel && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="OK"
          confirmBtnBsStyle="warning"
          cancelBtnBsStyle="light"
          title="Warning!"
          onConfirm={() => handleCancelClick()}
          onCancel={() => license.closeCancelAlert()}
          focusCancelBtn
        >
          <span className="sweet-alert-p d-block">Changes you made will not be saved</span>
        </SweetAlert>
      )}
    </div>
  );
}
