/* eslint-disable react/no-danger */
import React from 'react';
import moment from 'moment';
import { Container, Row, Col, Image } from 'react-bootstrap';

import StyleFooter from './Footer.styled';

import FooterLogo from '../../../assets/images/footer-logo.png';
import Location from '../../../assets/images/location.svg';
import Email from '../../../assets/images/email.svg';
import Call from '../../../assets/images/phone.svg';

function Footer({ address, email, contact }) {
  return (
    <StyleFooter>
      <Container>
        <Row>
          <Col md={2} className="text-center">
            <div>
              <Image src={FooterLogo} alt="Simplifya Verified Logo" />
            </div>
            © {moment().format('YYYY')} Simplifya, LLC
          </Col>
          <Col md={{ span: 3, offset: 1 }} className="d-flex">
            <Image src={Location} alt="Location" />
            <p className="ml-3 mb-0" dangerouslySetInnerHTML={{ __html: address }} />
          </Col>
          <Col md={{ span: 3 }} className="d-flex">
            <Image src={Email} alt="Email" />
            <p className="mb-0" dangerouslySetInnerHTML={{ __html: email }} />
          </Col>
          <Col md={{ span: 3 }} className="d-flex">
            <Image src={Call} alt="Call" />
            <p className="mb-0" dangerouslySetInnerHTML={{ __html: contact }} />
          </Col>
        </Row>
      </Container>
    </StyleFooter>
  );
}

export default Footer;
