import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import { useAuth } from '../../containers/auth/AuthContext';

function Permissions(props) {
  const auth = useAuth();
  const { permissionRole, children } = props;
  if (!isNil(auth.permissionRole) && auth.permissionRole.includes(permissionRole)) {
    return children;
  }
  return null;
}

Permissions.propTypes = {
  children: PropTypes.node.isRequired,
  permissionRole: PropTypes.string.isRequired,
};

export default Permissions;
