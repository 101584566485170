export default Object.freeze({
  DEFAULT_THEME_DATA: {
    qna: {
      ansFontSize: '14px',
      ansFontColor: '#000000',
      quizFontSize: '18px',
      ansFontWeight: '400',
      quizFontColor: '#000000',
      quizFontWeight: '900',
    },
    table: {
      tableHeaderFontSize: '14px',
      tableContentFontSize: '14px',
      tableHeaderFontColor: '#000000',
      tableContentFontColor: '#000000',
      tableHeaderFontWeight: '700',
      tableContentFontWeight: '400',
    },
    footer: {
      companyEmail: '<p>Technical Support: <br>support@simplifya.com</p>',
      companyAddress: '<p>7830 W Alameda Avenue<br>Suite 103-306<br>Lakewood, CO 80226</p>',
      companyContact: '<p>Toll-Free:<br>877-464-8398 x2<br>(please leave a message)</p>',
      footerBackground: '#ffffff',
    },
    header: {
      headerFontSize: '25px',
      headerFontColor: '#1d1603',
      headerFontWeight: '900',
    },
    general: {
      primaryColor: '#5dadae',
      secondaryColor: '#fdc126',
      backgroundColor: '#ffffff',
      generalFontColor: '#000000',
      generalLinkColor: '#2d8eff',
      generalFontFamily: 'Lato, sans-serif',
      generalBorderColor: '#f0f0f0',
      generalButtonColor: {
        a: 1,
        b: 0,
        g: 0,
        r: 0,
      },
      generalHeaderColor: '#5dadae',
    },
    navigation: {
      backgroundColor: '#ffffff',
      navigationFontSize: '18px',
      navigationFontWeight: '400',
    },
  },
  TOST_TYPE_SUCCESS: 'success',
  TOST_TYPE_ERROR: 'error',
  SUCCESS: 'SUCCESS',
  UPDATE_SUCCESS: 'Setting Successfully Updated!',
  ERROR: 'ERROR',
  UPDATE_FAILED: 'Settings failed to update!',
  FETCH_FAILED: 'Someting Went Wrong!',
  NAVIGATION_PANE: {
    SECTIONS: {
      GENERAL: 'General',
      NAVIGATION: 'Navigation',
      HEADER: 'Page Header',
      QNA: 'Question and Answers',
      TABLE: 'Table',
      FOOTER: 'Footer Manager',
      SESSION_TIME: 'Session Time',
    },
  },
  PAGE_HEADERS: {
    SECTIONS: {
      GENERAL: { GENERAL: 'General', SESSION: 'Session Time' },
      NAVIGATION: 'Navigation Styles',
      HEADER: 'Page Headers Styles',
      QNA: {
        QUIZ: 'Question Styles',
        ANS: 'Answer Styles',
      },
      TABLE: {
        HEADER: 'Table Header Styles',
        CONTENT: 'Table Content Styles',
      },
      FOOTER: {
        STYLES: 'Footer Styles',
        ADDRESS: 'Address Section',
        EMAIL: 'Email Section',
        CONTACT: 'Contact Number Section',
      },
    },
  },
  SECTIONS: {
    GENERAL: 'general',
    NAVIGATION: 'navigation',
    HEADER: 'header',
    QNA: 'qna',
    TABLE: 'table',
    FOOTER: 'footer',
  },
  SUB_SECTIONS: {
    GENERAL: {
      PRIMARY: 'primaryColor',
      SECONDARY: 'secondaryColor',
      BACKGROUND: 'backgroundColor',
      FONT_COLOR: 'generalFontColor',
      LINK_COLOR: 'generalLinkColor',
      FONT_FAMILY: 'generalFontFamily',
      BORDER: 'generalBorderColor',
      HEADER: 'generalHeaderColor',
    },
    NAVIGATION: {
      BACKGROUND: 'backgroundColor',
      FONT_SIZE: 'navigationFontSize',
      FONT_WEIGHT: 'navigationFontWeight',
    },
    HEADER: {
      FONT_COLOR: 'headerFontColor',
      FONT_SIZE: 'headerFontSize',
      FONT_WEIGHT: 'headerFontWeight',
    },
    QNA: {
      QUIZ_FONT_COLOR: 'quizFontColor',
      QUIZ_FONT_SIZE: 'quizFontSize',
      QUIZ_FONT_WEIGHT: 'quizFontWeight',
      ANS_FONT_COLOR: 'ansFontColor',
      ANS_FONT_SIZE: 'ansFontSize',
      ANS_FONT_WEIGHT: 'ansFontWeight',
    },
    TABLE: {
      TABLE_HEADER_FONT_COLOR: 'tableHeaderFontColor',
      TABLE_HEADER_FONT_SIZE: 'tableHeaderFontSize',
      TABLE_HEADER_FONT_WEIGHT: 'tableHeaderFontWeight',
      TABLE_CONTENT_COLOR: 'tableContentFontColor',
      TABLE_CONTENT_SIZE: 'tableContentFontSize',
      TABLE_CONTENT_WEIGHT: 'tableContentFontWeight',
    },
    FOOTER: {
      BACKGROUND: 'footerBackground',
      ADDRESS: 'companyAddress',
      EMAIL: 'companyEmail',
      CONTACT: 'companyContact',
    },
  },
  DROPDOWN_VALUE_LIST: {
    GENERAL: {
      FONTS: [
        { label: 'Lato, sans-serif', value: 'Lato, sans-serif' },
        { label: 'Poppins, sans-serif', value: 'Poppins, sans-serif' },
        { label: 'Raleway, sans-serif', value: 'Raleway, sans-serif' },
        { label: 'Roboto, sans-serif', value: 'Roboto, sans-serif' },
      ],
    },
    NAVIGATION: {
      FONT_SIZES: [
        { label: '14px', value: '14px' },
        { label: '16px', value: '16px' },
        { label: '18px', value: '18px' },
        { label: '20px', value: '20px' },
        { label: '22px', value: '22px' },
      ],
      FONT_WEIGHTS: [
        { label: '300', value: '300' },
        { label: '400', value: '400' },
        { label: '700', value: '700' },
        { label: '900', value: '900' },
      ],
    },
    PAGE_HEADER: {
      FONT_SIZES: [
        { label: '25px', value: '25px' },
        { label: '28px', value: '28px' },
        { label: '30px', value: '30px' },
        { label: '36px', value: '36px' },
      ],
      FONT_WEIGHTS: [
        { label: '300', value: '300' },
        { label: '400', value: '400' },
        { label: '700', value: '700' },
        { label: '900', value: '900' },
      ],
    },
    QNA: {
      FONT_SIZES: [
        { label: '14px', value: '14px' },
        { label: '16px', value: '16px' },
        { label: '18px', value: '18px' },
        { label: '20px', value: '20px' },
      ],
      FONT_WEIGHTS: [
        { label: '300', value: '300' },
        { label: '400', value: '400' },
        { label: '700', value: '700' },
        { label: '900', value: '900' },
      ],
    },
    TABLE: {
      HEAD: {
        FONT_SIZES: [
          { label: '14px', value: '14px' },
          { label: '16px', value: '16px' },
          { label: '18px', value: '18px' },
          { label: '20px', value: '20px' },
          { label: '22px', value: '22px' },
        ],
        FONT_WEIGHTS: [
          { label: '300', value: '300' },
          { label: '400', value: '400' },
          { label: '700', value: '700' },
          { label: '900', value: '900' },
        ],
      },
      DESCIPTION: {
        FONT_SIZES: [
          { label: '14px', value: '14px' },
          { label: '16px', value: '16px' },
          { label: '18px', value: '18px' },
          { label: '20px', value: '20px' },
          { label: '22px', value: '22px' },
          { label: '24px', value: '24px' },
        ],
        FONT_WEIGHTS: [
          { label: '300', value: '300' },
          { label: '400', value: '400' },
          { label: '700', value: '700' },
          { label: '900', value: '900' },
        ],
      },
    },
  },
  SAVE_BUTTON_TEXT: 'Save',
  DISCARD_BUTTON_TEXT: 'Discard Changes',
  RESET_BUTTON_TEXT: 'Reset To Default',
  ALERTS: {
    DISCARD_WARNING: {
      MESSAGE: 'Are you sure you want to discard all changes that you have already made?',
      TITLE: 'Confirm Discarding Changes!',
      DISCARD_WARNING_ALERT_TOST_SUCCESS_BUTTON: 'Yes, Confirm',
      DISCARD_WARNING_ALERT_TOST_CANCEL_BUTTON: 'No, Cancel',
    },
    NO_CHANGES_ALERT: {
      MESSAGE: 'There are no changes to discard!',
      TITLE: 'Error!',
      NO_CHANGES_ALERT_TOST_SUCCESS_BUTTON: 'OK',
    },
    RESET_WARNING: {
      MESSAGE: 'Are you sure you want to reset settings to default?',
      TITLE: 'Confirm Reset!',
      RESET_WARNING_ALERT_TOST_SUCCESS_BUTTON: 'Yes, Confirm',
      RESET_WARNING_ALERT_TOST_CANCEL_BUTTON: 'No, Cancel',
    },
    SAVE_WARNING_ALERT: {
      MESSAGE: 'You can not save the changes due to some errors. Please try again!',
      TITLE: 'Error!',
      SAVE_WARNING_ALERT_TOST_SUCCESS_BUTTON: 'OK',
    },
  },
});
