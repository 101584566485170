import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { StylePage, MinContentWrap } from '../../styles/Page.styled';
import PageHeader from '../../common/pageHeader/PageHeader';

import { SearchLicencesContext } from '../../../containers/searchLicenses/SearchLicensesContext';
import SearchLicensesHelper from '../../../helpers/features/SearchLicensesHelper';
import { decodeJwt } from '../../../helpers/commonHelper';
import routes from '../../../helpers/routes';

export default function UploadFailureReportDownload() {
  const { hadleBulkUploadFailureReportDownload, bulkUploadFailureReportDownloadURL } =
    useContext(SearchLicencesContext);
  const { filename, importName } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (decodeJwt(localStorage.getItem('token')).role === 'ADMIN') {
      hadleBulkUploadFailureReportDownload(filename);
    } else {
      navigate('/');
    }
  }, []);

  return (
    <Container>
      <StylePage>
        <MinContentWrap className="d-flex justify-content-center align-items-center">
          <div>
            <PageHeader
              className="m-0 p-0"
              title={SearchLicensesHelper.BULK_UPLOAD_FAILURE_REPORT_TITLE}
            />
            <p>
              {`The import of one or more of the records from ${importName} have failed due to errors. Please click the "Download" button below to access a detailed report of the import failures.`}
            </p>
            <div className={isEmpty(bulkUploadFailureReportDownloadURL) && 'not-allowed-cursor'}>
              <a
                href={bulkUploadFailureReportDownloadURL}
                className={
                  isEmpty(bulkUploadFailureReportDownloadURL)
                    ? 'disabled table-action-btn'
                    : 'table-action-btn'
                }
              >
                Download
              </a>
            </div>
          </div>
        </MinContentWrap>
      </StylePage>
    </Container>
  );
}
