import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LoginHelper from '../../helpers/features/LoginHelper';
import ErrorNotification from '../common/errorNotification/ErrorNotification';
import AppInput from '../common/input/AppInput';
import AppButton from '../styles/ButtonLink.styled';
import { useAuth } from '../../containers/auth/AuthContext';

function ForgetPasswordForm() {
  const auth = useAuth();
  const loginSchema = yup.object().shape({
    email: yup.string().email('Invalid email address').required('Email is required'),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{ email: '' }}
      onSubmit={async values => {
        auth.handleForgetPassword(values);
      }}
      validationSchema={loginSchema}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        handleBlur,
        isValid,
        // eslint-disable-next-line no-unused-vars
        validateOnMount,
        dirty,
      }) => (
        <Form onSubmit={handleSubmit} noValidate>
          <AppInput
            label={LoginHelper.LOGIN_FORM_EMAIL_LABLE}
            requied="*"
            type="email"
            name="email"
            placeholder={LoginHelper.LOGIN_FORM_EMAIL_PLACEHOLDER}
            helpText=""
            helpTextLink=""
            linkUrl=""
            onChangeInput={handleChange}
            onBlurInput={handleBlur}
            value={values.email}
            errorText={errors.email ? errors.email : null}
          />
          <div className="d-grid">
            <AppButton type="submit" disabled={!(dirty && isValid)}>
              {LoginHelper.FORGET_PASSWORD_BTN_TEXT}
            </AppButton>
            <p className="text-center p-0">
              <Link className="app-link" to="/login">
                {LoginHelper.FORGET_PASSWORD_BACK_LINK_TEXT}
              </Link>
            </p>
          </div>
          {auth.message.type === 'error' && (
            <ErrorNotification errorMessage={auth.message.message} />
          )}
        </Form>
      )}
    </Formik>
  );
}

export default ForgetPasswordForm;
