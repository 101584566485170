import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Form, Image } from 'react-bootstrap';

import StyleForm from './Form.styled';
import ToolTip from '../toolTips/ToolTip';

function ThemeSelect(props) {
  const {
    label,
    requied,
    icon,
    placeholder,
    errorText,
    helpText,
    name,
    value,
    onChangeInput,
    onBlurInput,
    disabled,
    options,
    index,
    selectedObject,
    isBulk,
    isSearchable,
    errorSet,
    isSettingsDropdown,
    toolTipContent,
  } = props;

  if (errorText) {
    errorSet(true);
  } else {
    errorSet(false);
  }

  const [selection, setSelection] = useState('');

  return (
    <StyleForm>
      <Form.Group className="form-group" controlId="">
        <Form.Label>
          {label}
          <span className="red"> {requied}</span>
          <ToolTip icon={icon} toolTipContent={toolTipContent} />
        </Form.Label>
        <Select
          className="form-control theme-select"
          options={options}
          name={name}
          isDisabled={disabled}
          onChange={
            isBulk
              ? val => onChangeInput(index, selectedObject, val, '')
              : val => {
                  onChangeInput(val);
                  setSelection(val);
                }
          }
          onBlur={
            isBulk
              ? val => onBlurInput(index, selectedObject, val, '')
              : val => {
                  onBlurInput(val);
                  setSelection(val);
                }
          }
          value={isSettingsDropdown && selection ? selection : value}
          placeholder={placeholder}
          isSearchable={isSearchable}
        />
        <Form.Text className="error-text">{errorText}</Form.Text>
        <Form.Text className="text-muted">{helpText}</Form.Text>
      </Form.Group>
    </StyleForm>
  );
}

ThemeSelect.propTypes = {
  label: PropTypes.string.isRequired,
  requied: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  errorText: PropTypes.string,
  helpText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  onBlurInput: PropTypes.func,
  options: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  index: PropTypes.number.isRequired,
  selectedObject: PropTypes.node,
  isBulk: PropTypes.bool,
  isSearchable: PropTypes.bool,
  errorSet: PropTypes.func,
};

ThemeSelect.defaultProps = {
  errorText: null,
  onBlurInput: () => {},
  disabled: false,
  selectedObject: {},
  isBulk: false,
  isSearchable: false,
  errorSet: () => {},
};

export default ThemeSelect;
