export default Object.freeze({
  ENV: {
    DEVELOPMENT: 'development',
    TEST: 'test',
    PRODUCTION: 'production',
  },
  USER_ENV: {
    DEVELOPMENT: 'development',
    QA: 'qa',
    STAGING: 'staging',
    PRODUCTION: 'production',
  },
  TOAST_ALERT_TYPES: {
    SUCCESS: {
      type: 'success',
      icon: 'fa fa-check',
      cssClass: 'success',
    },
    INFO: {
      type: 'info',
      icon: 'fa fa-info-circle',
      cssClass: 'info',
    },
    WARNING: {
      type: 'warning',
      icon: 'fa fa-exclamation-triangle',
      cssClass: 'warning',
    },
    ERROR: {
      type: 'error',
      icon: 'fa fa-times',
      cssClass: 'danger',
    },
  },
  NAVIGATIN_HEADER_TEXT: {
    SEARCH_LICENSES: 'Search Licenses',
    USERS: 'Users',
    DATA_DICTONARY: 'Data Dictionary',
    FAQ: 'FAQ',
    LICENSES: 'Licenses',
    REPORTS: 'Reports',
    SOURCES: 'Sources',
    DATA_VALIDATION: 'Data Validation',
    SETTINGS: 'Settings',
  },
  REPORT_MENU_TEXT: {
    LICENSES: 'License Update Logs',
    USERS: 'User Logins Report',
    WEEKLY_LICENSE: 'Weekly License Report',
  },
  PAGINATION_DROPDOWN_VALUES: [
    { value: 10, key: 1 },
    { value: 20, key: 2 },
    { value: 50, key: 3 },
    { value: 100, key: 4 },
  ],
});
