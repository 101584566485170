import styled from 'styled-components';

const StyleBreadcrumb = styled.div`
  font-size: 16px;
  padding: 30px 0;
  ol {
    margin-bottom: 0;
  }
  a {
    text-decoration: none;
  }
`;

export default StyleBreadcrumb;
