import React from 'react';

import SearchLicenses from '../../../components/searchLicenses/SearchLicenses';
import SearchLicencesProvider from '../SearchLicensesContext';

function LicenseTable(props) {
  return (
    <SearchLicencesProvider componentProps={props}>
      <SearchLicenses />
    </SearchLicencesProvider>
  );
}

export default LicenseTable;
