import styled from 'styled-components';

const StyleCustomTab = styled.div`
  h6 {
    font-weight: 800;
  }
  .nav-pills .nav-item {
    .nav-link {
      padding: 0.9375rem 1rem;
      border-radius: 0;
      border-bottom: 1px solid ${props => props.theme?.general?.generalBorderColor} !important;
      color: ${props => props.theme?.general?.generalFontColor};
      font-size: 1rem;
      font-weight: 500;
      &.active,
      &:hover {
        background-color: #f0f0f0;
        opacity: 0.7;
      }
    }
  }
  .general-color-box {
    background: #f5fbfb;
    border: 1px solid #7ecdc4;
    padding: 1rem;
    @media (max-width: 575.98px) {
      .color-row {
        flex-flow: column;
      }
    }
    .left {
      height: 50px;
      width: 50px;
      border-radius: 5px;
      margin-right: 0.5rem;
      box-shadow: 0 3px 6px 2px rgba(161, 176, 205, 0.5);
      &.primary-color {
        background-color: #5dadae;
      }
      &.secondary-color {
        background-color: #fdc126;
      }
      &.links-color {
        background-color: #2d8eff;
      }
      &.background-color {
        background-color: #ffffff;
      }
      &.header-color {
        background-color: #000000;
      }
      &.general-text-color {
        background-color: #000000;
      }
      &.general-border-color {
        background-color: #dce2e9;
      }
    }
    small {
      display: block;
      color: #868686;
      min-width: 155px;
    }
    h6 {
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
`;

export default StyleCustomTab;
