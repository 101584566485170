/* eslint-disable no-restricted-properties */
/* eslint-disable prefer-exponentiation-operator */
/* eslint-disable prefer-template */
/* eslint-disable radix */
/* eslint-disable eqeqeq */
/* eslint-disable arrow-body-style */
import { LOGS_ENABLED } from '../config/config';
import StateHelper from '../helpers/stateHelper';

/* -------------------------------------------------------------------------- */
/*                                   String                                   */
/* -------------------------------------------------------------------------- */
/**
 * This function will return a given string after removing trailing slash
 * @param {String} value string for modify
 * @returns modified string
 */
const removeTrailingSlash = (value = '') => value.replace(/\/$/, '');

/**
 * This function will convert bytes and return a string with converted value and proper measuring unit
 * @param {Number} value string for modify
 * @returns modified string
 */
const bytesToSize = bytes => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes == 0 || !bytes) return 'N/A';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i == 0) return bytes + ' ' + sizes[i];
  return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
};

/**
 * This function will remove the timestamp return file name in string format
 * @param {Number} value string for modify
 * @returns modified string
 */
const removeTimestampFromFileName = name => {
  const timestampSplit = name.split('_');
  const extensionSplit = timestampSplit[timestampSplit.length - 1].split('.');
  const fileNameContent = timestampSplit.toSpliced(timestampSplit.length - 1);

  return timestampSplit.length > 1 ? `${fileNameContent.join('_')}.${extensionSplit[1]}` : name;
};

const replaceStrParameters = (value, parameters) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const param of Object.keys(parameters)) {
    value = value.replace('{{'.concat(param).concat('}}'), parameters[param]);
  }

  return value;
};

/* -------------------------------------------------------------------------- */
/*                                    Array                                   */
/* -------------------------------------------------------------------------- */
/**
 * This function will return true, when the given index is the last
 * @param {number} index current element array index
 * @param {number} length array length
 * @returns {boolean}
 */
const isLastElementInArray = (index = 0, length = 0) => length - 1 === index;

/**
 * This function will return new array after removing given element.
 * This will consider content of the given item to match correct element.
 * @param {Array} arr Array to remove element
 * @param {any} itemToRemove The item which needs to remove from the array
 * @returns {Array}
 */
const removeElementFromArrayConsideringData = (arr, itemToRemove) =>
  arr.filter(i => i !== itemToRemove);

/**
 * This function will return new array after adding given element.
 * @param {Array} arr Array to push new element
 * @param {*} itemToPush The item which needs to push to the array
 * @returns {Array}
 */
const pushToArray = (arr, itemToPush) => [...arr, itemToPush];

/* -------------------------------------------------------------------------- */
/*                                    Logs                                    */
/* -------------------------------------------------------------------------- */
/**
 * This function will return true, if REACT_APP_LOGS_ENABLED is true
 * @returns {Boolean}
 */
const isLogsEnabled = () => LOGS_ENABLED === 'true' || LOGS_ENABLED === true;

const filterStateName = stateAbbName => {
  const state = StateHelper.STATES.filter(item => {
    return item.label === stateAbbName;
  });
  return state[0].value || null;
};

export {
  removeTrailingSlash,
  bytesToSize,
  removeTimestampFromFileName,
  replaceStrParameters,
  isLastElementInArray,
  isLogsEnabled,
  removeElementFromArrayConsideringData,
  pushToArray,
  filterStateName,
};
