/* eslint-disable function-paren-newline */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unused-vars */
/* eslint-disable func-call-spacing */
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import moment from 'moment';

import { getUserLoginRecords, getCompanyList, getLicenseLogs } from '../../apis/reportsApi';
import ReportsHelper from '../../helpers/features/ReportsHelper';
import StateHelper from '../../helpers/stateHelper';

const ReportsContext = createContext();

function ReportProvider({ children }) {
  const currentUserPermissions = useSelector(state => state.AUTH.rolePermissions);

  // Report Type
  const [reportType, setReportType] = useState(children.props.reportType);

  // Login Data
  const [userLoginReportData, setUserLoingReportData] = useState([]);
  const [totalUserLoginRecords, setTotalUserLoginRecords] = useState(0);
  const [isLoadingUserLoginReportData, setIsLoadingUserLoginReportData] = useState(false);

  // Pagination
  const [currentPage, setCurrentPage] = useState(ReportsHelper.USER_LOGIN_RECORDS_STARTING_PAGE);
  const [nextPage, setNextPage] = useState(ReportsHelper.USER_LOGIN_RECORDS_STARTING_PAGE);
  const [totalPages, setTotalPages] = useState(0);

  // Sorting
  const [currentSortedTitle, setCurrentSortedTitle] = useState(
    reportType === 1
      ? ReportsHelper.DEFAULT_SORTING_PARAMETER_LICENSE
      : ReportsHelper.DEFAULT_SORTING_PARAMETER,
  );
  const [sortingOrder, setSortingOrder] = useState('asc');

  // Filters
  const [reordsPerPage, setReordsPerPage] = useState(
    ReportsHelper.USER_LOGIN_RECORDS_DEFAULT_LIMIT,
  );
  const [stateList, setStateList] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);

  // Search
  const [searchParameter, setSearchParameter] = useState('');

  // Admin Identifier
  const [isAdmin, setIsAdmin] = useState(false);

  // User Login Report Company filter
  const [companyList, setCompanyList] = useState([]);

  // User Login Report Date Filter
  const [filteringDate, setFilteringDate] = useState(null);
  const [sortObj, setSortObj] = useState({
    sort_by: ReportsHelper.DEFAULT_SORTING_PARAMETER_LICENSE,
    sort_order: 'asc',
  });
  /**
   * User dashboard
   */

  // Handle google map open
  const showOnMap = (address, lat, lang, zoom) => {
    window.open(
      `https://www.google.com/maps/search/${address
        .join(' ')
        .replace(/[^a-zA-Z0-9 ]/g, '')}/@${lat},${lang},${zoom}z`,
    );
  };

  // Fetch all companies
  const handleFetchCompanyData = async () => {
    const response = await getCompanyList();
    if (response.success && response.data) {
      setCompanyList(response.data);
    }
  };

  // Fetch total count and license data
  const handleFetchLicenseLogsData = async parameters => {
    setIsLoadingUserLoginReportData(true);
    setUserLoingReportData([]);
    const response = await getLicenseLogs(parameters);
    if (response.success && response.data) {
      setIsLoadingUserLoginReportData(false);
      setUserLoingReportData(response.data);
      if (response.data.meta) {
        setTotalUserLoginRecords(response.data.meta.totalRecords);
        setTotalPages(response.data.meta.totalPages);
      }
    }
  };

  // Fetch total count and license data
  const handleFetchUserLoginReportData = async parameters => {
    setIsLoadingUserLoginReportData(true);
    setUserLoingReportData([]);
    const response = await getUserLoginRecords(parameters);
    if (response.success && response.data) {
      setIsLoadingUserLoginReportData(false);
      setUserLoingReportData(response.data);
      // setUserLoingReportData([]);
      if (response.data.meta) {
        setTotalUserLoginRecords(response.data.meta.totalRecords);
        setTotalPages(response.data.meta.totalPages);
      }
    }
  };

  // Handle search reocrds
  const handleSearch = searchParam => {
    let parameters = {
      page: ReportsHelper.USER_LOGIN_RECORDS_STARTING_PAGE,
      limit: reordsPerPage,
    };

    const trimmedSearchParam = searchParam.trim();

    if (!isEmpty(selectedOption)) {
      const selectedStateValues = [];
      // eslint-disable-next-line array-callback-return
      selectedOption.map(state => {
        selectedStateValues.push({
          code: state.label,
          name: state.value,
        });
      });
      parameters = { ...parameters, state: selectedStateValues };
    }
    if (!isEmpty(sortingOrder)) {
      if (isArray(currentSortedTitle)) {
        parameters[sortingOrder] = currentSortedTitle;
      } else {
        parameters[sortingOrder] = [currentSortedTitle];
      }
    }

    if (filteringDate) {
      parameters = { ...parameters, date: moment(filteringDate).format('YYYY-MM-DD') };
    }

    if (searchParam) {
      parameters = { ...parameters, key: trimmedSearchParam };
      handleFetchUserLoginReportData(parameters);
      setSearchParameter(searchParam);
      setCurrentPage(1);
    } else {
      setCurrentPage(ReportsHelper.USER_LOGIN_RECORDS_STARTING_PAGE);
      setNextPage(0);
      setSearchParameter('');
      setCurrentPage(1);
      handleFetchUserLoginReportData(parameters);
    }
  };

  // Handle navigation between pages
  const handleReportTablePageNavigation = npage => {
    let parameters = {
      limit: reordsPerPage,
    };

    if (!isEmpty(selectedOption)) {
      const selectedStateValues = [];
      if (reportType === 1) {
        // eslint-disable-next-line array-callback-return
        selectedOption.map(state => {
          selectedStateValues.push({
            code: state.label,
            name: state.value,
          });
        });
        parameters = { ...parameters, state: selectedStateValues };
      } else {
        // eslint-disable-next-line array-callback-return
        // eslint-disable-next-line array-callback-return
        selectedOption.map(company => {
          selectedStateValues.push(company.value);
        });
        parameters = { ...parameters, company: selectedStateValues };
      }
    }

    if (!isEmpty(searchParameter)) {
      parameters = { ...parameters, key: searchParameter.trim() };
    }

    if (filteringDate) {
      parameters = { ...parameters, date: moment(filteringDate).format('YYYY-MM-DD') };
    }

    if (!isEmpty(sortingOrder)) {
      if (isArray(currentSortedTitle)) {
        parameters[sortingOrder] = currentSortedTitle;
      } else {
        parameters[sortingOrder] = [currentSortedTitle];
      }
    }

    if (npage !== currentPage && npage > currentPage) {
      setCurrentPage(npage);
      setNextPage(npage + 1);
      if (reportType === 1) {
        handleFetchLicenseLogsData({
          ...parameters,
          page: npage,
        });
      } else {
        handleFetchUserLoginReportData({
          ...parameters,
          page: npage,
        });
      }
    } else if (npage !== currentPage && npage < currentPage && currentPage > 0) {
      setCurrentPage(npage);
      setNextPage(npage + 1);
      if (reportType === 1) {
        handleFetchLicenseLogsData({
          ...parameters,
          page: npage,
        });
      } else {
        handleFetchUserLoginReportData({
          ...parameters,
          page: npage,
        });
      }
    }
  };

  // Handle sorting of the table
  const handleSortedData = async title => {
    let parameters = {};
    if (title.db_key === currentSortedTitle && sortingOrder === 'asc') {
      setSortingOrder('desc');
      parameters = isArray(title.db_key) ? { desc: title.db_key } : { desc: [title.db_key] };
    } else if (sortingOrder === 'desc') {
      setSortingOrder('asc');
      parameters = isArray(title.db_key) ? { asc: title.db_key } : { asc: [title.db_key] };
    } else {
      setSortingOrder('asc');
      parameters = isArray(title.db_key) ? { asc: title.db_key } : { asc: [title.db_key] };
    }

    if (!isEmpty(selectedOption)) {
      const selectedStateValues = [];
      // eslint-disable-next-line array-callback-return
      selectedOption.map(state => {
        selectedStateValues.push({
          code: state.label,
          name: state.value,
        });
      });
      parameters = { ...parameters, state: selectedStateValues };
    }

    if (!isEmpty(searchParameter)) {
      parameters = { ...parameters, key: searchParameter.trim() };
    }

    if (filteringDate) {
      parameters = { ...parameters, date: moment(filteringDate).format('YYYY-MM-DD') };
    }

    const queryParameters = {
      page: currentPage,
      limit: reordsPerPage,
      ...parameters,
    };
    if (reportType === 1) {
      let varSortObj;
      if (sortObj.sort_by === title.db_key && sortObj.sort_order === 'asc') {
        varSortObj = {
          sort_by: title.db_key,
          sort_order: 'desc',
        };
      } else {
        varSortObj = {
          sort_by: title.db_key,
          sort_order: 'asc',
        };
      }
      handleFetchLicenseLogsData({
        page: currentPage,
        limit: reordsPerPage,
        ...varSortObj,
      });
      setSortObj(varSortObj);
    } else {
      handleFetchUserLoginReportData(queryParameters);
    }
  };
  console.log(sortObj);
  // Handle reords per page filter
  const handleRecordsPerPage = records => {
    let params = {
      page: ReportsHelper.USER_LOGIN_RECORDS_STARTING_PAGE,
      limit: records,
    };

    if (!isEmpty(selectedOption)) {
      const selectedStateValues = [];
      // eslint-disable-next-line array-callback-return
      selectedOption.map(state => {
        selectedStateValues.push({
          code: state.label,
          name: state.value,
        });
      });
      params = { ...params, state: selectedStateValues };
    }

    if (!isEmpty(searchParameter)) {
      params = { ...params, key: searchParameter.trim() };
    }

    if (filteringDate) {
      params = { ...params, date: moment(filteringDate).format('YYYY-MM-DD') };
    }

    if (!isEmpty(sortingOrder)) {
      if (isArray(currentSortedTitle)) {
        params[sortingOrder] = currentSortedTitle;
      } else {
        params[sortingOrder] = [currentSortedTitle];
      }
    }

    setReordsPerPage(records);
    setCurrentPage(ReportsHelper.USER_LOGIN_RECORDS_STARTING_PAGE);
    setNextPage(0);
    if (reportType === 1) {
      handleFetchLicenseLogsData(params);
    } else {
      handleFetchUserLoginReportData(params);
    }
  };

  // Handle State Filter
  const handleStateFilter = selectedState => {
    const selectedStateValues = [];

    let params = {
      page: ReportsHelper.USER_LOGIN_RECORDS_STARTING_PAGE,
      limit: reordsPerPage,
    };

    // eslint-disable-next-line array-callback-return
    selectedState.map(state => {
      selectedStateValues.push(state.value);
    });

    if (!isEmpty(searchParameter)) {
      params = { ...params, key: searchParameter.trim() };
    }

    if (filteringDate) {
      params = { ...params, date: moment(filteringDate).format('YYYY-MM-DD') };
    }

    if (!isEmpty(sortingOrder)) {
      if (isArray(currentSortedTitle)) {
        params[sortingOrder] = currentSortedTitle;
      } else {
        params[sortingOrder] = [currentSortedTitle];
      }
    }

    if (isEmpty(selectedState)) {
      handleFetchLicenseLogsData(params);
    } else {
      params = { ...params, state: selectedStateValues };
      setCurrentPage(ReportsHelper.USER_LOGIN_RECORDS_STARTING_PAGE);
      setNextPage(0);
      handleFetchLicenseLogsData(params);
    }
  };

  const isValidState = state => {
    return StateHelper.STATE_SHORT.includes(state);
  };

  // Check user is admin
  const checkIsAdmin = () => {
    if (currentUserPermissions.includes(ReportsHelper.USER_PERMISSION_ROLE_ADMIN)) {
      setIsAdmin(true);
    }
  };

  // Handle Company Filter
  const handleCompanyFilter = selectedCompany => {
    const selectedCompanyValues = [];

    let params = {
      page: ReportsHelper.USER_LOGIN_RECORDS_STARTING_PAGE,
      limit: reordsPerPage,
    };

    // eslint-disable-next-line array-callback-return
    selectedCompany.map(company => {
      selectedCompanyValues.push(company.value);
    });

    if (!isEmpty(searchParameter)) {
      params = { ...params, key: searchParameter.trim() };
    }

    if (filteringDate) {
      params = { ...params, date: moment(filteringDate).format('YYYY-MM-DD') };
    }

    if (!isEmpty(sortingOrder)) {
      if (isArray(currentSortedTitle)) {
        params[sortingOrder] = currentSortedTitle;
      } else {
        params[sortingOrder] = [currentSortedTitle];
      }
    }

    if (isEmpty(selectedCompany)) {
      handleFetchUserLoginReportData(params);
    } else {
      params = { ...params, company: selectedCompanyValues };
      setCurrentPage(ReportsHelper.USER_LOGIN_RECORDS_STARTING_PAGE);
      setNextPage(0);
      handleFetchUserLoginReportData(params);
    }
  };

  // Handle Date Filter
  const handleDateFilter = selectedDate => {
    let params = {
      page: ReportsHelper.USER_LOGIN_RECORDS_STARTING_PAGE,
      limit: reordsPerPage,
    };

    if (!isEmpty(searchParameter)) {
      params = { ...params, key: searchParameter.trim() };
    }

    if (!isEmpty(sortingOrder)) {
      if (isArray(currentSortedTitle)) {
        params[sortingOrder] = currentSortedTitle;
      } else {
        params[sortingOrder] = [currentSortedTitle];
      }
    }

    if (isEmpty(selectedDate) || selectedDate === 'Invalid date') {
      if (reportType === 1) {
        handleFetchLicenseLogsData(params);
      } else {
        handleFetchUserLoginReportData(params);
      }
    } else {
      params = { ...params, date: selectedDate };
      setCurrentPage(ReportsHelper.USER_LOGIN_RECORDS_STARTING_PAGE);
      setNextPage(0);
      if (reportType === 1) {
        handleFetchLicenseLogsData(params);
      } else {
        handleFetchUserLoginReportData(params);
      }
    }
  };

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    handleFetchCompanyData();
    // eslint-disable-next-line no-unused-expressions
    reportType === 1
      ? handleFetchLicenseLogsData(ReportsHelper.GET_LICENSE_LOGS_DEFAULT_PARAMETERS)
      : handleFetchUserLoginReportData(ReportsHelper.GET_USER_LOGIN_RECORDS_DEFAULT_PARAMETERS);
    setSearchParameter('');
    setSelectedOption([]);
    setCurrentPage(ReportsHelper.USER_LOGIN_RECORDS_STARTING_PAGE);
    setNextPage(0);
    setReordsPerPage(ReportsHelper.USER_LOGIN_RECORDS_DEFAULT_LIMIT);
  }, []);

  // To check admin permissions
  useEffect(() => {
    if (!isEmpty(currentUserPermissions)) {
      checkIsAdmin();
    }
  }, [currentUserPermissions]);

  return (
    <ReportsContext.Provider
      value={{
        userLoginReportData,
        totalUserLoginRecords,
        handleSearch,
        handleReportTablePageNavigation,
        totalPages,
        currentPage,
        nextPage,
        setCurrentSortedTitle,
        handleSortedData,
        setCurrentPage,
        reordsPerPage,
        handleRecordsPerPage,
        isLoadingUserLoginReportData,
        handleStateFilter,
        stateList,
        setSelectedOption,
        selectedOption,
        setSearchParameter,
        searchParameter,
        isAdmin,
        showOnMap,
        currentSortedTitle,
        isValidState,
        handleCompanyFilter,
        companyList,
        filteringDate,
        setFilteringDate,
        handleDateFilter,
        setReportType,
        reportType,
      }}
    >
      {children}
    </ReportsContext.Provider>
  );
}

ReportProvider.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

export { ReportsContext };
export default ReportProvider;
