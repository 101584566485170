/* eslint-disable operator-linebreak */
/* eslint-disable max-len */
import React, { useContext } from 'react';

import BulkEditFormFeild from './BulkEditFormFeild';
import CommonModal from '../../common/modal/CommonModal';

import SearchLicensesHelper from '../../../helpers/features/SearchLicensesHelper';
import { SearchLicencesContext } from '../../../containers/searchLicenses/SearchLicensesContext';

function BulkEditModal() {
  const { setIsBulkEditModalOpen, isSaving, setIsSaving } = useContext(SearchLicencesContext);

  // Main Hader
  return (
    <CommonModal
      className=""
      size="lg"
      title={
        isSaving
          ? SearchLicensesHelper.BULK_EDIT_CONFIRM_CHANGES_HEADER
          : SearchLicensesHelper.LICENSE_BULK_EDIT_MODAL_HEADER
      }
      handleClose={() => {
        setIsSaving(false);
        setIsBulkEditModalOpen(false);
      }}
    >
      <BulkEditFormFeild />
    </CommonModal>
  );
}

export default BulkEditModal;
