/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import User from '../../../assets/images/user.svg';
import { useAuth } from '../../../containers/auth/AuthContext';

import { AppNav } from '../../layouts/header/Header.styled';

function UserAction() {
  const auth = useAuth();

  const logout = () => {
    auth.handleLogout();
  };

  return (
    <AppNav>
      <Link to="/my-profile">
        <Image src={User} alt="User Accout" className="me-2" />
      </Link>
      <button type="button" onClick={logout}>
        Logout
      </button>
    </AppNav>
  );
}

export default UserAction;
