/* eslint-disable no-nested-ternary */

import React, { useContext } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import uniqueId from 'lodash/uniqueId';
import isNull from 'lodash/isNull';

import EntryCountCard from './entryCountCard/EntryCountCard';
import HistoryListRow from './HistoryListRow';
import { StylePage } from '../../styles/Page.styled';
import StyleForm from '../../common/input/Form.styled';
import PageHeader from '../../common/pageHeader/PageHeader';
import PaginationDropdown from '../../common/input/PaginationDropdown';
import PaginationComponent from '../../common/pagination/Pagination';
import TableComponent from '../../common/table/Table';
import TableHeader from '../../common/table/TableHeader';
import SingleTableHeader from '../../common/table/SingleTableHeader';
import TableBody from '../../common/table/TableBody';
import Search from '../../common/search/Search';

import { ExecutionHistoryContext } from '../../../containers/dataValidation/executionHistory/ExecutionHistoryContext';
import DataValidationHelper from '../../../helpers/features/DataValidationHelper';

import FilterIcon from '../../../assets/images/filter.svg';
import LoadingIcon from '../../../assets/images/spinner.gif';
import CalendarIcon from '../../../assets/images/calender-icon.svg';
import PendingIcon from '../../../assets/images/pending-approvals.svg';
import SuccessfulIcon from '../../../assets/images/successful-approvals.svg';
import RejectedIcon from '../../../assets/images/rejected.svg';

const HistoryListView = () => {
  const {
    totalPages,
    totalRecords,
    recordsPerPage,
    currentPage,
    nextPage,
    currentSortedTitle,
    setCurrentSortedTitle,
    selectedStatusFilter,
    handleStatusFilterChange,
    selectedFromDateFilter,
    handleSelectedFromDateFilter,
    selectedToDateFilter,
    handleSelectedToDateFilter,
    handleRecordsPerPage,
    handleTablePageNavigation,
    handleFetchSortedHistoryEntries,
    handleSearch,
    searchParameter,
    setSearchParameter,
    historyTableData,
    entryCounts,
    isLoadingHistoryTableData,
    handleEntryCardClick,
  } = useContext(ExecutionHistoryContext);

  const { PAGE_HEADER, HISTORY_TABLE } = DataValidationHelper.HISTORY_LIST;

  return (
    <Container>
      <StylePage>
        <Row>
          <Col md={12}>
            <PageHeader title={PAGE_HEADER} subheader={`${totalRecords} Records`} />
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col md={4}>
            <EntryCountCard
              icon={PendingIcon}
              title="Pending Executions"
              count={entryCounts.pending}
              handleClick={() => handleEntryCardClick('pending')}
              isActive={selectedStatusFilter?.value === 0}
            />
          </Col>
          <Col md={4}>
            <EntryCountCard
              icon={SuccessfulIcon}
              title="Successful Executions"
              count={entryCounts.approved}
              handleClick={() => handleEntryCardClick('approved')}
              isActive={selectedStatusFilter?.value === 1}
            />
          </Col>
          <Col md={4}>
            <EntryCountCard
              icon={RejectedIcon}
              title="Rejected Executions"
              count={entryCounts.rejected}
              handleClick={() => handleEntryCardClick('rejected')}
              isActive={selectedStatusFilter?.value === 2}
            />
          </Col>
        </Row>
        <Row className="d-flex align-items-center">
          <Col md={2} className="mb-3">
            <StyleForm className="state-dropdown">
              <Image src={FilterIcon} />
              <Select
                className="single-select"
                options={HISTORY_TABLE.STATUS_FILTER_OPTIONS}
                onChange={value => {
                  handleStatusFilterChange(value);
                }}
                value={selectedStatusFilter}
                placeholder="Status"
                isSearchable
                isClearable
                closeMenuOnSelect
                hideSelectedOptions
              />
            </StyleForm>
          </Col>
          <Col md={2} className="admin-sarch">
            <Search
              customClass="admin-search"
              handleSearch={handleSearch}
              setSearchParameter={setSearchParameter}
              searchParameter={searchParameter}
              placeholder="Source Identifier"
            />
          </Col>
          <Col md={2} className="mb-3">
            <StyleForm>
              <div className="admin-filter-datepicke">
                <DatePicker
                  className="form-control"
                  format="yyyy/MM/dd"
                  onKeyDown={e => {
                    e.preventDefault();
                  }}
                  placeholderText="From Date"
                  selected={selectedFromDateFilter}
                  onChange={date => {
                    handleSelectedFromDateFilter(date);
                  }}
                  isClearable
                />
                {isNull(selectedFromDateFilter) && <Image src={CalendarIcon} />}
              </div>
            </StyleForm>
          </Col>
          <Col md={2} className="mb-3">
            <StyleForm>
              <div className="admin-filter-datepicke">
                <DatePicker
                  className="form-control"
                  format="yyyy/MM/dd"
                  onKeyDown={e => {
                    e.preventDefault();
                  }}
                  placeholderText="To Date"
                  selected={selectedToDateFilter}
                  onChange={date => {
                    handleSelectedToDateFilter(date);
                  }}
                  minDate={selectedFromDateFilter}
                  isClearable
                />
                {isNull(selectedToDateFilter) && <Image src={CalendarIcon} />}
              </div>
            </StyleForm>
          </Col>
          <Col md={4} className="mb-3 d-flex justify-content-end">
            <PaginationDropdown
              selectedOption={recordsPerPage}
              handleRecordsPerPage={handleRecordsPerPage}
            />
            {totalPages > 1 && (
              <PaginationComponent
                totalPages={totalPages}
                currentPage={currentPage}
                handleTablePageNavigation={handleTablePageNavigation}
                nextPage={nextPage}
              />
            )}
          </Col>
        </Row>
        <TableComponent className="admin-licenes">
          <TableHeader>
            {HISTORY_TABLE.HEADERS.map(header => {
              return (
                <SingleTableHeader
                  title={header}
                  key={uniqueId()}
                  currentSortedTitle={currentSortedTitle}
                  setCurrentSortedTitle={setCurrentSortedTitle}
                  handleFetchSortedData={handleFetchSortedHistoryEntries}
                />
              );
            })}
          </TableHeader>
          <TableBody>
            {isLoadingHistoryTableData ? (
              <tr className="loding-data">
                <td colSpan="24">
                  <Image src={LoadingIcon} sizes="50px" />
                </td>
              </tr>
            ) : historyTableData?.length > 0 ? (
              historyTableData.map(item => <HistoryListRow data={item} />)
            ) : (
              <tr className="without-data">
                <td colSpan="24">
                  <p>{HISTORY_TABLE.NO_DATA_TEXT}</p>
                </td>
              </tr>
            )}
          </TableBody>
        </TableComponent>
      </StylePage>
    </Container>
  );
};

export default HistoryListView;
