import React from 'react';
import SettingsContextProvider from './SettingsContext';
import SettingsPageMain from '../../components/settings/SettingsPageMain';

export default function Settings(props) {
  return (
    <SettingsContextProvider componentProps={props}>
      <SettingsPageMain />
    </SettingsContextProvider>
  );
}
