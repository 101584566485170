const SearchLicenseHelper = Object.freeze({
  HEMP_LICENSES_TABLE_HEADERS: [
    { label: 'License Number', key: 1, db_key: 'license_number' },
    { label: 'Legal Name', key: 2, db_key: 'legal_name' },
    { label: 'DBA', key: 3, db_key: 'dba_name' },
    { label: 'Location Address', key: 4, db_key: ['street', 'city'] },
    { label: 'License Issuer', key: 5, db_key: 'license_issuer' },
    { label: 'License Type', key: 6, db_key: 'license_type' },
    { label: 'License Status', key: 7, db_key: 'status' },
    { label: '', key: 8 },
  ],
  HEMP_LICENSES_ADMIN_TABLE_HEADERS: [
    { label: 'License Number', key: 1, db_key: 'license_number' },
    { label: 'Legal Name', key: 2, db_key: 'legal_name' },
    { label: 'DBA', key: 3, db_key: 'dba_name' },
    { label: 'Street', key: 4, db_key: 'street' },
    { label: 'City', key: 5, db_key: 'city' },
    { label: 'State', key: 6, db_key: 'state' },
    { label: 'State Name', key: 7, db_key: 'state_name' },
    { label: 'Zip', key: 8, db_key: 'zip' },
    { label: 'Country', key: 9, db_key: 'country_name' },
    { label: 'Country Code', key: 10, db_key: 'country_code' },
    { label: 'License Issuer', key: 11, db_key: 'license_issuer' },
    { label: 'License Type', key: 12, db_key: 'license_type' },
    { label: 'License Details', key: 13, db_key: 'license_details' },
    { label: 'License Designation', key: 14, db_key: 'license_designation' },
    { label: 'License Issue Date', key: 15, db_key: 'license_issue_date' },
    { label: 'License Expiration Date', key: 16, db_key: 'license_expiration_date' },
    { label: 'County Name', key: 17, db_key: 'county_name' },
    { label: 'Parent Name', key: 19, db_key: 'parent_name' },
    { label: 'Company Website', key: 20, db_key: 'company_website' },
    { label: 'Company Contact Name', key: 21, db_key: 'company_contact_name' },
    { label: 'Company Email', key: 22, db_key: 'company_email' },
    { label: 'Company Phone', key: 23, db_key: 'company_phone' },
    { label: 'Notes', key: 24, db_key: 'note' },
    { label: 'Last updated', key: 25, db_key: ['updated_at'] },
    { label: 'License Status', key: 26, db_key: 'status' },
    { label: '', key: 27 },
  ],
  PAGE_HEADER: 'Hemp Licenses',
  GET_LICENSE_RECORDS_DEFAULT_PARAMETERS: {
    page: 1,
    limit: 10,
    asc: ['legal_name'],
  },
  NO_VALUES: '-',
  NO_VALUES_EDIT: '',
  LICENSE_DETAILS_LABLES: [
    { LABEL: 'License Number', VALUE: 1 },
    { LABEL: 'License Issuer', VALUE: 2 },
    { LABEL: 'License Designation', VALUE: 3 },
    { LABEL: 'License Type', VALUE: 4 },
    { LABEL: 'License Details', VALUE: 5 },
    { LABEL: 'License Issue Date', VALUE: 6 },
    { LABEL: 'License Expiration Date', VALUE: 7 },
    { LABEL: 'License Status', VALUE: 8 },
    { LABEL: 'Legal Name', VALUE: 9 },
    { LABEL: 'DBA Name', VALUE: 10 },
    { LABEL: 'Location Address', VALUE: 11 },
    { LABEL: 'Company Contact', VALUE: 12 },
    { LABEL: 'Company Email', VALUE: 13 },
    { LABEL: 'Company Phone', VALUE: 14 },
    { LABEL: 'Company Website', VALUE: 15 },
    { LABEL: 'County Name', VALUE: 16 },
    { LABEL: 'State Name', VALUE: 17 },
    { LABEL: 'Country Code', VALUE: 18 },
    { LABEL: 'Country Name', VALUE: 19 },
    { LABEL: 'Last Updated', VALUE: 20 },
    { LABEL: 'Notes', VALUE: 21 },
  ],
  LICENSE_DETAILS_PAGE_COLUMN_COUNT: 12,
  LICENSE_RECORDS_STARTING_PAGE: 1,
  LICENSE_RECORDS_DEFAULT_LIMIT: 10,
  RECORDS_PER_PAGES_VALUES: [
    { value: 10, key: 1 },
    { value: 20, key: 2 },
    { value: 50, key: 3 },
    { value: 100, key: 4 },
  ],
  DEFAULT_SORTING_PARAMETER: 'legal_name',
  INLINE_SAVE_CONFIRMATION_ALERT_TITLE: 'Confirm Update',
  INLINE_SAVE_EDIT_MODAL_TITLE: 'Edit Note',
  INLINE_SAVE_EDIT_MODAL_LABEL: 'Note',
  INLINE_SAVE_EDIT_CHAR_LIMIT: 2000,
  INLINE_SAVE_LICENSE_DETAILS_MODAL_TITLE: 'Edit License Details',
  INLINE_SAVE_LICENSE_DETAILS_MODAL_LABEL: 'License Details',
  INLINE_SAVE_LICENSE_DETAILS_CHAR_LIMIT: 200,
  INLINE_SAVE_CONFIRMATION_ALERT_DESCRIPTION: 'Are you sure you want to update this record?',
  INLINE_SAVE_CONFIRMATION_ALERT_TOST_SUCCESS: 'Success! This record has been successfully updated',
  INLINE_SAVE_CONFIRMATION_ALERT_TOST_SUCCESS_BUTTON: 'Yes, Confirm',
  INLINE_SAVE_CONFIRMATION_ALERT_TOST_CANCEL_BUTTON: 'No, Cancel',
  INLINE_DELETE_CONFIRMATION_ALERT_DESCRIPTION: 'Are you sure you want to delete this record?',
  INLINE_DELETE_CONFIRMATION_ALERT_DESCRIPTION_BULK:
    'Are you sure you want to delete these records?',
  INLINE_DELETE_CONFIRMATION_ALERT_TOST_SUCCESS: 'Record deleted successfully',
  INLINE_DELETE_CONFIRMATION_ALERT_TOST_SUCCESS_BUTTON: 'Yes, Delete',
  INLINE_DELETE_CONFIRMATION_ALERT_TOST_CANCEL_BUTTON: 'No, Cancel',
  USER_PERMISSION_ROLE_ADMIN: 'ADMIN',
  INLINE_SAVE_VALIDATION_TEXT_ONLY_FEILDS: ['city', 'country_name', 'county_name', 'state_name'],
  INLINE_SAVE_VALIDATION_LONG_TEXT_FEILDS: ['note', 'license_details'],
  BULK_OPERATIONS_HEADER_TEXT_EDIT: 'Edit',
  BULK_OPERATIONS_HEADER_TEXT_DELETE: 'Delete',
  LICENSE_DETAILS_MODAL_TITLE: 'License Details',
  LICENSE_EDIT_MODAL_TITLE: 'Edit License',
  LICENSE_BULK_EDIT_MODAL_HEADER: 'Edit Columns',
  LICENSE_BULK_EDIT_SUCCESS_TOAST: 'Records updated successfully',
  LICENSE_BULK_DELETE_SUCCESS_TOAST: 'Records deleted successfully',
  COOMON_BACKEND_ERROR_TOATS: 'Something Went Wrong!',
  TOST_TYPE_SUCCESS: 'success',
  TOST_TYPE_ERROR: 'error',
  BULK_EDIT_CONFIRM_CHANGES_HEADER: 'Confirm Changes',
  BULK_EDIT_CONFIRM_CHGANGES_MESSAGE_END: 'Do you want to continue?',
  NO_RECORDS_FOUND: 'No Records Found',
  FIELD_TEXT: 'Field',
  VALUES_TEXT: 'Values',
  CANCEL_TEXT: 'Cancel',
  BACK_TEXT: 'Back',
  MAKE_CHANGES_TEXT: 'Make Changes',
  ADD_NEW_FEILD_TEXT: 'Add New Field',
  BULK_EDIT_MODAL_WARNING: 'Please note that this will effect all the rows of data selected',
  BULK_EDIT_MODAL_EMPTY_VALUES_ERROR: 'Empty values are not allowed to submit. Please add a value',
  BULK_EDIT_MODAL_EMPTY_FIELDS_ERROR: 'Please select a field',
  HEMP_LICENSES_ADMIN_EDITABLE_FEILDS: [
    { label: 'License Number', value: 'License Number', key: 1, db_key: 'license_number' },
    { label: 'Legal Name', value: 'Legal Name', key: 2, db_key: 'legal_name' },
    { label: 'DBA', value: 'License Number', key: 3, db_key: 'dba_name' },
    { label: 'Street', value: 'DBA', key: 4, db_key: 'street' },
    { label: 'City', value: 'City', key: 5, db_key: 'city' },
    { label: 'State', value: 'State', key: 6, db_key: 'state' },
    { label: 'State Name', value: 'State Name', key: 7, db_key: 'state_name' },
    { label: 'Zip', value: 'Zip', key: 8, db_key: 'zip' },
    { label: 'Country', value: 'Country', key: 9, db_key: 'country_name' },
    { label: 'Country Code', value: 'Country Code', key: 10, db_key: 'country_code' },
    { label: 'License Issuer', value: 'License Issuer', key: 11, db_key: 'license_issuer' },
    { label: 'License Type', value: 'License Type', key: 12, db_key: 'license_type' },
    { label: 'License Details', value: 'License Details', key: 13, db_key: 'license_details' },
    {
      label: 'License Designation',
      value: 'License Designation',
      key: 14,
      db_key: 'license_designation',
    },
    {
      label: 'License Issue Date',
      value: 'License Issue Date',
      key: 15,
      db_key: 'license_issue_date',
    },
    {
      label: 'License Expiration Date',
      value: 'License Expiration Date',
      key: 16,
      db_key: 'license_expiration_date',
    },
    { label: 'County Name', value: 'County Name', key: 17, db_key: 'county_name' },
    { label: 'Parent Name', value: 'Parent Name', key: 19, db_key: 'parent_name' },
    { label: 'Company Website', value: 'Company Website', key: 20, db_key: 'company_website' },
    {
      label: 'Company Contact Name',
      value: 'Company Contact Name',
      key: 21,
      db_key: 'company_contact_name',
    },
    { label: 'Company Email', value: 'Company Email', key: 22, db_key: 'company_email' },
    { label: 'Company Phone', value: 'Company Phone', key: 23, db_key: 'company_phone' },
    { label: 'Notes', value: 'Notes', key: 24, db_key: 'note' },
    { label: 'License Status', value: 'License Status', key: 25, db_key: 'status' },
  ],
  HEMP_LICENSES_ADMIN_BULK_EDITABLE_FEILDS: [
    // { label: 'License Number', value: 'License Number', key: 1, db_key: 'license_number' },
    { label: 'Legal Name', value: 'Legal Name', key: 2, db_key: 'legal_name' },
    { label: 'DBA', value: 'License Number', key: 3, db_key: 'dba_name' },
    { label: 'Street', value: 'DBA', key: 4, db_key: 'street' },
    { label: 'City', value: 'City', key: 5, db_key: 'city' },
    { label: 'State', value: 'State', key: 6, db_key: 'state' },
    { label: 'State Name', value: 'State Name', key: 7, db_key: 'state_name' },
    { label: 'Zip', value: 'Zip', key: 8, db_key: 'zip' },
    { label: 'Country', value: 'Country', key: 9, db_key: 'country_name' },
    { label: 'Country Code', value: 'Country Code', key: 10, db_key: 'country_code' },
    { label: 'License Issuer', value: 'License Issuer', key: 11, db_key: 'license_issuer' },
    { label: 'License Type', value: 'License Type', key: 12, db_key: 'license_type' },
    { label: 'License Details', value: 'License Details', key: 13, db_key: 'license_details' },
    {
      label: 'License Designation',
      value: 'License Designation',
      key: 14,
      db_key: 'license_designation',
    },
    {
      label: 'License Issue Date',
      value: 'License Issue Date',
      key: 15,
      db_key: 'license_issue_date',
    },
    {
      label: 'License Expiration Date',
      value: 'License Expiration Date',
      key: 16,
      db_key: 'license_expiration_date',
    },
    { label: 'County Name', value: 'County Name', key: 17, db_key: 'county_name' },
    { label: 'Parent Name', value: 'Parent Name', key: 19, db_key: 'parent_name' },
    { label: 'Company Website', value: 'Company Website', key: 20, db_key: 'company_website' },
    {
      label: 'Company Contact Name',
      value: 'Company Contact Name',
      key: 21,
      db_key: 'company_contact_name',
    },
    { label: 'Company Email', value: 'Company Email', key: 22, db_key: 'company_email' },
    { label: 'Company Phone', value: 'Company Phone', key: 23, db_key: 'company_phone' },
    { label: 'Notes', value: 'Notes', key: 24, db_key: 'note' },
    { label: 'License Status', value: 'License Status', key: 25, db_key: 'status' },
  ],
  SUCCESS_TOAST_TEXT: 'SUCCESS',
  ERROR_TOAST_TEXT: 'ERROR',
  INPUT_TYPE_TEXT: 'text',
  INPUT_TYPE_NUMBER: 'number',
  INLINE_EDIT_UPDATE_BUTTON_TEXT: 'Update',
  INLINE_EDIT_CANCEL_BUTTON_TEXT: 'Cancel',
  INLINE_EDIT_CHAR_LIMIT_ERRORR_TEXT: 'You have reached the character limit',
  INLINE_EDIT_ON_PASTE_CHAR_LIMIT_ERRORR_TEXT: 'You have exceeded the character limit',
  COUNTRY_CODES: [{ label: 'US', value: 'US', key: 1 }],
  DATE_SELECTION_ERROR_INLINE_EDIT: 'You can not select date prior to license issue date',
  AVAILABLE_COUNTRIES: [
    { label: 'United States of America', value: 'United States of America', key: 1 },
  ],
  MANDATORY_FEILD_ERROR_ALERT:
    'To add a new record, please enter a value for one or more of the following fields: Legal Name, License Issuer, License Type, License Details, DBA Name, City, County, and State Name.',
  MANDATORY_FEILD_EDIT_ERROR_ALERT:
    'To update record, please enter a value for one or more of the following fields: Legal Name, License Issuer, License Type, License Details, DBA Name, City, County, and State Name.',
  BULK_UPLOAD_FAILURE_REPORT_INVALID_USER_MESSAGE:
    'This user can not download this file. Please login with previladged user.',
  BULK_UPLOAD_FAILURE_REPORT_TITLE: 'Import Failure Report',
});

export default SearchLicenseHelper;
