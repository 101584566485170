import React, { useContext } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import CommonModal from '../common/modal/CommonModal';
import { UsersContext } from '../../containers/users/UsersContext';
import { UsersHelper } from '../../helpers/usersHelper';
import AppButton from '../styles/ButtonLink.styled';
import DeleteIcon from '../../assets/images/delete-icon.svg';

export const DeleteUserModal = () => {
  const { isDeleteUserModalOpen, setDeleteUserModalOpen, handleDeleteUser } =
    useContext(UsersContext);
  return (
    <SweetAlert
      custom
      showCancel
      showCloseButton
      confirmBtnText={UsersHelper.LABELS.DELETE_USER_CONFIRM_BTN}
      confirmBtnCssClass="popup"
      cancelBtnText={UsersHelper.LABELS.DELETE_USER_CANCEL_BTN}
      cancelBtnCssClass="popup cancel"
      confirmBtnBsStyle="danger"
      customIcon={DeleteIcon}
      show={isDeleteUserModalOpen.isOpen}
      title={UsersHelper.LABELS.DELETE_USER_BODY_TEXT}
      onConfirm={() => {
        handleDeleteUser();
      }}
      onCancel={() => setDeleteUserModalOpen({ id: null, isOpen: false })}
    />
  );
};
