import styled from 'styled-components';

const StyleLogin = styled.div`
  img {
    margin-bottom: 25px;
  }
  h1 {
    font-size: 35px;
    font-weight: 900;
    margin-bottom: 11px;
  }
  p {
    font-size: 15px;
    font-weight: 400;
  }
  .form-group {
    padding-bottom: 5px;
  }
  div.password-reset {
    text-align: right;
    margin-top: 25px;
  }
  .confirm-password {
    margin-top: 25px;
  }
`;
export default StyleLogin;
