import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import CommonModal from '../../common/modal/CommonModal';

import 'react-circular-progressbar/dist/styles.css';

export const BulkUploadSuccessModal = ({ setBulkUploadSuccessModalOpen, bulkUploadProgress }) => {
  return (
    <CommonModal
      className=""
      size="lg"
      title=""
      handleClose={() => setBulkUploadSuccessModalOpen(false)}
    >
      <div style={{ width: 100, height: 100, margin: 'auto' }}>
        <CircularProgressbar
          value={bulkUploadProgress.progress}
          text={`${bulkUploadProgress.progress}%`}
          styles={buildStyles({
            pathColor: `rgba(93, 173, 174, ${bulkUploadProgress.progress / 100})`,
            textColor: '#4ab1af',
          })}
        />
      </div>
      {bulkUploadProgress.progress === 100 && (
        <>
          <div
            style={{
              textAlign: 'center',
              marginTop: '30px',
              fontSize: '18px',
              fontWeight: 400,
            }}
          >
            Complete
          </div>
          <div
            style={{
              textAlign: 'center',
              marginTop: '10px',
              fontSize: '20px',
              fontWeight: 700,
            }}
          >
            {bulkUploadProgress.message}
          </div>
        </>
      )}
    </CommonModal>
  );
};
