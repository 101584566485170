import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import authReducer from './reducers/auth';
import commonReducer from './reducers/common';
import dataValidationReducer from './reducers/dataValidation';
import reducerTypes from './reducerTypes';

const rootReducer = {};

const dataValidationPersistConfig = {
  key: 'dataValidation',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['dataValidationTableFilters', 'entryDetailsTableFilters'],
};

rootReducer[reducerTypes.auth] = authReducer;
rootReducer[reducerTypes.common] = commonReducer;
rootReducer[reducerTypes.dataValidation] = persistReducer(
  dataValidationPersistConfig,
  dataValidationReducer,
);

export default combineReducers(rootReducer);
