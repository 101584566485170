/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import React, { useContext, useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { SearchLicencesContext } from '../../../containers/searchLicenses/SearchLicensesContext';
import DataPreview from '../../common/dataPreview/DataPreview';
import SearchLicensesHelper from '../../../helpers/features/SearchLicensesHelper';
import { formatAddress, formatPhoneNumber } from '../../../helpers/commonHelper';

function DetailsView() {
  const { selectedRow, handleFetchSingleLicenseData, singleLicenseData, showOnMap } =
    useContext(SearchLicencesContext);
  const { id } = useParams();

  // eslint-disable-next-line no-unused-vars
  const [selectedRowData, setSelectedRowData] = useState(selectedRow);

  useEffect(() => {
    if (isEmpty(selectedRow)) {
      handleFetchSingleLicenseData(id);
    }
  }, []);

  return (
    <div>
      <Row>
        <Col md={4}>
          <DataPreview
            label={SearchLicensesHelper.LICENSE_DETAILS_LABLES[0].LABEL}
            data={
              selectedRowData.license_number ||
              singleLicenseData.license_number ||
              SearchLicensesHelper.NO_VALUES
            }
            colcount={SearchLicensesHelper.LICENSE_DETAILS_PAGE_COLUMN_COUNT}
          />
          <DataPreview
            label={SearchLicensesHelper.LICENSE_DETAILS_LABLES[1].LABEL}
            data={
              selectedRowData.license_issuer ||
              singleLicenseData.license_issuer ||
              SearchLicensesHelper.NO_VALUES
            }
          />
          <DataPreview
            label={SearchLicensesHelper.LICENSE_DETAILS_LABLES[3].LABEL}
            data={
              selectedRowData.license_type ||
              singleLicenseData.license_type ||
              SearchLicensesHelper.NO_VALUES
            }
          />
          <DataPreview
            label={SearchLicensesHelper.LICENSE_DETAILS_LABLES[4].LABEL}
            data={
              selectedRowData.license_details ||
              singleLicenseData.license_details ||
              SearchLicensesHelper.NO_VALUES
            }
            isLicenseDetails
          />
          <DataPreview
            label={SearchLicensesHelper.LICENSE_DETAILS_LABLES[5].LABEL}
            data={
              selectedRowData.license_issue_date ||
              singleLicenseData.license_issue_date ||
              SearchLicensesHelper.NO_VALUES
            }
          />
          <DataPreview
            label={SearchLicensesHelper.LICENSE_DETAILS_LABLES[6].LABEL}
            data={
              selectedRowData.license_expiration_date ||
              singleLicenseData.license_expiration_date ||
              SearchLicensesHelper.NO_VALUES
            }
          />
          <DataPreview
            label={SearchLicensesHelper.LICENSE_DETAILS_LABLES[7].LABEL}
            data={
              selectedRowData.status || singleLicenseData.status || SearchLicensesHelper.NO_VALUES
            }
          />
        </Col>
        <Col md={4}>
          <DataPreview
            label={SearchLicensesHelper.LICENSE_DETAILS_LABLES[8].LABEL}
            data={
              selectedRowData.legal_name ||
              singleLicenseData.legal_name ||
              SearchLicensesHelper.NO_VALUES
            }
          />
          <DataPreview
            label={SearchLicensesHelper.LICENSE_DETAILS_LABLES[9].LABEL}
            data={
              selectedRowData.dba_name ||
              singleLicenseData.dba_name ||
              SearchLicensesHelper.NO_VALUES
            }
          />
          <DataPreview
            label={SearchLicensesHelper.LICENSE_DETAILS_LABLES[10].LABEL}
            data={
              !isEmpty(selectedRowData.city || singleLicenseData.city) ||
              !isEmpty(selectedRowData.zip || singleLicenseData.zip) ||
              isEmpty(selectedRowData.state || singleLicenseData.state)
                ? formatAddress(
                    selectedRowData.city || singleLicenseData.city,
                    selectedRowData.state || singleLicenseData.state,
                    selectedRowData.zip || singleLicenseData.zip,
                  )
                : SearchLicensesHelper.NO_VALUES
            }
            addressLineOne={selectedRowData.street || singleLicenseData.street}
            onClickPreview={() => {
              if (
                !isEmpty(selectedRowData.city || singleLicenseData.city) ||
                !isEmpty(selectedRowData.zip || singleLicenseData.zip)
              ) {
                showOnMap([
                  selectedRowData.street || singleLicenseData.street,
                  selectedRowData.city || singleLicenseData.city,
                  selectedRowData.state || singleLicenseData.state,
                  selectedRowData.zip || singleLicenseData.zip,
                ]);
              }
            }}
            isAddress
          />
          <DataPreview
            label={SearchLicensesHelper.LICENSE_DETAILS_LABLES[11].LABEL}
            data={
              selectedRowData.company_contact_name ||
              singleLicenseData.company_contact_name ||
              SearchLicensesHelper.NO_VALUES
            }
          />
          <DataPreview
            label={SearchLicensesHelper.LICENSE_DETAILS_LABLES[12].LABEL}
            data={
              selectedRowData.company_email ||
              singleLicenseData.company_email ||
              SearchLicensesHelper.NO_VALUES
            }
          />
          <DataPreview
            label={SearchLicensesHelper.LICENSE_DETAILS_LABLES[13].LABEL}
            data={
              formatPhoneNumber(selectedRowData.company_phone || singleLicenseData.company_phone) ||
              SearchLicensesHelper.NO_VALUES
            }
          />
          <DataPreview
            label={SearchLicensesHelper.LICENSE_DETAILS_LABLES[14].LABEL}
            data={
              selectedRowData.company_website ||
              singleLicenseData.company_website ||
              SearchLicensesHelper.NO_VALUES
            }
          />
        </Col>
        <Col md={4}>
          <DataPreview
            label={SearchLicensesHelper.LICENSE_DETAILS_LABLES[15].LABEL}
            data={
              selectedRowData.county_name ||
              singleLicenseData.county_name ||
              SearchLicensesHelper.NO_VALUES
            }
          />
          <DataPreview
            label={SearchLicensesHelper.LICENSE_DETAILS_LABLES[16].LABEL}
            data={
              selectedRowData.state_name ||
              singleLicenseData.state_name ||
              SearchLicensesHelper.NO_VALUES
            }
          />
          <DataPreview
            label={SearchLicensesHelper.LICENSE_DETAILS_LABLES[17].LABEL}
            data={
              selectedRowData.country_code ||
              singleLicenseData.country_code ||
              SearchLicensesHelper.NO_VALUES
            }
          />
          <DataPreview
            label={SearchLicensesHelper.LICENSE_DETAILS_LABLES[18].LABEL}
            data={
              selectedRowData.country_name ||
              singleLicenseData.country_name ||
              SearchLicensesHelper.NO_VALUES
            }
          />
          <DataPreview
            label={SearchLicensesHelper.LICENSE_DETAILS_LABLES[19].LABEL}
            data={
              selectedRowData.last_update ||
              selectedRowData.updated_at ||
              singleLicenseData.last_update ||
              singleLicenseData.updated_at ||
              SearchLicensesHelper.NO_VALUES
            }
          />
        </Col>
      </Row>
      <div className="mt-4">
        <DataPreview
          label={SearchLicensesHelper.LICENSE_DETAILS_LABLES[20].LABEL}
          data={selectedRowData.note || singleLicenseData.note || SearchLicensesHelper.NO_VALUES}
          isNote
        />
      </div>
    </div>
  );
}

export default DetailsView;
