export default Object.freeze({
  UN_AUTHENTICATED: {
    LOGIN: {
      PATH: 'login',
    },
    LOGIN_REDIRECT: {
      PATH: 'not-found',
    },
    FORGET_PASSWORD: {
      PATH: 'forgot-password',
    },
    RESET_PASSWORD: {
      PATH: 'reset-password/:code',
    },
    SETUP_PASSWORD: {
      PATH: 'setup-account/set-password/:code',
    },
  },
  AUTHENTICATED: {
    ROOT: {
      PATH: '/',
    },
    SEARCH_LICENSES: {
      PATH: 'search-licenses',
    },
    LICENSES_DETAILS: {
      PATH: 'licenses-details/:id',
    },
    DATA_DICTIONARY: {
      PATH: 'data-dictionary',
    },
    FAQ: {
      PATH: 'faq',
    },
    MY_PROFILE: {
      PATH: 'my-profile',
    },
    MAP: {
      PATH: 'map',
    },
    ADMIN_LICENSES: {
      PATH: 'licenses',
    },
    ADMIN_ADD_NEW_LICENSES: {
      PATH: 'licenses/create',
    },
    USERS: {
      PATH: 'users',
    },
    EDIT_USERS: {
      PATH: 'users/:crudType/:userId',
    },
    ADD_USER: {
      PATH: 'users/:crudType/',
    },
    CSV_BULK_UPLOAD_FAILURE: {
      PATH: 'licenses/bulk-upload-failure-report/download/:importName/:filename',
    },
    SETTINGS: {
      PATH: 'settings',
    },
    LICENSES_UPDATE_LOGS: {
      PATH: 'reports/license-update-logs',
    },
    USER_LOGINS_REPORT: {
      PATH: 'reports/user-logins-report',
    },
    WEEKLY_LICENSE_REPORT: {
      PATH: 'reports/weekly-license-report',
    },
    SOURCES: {
      PATH: 'sources',
    },
    DATA_VALIDATION: {
      PATH: 'data-validation',
    },
    EXECUTION_DETAILS: {
      PATH: 'data-validation/:uploadId/:executionId',
    },
  },
});
