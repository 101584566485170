import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import * as yup from 'yup';

import { Formik } from 'formik';
import { isNil } from 'lodash';
import AppButton from '../styles/ButtonLink.styled';
import AppInput from '../common/input/AppInput';
import MyProfileHelper from '../../helpers/features/MyProfileHelper';
import { useMyProfile } from '../../containers/myProfile/MyProfileContext';
import ErrorNotification from '../common/errorNotification/ErrorNotification';

function MyProfileForm() {
  const userProfile = useMyProfile();

  const profileSchema = yup.object().shape({
    first_name: yup
      .string()
      .test('first_name', 'First name cannot contain spaces', value => {
        if (isNil(value)) {
          return true;
        }
        return !value.includes(' ');
      })
      .required('First name is required'),
    last_name: yup
      .string()
      .test('last_name', 'Last name cannot contain spaces', value => {
        if (isNil(value)) {
          return true;
        }
        return !value.includes(' ');
      })
      .required('Last name is required'),
    company: yup
      .string()
      .test('company', 'Company name cannot contain spaces in the end', value => {
        if (isNil(value)) {
          return true;
        }
        const str = value.split(' ');
        if (str[str.length - 1] === '') {
          return false;
        }
        if (str[0] === '') {
          return false;
        }
        return true;
      })
      .required('Company is required'),
  });

  const handleOnChange = () => {
    userProfile.handleSubmitBtn(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        id: userProfile.userDetails.id || '',
        email: userProfile.userDetails.email || '',
        first_name: userProfile.userDetails.first_name || '',
        last_name: userProfile.userDetails.last_name || '',
        company: userProfile.userDetails.company || '',
      }}
      onSubmit={async values => {
        userProfile.handleUpdateUserDetails(values, values.id);
      }}
      validationSchema={profileSchema}
    >
      {({ values, errors, handleSubmit, handleChange, handleBlur, isValid, dirty }) => (
        <Form onSubmit={handleSubmit} onChange={handleOnChange} noValidate>
          <Row>
            <Col md={6}>
              <AppInput
                label={MyProfileHelper.MY_PROFILE_FORM_FIRST_NAME_LABLE}
                requied="*"
                type="text"
                placeholder={MyProfileHelper.MY_PROFILE_FORM_FIRST_NAME_PLACEHOLDER}
                helpText=""
                helpTextLink=""
                linkUrl=""
                name="first_name"
                onChangeInput={handleChange}
                onBlurInput={handleBlur}
                value={values.first_name}
                errorText={errors.first_name ? errors.first_name : null}
              />
              <AppInput
                label={MyProfileHelper.MY_PROFILE_FORM_LAST_NAME_LABLE}
                requied="*"
                type="text"
                placeholder={MyProfileHelper.MY_PROFILE_FORM_LAST_NAME_PLACEHOLDER}
                helpText=""
                helpTextLink=""
                linkUrl=""
                name="last_name"
                onChangeInput={handleChange}
                onBlurInput={handleBlur}
                value={values.last_name}
                errorText={errors.last_name ? errors.last_name : null}
              />
              <AppInput
                label={MyProfileHelper.MY_PROFILE_FORM_COMPANY_LABLE}
                requied="*"
                type="text"
                placeholder={MyProfileHelper.MY_PROFILE_FORM_COMPANY_PLACEHOLDER}
                helpText=""
                helpTextLink=""
                linkUrl=""
                name="company"
                onChangeInput={handleChange}
                onBlurInput={handleBlur}
                value={values.company}
                errorText={errors.company ? errors.company : null}
              />

              <AppInput
                className="m-0"
                label={MyProfileHelper.MY_PROFILE_FORM_EMAIL_LABLE}
                requied="*"
                type="email"
                placeholder={MyProfileHelper.MY_PROFILE_FORM_EMAIL_PLACEHOLDER}
                helpText={MyProfileHelper.MY_PROFILE_FORM_EMAIL_HELP_TEXT}
                helpTextLink={MyProfileHelper.MY_PROFILE_FORM_EMAIL_HELP_TEXT_LINK}
                linkUrl={`mailto:${MyProfileHelper.MY_PROFILE_FORM_EMAIL_HELP_TEXT_LINK}`}
                name="email"
                onChangeInput={handleChange}
                onBlurInput={handleBlur}
                value={values.email}
                errorText={errors.email ? errors.email : null}
                disabled
              />
              <AppButton disabled={!(dirty && isValid) || userProfile.isFormSubmit}>
                {MyProfileHelper.MY_PROFILE_BTN_TEXT}
              </AppButton>
            </Col>
          </Row>
          {userProfile.message.type === 'error' && (
            <ErrorNotification errorMessage={userProfile.message.message} />
          )}
        </Form>
      )}
    </Formik>
  );
}

export default MyProfileForm;
