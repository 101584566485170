/* eslint-disable react/forbid-prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Image, Form } from 'react-bootstrap';
import noop from 'lodash/noop';

import StyledUpload from './Upload.styled';

import UploadIcon from '../../../assets/images/upload.svg';
import ToolTip from '../toolTips/ToolTip';

const Dropzone = props => {
  const {
    label,
    className,
    required,
    toolTipIcon,
    toolTipContent,
    onDrop,
    maxFiles,
    multiple,
    config,
  } = props;
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...config,
    onDrop,
    maxFiles,
    multiple,
  });

  return (
    <StyledUpload>
      <Form.Label>
        {label}
        <span className="red"> {required}</span>
        <ToolTip icon={toolTipIcon} toolTipContent={toolTipContent} />
      </Form.Label>
      <div {...getRootProps({ className: 'dropzone' })} className={className}>
        <input className="input-zone" {...getInputProps()} />
        <div className="upload-drag-container text-center">
          <Image src={UploadIcon} className="mb-2" />
          {isDragActive ? (
            <p className="dropzone-content m-0">Release files here</p>
          ) : (
            <p className="dropzone-content m-0">Drag and drop or browse files to import</p>
          )}
        </div>
      </div>
    </StyledUpload>
  );
};

Dropzone.propTypes = {
  label: PropTypes.string,
  toolTipIcon: PropTypes.string,
  required: PropTypes.string,
  className: PropTypes.string,
  open: PropTypes.func,
  onDrop: PropTypes.func,
  maxFiles: PropTypes.number,
  multiple: PropTypes.bool,
  config: PropTypes.object,
};

Dropzone.defaultProps = {
  label: '',
  toolTipIcon: '',
  required: '',
  className: '',
  open: noop,
  onDrop: noop,
  maxFiles: 0,
  multiple: true,
  config: {},
};
export default Dropzone;
