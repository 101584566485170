import styled from 'styled-components';

const AppButton = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => (props.fill ? props.theme?.general?.secondaryColor : 'white')};
  color: ${props => (props.fill ? 'white' : props.theme?.general?.secondaryColor)};
  &:hover,
  &:focus,
  &:active {
    background: ${props => (props.fill ? props.theme?.general?.secondaryColor : 'white')};
    color: ${props => (props.fill ? 'white' : props.theme?.general?.secondaryColor)};
    opacity: 0.75;
  }
  &:disabled {
    opacity: ${props => (props.fill ? '0.5' : '0.5')};
    color: #999999 ;
    background: #EAEAEA;
    border-color: #EAEAEA;
    cursor: not-allowed;
    &:hover {
      opacity: ${props => (props.fill ? '0.5' : '0.5')};
      color: #999999 ;
    }
  }
  border: 1px solid ${props => props.theme?.general?.secondaryColor};
  height: fit-content;
  padding: 13px 25px;
  font-size: 15px;
  font-weight: 900;
  border-radius: 8px;
  margin: 0 0 25px 0;
  transition: 0.2s cubic-bezier(0.39, 0.58, 0.57, 1);
  &.secondary-btn{
    background: #EAF7F6;
    border-color: #EAF7F6;
    color:  ${props => props.theme?.general?.primaryColor};
    &:hover{
      background: #e0f0ef;
    }
    &:disabled {
      &:hover{
        background: #EAF7F6;
        color:  ${props => props.theme?.general?.primaryColor};
      }
  }
  &.back-btn{
    background: black;
    border-color: black;
    color: white;
    &:disabled {
      opacity: 0.25;
      &:hover {
        background: black;
        opacity: 0.25;
      }
    }
    &:hover {
      background: #313131;
      color: white;
    }
  }
`;

export default AppButton;
